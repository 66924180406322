const defaultValues = {
    display_disclaimer: false,
    display_disclaimer_message: '',
    navigation_to_triage_enabled: false,
    search_question_suggestion_enabled: true,
    send_email_notifications_for_document_import_status: false,
    documents_import_status_email: '',
    enable_edit_responses_by_experts: false,
    display_keywords_disclaimer: false
};

class ScopSettings {
    displayGeneralDisclaimer: boolean;
    generalDisclaimerMessage: string;
    navigationToTriageEnabled: boolean;
    showInvestigateButtonBasedOnObservations: boolean;
    searchQuestionSuggestionEnabled: boolean;
    sendEmailNotificationsForDocumentImportStatus: boolean;
    documentsImportStatusEmail: string;
    enableEditResponsesByExperts: boolean;
    displayKeywordDisclaimers: boolean;

    constructor(settings = defaultValues, packageVersion = 0) {
        const getValue = this.getSettingValueFunc(settings);
        this.displayGeneralDisclaimer = getValue('display_disclaimer');
        this.generalDisclaimerMessage = getValue('display_disclaimer_message');
        this.navigationToTriageEnabled = getValue(
            'navigation_to_triage_enabled'
        );
        this.showInvestigateButtonBasedOnObservations = getValue(
            'show_investigate_button_based_on_observations'
        );
        this.searchQuestionSuggestionEnabled =
            getValue('search_question_suggestion_enabled') &&
            packageVersion > 107;
        this.sendEmailNotificationsForDocumentImportStatus = getValue(
            'send_email_notifications_for_document_import_status'
        );
        this.documentsImportStatusEmail = getValue(
            'documents_import_status_email'
        );
        this.enableEditResponsesByExperts = getValue(
            'enable_edit_responses_by_experts'
        );
        this.displayKeywordDisclaimers = getValue(
            'display_keywords_disclaimer'
        );
    }

    private getSettingValueFunc(settings) {
        return (valueName: string) =>
            settings?.[valueName] ?? defaultValues[valueName];
    }
}

export default ScopSettings;
