export const combineRefs =
    (...refs) =>
    (val) => {
        for (let ref of refs) {
            if (typeof ref === 'function') {
                ref(val);
            } else if (ref) {
                ref.current = val;
            }
        }
    };

export const getUrlParams = () => {
    const urlParams = {};
    let params = new URL(document.location).searchParams;
    urlParams.model = params.get('Model');
    urlParams.productType = params.get('ProductType');
    urlParams.manufacturer = params.get('Manufacturer');
    return urlParams;
};
