import React, { FunctionComponent, useMemo, useState } from 'react';
import { Typography } from 'aqui';
import SearchSelect from '../../../SearchSelect/SearchSelect';
import { t } from 'i18next';
import AddUsersBox from '../AddUsersBox';
import { usePersonasManagementRows } from '../PersonaManagementHooks';

type AddUsersModalBodyProps = {
    users: string;
    setUsers: (users: string) => void;
    selectedPersona: string;
    setSelectedPersona: (persona: string) => void;
    personaSearchText: string;
    setPersonaSearchText: (text: string) => void;
};

const AddUsersModalBody: FunctionComponent<AddUsersModalBodyProps> = ({
    users,
    setUsers,
    selectedPersona,
    setSelectedPersona,
    personaSearchText,
    setPersonaSearchText
}) => {
    const { personasNames } = usePersonasManagementRows();
    const [isOpenPersonaSelect, setIsOpenPersonaSelect] =
        useState<boolean>(false);

    const filteredValues = useMemo(() => {
        return personasNames.filter((row) =>
            row.toLowerCase().includes(personaSearchText)
        );
    }, [personaSearchText, personasNames]);

    const onValueSelect = (value: string) => {
        setSelectedPersona(value);
        setPersonaSearchText(value);
    };

    return (
        <div className="flex flex-col addUsersModal__container">
            <div className="flex flex-col addUsersModal__persona-select">
                <Typography type="subtitle3">
                    {t('add-persona.persona')}
                </Typography>
                <SearchSelect
                    values={filteredValues}
                    setValue={onValueSelect}
                    value={selectedPersona}
                    classes={{
                        popover: 'addUsersModal__personas-popover'
                    }}
                    leftIcon={null}
                    disabled={false}
                    isOpen={isOpenPersonaSelect}
                    setIsOpen={setIsOpenPersonaSelect}
                    dataTestId="add-users-select-persona"
                    searchPlaceholder={t('add-persona.select-persona')}
                    searchText={personaSearchText}
                    setSearchText={setPersonaSearchText}
                    processing={false}
                    onClearButtonClick={() => setSelectedPersona('')}
                    noValuesMessage={t('add-persona.no-persona-found')}
                />
            </div>
            <div className="flex flex-col addUsersModal__users-select">
                <AddUsersBox
                    dataTestId="add-users-text-area"
                    value={users}
                    setValue={setUsers}
                    className={'addUsersModal__textarea'}
                />
            </div>
        </div>
    );
};

export default AddUsersModalBody;
