const LockRoundSmallIcon = ({ color = 'currentColor', ...props }) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect width="16" height="16" rx="8" fill={color} />
            <path
                d="M10.764 7.02344H5.283C4.85056 7.02344 4.5 7.374 4.5 7.80644V10.5469C4.5 10.9794 4.85056 11.3299 5.283 11.3299H10.764C11.1964 11.3299 11.547 10.9794 11.547 10.5469V7.80644C11.547 7.374 11.1964 7.02344 10.764 7.02344Z"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.06567 7.0235V5.4575C6.06567 4.93834 6.27191 4.44044 6.63901 4.07334C7.00611 3.70624 7.50401 3.5 8.02317 3.5C8.54233 3.5 9.04023 3.70624 9.40733 4.07334C9.77444 4.44044 9.98067 4.93834 9.98067 5.4575V7.0235"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default LockRoundSmallIcon;
