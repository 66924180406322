

const ChatBubble = () => {
    return <div className="typing">
    <div className="dot"></div>
    <div className="dot"></div>
    <div className="dot"></div>
</div>
}

export default ChatBubble