import { Typography } from 'aqui';
import { FC } from 'react';
import classNames from 'classnames';
import ChevronIconSmall from '../../SVG/ChevronIconSmall';

interface TextButtonProps {
    IconComponent?: React.ElementType;
    disabled?: boolean;
    processing?: boolean;
    text?: string;
    iconPosition?: 'left' | 'right';
    chevronPosition?: 'left' | 'right';
    color?: 'grey' | 'blue';
    dataTestId?: string;
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    className?: string;
    isActive?: boolean;
    withChevron?: boolean;
    typographyType?: string;
}

const TextButton: FC<TextButtonProps> = ({
    IconComponent = () => null,
    disabled = false,
    processing = false,
    text = '',
    iconPosition = 'left',
    chevronPosition = 'right',
    color = 'grey',
    dataTestId = 'common-text-button',
    onClick = () => null,
    className = '',
    isActive = false,
    withChevron = false,
    typographyType = 'small1'
}) => {
    const handleOnClick = (e) => {
        if (disabled || processing) {
            return;
        }
        onClick(e);
    };

    return (
        <div
            data-testid={dataTestId}
            data-disabled={disabled}
            className={classNames('text-button', className, {
                '--processing': processing,
                '--disabled': disabled,
                '--reversed': chevronPosition === 'left',
                '--active': isActive,
                '--blue-color': color === 'blue',
                '--with-chevron': withChevron
            })}
            onClick={handleOnClick}
        >
            <div
                className={classNames('text-icon-container', {
                    '--reversed': iconPosition === 'right'
                })}
            >
                {<IconComponent />}
                <Typography type={typographyType}>{text}</Typography>
            </div>
            {withChevron && (
                <ChevronIconSmall className="text-button__chevron" />
            )}
        </div>
    );
};

export default TextButton;
