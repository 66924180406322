const RefreshIcon = () => {
    return (
        <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.49812 0.500007L5.5 0.5C6.82608 0.500004 8.09785 1.02679 9.03553 1.96447C9.97322 2.90215 10.5 4.17392 10.5 5.5C10.5 5.77615 10.2761 6 10 6C9.72386 6 9.5 5.77615 9.5 5.5C9.5 4.43914 9.07857 3.42172 8.32843 2.67158C7.5785 1.92165 6.56146 1.50025 5.50094 1.5C4.37365 1.50449 3.29165 1.94393 2.48048 2.72663L1.35355 3.85356C1.15829 4.04882 0.841709 4.04882 0.646447 3.85356C0.451184 3.65829 0.451184 3.34171 0.646447 3.14645L1.78243 2.01047C2.77968 1.0464 4.11107 0.505225 5.49812 0.500007Z"
                fill="currentcolor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 0.5C1.27614 0.5 1.5 0.723858 1.5 1V3H3.5C3.77614 3 4 3.22386 4 3.5C4 3.77614 3.77614 4 3.5 4H1C0.723858 4 0.5 3.77614 0.5 3.5V1C0.5 0.723858 0.723858 0.5 1 0.5Z"
                fill="currentcolor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 5C1.27614 5 1.5 5.22386 1.5 5.5C1.5 6.56087 1.92143 7.57828 2.67157 8.32843C3.42149 9.07834 4.4385 9.49974 5.49901 9.5C6.62632 9.49553 7.70834 9.05609 8.51952 8.27337L9.64645 7.14645C9.84171 6.95118 10.1583 6.95118 10.3536 7.14645C10.5488 7.34171 10.5488 7.65829 10.3536 7.85355L9.21757 8.98954C8.22032 9.9536 6.88893 10.4948 5.50188 10.5L5.5 10.5C4.17392 10.5 2.90215 9.97322 1.96447 9.03553C1.02678 8.09785 0.5 6.82608 0.5 5.5C0.5 5.22386 0.723858 5 1 5Z"
                fill="currentcolor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.00024 7.5C7.00024 7.22386 7.2241 7 7.50024 7H10.0002C10.2764 7 10.5002 7.22386 10.5002 7.5V10C10.5002 10.2761 10.2764 10.5 10.0002 10.5C9.7241 10.5 9.50024 10.2761 9.50024 10V8H7.50024C7.2241 8 7.00024 7.77614 7.00024 7.5Z"
                fill="currentcolor"
            />
        </svg>
    );
};

export default RefreshIcon;
