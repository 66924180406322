import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Typography,
    Bold
} from 'aqui';
import { WarningIcon, ChevronIcon } from 'aqui/icons';
import { useNotValidEmails } from '../PersonaManagementHooks';
import { t } from 'i18next';
import { useState } from 'react';

const EmailValidationModal = () => {
    const [showMore, setShowMore] = useState(false);
    const { notValidEmails, setNotValidEmails } = useNotValidEmails();

    const onClickOk = () => {
        setShowMore(false);
        setNotValidEmails({});
    };

    return (
        <Modal
            isOpen={Boolean(Object.keys(notValidEmails).length)}
            closeModal={() => setNotValidEmails({})}
        >
            <ModalHeader>{t('emails-validation.incorrect-format')}</ModalHeader>
            <ModalBody className="emailValidationModal__body">
                <div className="flex flex-col emailValidationModal__container">
                    <div className="flex align-center emailValidationModal__title">
                        <WarningIcon className="emailValidationModal__title__icon" />
                        <Typography
                            type="body3"
                            className="emailValidationModal__title__text"
                        >
                            <Bold>
                                {`${
                                    Object.values(notValidEmails)[0]?.length
                                } ${t(
                                    'add-persona.user-emails'
                                ).toLowerCase()} `}
                            </Bold>
                            {t('emails-validation.not-assigned')}
                            <Bold>{` ${Object.keys(notValidEmails)[0]} `}</Bold>
                            {t('emails-validation.not-assigned-2')}
                        </Typography>
                    </div>
                    <div
                        className={`emailValidationModal__hidden-data ${
                            showMore ? 'show' : ''
                        }`}
                    >
                        <div className="flex flex-col emailValidationModal__emails">
                            {Object.values(notValidEmails)[0]?.map((email) => (
                                <Typography type="body3">{email}</Typography>
                            ))}
                        </div>
                        <div className="flex flex-col emailValidationModal__example">
                            <Typography type="body3">
                                {t('emails-validation.instructions')}
                            </Typography>
                            <Typography type="body3">
                                <Bold>{t('emails-validation.format')}</Bold>
                            </Typography>
                        </div>
                    </div>
                    <div
                        className="flex align-center emailValidationModal__show-more"
                        onClick={() => setShowMore(!showMore)}
                    >
                        <Typography type="small1">
                            {t('common.more-details')}
                        </Typography>
                        <ChevronIcon
                            direction="down"
                            className={`emailValidationModal__show-more__icon ${
                                showMore ? 'open' : ''
                            }`}
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                    dataTestId="ok-btn-email-validation-modal"
                    color="blue"
                    onClick={onClickOk}
                    text={t('common.ok')}
                    variant="filled"
                />
            </ModalFooter>
        </Modal>
    );
};

export default EmailValidationModal;
