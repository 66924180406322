const OctagonWarning = ({ color = 'currentcolor', ...props }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.83445 4.14645C7.92822 4.05268 8.05539 4 8.188 4H14.812C14.9446 4 15.0718 4.05268 15.1656 4.14645L19.8536 8.83445C19.9473 8.92822 20 9.05539 20 9.188V15.812C20 15.9446 19.9473 16.0718 19.8536 16.1656L15.1656 20.8536C15.0718 20.9473 14.9446 21 14.812 21H8.188C8.05539 21 7.92822 20.9473 7.83445 20.8536L3.14645 16.1656C3.05268 16.0718 3 15.9446 3 15.812V9.188C3 9.05539 3.05268 8.92822 3.14645 8.83445L7.83445 4.14645ZM8.39511 5L4 9.39511V15.6049L8.39511 20H14.6049L19 15.6049V9.39511L14.6049 5H8.39511ZM11.5 8C11.7761 8 12 8.22386 12 8.5V12.7C12 12.9761 11.7761 13.2 11.5 13.2C11.2239 13.2 11 12.9761 11 12.7V8.5C11 8.22386 11.2239 8 11.5 8ZM12 15.5C12 15.2239 11.7761 15 11.5 15C11.2239 15 11 15.2239 11 15.5V16.5C11 16.7761 11.2239 17 11.5 17C11.7761 17 12 16.7761 12 16.5V15.5Z"
                fill={color}
            />
        </svg>
    );
};

export default OctagonWarning;
