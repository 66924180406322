import { GridSortModel } from '@mui/x-data-grid-pro';
import { SORT_TYPES } from '../../Table/consts';

export const MAX_NUMBER_OF_ROWS_AT_CALL = 10000;

export const COLUMNS = {
    CHECKBOX: 'checkbox',
    FILE_NAME: 'document_name',
    MODEL: 'tenant_model',
    CREATED_AT: 'created_at',
    CREATED_BY: 'created_by',
    INGEST_STATUS: 'status',
    ACTIONS: 'actions',
    PERSONAS: 'personas',
    MODEL_DISPLAY: 'modelDisplay'
};

export const INGESTED_SOURCES_PINNED_COLUMNS = {
    left: [COLUMNS.CHECKBOX, COLUMNS.FILE_NAME]
};

export const INGESTED_SOURCES_DEFAULT_SORT_MODEL: GridSortModel = [
    {
        field: COLUMNS.CREATED_AT,
        sort: SORT_TYPES.DESC
    }
];

export const IMPORT_COLUMNS = ['FILE NAME', 'MODEL', 'PERSONAS'];
export const UNKNOWN_USER = 'Unknown';
export const AQUANT_USER = 'Service Co-Pilot';
export const UPDATING_ALL = 'UPDATING_ALL';
