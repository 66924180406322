import { FunctionComponent } from 'react';

import { Fade } from '@material-ui/core';
import { SourceFile } from '../../types';
import { useSourceFiles, useUploadFiles } from './UploadFilesModalHooks';
import UploadFileFileRowStatus from './UploadFileFileRowStatus';
import UploadFileFileRowName from './UploadFileFileRowName';

type UploadFilesModalFileRowProps = { sourceFile: SourceFile };

const UploadFilesModalFileRow: FunctionComponent<
    UploadFilesModalFileRowProps
> = ({ sourceFile }) => {
    const { isUploadFiled, isDeleting, percentLoaded, isUploadStarted } =
        sourceFile;
    const { deleteUploadedFile } = useUploadFiles();

    const isDeleteDisabled =
        isDeleting || (isUploadStarted && !percentLoaded && !isUploadFiled);

    const onDeleteFile = () => {
        if (!isDeleteDisabled) {
            deleteUploadedFile(sourceFile);
        }
    };

    return (
        <Fade in timeout={500}>
            <div className="upload-files__file">
                <UploadFileFileRowName
                    sourceFile={sourceFile}
                    onDelete={onDeleteFile}
                    isDeleteDisabled={isDeleteDisabled}
                />
                <UploadFileFileRowStatus
                    sourceFile={sourceFile}
                    onDelete={onDeleteFile}
                />
            </div>
        </Fade>
    );
};

export default UploadFilesModalFileRow;
