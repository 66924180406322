import { t } from 'i18next';
import { SourceDocument } from '../../types';
import { getFileNameAndType } from '../../utils/fileUtils';

const filterDuplicates = (results: SourceDocument[]): SourceDocument[] => {
    const uniquePagesMap = {};
    results.forEach((result) => {
        const key = result.metadata.filename + getPageIndication(result);
        if (!uniquePagesMap[key]) {
            uniquePagesMap[key] = result;
        }
    });
    return Object.values(uniquePagesMap);
};

export const aggregateResults = (
    results: Array<SourceDocument>
): SourceDocument[][] => {
    // Filter all duplicate pages for every file
    const filteredFromDuplicates = filterDuplicates(results);
    const aggregatedResults: SourceDocument[][] = [];

    // Aggregate results by file name
    filteredFromDuplicates.forEach((result) => {
        const lastGroup = aggregatedResults[aggregatedResults.length - 1];
        const isLastGroupHasSameFileName =
            lastGroup?.[0].metadata.filename === result.metadata.filename;

        if (isLastGroupHasSameFileName) {
            lastGroup.push(result);
        } else {
            aggregatedResults.push([result]);
        }
    });

    return aggregatedResults;
};

export const getAggregatedResultDetails = (
    results: SourceDocument[]
): { fileType: string; fileName: string; mediaType: string | undefined } => {
    const [fileType, fileName] = getFileNameAndType(
        results[0].metadata.filename
    );
    return {
        fileType,
        fileName,
        mediaType: results[0].metadata.media_type
    };
};

export const getNumOfUniqueFiles = (
    results: Array<SourceDocument> = []
): number => {
    const uniqueFilenames = new Set<string>();
    results.forEach((result) =>
        uniqueFilenames.add(result?.metadata?.filename)
    );
    return uniqueFilenames.size;
};

export const getTimeStringFromSeconds = (seconds: number) =>
    new Date(seconds * 1000).toISOString().slice(seconds >= 3600 ? 11 : 14, 19);

export const formatSourceNumbersMessage = (inputString: string) => {
    const pattern = /\s*SOURCE_\d+\s*/g;
    return inputString.replace(pattern, '');
};

export const getPageIndication = (
    source: SourceDocument,
    withAbbreviation: boolean = true
) => {
    const { page, start, media_type, max_page } = source.metadata;
    const pageAbbr = withAbbreviation ? t('common.page-short') : '';
    if (media_type === 'video') {
        return getTimeStringFromSeconds(start);
    }
    if (max_page && max_page !== page) {
        return `${pageAbbr}${page}-${max_page}`;
    }
    return `${pageAbbr}${page}`;
};
