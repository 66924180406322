import ThreadsPanelHeader from './ThreadsPanelHeader';
import ThreadsPanelFooter from './ThreadsPanelFooter';
import ThreadsPanelBody from './ThreadsPanelBody';
import { useThreadsPanel } from '../../AppContext';

const ThreadsPanel = () => {
    const { isThreadsPanelOpen, wasOpenedOnce } = useThreadsPanel();

    const animationClass = isThreadsPanelOpen
        ? 'show-panel'
        : wasOpenedOnce
        ? 'hide-panel'
        : '';

    return (
        <div
            className={`flex flex-col space-between threadsPanel__container ${animationClass}`}
        >
            <div className="threadsPanel__upper-part">
                <ThreadsPanelHeader />
                <ThreadsPanelBody />
            </div>
            <ThreadsPanelFooter />
        </div>
    );
};

export default ThreadsPanel;
