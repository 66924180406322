const SendIcon = ({ withGradient, ...props }) => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.55865 3.19216C3.78478 2.98376 4.11521 2.93451 4.39228 3.06791L29.8481 15.3242C30.1077 15.4492 30.2728 15.7118 30.2728 15.9999C30.2728 16.288 30.1077 16.5507 29.8481 16.6757L4.39229 28.9324C4.11522 29.0659 3.78479 29.0166 3.55866 28.8082C3.33253 28.5998 3.25652 28.2745 3.36691 27.9875L7.9774 15.9999L3.36692 4.01291C3.25652 3.72589 3.33252 3.40057 3.55865 3.19216ZM9.29606 16.7499L5.44602 26.7603L26.2363 16.7499H9.29606ZM26.2362 15.2499H9.29605L5.44604 5.24008L26.2362 15.2499Z"
                fill={
                    withGradient
                        ? 'url(#paint0_linear_77_38524)'
                        : 'currentColor'
                }
            />
            <defs>
                <linearGradient
                    id="paint0_linear_77_38524"
                    x1="26.4219"
                    y1="6.7098"
                    x2="4.13914"
                    y2="29.8002"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2468FF" />
                    <stop offset="1" stopColor="#24A4FF" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default SendIcon;
