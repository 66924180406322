import React from 'react';

import SparklesMedium from "../../SVG/SparklesMedium";
import ValueWithTooltip from '../ValueWithTooltip/ValueWithTooltip';
import { Tooltip } from '@mui/material';
import { PencilEditIcon } from 'aqui/icons';


const CopilotQuestionsList = React.memo(({ copilotQuestions, onClick, onPencilClick }) => {
    return (
        <div data-testid="highlight-suggestions-question-list">
            {copilotQuestions.map((question, index) => {
                return (
                    <div
                        key={index}
                        className="flex space-between copilot-popper__question"
                        onClick={() => onClick(question)}
                        data-testid="highlight-suggestions-question"
                    >
                        <div className="flex align-center">
                            <SparklesMedium />
                            <ValueWithTooltip
                                className="copilot-popper__question-text"
                                title={question}
                                type="body2"
                            />
                        </div>
                        <Tooltip
                            title={'New! Edit question before sending'}
                            placement="top"
                            classes={{
                                tooltip:
                                    'tooltip tooltip__edit-suggestion-question'
                            }}
                        >
                            <div className="flex justify-flex-end copilot-popper__editIcon">
                                <PencilEditIcon
                                    onClick={(e) => onPencilClick(e, question)}
                                    dataTestId="highlight-edit-question-button"
                                />
                            </div>
                        </Tooltip>
                    </div>
                );
            })}
        </div>
    );
});

export default CopilotQuestionsList;