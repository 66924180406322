import {
    Dispatch,
    FunctionComponent,
    SetStateAction,
    useEffect,
    useMemo,
    useRef
} from 'react';
import { getIsNextPageDisabled, getIsPrevPageDisabled } from '../helpers';
import { MAX_SCALE, MIN_SCALE, SCALES } from '../consts';
import DocNavigationPagination from './PaginationController';
import ScaleController from './ScaleController';
import RotateController from './RotateController';
import { useSmallChatInPortal } from '../../../context/ChatContext';
import useSwitchPanel from '../../../hooks/useSwitchPanel';

interface DocumentControlPanelProps {
    pageNum: number;
    setPageNum: Dispatch<SetStateAction<number>>;
    numPages: number;
    scale: number;
    setScale: Dispatch<SetStateAction<number>>;
    pagesInViewport: number[];
    onRotate: Function;
}

const DocumentControlPanel: FunctionComponent<DocumentControlPanelProps> = ({
    pageNum,
    setPageNum,
    numPages,
    scale,
    setScale,
    pagesInViewport,
    onRotate
}) => {
    const increaseScaleDisabled = scale >= MAX_SCALE;
    const decreaseScaleDisabled = scale <= MIN_SCALE;
    const pageNumRef = useRef(pageNum);
    const pagesInViewportRef = useRef(pagesInViewport);
    const isPrevPageDisabled = useMemo(
        () => getIsPrevPageDisabled(pageNum),
        [pageNum]
    );
    const { isSmallChatInPortalCollapsed } = useSmallChatInPortal();
    const { isDataManagementPanel } = useSwitchPanel();
    const isNextPageDisabled = useMemo(
        () => getIsNextPageDisabled(pagesInViewport, pageNum, numPages),
        [pagesInViewport, pageNum, numPages]
    );
    const withChat = !isSmallChatInPortalCollapsed && !isDataManagementPanel;

    const onRotateCW = () => onRotate(1);
    const onRotateCCW = () => onRotate(-1);

    useEffect(() => {
        pageNumRef.current = pageNum;
        pagesInViewportRef.current = pagesInViewport;
    }, [pageNum, pagesInViewport]);

    useEffect(() => {
        if (numPages > 1) {
            document.addEventListener('keydown', handleArrowClick);
        }
        return () => document.removeEventListener('keydown', handleArrowClick);
    }, [numPages]);

    const navToPrevPage = () => {
        if (getIsPrevPageDisabled(pageNumRef.current)) {
            return;
        }
        setPageNum(pageNumRef.current - 1);
    };

    const navToNextPage = () => {
        if (
            getIsNextPageDisabled(
                pagesInViewportRef.current,
                pageNumRef.current,
                numPages
            )
        ) {
            return;
        }
        const pageNum = pageNumRef.current;
        const pagesInViewport = pagesInViewportRef.current;
        const nextPage = pagesInViewport.length
            ? pagesInViewport[pagesInViewport.length - 1] + 1
            : pageNum + 1;
        setPageNum(nextPage);
    };

    const increaseScale = () => {
        if (increaseScaleDisabled) {
            return;
        }
        const currentScaleIndex = SCALES.indexOf(scale);
        setScale(SCALES[currentScaleIndex + 1]);
    };

    const decreaseScale = () => {
        if (decreaseScaleDisabled) {
            return;
        }
        const currentScaleIndex = SCALES.indexOf(scale);
        setScale(SCALES[currentScaleIndex - 1]);
    };

    const handleArrowClick = (event: KeyboardEvent) => {
        if (event.key === 'ArrowLeft') {
            event.preventDefault();
            navToPrevPage();
        } else if (event.key === 'ArrowRight') {
            event.preventDefault();
            navToNextPage();
        }
    };

    return (
        <div className={`doc-control-panel ${withChat ? '--with-chat' : ''}`}>
            <DocNavigationPagination
                pageNum={pageNum}
                numPages={numPages}
                isNextPageDisabled={isNextPageDisabled}
                isPrevPageDisabled={isPrevPageDisabled}
                navToPrevPage={navToPrevPage}
                navToNextPage={navToNextPage}
            />
            <ScaleController
                decreaseScale={decreaseScale}
                increaseScale={increaseScale}
                decreaseScaleDisabled={decreaseScaleDisabled}
                increaseScaleDisabled={increaseScaleDisabled}
                scale={scale}
            />
            <RotateController
                onRotateCCW={onRotateCCW}
                onRotateCW={onRotateCW}
            />
        </div>
    );
};

export default DocumentControlPanel;
