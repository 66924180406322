import React, {
    useState,
    createContext,
    useContext,
    ReactNode,
    useMemo,
    useRef,
    useEffect
} from 'react';
import {
    DataManagementTab,
    editedResponseVersion,
    ExpertResponsesFilters,
    ExpertResponsesRow,
    FileValidation,
    IngestedSourcesFilters,
    IngestedSourcesRow,
    IntegratedModel,
    MultiSelectSearchValuesProps,
    PersonasManagementFilters,
    PersonasManagementRow,
    SourceFile,
    SourcesByPersona
} from '../types';
import { Dispatch, SetStateAction, MutableRefObject, FC } from 'react';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { INGESTED_SOURCES_DEFAULT_SORT_MODEL } from '../components/DataManagementPanel/IngestedSources/consts';
import { extractFilterValuesFromIngestedRows } from '../components/DataManagementPanel/IngestedSources/IngestedSourcesHelpers';
import { PERSONA_CONSTANT_NAMES } from '../components/DataManagementPanel/PersonasManagement/consts';
import Backend from '../backend/Backend';
import { EXPERT_RESPONSES_DEFAULT_SORT_MODEL } from '../components/DataManagementPanel/ExpertResponses/consts';

export interface DataManagementContextValue {
    resetDataManagementContext: () => void;
    resetUploadModalState: () => void;
    isUploadFilesModalOpen: boolean;
    setIsUploadFilesModalOpen: Dispatch<SetStateAction<boolean>>;
    sourceFiles: SourceFile[];
    setSourceFiles: Dispatch<SetStateAction<SourceFile[]>>;
    sourceFilesToValidate: SourceFile[];
    setSourceFilesToValidate: Dispatch<SetStateAction<SourceFile[]>>;
    uploadModel: IntegratedModel;
    setUploadModel: Dispatch<SetStateAction<IntegratedModel>>;
    sourceFilesRef: MutableRefObject<SourceFile[]>;
    validSourceFiles: SourceFile[];
    isIngestRequestInProgress: boolean;
    setIsIngestRequestInProgress: Dispatch<SetStateAction<boolean>>;
    uploadModelsList: IntegratedModel[];
    setUploadModelsList: Dispatch<SetStateAction<IntegratedModel[]>>;
    isCatalogModelsFetched: boolean;
    setIsCatalogModelsFetched: Dispatch<SetStateAction<boolean>>;
    currentModelUploadedFiles: Set<string>;
    currentTab: DataManagementTab;
    setCurrentTab: Dispatch<SetStateAction<DataManagementTab>>;
    ingestedSourcesFilters: IngestedSourcesFilters;
    setIngestedSourcesFilters: Dispatch<SetStateAction<IngestedSourcesFilters>>;
    ingestedSourcesUsers: string[];
    ingestedSourcesPersonas: string[];
    selectedIngestedSourceRows: IngestedSourcesRow[];
    setSelectedIngestedSourceRows: Dispatch<
        SetStateAction<IngestedSourcesRow[]>
    >;
    selectedIngestedSourceRowIdsSet: Set<string>;
    ingestedSourceRows: IngestedSourcesRow[];
    setIngestedSourceRows: Dispatch<SetStateAction<IngestedSourcesRow[]>>;
    ingestedSourceSearchTerm: string;
    setIngestedSourceSearchTerm: Dispatch<SetStateAction<string>>;
    ingestedSourcesModels: string[];
    areIngestedSourcesFetching: boolean;
    setAreIngestedSourcesFetching: Dispatch<SetStateAction<boolean>>;
    ingestedSourcesDisabledRowIds: Set<string>;
    setIngestedSourcesDisabledRowIds: Dispatch<SetStateAction<Set<string>>>;
    ingestedSourcesSortModel: GridSortModel;
    setIngestedSourcesSortModel: Dispatch<SetStateAction<GridSortModel>>;
    isAddPersonasToSourcesModalOpen: boolean;
    setIsAddPersonasToSourcesModalOpen: Dispatch<SetStateAction<boolean>>;
    isDeleteSourcesModalOpen: boolean;
    setIsDeleteSourcesModalOpen: Dispatch<SetStateAction<boolean>>;
    personaManagemantSearchTerm: string;
    setPersonaManagemantSearchTerm: Dispatch<SetStateAction<string>>;
    arePersonasManagementDataFetching: boolean;
    setArePersonasManagementDataFetching: Dispatch<SetStateAction<boolean>>;
    personasManagementRows: PersonasManagementRow[];
    setPersonasManagementRows: Dispatch<
        SetStateAction<PersonasManagementRow[]>
    >;
    addPersonaModalOpen: boolean;
    setAddPersonaModalOpen: Dispatch<SetStateAction<boolean>>;
    addUsersModalOpen: PersonasManagementRow | null;
    setAddUsersModalOpen: Dispatch<
        SetStateAction<PersonasManagementRow | null>
    >;
    selectedPersonaSources: string | null;
    setSelectedPersonaSources: Dispatch<SetStateAction<string | null>>;
    notValidEmails: Record<string, string[]>;
    setNotValidEmails: Dispatch<SetStateAction<Record<string, string[]>>>;
    copySourcesFromPersonaModal: string;
    setCopySourcesFromPersonaModal: Dispatch<SetStateAction<string>>;
    personaManagementFilters: PersonasManagementFilters;
    setPersonaManagementFilters: Dispatch<
        SetStateAction<PersonasManagementFilters>
    >;
    sourcesByPersona: SourcesByPersona[];
    setSourcesByPersona: Dispatch<SetStateAction<SourcesByPersona[]>>;
    filteredPersonaManagementRows: PersonasManagementRow[];
    setFilteredPersonaManagementRows: Dispatch<
        SetStateAction<PersonasManagementRow[]>
    >;
    triggerFetchIngestSources: boolean;
    setTriggerFetchIngestSources: Dispatch<SetStateAction<boolean>>;
    updatingPersonas: Set<string>;
    setUpdatingPersonas: Dispatch<SetStateAction<Set<string>>>;
    deletedIngestedRows: Set<string>;
    setDeletedIngestedRows: Dispatch<SetStateAction<Set<string>>>;
    filteredIngestSourcesRows: IngestedSourcesRow[];
    setFilteredIngestSourcesRows: Dispatch<
        SetStateAction<IngestedSourcesRow[]>
    >;
    disabledPersonaManagementRows: Set<string>;
    setDisabledPersonaManagementRows: Dispatch<SetStateAction<Set<string>>>;
    selectedPersonasForIngest: MultiSelectSearchValuesProps[];
    setSelectedPersonasForIngest: Dispatch<
        SetStateAction<MultiSelectSearchValuesProps[]>
    >;
    failedImportData: string[];
    setFailedImportData: Dispatch<SetStateAction<string[]>>;
    isPersonasEnabled: boolean;
    setIsPersonasEnabled: Dispatch<SetStateAction<boolean>>;
    // EXPERT RESPONSES CONTEXT
    expertResponsesRows: ExpertResponsesRow[];
    setExpertResponsesRows: Dispatch<SetStateAction<ExpertResponsesRow[]>>;
    expertResponsesEditModalData: ExpertResponsesRow;
    setExpertResponsesEditModalData: Dispatch<
        SetStateAction<ExpertResponsesRow>
    >;
    areExpertResponsesFetching: boolean;
    setAreExpertResponsesFetching: Dispatch<SetStateAction<boolean>>;
    expertResponsesSearchTerm: string;
    setExpertResponsesSearchTerm: Dispatch<SetStateAction<string>>;
    editedResponseVersions: editedResponseVersion[];
    setEditedResponseVersions: Dispatch<
        SetStateAction<editedResponseVersion[]>
    >;
    expertResponsesFilters: ExpertResponsesFilters;
    setExpertResponsesFilters: Dispatch<SetStateAction<ExpertResponsesFilters>>;
    expertResponsesSortModel: GridSortModel;
    setExpertResponsesSortModel: Dispatch<SetStateAction<GridSortModel>>;
}

const DataManagementContext = createContext<DataManagementContextValue | null>(
    null
);

export const DataManagementContextProvider: FC<{
    children: ReactNode;
}> = ({ children }) => {
    const [deletedIngestedRows, setDeletedIngestedRows] = useState<Set<string>>(
        new Set()
    );
    const [triggerFetchIngestSources, setTriggerFetchIngestSources] =
        useState<boolean>(false);
    const [updatingPersonas, setUpdatingPersonas] = useState<Set<string>>(
        new Set()
    );
    const [isPersonasEnabled, setIsPersonasEnabled] = useState<boolean>(true);
    const [failedImportData, setFailedImportData] = useState<string[]>([]);
    const [selectedPersonasForIngest, setSelectedPersonasForIngest] = useState<
        MultiSelectSearchValuesProps[]
    >([]);
    const [disabledPersonaManagementRows, setDisabledPersonaManagementRows] =
        useState<Set<string>>(new Set());
    const [filteredIngestSourcesRows, setFilteredIngestSourcesRows] = useState<
        IngestedSourcesRow[]
    >([]);
    const [filteredPersonaManagementRows, setFilteredPersonaManagementRows] =
        useState<PersonasManagementRow[]>([]);
    const [sourcesByPersona, setSourcesByPersona] = useState<
        SourcesByPersona[]
    >([]);
    const [copySourcesFromPersonaModal, setCopySourcesFromPersonaModal] =
        useState<string>('');
    const [personaManagementFilters, setPersonaManagementFilters] =
        useState<PersonasManagementFilters>({ date: '' });
    const [notValidEmails, setNotValidEmails] = useState<
        Record<string, string[]>
    >({});
    const [selectedPersonaSources, setSelectedPersonaSources] = useState<
        string | null
    >(null);
    const [addUsersModalOpen, setAddUsersModalOpen] =
        useState<PersonasManagementRow | null>(null);
    const [isDeleteSourcesModalOpen, setIsDeleteSourcesModalOpen] =
        useState<boolean>(false);
    const [addPersonaModalOpen, setAddPersonaModalOpen] =
        useState<boolean>(false);
    const [personasManagementRows, setPersonasManagementRows] = useState<
        PersonasManagementRow[]
    >([]);
    const [
        arePersonasManagementDataFetching,
        setArePersonasManagementDataFetching
    ] = useState<boolean>(false);
    const [personaManagemantSearchTerm, setPersonaManagemantSearchTerm] =
        useState<string>('');
    const [
        isAddPersonasToSourcesModalOpen,
        setIsAddPersonasToSourcesModalOpen
    ] = useState<boolean>(false);
    const [isUploadFilesModalOpen, setIsUploadFilesModalOpen] =
        useState<boolean>(false);
    const [sourceFiles, setSourceFiles] = useState<SourceFile[]>([]);
    const [sourceFilesToValidate, setSourceFilesToValidate] = useState<
        SourceFile[]
    >([]);
    const sourceFilesRef = useRef<SourceFile[]>(); // to keep the reference of sourceFiles for async operations
    const [uploadModel, setUploadModel] = useState<IntegratedModel>(null);
    const [isIngestRequestInProgress, setIsIngestRequestInProgress] =
        useState<boolean>(false);
    const [uploadModelsList, setUploadModelsList] = useState<IntegratedModel[]>(
        []
    );
    const [isCatalogModelsFetched, setIsCatalogModelsFetched] =
        useState<boolean>(false);
    const [currentTab, setCurrentTab] = useState<DataManagementTab>(
        DataManagementTab.IngestedSources
    );
    const [ingestedSourcesFilters, setIngestedSourcesFilters] =
        useState<IngestedSourcesFilters>({});
    const [selectedIngestedSourceRows, setSelectedIngestedSourceRows] =
        useState<IngestedSourcesRow[]>([]);
    const [ingestedSourceRows, setIngestedSourceRows] = useState<
        IngestedSourcesRow[]
    >([]);
    const [ingestedSourceSearchTerm, setIngestedSourceSearchTerm] =
        useState<string>('');
    const [areIngestedSourcesFetching, setAreIngestedSourcesFetching] =
        useState<boolean>(false);

    const [ingestedSourcesDisabledRowIds, setIngestedSourcesDisabledRowIds] =
        useState<Set<string>>(new Set());
    const [ingestedSourcesSortModel, setIngestedSourcesSortModel] =
        useState<GridSortModel>(INGESTED_SOURCES_DEFAULT_SORT_MODEL);
    //EXPERT RESPONSES STATE
    const [expertResponsesSearchTerm, setExpertResponsesSearchTerm] =
        useState<string>('');
    const [areExpertResponsesFetching, setAreExpertResponsesFetching] =
        useState<boolean>(false);
    const [expertResponsesRows, setExpertResponsesRows] = useState<
        ExpertResponsesRow[]
    >([]);
    const [expertResponsesEditModalData, setExpertResponsesEditModalData] =
        useState<ExpertResponsesRow>(null);
    const [editedResponseVersions, setEditedResponseVersions] = useState<
        editedResponseVersion[]
    >([]);
    const [expertResponsesFilters, setExpertResponsesFilters] =
        useState<ExpertResponsesFilters>({});
    const [expertResponsesSortModel, setExpertResponsesSortModel] =
        useState<GridSortModel>(EXPERT_RESPONSES_DEFAULT_SORT_MODEL);

    const selectedIngestedSourceRowIdsSet = useMemo(
        () => new Set(selectedIngestedSourceRows.map((row) => row.id)),
        [selectedIngestedSourceRows]
    );

    const validSourceFiles = useMemo(
        () =>
            sourceFiles.filter(
                (f) => f.validationType === FileValidation.Valid
            ),
        [sourceFiles]
    );

    const [ingestedSourcesModels, ingestedSourcesUsers] = useMemo(
        () => extractFilterValuesFromIngestedRows(ingestedSourceRows),
        [ingestedSourceRows]
    );

    const currentModelUploadedFiles = useMemo<Set<string>>(() => {
        if (!uploadModel) {
            return new Set();
        }
        return new Set(
            ingestedSourceRows
                .filter(
                    (row) =>
                        row.integratedModel.modelName === uploadModel.modelName
                )
                .map((row) => row.document_name)
        );
    }, [uploadModel, ingestedSourceRows]);

    useEffect(() => {
        sourceFilesRef.current = sourceFiles;
    }, [sourceFiles]);

    useEffect(() => {
        setUpdatingPersonas(new Set());
    }, [currentTab]);

    useEffect(() => {
        const getIsPersonasEnabled = async () => {
            const response = await Backend.getIsPersonasEnabled();
            setIsPersonasEnabled(response.data);
        };
        getIsPersonasEnabled();
    }, []);

    const ingestedSourcesPersonas = useMemo(() => {
        return personasManagementRows
            .filter((row) => row.name !== PERSONA_CONSTANT_NAMES.ALL_SOURCES)
            .map((persona) => persona.name);
    }, [personasManagementRows]);

    const resetDataManagementContext = () => {
        setCurrentTab(DataManagementTab.IngestedSources);
        resetUploadModalState();
        resetIngestedSourcesState();
    };

    const resetUploadModalState = () => {
        setIsUploadFilesModalOpen(false);
        setSourceFiles([]);
        setUploadModel(null);
        setIsIngestRequestInProgress(false);
        setSourceFilesToValidate([]);
        setSelectedPersonasForIngest([]);
    };

    const resetIngestedSourcesState = () => {
        setIngestedSourcesFilters({});
        setSelectedIngestedSourceRows([]);
        setIngestedSourceRows([]);
        setIngestedSourceSearchTerm('');
        setAreIngestedSourcesFetching(false);
        setIngestedSourcesDisabledRowIds(new Set());
        setIngestedSourcesSortModel(INGESTED_SOURCES_DEFAULT_SORT_MODEL);
    };

    const value: DataManagementContextValue = {
        resetDataManagementContext,
        resetUploadModalState,
        isUploadFilesModalOpen,
        setIsUploadFilesModalOpen,
        sourceFiles,
        validSourceFiles,
        setSourceFiles,
        sourceFilesToValidate,
        setSourceFilesToValidate,
        uploadModel,
        setUploadModel,
        sourceFilesRef,
        isIngestRequestInProgress,
        setIsIngestRequestInProgress,
        uploadModelsList,
        setUploadModelsList,
        isCatalogModelsFetched,
        setIsCatalogModelsFetched,
        currentModelUploadedFiles,
        currentTab,
        setCurrentTab,
        ingestedSourcesFilters,
        setIngestedSourcesFilters,
        ingestedSourcesUsers,
        ingestedSourcesPersonas,
        selectedIngestedSourceRows,
        setSelectedIngestedSourceRows,
        selectedIngestedSourceRowIdsSet,
        ingestedSourceRows,
        setIngestedSourceRows,
        ingestedSourceSearchTerm,
        setIngestedSourceSearchTerm,
        ingestedSourcesModels,
        areIngestedSourcesFetching,
        setAreIngestedSourcesFetching,
        ingestedSourcesDisabledRowIds,
        setIngestedSourcesDisabledRowIds,
        ingestedSourcesSortModel,
        setIngestedSourcesSortModel,
        isAddPersonasToSourcesModalOpen,
        setIsAddPersonasToSourcesModalOpen,
        isDeleteSourcesModalOpen,
        setIsDeleteSourcesModalOpen,
        personaManagemantSearchTerm,
        setPersonaManagemantSearchTerm,
        arePersonasManagementDataFetching,
        setArePersonasManagementDataFetching,
        personasManagementRows,
        setPersonasManagementRows,
        addPersonaModalOpen,
        setAddPersonaModalOpen,
        addUsersModalOpen,
        setAddUsersModalOpen,
        selectedPersonaSources,
        setSelectedPersonaSources,
        notValidEmails,
        setNotValidEmails,
        copySourcesFromPersonaModal,
        setCopySourcesFromPersonaModal,
        personaManagementFilters,
        setPersonaManagementFilters,
        sourcesByPersona,
        setSourcesByPersona,
        filteredIngestSourcesRows,
        setFilteredIngestSourcesRows,
        filteredPersonaManagementRows,
        setFilteredPersonaManagementRows,
        disabledPersonaManagementRows,
        setDisabledPersonaManagementRows,
        selectedPersonasForIngest,
        setSelectedPersonasForIngest,
        failedImportData,
        setFailedImportData,
        isPersonasEnabled,
        setIsPersonasEnabled,
        updatingPersonas,
        setUpdatingPersonas,
        deletedIngestedRows,
        setDeletedIngestedRows,
        triggerFetchIngestSources,
        setTriggerFetchIngestSources,
        // EXPERT RESPONSES CONTEXT
        expertResponsesSearchTerm,
        setExpertResponsesSearchTerm,
        areExpertResponsesFetching,
        setAreExpertResponsesFetching,
        expertResponsesRows,
        setExpertResponsesRows,
        expertResponsesEditModalData,
        setExpertResponsesEditModalData,
        editedResponseVersions,
        setEditedResponseVersions,
        expertResponsesFilters,
        setExpertResponsesFilters,
        expertResponsesSortModel,
        setExpertResponsesSortModel
    };
    return (
        <DataManagementContext.Provider value={value}>
            {children}
        </DataManagementContext.Provider>
    );
};

export const useDataManagementContext = (): DataManagementContextValue => {
    const context = useContext(DataManagementContext);

    if (!context) {
        throw new Error('Did you forget to use the Provider?');
    }

    return context;
};

export const useDataManagementTab = () => {
    const { currentTab, setCurrentTab } = useDataManagementContext();
    return { currentTab, setCurrentTab };
};

export const useFailedImportData = () => {
    const { failedImportData, setFailedImportData } =
        useDataManagementContext();
    return { failedImportData, setFailedImportData };
};

export const useIsPersonasEnabled = () => {
    const { isPersonasEnabled, setIsPersonasEnabled } =
        useDataManagementContext();
    return { isPersonasEnabled, setIsPersonasEnabled };
};
