const WarningRoundSmallIcon = ({ color = 'currentColor', ...props }) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect width="16" height="16" rx="8" fill={color} />
            <path
                d="M8.11719 5.6969C8.39333 5.6969 8.61719 5.92076 8.61719 6.1969V7.96459C8.61719 8.24073 8.39333 8.46459 8.11719 8.46459C7.84104 8.46459 7.61719 8.24073 7.61719 7.96459V6.1969C7.61719 5.92076 7.84104 5.6969 8.11719 5.6969Z"
                fill="white"
            />
            <path
                d="M8.11719 9.2323C7.84104 9.2323 7.61719 9.45616 7.61719 9.7323C7.61719 10.0084 7.84104 10.2323 8.11719 10.2323H8.12437C8.40051 10.2323 8.62437 10.0084 8.62437 9.7323C8.62437 9.45616 8.40051 9.2323 8.12437 9.2323H8.11719Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.4383 3.17798C7.64555 3.0613 7.87937 3 8.11721 3C8.35505 3 8.58887 3.0613 8.79612 3.17798C9.00337 3.29466 9.17705 3.46279 9.3004 3.66614L9.30184 3.66852L13.049 9.92417C13.1699 10.1334 13.2337 10.3707 13.2344 10.6123C13.2351 10.854 13.1725 11.0916 13.0528 11.3015C12.9332 11.5114 12.7606 11.6864 12.5524 11.8089C12.3441 11.9314 12.1074 11.9973 11.8658 12L11.8603 12L4.36863 12C4.12701 11.9973 3.8903 11.9314 3.68205 11.8089C3.47379 11.6864 3.30125 11.5114 3.18159 11.3015C3.06193 11.0916 2.99933 10.854 3.00001 10.6123C3.00068 10.3707 3.06462 10.1335 3.18545 9.9242L3.18945 9.91726L6.93402 3.66614C7.05736 3.46279 7.23105 3.29466 7.4383 3.17798ZM8.11721 4C8.05124 4 7.98638 4.017 7.9289 4.04937C7.87173 4.08155 7.82378 4.12784 7.7896 4.18382L4.04986 10.427C4.01737 10.4844 4.00019 10.5492 4 10.6151C3.99981 10.6822 4.01718 10.7481 4.05037 10.8063C4.08356 10.8645 4.13142 10.913 4.18918 10.947C4.24632 10.9807 4.31119 10.9989 4.37745 11H11.857C11.9232 10.9989 11.9881 10.9807 12.0452 10.947C12.103 10.913 12.1509 10.8645 12.184 10.8063C12.2172 10.7481 12.2346 10.6822 12.2344 10.6151C12.2342 10.5492 12.217 10.4844 12.1846 10.427L8.44539 4.18477L8.44484 4.18386C8.41066 4.12786 8.3627 4.08156 8.30552 4.04937C8.24804 4.017 8.18318 4 8.11721 4Z"
                fill="white"
            />
        </svg>
    );
};

export default WarningRoundSmallIcon;
