import { FunctionComponent } from 'react';
import { t } from 'i18next';
import { Button } from 'aqui';
import {
    useIngestFiles,
    useSourceFiles,
    useUploadFilesModal
} from './UploadFilesModalHooks';

const UploadFilesModalFooter: FunctionComponent = () => {
    const { closeUploadFilesModal } = useUploadFilesModal();
    const {
        isIngestAvailable,
        ingestUploadedSourceFiles,
        isIngestRequestInProgress
    } = useIngestFiles();
    const { validSourceFiles } = useSourceFiles();

    return (
        <div className="upload-files__footer-btns">
            <Button
                text={t('common.cancel')}
                dataTestId="upload-modal-cancel-button"
                onClick={closeUploadFilesModal}
            />
            <Button
                variant="filled"
                text={t('upload-files-modal.ingest')}
                dataTestId="upload-modal-ingest-button"
                disabled={!isIngestAvailable}
                onClick={() => ingestUploadedSourceFiles(validSourceFiles)}
                processing={isIngestRequestInProgress}
            />
        </div>
    );
};

export default UploadFilesModalFooter;
