
import { Typography } from 'aqui';
import { FunctionComponent, useMemo } from 'react';
import {
    getAggregatedResultDetails,
    getPageIndication
} from '../../KASection/KASectionHelpers';
import { SourceDocument } from '../../../types';
import VideoSmallIcon from '../../../SVG/VideoSmallIcon';
import DocumentSmallIcon from '../../../SVG/DocumentSmallIcon';

type SourceTooltipContentProps = {
    relatedSource: SourceDocument;
};

const SourceTooltipContent: FunctionComponent<SourceTooltipContentProps> = ({
    relatedSource
}) => {
    const { fileName, mediaType } = getAggregatedResultDetails([relatedSource]);

    const sourcePageText = useMemo(
        () => getPageIndication(relatedSource),
        [relatedSource]
    );

    const MediaTypeIcon = ({ type, ...rest }) =>
        type === 'video' ? (
            <VideoSmallIcon color="#FFFFFF" {...rest} />
        ) : (
            <DocumentSmallIcon color="#FFFFFF" {...rest} />
        );

    return (
        <div
            className={'flex align-center ka-hover-tooltip-area'}
            data-testid={`ka_row_${fileName}`}
        >
            <MediaTypeIcon type={mediaType} className="ka-row__type-icon" />
            <Typography
                type={'small1Bold'}
                className="lines-limit-1 ka-tooltip-filename"
            >
                {fileName}
            </Typography>
            <Typography
                type={'small1Bold'}
                tag="div"
                className="ka-row__page-num --tooltip-num"
            >
                {sourcePageText}
            </Typography>
        </div>
    );
};

export default SourceTooltipContent;
