import dayjs, { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Popover } from '@mui/material';
import { FC, useMemo, useRef, useState } from 'react';
import FilterSelect from './FilterSelect';
import { RangeDatePickerPresets } from '../../../types';
import DateRangePicker from '../../DateRangePicker/DateRangePicker';

const DATE_FORMAT = 'MMM D, YYYY';

interface DateRangeFilterProps {
    onChange: (minDate: Date, maxDate: Date) => void;
    allFilterLabel: string;
    dataTestId: string;
    disabled: boolean;
    minDate: Date;
    maxDate: Date;
}
const DateRangeFilter: FC<DateRangeFilterProps> = ({
    onChange,
    allFilterLabel,
    dataTestId,
    disabled,
    minDate = null,
    maxDate = null
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedPreset, setSelectedPreset] =
        useState<RangeDatePickerPresets>(null);
    const filterSelectRef = useRef(null);

    const { today, rangeValue, rangeDateString } = useMemo(() => {
        const today = dayjs();
        const minDay = minDate && dayjs(minDate);
        const maxDay = maxDate && dayjs(maxDate);
        const rangeDateString =
            minDay && maxDay
                ? `${minDay?.format(DATE_FORMAT)} - ${maxDay?.format(
                      DATE_FORMAT
                  )}`
                : '';
        return {
            today,
            rangeValue: [minDay, maxDay],
            rangeDateString
        };
    }, [minDate, maxDate]);

    const onPresetChange = (preset: RangeDatePickerPresets) => {
        setSelectedPreset(preset);
        if (preset === RangeDatePickerPresets.Custom) {
            setIsOpen(true);
        } else if (preset === RangeDatePickerPresets.LastWeek) {
            onChange(today.subtract(7, 'day').toDate(), today.toDate());
        } else if (preset === RangeDatePickerPresets.LastMonth) {
            onChange(today.subtract(1, 'month').toDate(), today.toDate());
        } else if (preset === RangeDatePickerPresets.LastYear) {
            onChange(today.subtract(1, 'year').toDate(), today.toDate());
        } else {
            onChange(null, null);
        }
    };

    const shortcutItems = [
        { label: 'All', onClick: () => onRangeChange([null, null], null) },
        {
            label: RangeDatePickerPresets.LastWeek,
            onClick: () => {
                onRangeChange(
                    [today.subtract(7, 'day'), today],
                    RangeDatePickerPresets.LastWeek
                );
            }
        },
        {
            label: RangeDatePickerPresets.LastMonth,
            onClick: () => {
                onRangeChange(
                    [today.subtract(1, 'month'), today],
                    RangeDatePickerPresets.LastMonth
                );
            }
        },
        {
            label: RangeDatePickerPresets.LastYear,
            onClick: () =>
                onRangeChange(
                    [today.subtract(1, 'year'), today],
                    RangeDatePickerPresets.LastYear
                )
        }
    ];

    const onRangeChange = (
        date: DateRange<Dayjs>,
        preset?: RangeDatePickerPresets
    ) => {
        if (date[0] && date[1]) {
            const normalizedMaxDate = date[1]
                .set('hour', 23)
                .set('minute', 59)
                .set('second', 59)
                .toDate();
            onChange(date[0].toDate(), normalizedMaxDate);
        }
        if (preset || preset === null) {
            setSelectedPreset(preset);
            setIsOpen(false);
        }
    };

    return (
        <>
            <div ref={filterSelectRef}>
                <FilterSelect
                    items={Object.values(RangeDatePickerPresets)}
                    selectedItem={selectedPreset}
                    onChange={onPresetChange}
                    allFilterLabel={allFilterLabel}
                    dataTestId={dataTestId}
                    disabled={disabled}
                    isSemiActive={isOpen}
                    selectedItemDisplay={rangeDateString}
                />
            </div>
            <Popover
                anchorEl={filterSelectRef.current}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                onClose={() => setIsOpen(false)}
                classes={{
                    paper: 'popoverBox__root'
                }}
            >
                <DateRangePicker
                    value={rangeValue as DateRange<Dayjs>}
                    shortcutItems={shortcutItems}
                    onChange={(range) => onRangeChange(range)}
                />
            </Popover>
        </>
    );
};

export default DateRangeFilter;
