import { SPECIAL_DIV_IDS } from '../../consts';
import { IntegratedModel } from '../../types';

export const genMessageDetailsKey = (
    question: string,
    productDetails: IntegratedModel
) => {
    return (
        productDetails &&
        productDetails?.productIds?.productTypeId +
            productDetails?.productIds?.manufacturerId +
            productDetails?.productIds?.productId +
            question
    );
};

export const followAnswer = () => {
    const objDiv = document.getElementById(SPECIAL_DIV_IDS.CHAT);
    if (objDiv) {
        objDiv.scrollTop = objDiv.scrollHeight;
    }
};
