const ProcessingIcon = ({ color = 'currentColor', ...props }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5 4.5C12.5 4.22386 12.2761 4 12 4C11.7239 4 11.5 4.22386 11.5 4.5V7.5C11.5 7.77614 11.7239 8 12 8C12.2761 8 12.5 7.77614 12.5 7.5V4.5ZM12.5 15.5C12.5 15.2239 12.2761 15 12 15C11.7239 15 11.5 15.2239 11.5 15.5V18.5C11.5 18.7761 11.7239 19 12 19C12.2761 19 12.5 18.7761 12.5 18.5V15.5ZM6.64645 6.14645C6.84171 5.95118 7.15829 5.95118 7.35355 6.14645L9.35355 8.14645C9.54882 8.34171 9.54882 8.65829 9.35355 8.85355C9.15829 9.04882 8.84171 9.04882 8.64645 8.85355L6.64645 6.85355C6.45118 6.65829 6.45118 6.34171 6.64645 6.14645ZM15.3536 14.1464C15.1583 13.9512 14.8417 13.9512 14.6464 14.1464C14.4512 14.3417 14.4512 14.6583 14.6464 14.8536L16.6464 16.8536C16.8417 17.0488 17.1583 17.0488 17.3536 16.8536C17.5488 16.6583 17.5488 16.3417 17.3536 16.1464L15.3536 14.1464ZM4.5 11.5C4.5 11.2239 4.72386 11 5 11H8C8.27614 11 8.5 11.2239 8.5 11.5C8.5 11.7761 8.27614 12 8 12H5C4.72386 12 4.5 11.7761 4.5 11.5ZM16 11C15.7239 11 15.5 11.2239 15.5 11.5C15.5 11.7761 15.7239 12 16 12H19C19.2761 12 19.5 11.7761 19.5 11.5C19.5 11.2239 19.2761 11 19 11H16ZM9.35355 14.1464C9.54882 14.3417 9.54882 14.6583 9.35355 14.8536L7.35355 16.8536C7.15829 17.0488 6.84171 17.0488 6.64645 16.8536C6.45118 16.6583 6.45118 16.3417 6.64645 16.1464L8.64645 14.1464C8.84171 13.9512 9.15829 13.9512 9.35355 14.1464ZM17.3536 6.85355C17.5488 6.65829 17.5488 6.34171 17.3536 6.14645C17.1583 5.95118 16.8417 5.95118 16.6464 6.14645L14.6464 8.14645C14.4512 8.34171 14.4512 8.65829 14.6464 8.85355C14.8417 9.04882 15.1583 9.04882 15.3536 8.85355L17.3536 6.85355Z"
                fill={color}
            />
        </svg>
    );
};

export default ProcessingIcon;
