import SidePanel from '../../../SidePanel/SidePanel';
import { t } from 'i18next';
import {
    usePersonaSourcesModal,
    useSourcesByPersona
} from '../PersonaManagementHooks';
import PersonaSourcesModalFooter from './PersonaSourcesModalFooter';
import PersonaSourcesModalBody from './PersonaSourcesModalBody';
import { useIsPersonasEnabled } from '../../../../context/DataManagementContext';
import { useServerlessIndex } from '../../../../AppContext';

const PersonaSourcesModal = () => {
    const { selectedPersonaSources, setSelectedPersonaSources } =
        usePersonaSourcesModal();
    const { setSourcesByPersona } = useSourcesByPersona();
    const { isPersonasEnabled } = useIsPersonasEnabled();
    const { isServerlessIndex } = useServerlessIndex();

    const handleClose = () => {
        setSourcesByPersona([]);
        setSelectedPersonaSources(null);
    };

    return (
        <SidePanel
            open={Boolean(selectedPersonaSources)}
            onClose={handleClose}
            classes={{
                container: 'side-panel__upload-container',
                body: 'side-panel__body side-panel__upload-body flex-col',
                header: 'ingestModal__side-panel__header',
                footer: 'side-panel__footer'
            }}
            title={`"${selectedPersonaSources}" ${t(
                'add-persona.persona-sources-title'
            )}`}
            footer={
                isPersonasEnabled && !isServerlessIndex ? (
                    <PersonaSourcesModalFooter />
                ) : (
                    <></>
                )
            }
        >
            <PersonaSourcesModalBody />
        </SidePanel>
    );
};

export default PersonaSourcesModal;
