import { Typography } from 'aqui';
import { ArrowIcon } from 'aqui/icons';
import { t } from 'i18next';
import { useIngestedSourcesFilters } from '../../IngestedSources/IngestedSourcesHooks';
import { useDataManagementTab } from '../../../../context/DataManagementContext';
import { DataManagementTab } from '../../../../types';
import {
    usePersonaSourcesModal,
    useSourcesByPersona
} from '../PersonaManagementHooks';

const PersonaSourcesModalFooter = () => {
    const { setPersonaToFilter } = useIngestedSourcesFilters();
    const { setCurrentTab } = useDataManagementTab();
    const { selectedPersonaSources, setSelectedPersonaSources } =
        usePersonaSourcesModal();
    const { setSourcesByPersona } = useSourcesByPersona();

    const handleOnClick = () => {
        setPersonaToFilter(selectedPersonaSources);
        setSelectedPersonaSources(null);
        setSourcesByPersona([]);
        setCurrentTab(DataManagementTab.IngestedSources);
    };

    return (
        <div
            data-testid="persona-sources-edit-sources-button"
            className="flex align-center personaSourcesModalFooter__container"
            onClick={handleOnClick}
        >
            <Typography type="small1">
                {t('add-persona.persona-sources-edit')}
            </Typography>
            <ArrowIcon className="personaSourcesModalFooter__icon" />
        </div>
    );
};

export default PersonaSourcesModalFooter;
