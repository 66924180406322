import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import { useSourceFiles, useUploadModel } from './UploadFilesModalHooks';
import SearchSelect from '../SearchSelect/SearchSelect';
import { MAX_MODELS_IN_PICKLIST } from '../../consts';
import {
    getIntegratedModelDisplay,
    getModelDisplaysWithMap
} from '../../utils/productUtils';
import { getAddNewModelButtonState } from './UploadFilesModalHelpers';
import AddModelButton from './AddModelButton';

const UploadModelSelect: FunctionComponent = () => {
    const {
        setUploadModel,
        fetchModels,
        uploadModelsList,
        isCatalogModelsFetched,
        uploadModel,
        addModelToUploadModelsList
    } = useUploadModel();
    const [isOpen, setIsOpen] = useState(false);
    const [searchText, setSearchText] = useState(
        uploadModel ? getIntegratedModelDisplay(uploadModel) : ''
    );
    const [filteredValues, setFilteredValues] = useState<string[]>([]);
    const { sourceFiles } = useSourceFiles();

    const selectedValue = useMemo(
        () => (uploadModel ? getIntegratedModelDisplay(uploadModel) : ''),
        [uploadModel]
    );

    useEffect(() => {
        if (!isCatalogModelsFetched) {
            fetchModels();
        }
    }, [isCatalogModelsFetched]);

    const { modelDisplays, integratedModelsMap, modelNamesSet } = useMemo(
        () => getModelDisplaysWithMap(uploadModelsList),
        [uploadModelsList]
    );

    const { shouldShowAddNewModel, isAddNewModelDisabled, disabledReason } =
        useMemo(
            () => getAddNewModelButtonState(searchText, modelNamesSet),
            [searchText, modelNamesSet]
        );

    useEffect(() => {
        const filtered = modelDisplays.filter((model) =>
            model.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredValues(filtered);
    }, [modelDisplays, searchText, integratedModelsMap]);

    const onModelSelect = (value: string) => {
        const model = integratedModelsMap[value];
        setUploadModel(model);
        setSearchText(value);
    };

    const onAddModelClick = () => {
        const modelName = searchText.trim();
        setUploadModel({ modelName });
        addModelToUploadModelsList({ modelName });
        setSearchText(modelName);
        setIsOpen(false);
    };

    const showFooterMessage = filteredValues.length > MAX_MODELS_IN_PICKLIST;

    const isUploadModelSelectDisabled =
        !!sourceFiles.length || !isCatalogModelsFetched;

    return (
        <SearchSelect
            values={filteredValues}
            setValue={onModelSelect}
            value={selectedValue}
            classes={{
                popover: 'upload-files__model-select-popover',
                menu: `upload-files__model-select-menu ${
                    showFooterMessage ? '--with-footer' : ''
                }`,
                search: 'upload-files__model-select-search'
            }}
            disabled={isUploadModelSelectDisabled}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            dataTestId="upload-files-model-multiselect"
            searchPlaceholder={t('upload-files-modal.input-placeholder')}
            searchText={searchText}
            setSearchText={setSearchText}
            processing={!isCatalogModelsFetched}
            showFooterMessage={showFooterMessage}
            footerMessageText={`${MAX_MODELS_IN_PICKLIST}+ ${t(
                'model.limit-shown-models'
            )}`}
            onClearButtonClick={() => setUploadModel(null)}
            StaticBottomRow={
                <AddModelButton
                    onClick={onAddModelClick}
                    modelName={searchText.trim()}
                    isDisabled={isAddNewModelDisabled}
                    disabledReason={disabledReason}
                />
            }
            noValuesMessage={t('model.model-not-found')}
            shouldShowStaticBottomRow={shouldShowAddNewModel}
        />
    );
};

export default UploadModelSelect;
