import React, {
    HTMLAttributes,
    MouseEvent,
    ReactNode,
    forwardRef
} from 'react';
import classnames from 'classnames/bind';

import styles from './Menu.module.scss';
const cx = classnames.bind(styles);

interface MenuItemProps extends HTMLAttributes<HTMLDivElement> {
    className?: string;
    disabled?: boolean;
    processing?: boolean;
    selected?: boolean;
    dataTestId?: string;
    children: ReactNode;
}

const MenuItem = forwardRef<HTMLDivElement, MenuItemProps>(
    (
        {
            className = 'menu_item',
            disabled = false,
            processing = false,
            selected = false,
            dataTestId = '',
            children,
            onClick,
            ...rest
        },
        ref
    ) => {
        const onItemClick = (e: MouseEvent<HTMLDivElement>) => {
            if (!disabled && !processing) {
                onClick?.(e);
            }
        };

        return (
            <div
                ref={ref}
                {...rest}
                onClick={onItemClick}
                data-testid={dataTestId}
                data-disabled={disabled}
                data-selected={selected}
                className={cx('menu__item', className, {
                    '--disabled': disabled,
                    '--processing': processing,
                    '--selected': selected
                })}
            >
                {children}
            </div>
        );
    }
);

export default MenuItem;
