import React, { FunctionComponent } from 'react';
import { useThreadsPanel } from '../../AppContext';
import useSwitchPanel from '../../hooks/useSwitchPanel';
import cl from 'classnames';

type PanelContainerProps = {
    children: React.ReactElement;
    isSmallChat: boolean;
};

const PanelContainer: FunctionComponent<PanelContainerProps> = ({
    children,
    isSmallChat
}) => {
    const { isThreadsPanelOpen } = useThreadsPanel();
    const { isDataManagementPanel } = useSwitchPanel();
    return (
        <div
            className={cl({
                'panel-container__container': !isSmallChat,
                '--full-width': isDataManagementPanel && !isSmallChat
            })}
        >
            <div className={!isSmallChat ? 'panel-container__section' : ''}>
                <div
                    className={cl({
                        'panel-container__wrapper': !isSmallChat,
                        '--with-threads-open':
                            isThreadsPanelOpen && !isSmallChat
                    })}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};

export default PanelContainer;
