import { IntegratedModel } from '../../../types';

export const modelsSuggestionByProbability = (
    integratedModels: Array<IntegratedModel>,
    relevantModels: Record<string, number>
) => {
    const availableModels = integratedModels.filter(({ modelName }) =>
        Object.keys(relevantModels).includes(modelName)
    );
    return availableModels.sort((a, b) => {
        const modelAProb = relevantModels[a.modelName];
        const modelBProb = relevantModels[b.modelName];
        const modelAName = (
            a.modelName +
            a.productTypeName +
            a.manufacturerName
        ).toUpperCase();
        const modelBName = (
            b.modelName +
            b.productTypeName +
            b.manufacturerName
        ).toUpperCase();
        if (modelAProb > modelBProb) {
            return -1;
        }
        if (modelAProb < modelBProb) {
            return 1;
        }
        if (modelAProb === modelBProb) {
            if (modelAName < modelBName) {
                return -1;
            }
            if (modelAName > modelBName) {
                return 1;
            }
        }
        return 0;
    });
};
