const StopIcon = () => {
    return (
        <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5 4H4.5V12H12.5V4ZM4.5 3C3.94772 3 3.5 3.44772 3.5 4V12C3.5 12.5523 3.94772 13 4.5 13H12.5C13.0523 13 13.5 12.5523 13.5 12V4C13.5 3.44772 13.0523 3 12.5 3H4.5Z"
                fill="currentcolor"
            />
        </svg>
    );
};

export default StopIcon;
