import axios, { Method } from 'axios';

class StarkBackend {
    private static getConfig(
        method: Method,
        baseUrl: string,
        endpoint: string,
        payload: any,
        accessToken: string
    ) {
        const url = baseUrl + endpoint;
        const headers = {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        };
        if (method.toLowerCase() !== 'get') {
            return { url, method, headers, data: payload };
        }
        return { url, method, headers };
    }

    private static async SendAPIRequest(
        method: Method,
        baseUrl: string,
        endpoint: string,
        payload: any,
        accessToken: string
    ) {
        const config = StarkBackend.getConfig(
            method,
            baseUrl,
            endpoint,
            payload,
            accessToken
        );

        try {
            const response = await axios.request(config);

            if (response.status === 200) {
                return response.data;
            }
            throw new Error(`Failed on ${endpoint}! ${response}`);
        } catch (error: any) {
            console.error(`Error in SendAPIRequest: ${error.message}`);
            window.sentry.log(error);
            throw error; // Re-throw the error to let the caller handle it if needed.
        }
    }

    public static async getScopSettings(
        triageBaseUrl: string,
        accessToken: string
    ) {
        try {
            return StarkBackend.SendAPIRequest(
                'get',
                triageBaseUrl,
                '/ScopSettings/get_settings',
                null,
                accessToken
            );
        } catch (ex: any) {
            window.sentry.log(ex);
        }
    }
}

export default StarkBackend;
