import { useDispatch, useSelector } from 'react-redux';
import { useScopSettings, useStartNewSession } from '../../AppContext';
import { rerenderTriage, setIsSmallChat } from '../../actions/coPilotActions';
import { t } from 'i18next';
import { PopoverRow } from '../../types';
import useSwitchPanel from '../../hooks/useSwitchPanel';
import { getIsUserExpertOrAdmin } from '../../selectors/userPermissionSetsSelectors';

const useMoreOptionsRows = () => {
    const dispatch = useDispatch();
    const { startNewSession } = useStartNewSession();
    const { navigationToTriageEnabled } = useScopSettings();
    const { setDataManagementPanel, isDataManagementPanel } = useSwitchPanel();
    const isUserAllowedToDataManagement = useSelector(getIsUserExpertOrAdmin);

    const goToTriage = () => {
        startNewSession();
        dispatch(setIsSmallChat(true));
        dispatch(rerenderTriage());
        const rootElement = document.getElementById('stark');
        rootElement.style.display = 'flex';
    };

    const rows: PopoverRow[] = [
        {
            show: isUserAllowedToDataManagement,
            title: t('app-menu.data-management'),
            onClick: setDataManagementPanel,
            dataTestId: 'data-management-menu-item',
            withSeparator: true,
            isSelected: isDataManagementPanel
        },
        {
            show: navigationToTriageEnabled,
            title: t('app-menu.go-to-triage'),
            onClick: goToTriage,
            dataTestId: 'go-to-triage'
        }
    ];
    const hasRowsToShow = rows.some((row) => row.show);
    return { rows, hasRowsToShow };
};

export default useMoreOptionsRows;
