import { FunctionComponent } from 'react';
import DateFilter from '../../DateFilter/DateFilter';
import {
    usePersonaManagenentFilters,
    usePersonasManagementRows
} from './PersonaManagementHooks';

const PersonasManagementFilters: FunctionComponent = () => {
    const { arePersonasManagementDataFetching } = usePersonasManagementRows();

    const { setFilter } = usePersonaManagenentFilters();

    const onChangeFilterDate = (date: string) => {
        setFilter({ date });
    };

    return (
        <DateFilter
            disabled={arePersonasManagementDataFetching}
            onChange={onChangeFilterDate}
        />
    );
};

export default PersonasManagementFilters;
