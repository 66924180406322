import { Typography, Tabs } from 'aqui';
import { t } from 'i18next';
import { FunctionComponent } from 'react';
import { ChevronIcon } from 'aqui/icons';
import useSwitchPanel from '../../hooks/useSwitchPanel';
import { DataManagementTab } from '../../types';
import { useDataManagementTab } from '../../context/DataManagementContext';
import IngestedSources from './IngestedSources/IngestedSources';
import PersonasManagement from './PersonasManagement/PersonasManagement';
import FailedImportModal from './FailedImportModal/FailedImportModal';

const TABS = [
    DataManagementTab.IngestedSources,
    DataManagementTab.PersonasManagement
];

const DataManagementPanel: FunctionComponent = () => {
    const { currentTab, setCurrentTab } = useDataManagementTab();
    const { backToPrevPanel } = useSwitchPanel();
    return (
        <div className="full-width flex flex-col">
            <div className="data-management__header">
                <ChevronIcon
                    direction="left"
                    className="data-management__back-btn"
                    onClick={backToPrevPanel}
                    dataTestId="data-management-back-btn"
                />
                <Typography type="h6">
                    {t('app-menu.data-management')}
                </Typography>
            </div>
            <Tabs
                tabs={TABS}
                selectedTab={currentTab}
                onTabClick={setCurrentTab}
                dataTestId="data-management-tabs"
                className="data-management__tabs"
            />
            <div className="data-management__body">
                {currentTab === DataManagementTab.IngestedSources && (
                    <IngestedSources />
                )}
                {currentTab === DataManagementTab.PersonasManagement && (
                    <PersonasManagement />
                )}
            </div>
            <FailedImportModal />
        </div>
    );
};

export default DataManagementPanel;
