import { FunctionComponent, useEffect, useState } from 'react';
import SidePanel from '../../../SidePanel/SidePanel';
import IngestModalTitle from './IngestModalTitle';
import IngestModalFooter from './IngestModalFooter';
import { useIngestModal } from './IngestModalHooks';
import IngestModalBody from './IngestModalBody';
import {
    useDeleteSourcesByModel,
    useIngestedSourceRows,
    useSelectedIngestedSourceRows,
    useUpdateFilePersonas
} from '../IngestedSourcesHooks';
import {
    EditedPersonasOfFile,
    MultiSelectSearchValuesProps
} from '../../../../types';
import { MAX_SELECTED_PERSONAS_ALLOWED } from '../../PersonasManagement/consts';

const IngestModal: FunctionComponent = () => {
    const { selectedIngestedSourceRows } = useSelectedIngestedSourceRows();
    const { ingestedSourceRows } = useIngestedSourceRows();
    const { deleteSourcesByModel } = useDeleteSourcesByModel();
    const { updateFilePersonas } = useUpdateFilePersonas();

    const [selectedSources, setSelectedSources] = useState<string[]>([]);
    const [selectedItems, setSelectedItems] = useState<
        MultiSelectSearchValuesProps[]
    >([]);
    const { isModalOpen, handleCloseModal, modalType } = useIngestModal();

    useEffect(() => {
        if (selectedIngestedSourceRows.length) {
            setSelectedSources(selectedIngestedSourceRows.map((row) => row.id));
        }
    }, [selectedIngestedSourceRows]);

    const onClickSave = () => {
        const ingestedRowsByIds = ingestedSourceRows.reduce((acc, row) => {
            acc[row.id] = row;
            return acc;
        }, {});
        const files: EditedPersonasOfFile[] = selectedSources.map((id) => {
            return {
                model: ingestedRowsByIds[id].tenant_model,
                filename: ingestedRowsByIds[id].document_name,
                new_tags: Array.from(
                    new Set([
                        ...ingestedRowsByIds[id].personas,
                        ...selectedItems.map((item) => item.Id)
                    ])
                ).slice(0, MAX_SELECTED_PERSONAS_ALLOWED)
            };
        });
        updateFilePersonas(files);
        handleCloseModal();
    };

    const onClickDelete = () => {
        const rows = ingestedSourceRows.filter((row) =>
            selectedSources.includes(row.id)
        );
        deleteSourcesByModel(rows);
        handleCloseModal();
    };

    return (
        <SidePanel
            open={isModalOpen}
            onClose={handleCloseModal}
            classes={{
                container: 'side-panel__upload-container',
                body: 'side-panel__body side-panel__upload-body flex-col',
                header: 'ingestModal__side-panel__header',
                footer: 'side-panel__footer'
            }}
            title={
                <IngestModalTitle
                    count={selectedSources.length}
                    modalType={modalType}
                />
            }
            footer={
                <IngestModalFooter
                    disableSave={!selectedSources.length}
                    modalType={modalType}
                    onClickSave={onClickSave}
                    onClickDelete={onClickDelete}
                />
            }
        >
            <IngestModalBody
                selectedSources={selectedSources}
                setSelectedSources={setSelectedSources}
                modalType={modalType}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
            />
        </SidePanel>
    );
};

export default IngestModal;
