import { PlusIcon } from 'aqui/icons';
import { Typography } from 'aqui';
import { FunctionComponent } from 'react';
import MinusIcon from '../../../icons/MinusIcon';

interface ScaleControllerProps {
    decreaseScale: Function;
    increaseScale: Function;
    decreaseScaleDisabled: boolean;
    increaseScaleDisabled: boolean;
    scale: number;
}

const ScaleController: FunctionComponent<ScaleControllerProps> = ({
    decreaseScale,
    increaseScale,
    decreaseScaleDisabled,
    increaseScaleDisabled,
    scale
}) => {
    return (
        <div className="doc-control-panel__scale">
            <MinusIcon
                onClick={decreaseScale}
                className={`doc-control-panel__button ${
                    decreaseScaleDisabled ? '--disabled' : ''
                }`}
                data-testid="pdf-controller-decrease-scale"
                data-disabled={decreaseScaleDisabled}
            />
            <Typography
                type="small1Bold"
                dataTestId="pdf-controller-scale-value"
            >{`${Math.trunc(scale * 100)}%`}</Typography>
            <PlusIcon
                onClick={increaseScale}
                className={`doc-control-panel__button ${
                    increaseScaleDisabled ? '--disabled' : ''
                }`}
                dataTestId="pdf-controller-increase-scale"
                data-disabled={increaseScaleDisabled}
            />
        </div>
    );
};

export default ScaleController;
