import React, { useEffect, useMemo, useState } from 'react';
import { Typography, Button } from 'aqui';
import { CrossMarkIcon } from 'aqui/icons';
import classnames from 'classnames/bind';

import styles from './Cells.module.scss';
import { t } from 'i18next';
import { Popover, Tooltip } from '@mui/material';
import ValueWithTooltip from '../../ValueWithTooltip/ValueWithTooltip';
import { useIsPersonasEnabled } from '../../../context/DataManagementContext';
import { arraysEqual } from '../../../utils/utils';
import {
    MAX_SELECTED_PERSONAS_ALLOWED,
    PERSONA_CONSTANT_NAMES
} from '../../DataManagementPanel/PersonasManagement/consts';
import { usePersonasManagementRows } from '../../DataManagementPanel/PersonasManagement/PersonaManagementHooks';
import { useUpdateFilePersonas } from '../../DataManagementPanel/IngestedSources/IngestedSourcesHooks';
import { useServerlessIndex } from '../../../AppContext';

const MAX_CHIPS_TO_SHOW = 3;

const cx = classnames.bind(styles);
const MultiSelectCell = ({ row, id, data, existingData }) => {
    const [newPersonasArr, setNewPersonasArr] = useState(existingData);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [search, setSearch] = useState('');

    const { isPersonasEnabled } = useIsPersonasEnabled();
    const { personaNameToId, personaIdToName, defaultPersona } =
        usePersonasManagementRows();
    const { updateFilePersonas } = useUpdateFilePersonas();
    const { isServerlessIndex } = useServerlessIndex();

    useEffect(() => {
        setNewPersonasArr(existingData?.sort());
    }, [personaIdToName]);

    const isDisabled = !isPersonasEnabled || isServerlessIndex;

    const [visibleChips, hiddenChips] = useMemo(() => {
        if (newPersonasArr.length <= MAX_CHIPS_TO_SHOW) {
            return [newPersonasArr, []];
        }
        return [
            newPersonasArr.slice(0, MAX_CHIPS_TO_SHOW),
            newPersonasArr.slice(MAX_CHIPS_TO_SHOW)
        ];
    }, [newPersonasArr]);

    const dataToShow = useMemo(() => {
        const newData = data.filter(
            (item) =>
                !newPersonasArr.includes(item) &&
                item !== PERSONA_CONSTANT_NAMES.ALL_SOURCES
        );
        if (search) {
            return newData.filter((item) =>
                item.toLowerCase().includes(search.toLowerCase())
            );
        }
        return newData;
    }, [newPersonasArr, search]);

    const disableSumitButton = useMemo(() => {
        const isExistingHadOnlyDefault =
            existingData.length === 1 &&
            existingData[0] === PERSONA_CONSTANT_NAMES.DEFAULT;
        return (
            arraysEqual(newPersonasArr, existingData) ||
            (!newPersonasArr.length && isExistingHadOnlyDefault)
        );
    }, [newPersonasArr, existingData]);

    const onClickCell = (e) => {
        if (isDisabled) {
            return;
        }
        setAnchorEl(e.currentTarget);
    };

    const onClickSubmit = () => {
        let newPersonasIds = [defaultPersona.id];
        if (newPersonasArr.length > 0) {
            newPersonasIds = newPersonasArr
                .sort()
                .map((persona) => personaNameToId[persona]);
        } else {
            setNewPersonasArr([defaultPersona.name]);
        }
        updateFilePersonas([
            {
                model: row.tenant_model,
                filename: row.document_name,
                new_tags:
                    newPersonasIds.length > 0
                        ? newPersonasIds
                        : [defaultPersona.id]
            }
        ]);
        setAnchorEl(null);
    };

    const onClickRemove = (item: string) => {
        setNewPersonasArr(newPersonasArr.filter((persona) => persona !== item));
    };

    const onClickSelectAll = () => {
        if (newPersonasArr.length === MAX_SELECTED_PERSONAS_ALLOWED) {
            return;
        }
        setNewPersonasArr(
            newPersonasArr.concat(
                dataToShow.slice(
                    0,
                    MAX_SELECTED_PERSONAS_ALLOWED - newPersonasArr.length
                )
            )
        );
    };

    const onClickAddPersona = (persona: string) => {
        if (newPersonasArr.length === MAX_SELECTED_PERSONAS_ALLOWED) {
            return;
        }
        setNewPersonasArr([...newPersonasArr, persona]);
    };

    const onSearch = (e) => {
        setSearch(e.target.value);
    };

    const openCellView = () => {
        return (
            <div
                className={cx('multi-select-cell-full')}
                data-testid="multi-select-cell-full"
            >
                {newPersonasArr.map((item) => {
                    return (
                        <div
                            data-testid="multi-select-cell-editable-chip"
                            className={cx('multi-select-cell-editable-item')}
                            key={item}
                        >
                            <ValueWithTooltip
                                type="small1"
                                dataTestId="multi-select-chip"
                                title={item}
                                className={cx('editable-chip')}
                            />
                            <CrossMarkIcon
                                data-testid="multi-select-cell-remove-chip"
                                onClick={() => onClickRemove(item)}
                            />
                        </div>
                    );
                })}
                <input
                    onChange={onSearch}
                    className={cx('multi-select-cell-input')}
                    data-testid="multi-select-cell-input"
                    type="text"
                    placeholder="Add or select..."
                />
            </div>
        );
    };

    const closedCellView = () => {
        if (!newPersonasArr.length) {
            return null;
        }
        return visibleChips.map((item) => {
            return (
                <ValueWithTooltip
                    type="small1"
                    dataTestId="multi-select-chip"
                    title={item}
                    className={cx('multi-select-cell-item')}
                    key={item}
                />
            );
        });
    };

    const moreChipsIndicator = () => {
        return (
            <Tooltip title={hiddenChips.join(', ')} placement="top">
                <Typography
                    data-testid="multi-select-show-more"
                    type="small1"
                    className={cx('multi-select-cell-indicator')}
                >
                    +{hiddenChips.length}
                </Typography>
            </Tooltip>
        );
    };

    return (
        <>
            <Tooltip
                title={
                    isPersonasEnabled ? '' : t('add-persona.feature-disabled')
                }
                placement="top"
                classes={{
                    tooltip: 'tooltip'
                }}
            >
                <div
                    className={cx('multi-select-cell', {
                        '--disabled': isDisabled
                    })}
                    data-testid="multi-select-cell"
                    onClick={onClickCell}
                >
                    {closedCellView()}
                    {!Boolean(anchorEl) &&
                        hiddenChips.length > 0 &&
                        moreChipsIndicator()}
                </div>
            </Tooltip>
            <Popover
                id={id}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left'
                }}
            >
                <div className={cx('multi-select-cell-search-header')}>
                    {openCellView()}
                    <div
                        data-testid="multi-select-cell-search-popper"
                        className={cx('multi-select-cell-popper')}
                    >
                        <div
                            data-testid="multi-select-cell-search-popper-header"
                            className={cx(
                                'multi-select-cell-popper-select_all'
                            )}
                            onClick={onClickSelectAll}
                        >
                            <Typography type="body3">
                                {t('ingested-sources.select-all')}
                            </Typography>
                        </div>
                        <div
                            data-testid="multi-select-cell-search-popper-body"
                            className={cx('multi-select-cell-popper-body')}
                        >
                            {dataToShow.map((item) => {
                                return (
                                    <div
                                        className={cx(
                                            'multi-select-cell-popper-body-item'
                                        )}
                                    >
                                        <ValueWithTooltip
                                            type="body3"
                                            className={cx(
                                                'multi-select-cell-popper-body-item-text'
                                            )}
                                            data-testid="multi-select-cell-search-popper-body-item"
                                            key={item}
                                            title={item}
                                            onClick={() =>
                                                onClickAddPersona(item)
                                            }
                                        />
                                    </div>
                                );
                            })}
                        </div>
                        <div
                            data-testid="multi-select-cell-search-popper-footer"
                            className={cx('multi-select-cell-popper-footer')}
                        >
                            <Button
                                text={t('common.cancel')}
                                dataTestId="multi-select-cell-search-popper-cancel-button"
                                onClick={() => {
                                    setNewPersonasArr(existingData.sort());
                                    setAnchorEl(null);
                                    setSearch('');
                                }}
                            />
                            <Tooltip
                                title={
                                    newPersonasArr.length > 0
                                        ? ''
                                        : t(
                                              'ingested-sources.must-have-persona'
                                          )
                                }
                                classes={{
                                    tooltip: 'tooltip'
                                }}
                                placement="top"
                            >
                                <Button
                                    variant="filled"
                                    text={t('common.submit')}
                                    dataTestId="multi-select-cell-search-popper-submit-button"
                                    disabled={disableSumitButton}
                                    onClick={onClickSubmit}
                                />
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </Popover>
        </>
    );
};

export default MultiSelectCell;
