import { FunctionComponent } from 'react';
import { Typography } from 'aqui';
import { PlusIcon } from 'aqui/icons';
import { t } from 'i18next';
import {
    useAskStream,
    useStartNewSession,
    useThreadsHandling
} from '../../AppContext';
import useAnalytics from '../../hooks/useAnalytics';
import { ANALYTICS_EVENTS } from '../../consts';

const NewChatButton: FunctionComponent = () => {
    const { isStreamInProgress } = useAskStream();
    const { startNewSession } = useStartNewSession();
    const { isChatHistoryLoaded } = useThreadsHandling();

    const { sendEvent } = useAnalytics();
    const handleClick = () => {
        if (isStreamInProgress || !isChatHistoryLoaded) {
            return;
        }
        startNewSession();
        sendEvent(ANALYTICS_EVENTS.NEW_CHAT_CLICK);
    };

    return (
        <div
            className={`main-header__new-chat-button flex align-center ${
                isStreamInProgress || !isChatHistoryLoaded ? '--disabled' : ''
            }`}
            data-testid="new-chat-button"
            data-disabled={isStreamInProgress || !isChatHistoryLoaded}
            onClick={handleClick}
        >
            <PlusIcon />
            <Typography type="small1">{t('chat.new-chat')}</Typography>
        </div>
    );
};

export default NewChatButton;
