import React, { useEffect } from 'react';
import ModelThreads from './ModelThreads';
import DateThreads from './DateThreads';
import { useThreadsHandling } from '../../AppContext';
import { SortingType } from '../../types';
import SkeletonContainer from '../SkeletonContainer/SkeletonContainer';
import { useSelector } from 'react-redux';

const ThreadsPanelBody = () => {
    const { threadsSortingType, chatHistoryData, setChatHistoryData } =
        useThreadsHandling();

    const { investigationId } = useSelector(
        (state: any) => state.deviceInspectionReducer
    );
    const { chatId } = useSelector((state: any) => state.coPilotReducer);

    useEffect(() => {
        if (investigationId) {
            chatHistoryData.some((modelData) => {
                let foundChat = false;
                modelData.data.some((chat) => {
                    if (chat.chat_id === chatId) {
                        chat.investigation_id = investigationId;
                    }
                    foundChat = true;
                    return true;
                });
                return foundChat;
            });
            setChatHistoryData(chatHistoryData);
        }
    }, [investigationId]);

    if (!chatHistoryData.length) {
        return (
            <div>
                {[...Array(3)].map((_, index) => (
                    <div
                        key={index}
                        className="flex flex-col threadsBody__loading"
                    >
                        <SkeletonContainer single dark />
                        <div className="withSkeleton">
                            <SkeletonContainer limit={4} dark />
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    return (
        <div className="flex flex-col threadsPanel__body">
            {threadsSortingType === SortingType.Date ? (
                <DateThreads />
            ) : (
                <ModelThreads />
            )}
        </div>
    );
};

export default ThreadsPanelBody;
