export const CircleBurgerArrow = ({ color = 'currentColor', ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        {...props}
    >
        <rect width="36" height="36" rx="18" fill="#333A4D" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 12C14 11.4477 14.4477 11 15 11H23C23.5523 11 24 11.4477 24 12C24 12.5523 23.5523 13 23 13H15C14.4477 13 14 12.5523 14 12ZM11.7071 14.2929C12.0976 14.6834 12.0976 15.3166 11.7071 15.7071L9.41421 18L11.7071 20.2929C12.0976 20.6834 12.0976 21.3166 11.7071 21.7071C11.3166 22.0976 10.6834 22.0976 10.2929 21.7071L7.29289 18.7071C6.90237 18.3166 6.90237 17.6834 7.29289 17.2929L10.2929 14.2929C10.6834 13.9024 11.3166 13.9024 11.7071 14.2929ZM14 18C14 17.4477 14.4477 17 15 17H23C23.5523 17 24 17.4477 24 18C24 18.5523 23.5523 19 23 19H15C14.4477 19 14 18.5523 14 18ZM14 24C14 23.4477 14.4477 23 15 23H23C23.5523 23 24 23.4477 24 24C24 24.5523 23.5523 25 23 25H15C14.4477 25 14 24.5523 14 24Z"
            fill={color}
        />
    </svg>
);
