import { HTMLAttributes, forwardRef, useState } from 'react';
import useControlledState from '../../hooks/useControlledState';
import { Popover, PopoverOrigin } from '@mui/material';
import { Typography } from 'aqui';
import { combineRefs } from '../../utils/componentUtils';
import { ChevronIcon } from 'aqui/icons';
import classnames from 'classnames/bind';

import styles from './FilterSelect.module.scss';
import ValueWithTooltip from '../ValueWithTooltip/ValueWithTooltip';
import SelectMenu from './SelectMenu';
const cx = classnames.bind(styles);

interface FilterSelectProps extends HTMLAttributes<HTMLDivElement> {
    filters: string[];
    filter: string;
    disabled?: boolean;
    className: string;
    dataTestId?: string;
    setFilter: (filter: string) => void;
    onSelectedValue?: (filter?: string) => void;
    processing?: boolean;
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    headerText?: string;
    isOpen: boolean;
    setIsOpen: Function;
    showFooterMessage?: boolean;
    footerMessageText?: string;
    withSearch?: boolean;
    searchPlaceholder?: string;
    searchText?: string;
    setSearchText?: Function;
    inProgress?: boolean;
    selectLabel?: string;
    noValuesMessage?: string;
}

const FilterSelect = forwardRef<HTMLDivElement, FilterSelectProps>(
    (
        {
            filters,
            filter,
            disabled,
            className = '',
            dataTestId = '',
            setFilter,
            onClick,
            onSelectedValue,
            processing = false,
            anchorOrigin,
            transformOrigin,
            headerText,
            isOpen,
            setIsOpen,
            showFooterMessage = false,
            footerMessageText = '',
            withSearch = false,
            searchPlaceholder = '',
            searchText = '',
            setSearchText = () => {},
            inProgress = false,
            selectLabel = '',
            noValuesMessage,
            ...rest
        },
        ref
    ) => {
        const [anchorEl, setAnchorEl] = useState<null | Element>(null);

        const [currentFilter, setCurrentFilter] = useControlledState([
            filter,
            setFilter
        ]);

        const handleClick = (e: any) => {
            if (disabled || processing || e.target !== e.currentTarget) {
                return;
            }
            onClick?.(e);
            setIsOpen(!isOpen);
        };

        const closeHandler = () => setIsOpen(false);

        const onChangeFilter = (filter: string) => {
            setCurrentFilter(filter);
            onSelectedValue?.(filter);
            setIsOpen(false);
        };

        return (
            <div
                className={cx('filter-select', className, {
                    '--bold': currentFilter,
                    '--disabled': disabled
                })}
                data-testid={dataTestId}
                data-current={currentFilter}
                onClick={handleClick}
                ref={combineRefs(setAnchorEl, ref)}
                {...rest}
            >
                <Typography
                    type="small2"
                    onClick={handleClick}
                    className={cx('filter-select__label')}
                >
                    {selectLabel}
                </Typography>
                <ValueWithTooltip
                    title={currentFilter}
                    className={cx('filter-select__selected-filter')}
                    onClick={handleClick}
                />
                <ChevronIcon
                    onClick={handleClick}
                    className={cx('filter-select__chevron-icon', className, {
                        '--flipped': isOpen
                    })}
                    direction="down"
                />
                <Popover
                    anchorEl={anchorEl}
                    open={isOpen}
                    anchorOrigin={anchorOrigin}
                    transformOrigin={transformOrigin}
                    onClose={closeHandler}
                    classes={{
                        paper: 'popoverBox__root'
                    }}
                >
                    <SelectMenu
                        value={currentFilter}
                        values={filters}
                        onRowClick={onChangeFilter}
                        withSearch={withSearch}
                        searchPlaceholder={searchPlaceholder}
                        showFooterMessage={showFooterMessage}
                        footerMessageText={footerMessageText}
                        disabled={disabled || processing}
                        headerText={headerText}
                        searchText={searchText}
                        setSearchText={setSearchText}
                        noValuesMessage={noValuesMessage}
                    />
                </Popover>
            </div>
        );
    }
);

export default FilterSelect;
