import React, { useCallback } from 'react';
import { Typography } from 'aqui';
import ValueWithTooltip from '../ValueWithTooltip/ValueWithTooltip';
import { ANALYTICS_EVENTS, ANALYTICS_EVENT_PARAMS_KEYS } from '../../consts';
import {
    useCopilotQuestions,
    useHighlightedMessageIndex,
    useSelectedText,
    useCopilotQuestionsInProgress,
    useTriggeredMessage,
    useShowMagicButton,
    useInputMessage
} from '../../context/ChatContext';
import useAnalytics from '../../hooks/useAnalytics';
import ChatBubble from '../../layouts/ChatBubble/ChatBubble';
import CopilotQuestionsList from '../CopilotQuestionsList/CopilotQuestionsList';

const CoPilotMagicButtonPopper = ({ setOpen, popperRef }) => {
    const { copilotQuestions } = useCopilotQuestions();
    const { copilotQuestionsInProgress } = useCopilotQuestionsInProgress();
    const { selectedText } = useSelectedText();
    const { setTriggeredMessage } = useTriggeredMessage();
    const { setShowMagicButton } = useShowMagicButton();
    const { setHighlightedMessageIndex } = useHighlightedMessageIndex();
    const { sendEvent } = useAnalytics();
    const { setInputMessage } = useInputMessage();

    const onClick = useCallback((question) => {
        setOpen(false);
        setTriggeredMessage(question);
        setHighlightedMessageIndex(null);
        setShowMagicButton(false);
        sendEvent(ANALYTICS_EVENTS.RECOMMENDED_QUESTION_CLICK, {
            [ANALYTICS_EVENT_PARAMS_KEYS.QUESTION_VALUE]: question,
            [ANALYTICS_EVENT_PARAMS_KEYS.MARKED_TEXT]: selectedText
        });
    }, [selectedText]);

    const onPencilClick = useCallback((e, question) => {
        e.stopPropagation();

        setInputMessage(question);
        sendEvent(ANALYTICS_EVENTS.EDIT_SUGGESTION_QUESTION, {
            [ANALYTICS_EVENT_PARAMS_KEYS.QUESTION_VALUE]: question
        });
    }, []);

    const title = (
        <>
            {`Ask in chat `}
            <span style={{ fontWeight: 800 }}>"{selectedText}"</span>
        </>
    );

    return (
        <div
            ref={popperRef}
            className="copilot-popper__container flex flex-col"
        >
            <div
                className="flex align-center copilot-popper__question copilot-popper__selectedQuestion"
                onClick={() => onClick(selectedText)}
                data-testid="highlight-suggestions-ask-selected-text"
            >
                <ValueWithTooltip
                    title={title}
                    type="body2"
                    className="copilot-popper__selectedQuestionLabel"
                />
            </div>
            {copilotQuestionsInProgress ? (
                <div className="copilot-popper__skeleton">
                    <ChatBubble />
                    <span
                        className="copilot-popper__loading"
                        data-testid="highlight-suggestions-loading-text"
                    >
                        Loading suggestions
                    </span>
                </div>
            ) : (
                <>
                    <Typography
                        type="small2"
                        className="copilot-popper__header"
                    >
                        {'Ask service co-pilot'.toUpperCase()}
                    </Typography>
                    <CopilotQuestionsList
                        copilotQuestions={copilotQuestions}
                        onClick={onClick}
                        onPencilClick={onPencilClick}
                    />
                </>
            )}
        </div>
    );
};

export default CoPilotMagicButtonPopper;
