import { Disclaimer } from '../../types';
import { getLinksFromText } from '../../utils/utils';

export const getAggregatedDisclaimersByType = (
    disclaimers: Disclaimer[]
): Disclaimer[][] => {
    const disclaimersByTypeMap = {};
    disclaimers.forEach((disclaimer) => {
        if (!disclaimersByTypeMap[disclaimer.disclaimer_type]) {
            disclaimersByTypeMap[disclaimer.disclaimer_type] = [];
        }
        disclaimersByTypeMap[disclaimer.disclaimer_type].push(disclaimer);
    });
    return Object.values(disclaimersByTypeMap);
};

const getDisclaimerEventTrigger = (disclaimer: Disclaimer) =>
    (disclaimer.appear_in_question ? 'Question' : '') +
    (disclaimer.appear_in_question && disclaimer.appear_in_answer ? ', ' : '') +
    (disclaimer.appear_in_answer ? 'Answer' : '');

export const getDisclaimersAnalyticsData = (disclaimer: Disclaimer) => {
    const links = getLinksFromText(disclaimer.disclaimer_text).map((link) => [
        link.type,
        link.url
    ]);
    return {
        Keywords: disclaimer.keywords,
        Type: disclaimer.disclaimer_type,
        Trigger: getDisclaimerEventTrigger(disclaimer),
        Links: links.length ? links : null,
        'Disclaimer Id': disclaimer.rowid
    };
};
