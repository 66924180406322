import { t } from 'i18next';
import { COLUMNS } from './consts';

export const getColumnsData = () => [
    {
        field: COLUMNS.MODEL,
        headerName: t('expert-responses-dm.model-column'),
        minWidth: 310,
        flex: 1
    },
    {
        field: COLUMNS.INITIAL_QUESTION,
        headerName: t('expert-responses-dm.initial-question-column'),
        minWidth: 330,
        flex: 1,
        sortable: false
    },
    {
        field: COLUMNS.QUESTION,
        headerName: t('expert-responses-dm.question-column'),
        minWidth: 260,
        flex: 1,
        sortable: false
    },
    {
        field: COLUMNS.ORIGINAL_RESPONSE,
        headerName: t('expert-responses-dm.original-respons-column'),
        minWidth: 410,
        flex: 1,
        sortable: false
    },
    {
        field: COLUMNS.EXPERT_RESPONSE,
        headerName: t('expert-responses-dm.expert-response-column'),
        minWidth: 410,
        flex: 1,
        sortable: false
    },
    {
        field: COLUMNS.ACTIVE,
        headerName: t('expert-responses-dm.display-response-column'),
        width: 200,
        sortable: false
    },
    {
        field: COLUMNS.DATE,
        headerName: t('expert-responses-dm.date-column'),
        width: 170
    },
    {
        field: COLUMNS.EXPERT_MAIL,
        headerName: t('expert-responses-dm.expert-mail-column'),
        minWidth: 190,
        maxWidth: 250,
        flex: 1
    },
    {
        field: COLUMNS.ACTIONS,
        headerName: t('data-management.actions-column'),
        width: 85,
        sortable: false
    }
];
