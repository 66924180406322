import React, { FunctionComponent } from 'react';
type SkeletonContainerProps = {
    single?: boolean;
    limit?: number;
    minHeight?: string;
    borderRadius?: string;
    dots?: boolean;
    Icon?: React.ElementType;
    dotsClassName?: string;
    dark?: boolean;
};
const SkeletonContainer: FunctionComponent<SkeletonContainerProps> = ({
    single = false,
    limit = 4,
    minHeight = '24px',
    borderRadius = '4px',
    dots = false,
    Icon = null,
    dotsClassName = '',
    dark = false
}) => {
    if (dots) {
        return (
            <div className={`chat-bubble ${dotsClassName}`}>
                <div className="typing">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                </div>
            </div>
        );
    }
    if (single) {
        return (
            <div
                className={`skeleton ${dark ? 'dark' : ''}`}
                style={{
                    minHeight,
                    borderRadius
                }}
            ></div>
        );
    }

    const getRandomWidth = () => {
        return Math.floor(Math.random() * 30) + 50;
    };

    return (
        <div className="skeleton-group flex flex-col">
            {[...Array(limit)].map((_, index) => {
                return (
                    <div
                        className={`skeleton ${dark ? 'dark' : ''}`}
                        style={{
                            minHeight,
                            width: `calc(100% - ${getRandomWidth()}px`,
                            borderRadius
                        }}
                        key={index}
                    ></div>
                );
            })}
        </div>
    );
};

export default React.memo(SkeletonContainer);
