import React, { FunctionComponent } from 'react';
import { Typography } from 'aqui';
import { ChevronIcon } from 'aqui/icons';
import { MessageInfo } from '../../../types';

const LEFT = 'left';
const RIGHT = 'right';

type AnswersNavigatorProps = {
    onClickMessageArrow: Function;
    messageIndex: number;
    historyMessages: MessageInfo[];
    isSmallChat: boolean;
};

const AnswersNavigator: FunctionComponent<AnswersNavigatorProps> = ({
    onClickMessageArrow,
    messageIndex,
    historyMessages,
    isSmallChat
}) => {
    const leftArrowEnabled = messageIndex !== 0;
    const rightArrowEnabled = messageIndex !== null;
    return (
        <div
            className={`flex align-center answersNavigator__container ${
                isSmallChat ? 'smallChat' : ''
            }`}
        >
            <ChevronIcon
                className={`clickable answersNavigator__arrowIcon ${
                    leftArrowEnabled ? '' : 'disabled'
                }`}
                onClick={() => {
                    leftArrowEnabled && onClickMessageArrow(LEFT);
                }}
                direction={LEFT}
            />
            <Typography type="small2">{`${
                messageIndex === null
                    ? historyMessages.length + 1
                    : messageIndex + 1
            }/${historyMessages.length + 1}`}</Typography>
            <ChevronIcon
                className={`clickable answersNavigator__arrowIcon ${
                    rightArrowEnabled ? '' : 'disabled'
                }`}
                onClick={() => {
                    rightArrowEnabled && onClickMessageArrow(RIGHT);
                }}
                direction={RIGHT}
            />
        </div>
    );
};

export default AnswersNavigator;
