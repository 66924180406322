import { FunctionComponent, useState } from 'react';
import { t } from 'i18next';

import CoPilotMagicButton from '../../CoPilotMagicButton/CoPilotMagicButton';
import HighlightSelectedText from '../HighlightSelectedText/HighlightSelectedText';

import {
    MessageInfo,
    MagicButtonRect,
    SourceDocument,
    MessageSenderType
} from '../../../types';
import SourceTooltipContent from '../SourceTooltipContent/SourceTooltipContent';
import { getAggregatedResultDetails } from '../../KASection/KASectionHelpers';
import { useEditedMessages, useKAModal } from '../../../AppContext';
import MessageChunk from '../MessageChunk/MessageChunk';
import FullMessageEdit from '../FullMessageEdit/FullMessageEdit';
import ExpertResponseLabel from '../ExpertResponseLabel/ExpertResponseLabel';
import { useShowHighlightedText } from '../../../context/ChatContext';
import { splitTextByIndicators } from '../../../utils/utils';

type AquantSenderMessageProps = {
    senderRef: any;
    message: MessageInfo;
    askedQuestion: string;
    highlightedMessageIndex: number;
    magicButtonRect: MagicButtonRect;
    rects: any;
    messageIndex: number;
    historyMessageIndex: number;
    showMagicButton: boolean;
    historyMessages: MessageInfo[];
    setExpertEdited: (value: boolean) => void;
};

const AquantSenderMessage: FunctionComponent<AquantSenderMessageProps> = ({
    senderRef,
    message,
    askedQuestion,
    highlightedMessageIndex,
    magicButtonRect,
    rects,
    messageIndex,
    showMagicButton,
    historyMessages,
    historyMessageIndex,
    setExpertEdited
}) => {
    const [clickedEditIndex, setClickedEditIndex] = useState(null);
    const { handleShowSourceDocumentDetails } = useKAModal();
    const [showEditBtnIndex, setShowEditBtnIndex] = useState(null);
    const { indexAndHeightOfEditedEntireMessage } = useEditedMessages();
    const { showHighlightedText } = useShowHighlightedText();

    if (message.sender !== MessageSenderType.Aquant) {
        return <>{message.message}</>;
    }
    const showSelectedText =
        highlightedMessageIndex && showMagicButton
            ? highlightedMessageIndex &&
              highlightedMessageIndex === messageIndex
            : false;
    const CopilotMagicWrapper = showSelectedText && (
        <span>
            <CoPilotMagicButton
                rect={magicButtonRect}
                message={message}
                askedQuestion={askedQuestion}
            />
            {showHighlightedText && (
                <HighlightSelectedText
                    relativeRect={{
                        top: magicButtonRect.msgTop,
                        left: magicButtonRect.msgLeft
                    }}
                    rects={rects}
                />
            )}
        </span>
    );

    const onRelatedSourceClick = (selectedSource: SourceDocument) => {
        if (
            !selectedSource ||
            !message?.relatedSourceDocuments ||
            !selectedSource?.metadata?.source
        ) {
            return;
        }
        let relatedSourceDocuments = message.relatedSourceDocuments;
        if (historyMessageIndex !== null) {
            relatedSourceDocuments =
                historyMessages[historyMessageIndex].relatedSourceDocuments;
        }
        const { fileType } = getAggregatedResultDetails([selectedSource]);
        handleShowSourceDocumentDetails(
            relatedSourceDocuments.indexOf(selectedSource),
            relatedSourceDocuments,
            fileType
        );
    };

    const getTooltipTitle = (relatedSource: SourceDocument) => {
        if (!relatedSource || !relatedSource.metadata.source) {
            return t('ingested-sources.source-not-available');
        }
        return <SourceTooltipContent relatedSource={relatedSource} />;
    };

    const renderTextWithSources = (messageData = message) => {
        const textArrayWithGroupedSourceIndications = splitTextByIndicators(
            messageData.message
        );

        if (indexAndHeightOfEditedEntireMessage[messageIndex]) {
            return (
                <FullMessageEdit
                    textArr={textArrayWithGroupedSourceIndications}
                    messageIndex={messageIndex}
                    setExpertEdited={setExpertEdited}
                    noResponse={message.noResponse}
                    historyMessageIndex={historyMessageIndex}
                />
            );
        }
        return textArrayWithGroupedSourceIndications.map((segment, index) => {
            let showEditOnNewParagraph = false;
            const lastSegment =
                textArrayWithGroupedSourceIndications[index - 1];
            if (
                segment === '\n\n' &&
                !lastSegment?.includes('SOURCE_') &&
                !lastSegment?.includes('EXPERT_EDIT')
            ) {
                showEditOnNewParagraph = true;
            }
            return (
                <MessageChunk
                    messageIndex={messageIndex}
                    key={index + messageIndex}
                    index={index}
                    segment={segment}
                    messageData={messageData}
                    onRelatedSourceClick={onRelatedSourceClick}
                    clickedEditIndex={clickedEditIndex}
                    setClickedEditIndex={setClickedEditIndex}
                    setShowEditBtnIndex={setShowEditBtnIndex}
                    showEditBtn={showEditBtnIndex === index}
                    getTooltipTitle={getTooltipTitle}
                    showEditOnNewParagraph={showEditOnNewParagraph}
                    textArr={textArrayWithGroupedSourceIndications}
                    setExpertEdited={setExpertEdited}
                    historyMessageIndex={historyMessageIndex}
                />
            );
        });
    };

    const showExpertDetails = () => {
        if (
            historyMessageIndex !== null &&
            !historyMessages[historyMessageIndex].expertDetails
        ) {
            return;
        } else if (historyMessageIndex === null && !message.expertDetails) {
            return;
        }
        let date = message?.expertDetails?.date;
        let name = message?.expertDetails?.name;
        if (historyMessageIndex !== null) {
            date = historyMessages[historyMessageIndex].expertDetails.date;
            name = historyMessages[historyMessageIndex].expertDetails.name;
        }
        return <ExpertResponseLabel name={name} date={date} />;
    };

    if (historyMessageIndex || historyMessageIndex === 0) {
        console.debug('*', [historyMessageIndex, historyMessages]);
        return (
            <div ref={senderRef}>
                {showExpertDetails()}
                {renderTextWithSources(historyMessages[historyMessageIndex])}
                {CopilotMagicWrapper}
            </div>
        );
    }

    if (message.promptInfo?.default_standalone_prompt) {
        return (
            <div ref={senderRef}>
                <div>
                    Here’s the answer to
                    <span className="bold">{` "${message.promptInfo?.default_standalone_prompt}"`}</span>
                    :
                </div>
                <br />
                {CopilotMagicWrapper}
                <div>{renderTextWithSources()}</div>
            </div>
        );
    }

    return (
        <div ref={senderRef}>
            {showExpertDetails()}
            {renderTextWithSources()} {CopilotMagicWrapper}
        </div>
    );
};

export default AquantSenderMessage;
