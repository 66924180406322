import React, { HTMLAttributes, ReactNode, forwardRef } from 'react';
import classnames from 'classnames/bind';

import styles from './Menu.module.scss';
const cx = classnames.bind(styles);

interface MenuProps extends HTMLAttributes<HTMLDivElement> {
    className?: string;
    dataTestId?: string;
    noShadow?: boolean;
    children?: ReactNode;
}

const Menu = forwardRef<HTMLDivElement, MenuProps>(
    (
        {
            children,
            className = 'menu',
            dataTestId = '',
            noShadow = false,
            ...rest
        },
        ref
    ) => {
        return (
            <div
                ref={ref}
                data-testid={dataTestId}
                className={cx('menu', className, { '--no-shadow': noShadow })}
                {...rest}
            >
                {children}
            </div>
        );
    }
);

export default Menu;
