import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import NewChatButton from './NewChatButton';
import MoreOptionsButton from './MoreOptionsButton';
import ThreadsButton from './ThreadsButton';
import { Fade } from '@material-ui/core';
import useSwitchPanel from '../../hooks/useSwitchPanel';
import useMoreOptionsRows from './useMoreOptionsRows';

const MainHeader: FunctionComponent = () => {
    const customerLogo = useSelector((state: any) => state.logoReducer.logo);
    const { isDataManagementPanel, isCoPilotPanel } = useSwitchPanel();
    const { rows, hasRowsToShow } = useMoreOptionsRows();
    const { isUsingCustomLogo } = useSelector(
        (state: any) => state.globalReducer
    );
    const shouldShowNewChatButton = isCoPilotPanel;

    return (
        <div className="main-header">
            <div className="main-header__left flex align-center">
                <Fade in={!isDataManagementPanel} timeout={350}>
                    <div>
                        <ThreadsButton />
                    </div>
                </Fade>
                <div className="main-header__customer-logo">
                    {customerLogo && <img src={customerLogo} alt="logo" />}
                </div>
            </div>
            <div className="main-header__right flex align-center">
                {shouldShowNewChatButton && <NewChatButton />}
                <MoreOptionsButton
                    rows={rows}
                    hasRowsToShow={hasRowsToShow}
                    withAquantIcon={!isUsingCustomLogo}
                />
            </div>
        </div>
    );
};

export default MainHeader;
