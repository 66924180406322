import { FunctionComponent, useMemo } from 'react';
import { useIngestedSourcesFilters } from './IngestedSourcesHooks';
import FilterMultiSelect from '../filters/FiltersMultiSelect';
import { t } from 'i18next';
import FilterSelect from '../filters/FilterSelect';
import { useDataManagementContext } from '../../../context/DataManagementContext';
import { getStatusFilterItems } from './IngestedSourcesHelpers';
import { FiltersRow } from 'aqui';
import DateRangeFilter from '../filters/DateRangeFilter';

const IngestedSourcesFilters: FunctionComponent = () => {
    const {
        setModelsToFilter,
        ingestedSourcesFilters,
        setStatusToFilter,
        setUserToFilter,
        ingestedSourcesUsers,
        ingestedSourcesModels,
        setPersonaToFilter,
        ingestedSourcesPersonas,
        setCreateDateToFilter
    } = useIngestedSourcesFilters();
    const { areIngestedSourcesFetching } = useDataManagementContext();

    const statusFilterItems = useMemo(getStatusFilterItems, []);

    return (
        <FiltersRow withFilterIcon={false}>
            <FilterMultiSelect
                filters={ingestedSourcesModels}
                selectedFilters={ingestedSourcesFilters.models}
                onApplyClick={setModelsToFilter}
                allFilterLabel={`${t('model.models')}: ${t('common.all')}`}
                dataTestId="ingested-sources-models-filter"
                disabled={
                    !ingestedSourcesModels.length || areIngestedSourcesFetching
                }
            />
            <DateRangeFilter
                onChange={setCreateDateToFilter}
                allFilterLabel={`${t('data-management.create-date')}: ${t(
                    'common.all'
                )}`}
                dataTestId="ingested-sources-create-date-filter"
                disabled={areIngestedSourcesFetching}
                minDate={ingestedSourcesFilters.minCreateDate}
                maxDate={ingestedSourcesFilters.maxCreateDate}
            />
            <FilterSelect
                items={ingestedSourcesUsers}
                selectedItem={ingestedSourcesFilters.user}
                onChange={setUserToFilter}
                allFilterLabel={`${t('data-management.user')}: ${t(
                    'common.all'
                )}`}
                disabled={
                    !ingestedSourcesUsers.length || areIngestedSourcesFetching
                }
                dataTestId="ingested-sources-user-filter"
            />
            <FilterSelect
                items={statusFilterItems}
                selectedItem={ingestedSourcesFilters.status}
                onChange={setStatusToFilter}
                allFilterLabel={`${t('data-management.ingest-status')}: ${t(
                    'common.all'
                )}`}
                disabled={areIngestedSourcesFetching}
                dataTestId="ingested-sources-ingest-status-filter"
            />
            <FilterSelect
                items={ingestedSourcesPersonas}
                selectedItem={ingestedSourcesFilters.persona}
                onChange={setPersonaToFilter}
                allFilterLabel={`${t('data-management.Persona')}: ${t(
                    'common.all'
                )}`}
                disabled={
                    !ingestedSourcesPersonas.length ||
                    areIngestedSourcesFetching
                }
                dataTestId="ingested-sources-persona-filter"
            />
        </FiltersRow>
    );
};

export default IngestedSourcesFilters;
