import { CheckBox } from 'aqui';
import { useMemo } from 'react';

interface CheckboxColumnHeaderProps {
    rows: any[];
    selectedRows: any[];
    setSelectedRows: React.Dispatch<React.SetStateAction<any[]>>;
    disabledRows: any[];
}

const CheckboxColumnHeader: React.FC<CheckboxColumnHeaderProps> = ({
    rows,
    selectedRows,
    setSelectedRows,
    disabledRows = []
}) => {
    const selectedRowIdsSet = useMemo(
        () => new Set(selectedRows.map((row: any) => row.id)),
        [selectedRows]
    );

    const checkAllVisibleRows = () => {
        const newRows = rows.filter(
            (row: any) => !selectedRowIdsSet.has(row.id)
        );
        setSelectedRows((prevRows: any[]) => [...prevRows, ...newRows]);
    };

    const uncheckAllVisibleRows = () => {
        setSelectedRows((prevRows: any[]) =>
            prevRows.filter(
                (prevRow: any) =>
                    !rows.some((row: any) => row.id === prevRow.id)
            )
        );
    };

    const areAllVisibleRowsSelected = useMemo(() => {
        const disabledRowIdsSet = new Set(disabledRows.map((row) => row.id));
        const enabledRows = rows.filter(
            (row) => !disabledRowIdsSet.has(row.id)
        );
        return enabledRows.every((row) => selectedRowIdsSet.has(row.id));
    }, [rows, selectedRowIdsSet, disabledRows]);

    const isAnyVisibleRowSelected = useMemo(
        () =>
            !!rows.length && rows.some((row) => selectedRowIdsSet.has(row.id)),
        [rows, selectedRowIdsSet]
    );

    const toggleAllVisibleSelectedRows = () => {
        if (areAllVisibleRowsSelected) {
            uncheckAllVisibleRows();
        } else {
            checkAllVisibleRows();
        }
    };

    return (
        <CheckBox
            intermediate={!areAllVisibleRowsSelected}
            checked={isAnyVisibleRowSelected}
            color="lightgrey"
            controlled
            onClick={toggleAllVisibleSelectedRows}
        />
    );
};

export default CheckboxColumnHeader;
