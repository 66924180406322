import React from 'react';
import styles from './Cells.module.scss';
import classnames from 'classnames/bind';
import { Typography } from 'aqui';
import { Tooltip } from '@mui/material';

const cx = classnames.bind(styles);

const ClickableCell = ({
    field,
    row,
    onClick,
    tooltip = '',
    text = '',
    icon
}) => {
    return (
        <Tooltip
            title={tooltip}
            placement="top"
            classes={{
                tooltip: 'tooltip'
            }}
        >
            <div
                className={cx('clickableCell__container')}
                data-testid="clickable-cell"
                onClick={onClick}
            >
                <Typography type="subtitle3">{`${row[field]} ${text}`}</Typography>
                {icon}
            </div>
        </Tooltip>
    );
};

export default ClickableCell;
