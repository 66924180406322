const VideoSmallIcon = ({ color = 'currentColor', ...props }) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.3273 8.37796C10.437 8.28299 10.5 8.14507 10.5 8C10.5 7.85493 10.437 7.71701 10.3273 7.62204L7.32733 5.02396C7.17937 4.89583 6.97026 4.8658 6.79223 4.94714C6.6142 5.02847 6.5 5.2062 6.5 5.40192V10.5981C6.5 10.7938 6.6142 10.9715 6.79223 11.0529C6.97026 11.1342 7.17937 11.1042 7.32733 10.976L10.3273 8.37796ZM7.5 9.50362V6.49637L9.23624 8L7.5 9.50362Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5ZM2.5 8C2.5 4.96243 4.96243 2.5 8 2.5C11.0376 2.5 13.5 4.96243 13.5 8C13.5 11.0376 11.0376 13.5 8 13.5C4.96243 13.5 2.5 11.0376 2.5 8Z"
                fill={color}
            />
        </svg>
    );
};

export default VideoSmallIcon;
