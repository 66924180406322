import { useState } from 'react';

const useSessionStorage = (
    keyName: string,
    defaultValue: any
): [any, (newValue: any) => void] => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const value = window.sessionStorage.getItem(keyName);

            if (value) {
                return JSON.parse(value);
            } else {
                window.sessionStorage.setItem(
                    keyName,
                    JSON.stringify(defaultValue)
                );
                return defaultValue;
            }
        } catch (err: any) {
            window.sentry.log(err);
            return defaultValue;
        }
    });

    const setValue = (newValue: any) => {
        try {
            window.sessionStorage.setItem(keyName, JSON.stringify(newValue));
        } catch (err: any) {
            window.sentry.log(err);
        }
        setStoredValue(newValue);
    };

    return [storedValue, setValue];
};

export default useSessionStorage;
