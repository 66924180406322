import { FunctionComponent } from 'react';
import { Document } from 'react-pdf';

const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/'
};

type PDFValidatorProps = {
    file: File;
    onPassword?: (callback: Function) => void;
    onSuccess?: (doc: any) => void;
    onError?: (error: any) => void;
};

const PDFValidator: FunctionComponent<PDFValidatorProps> = ({
    file,
    onSuccess,
    onPassword,
    onError
}) => {
    return (
        <Document
            file={file}
            onLoadSuccess={(doc: any) => onSuccess(doc)}
            options={options}
            onPassword={onPassword}
            loading={''}
            noData={''}
            error={''}
            onLoadError={(error: any) => onError?.(error)}
            onSourceError={(error: any) => onError?.(error)}
        />
    );
};

export default PDFValidator;
