import { Button, SearchInput } from 'aqui';
import { t } from 'i18next';
import { FunctionComponent, useEffect, useState } from 'react';
import { useUploadFilesModal } from '../../UploadFilesModal/UploadFilesModalHooks';
import RefreshIconStandardSize from '../../../SVG/RefreshIconStandardSize';
import IngestedSourcesFilters from './IngestedSourcesFilters';
import {
    useIngestedSourceRows,
    useIngestedSourcesFilters,
    useMoreOptionsRowsIngestedSources,
    useUpdateFilePersonas
} from './IngestedSourcesHooks';
import { debounce } from 'lodash';
import MoreOptionsButton from '../../MainHeader/MoreOptionsButton';
import { useScopSettings } from '../../../AppContext';
import { UPDATING_ALL } from './consts';

const IngestedSourcesHeader: FunctionComponent = () => {
    const { openUploadFilesModal } = useUploadFilesModal();
    const { setIngestedSourceSearchTerm } = useIngestedSourcesFilters();
    const { fetchIngestedSourceRowsAndPersonas, areIngestedSourcesFetching } =
        useIngestedSourceRows();
    const { rows, hasRowsToShow } = useMoreOptionsRowsIngestedSources();
    const { enableSourceImportByAdminAndExpert } = useScopSettings();
    const { updatingPersonas } = useUpdateFilePersonas();
    const [searchTerm, setSearchTerm] = useState<string>('');

    useEffect(() => {
        debounce(() => setIngestedSourceSearchTerm(searchTerm), 500)();
    }, [searchTerm]);

    return (
        <div className="data-management-table__header">
            <IngestedSourcesFilters />
            <div className="data-management-table__header__section">
                <Button
                    dataTestId="ingested-sources-refresh-btn"
                    IconComponent={RefreshIconStandardSize}
                    variant="text"
                    shape="circle"
                    onClick={() => fetchIngestedSourceRowsAndPersonas()}
                />
                {enableSourceImportByAdminAndExpert && (
                    <Button
                        variant="filled"
                        text={t('upload-files-modal.open-btn-title')}
                        dataTestId="open-upload-files-modal-btn"
                        onClick={openUploadFilesModal}
                        disabled={
                            areIngestedSourcesFetching ||
                            updatingPersonas.has(UPDATING_ALL)
                        }
                    />
                )}
                <MoreOptionsButton
                    rows={rows}
                    hasRowsToShow={hasRowsToShow}
                    disabled={
                        areIngestedSourcesFetching ||
                        updatingPersonas.has(UPDATING_ALL)
                    }
                />
                <SearchInput
                    className={'data-management-table__search'}
                    placeholder={t('ingested-sources.search-filename')}
                    value={searchTerm}
                    setValue={setSearchTerm}
                    disabled={areIngestedSourcesFetching}
                    dataTestId="ingested-sources-search"
                />
            </div>
        </div>
    );
};

export default IngestedSourcesHeader;
