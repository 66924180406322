import { forwardRef, ReactNode } from 'react';
import { Tooltip } from '@mui/material';
import classnames from 'classnames/bind';

import styles from './FloatingMenu.module.scss';
const cx = classnames.bind(styles);

interface FloatingMenuItemProps {
    children: ReactNode;
    className?: string;
    disabled?: boolean;
    onClick?: () => void;
    dataTestId?: string;
    tooltipTitle?: string;
}

const FloatingMenuItem = forwardRef<HTMLDivElement, FloatingMenuItemProps>(
    (
        {
            children,
            className,
            disabled = false,
            onClick = null,
            dataTestId = '',
            tooltipTitle = '',
            ...rest
        },
        ref
    ) => (
        <Tooltip title={tooltipTitle} classes={{ tooltip: 'tooltip' }}>
            <div
                ref={ref}
                data-testid={dataTestId}
                onClick={() => (!disabled && onClick ? onClick() : null)}
                className={cx(className, 'floating-menu__item', {
                    '--clickable': !disabled && onClick,
                    '--disabled': disabled
                })}
                {...rest}
            >
                {children}
            </div>
        </Tooltip>
    )
);

export default FloatingMenuItem;
