import React, { FunctionComponent } from 'react';
import { CheckBox, Typography } from 'aqui';
import ValueWithTooltip from '../ValueWithTooltip/ValueWithTooltip';
import { IngestedSourcesRow } from '../../types';
import { adjustIntegratedModel } from '../../utils/productUtils';

type CheckBoxSourceListProps = {
    data: IngestedSourcesRow[];
    selectedSources: string[];
    setSelectedSources: (selectedSources: string[]) => void;
};

const CheckBoxSourceList: FunctionComponent<CheckBoxSourceListProps> = ({
    data,
    selectedSources,
    setSelectedSources
}) => {
    const handleCheckboxChange = (source: string, selected: boolean) => {
        if (selected) {
            setSelectedSources(
                selectedSources.filter(
                    (selectedSource) => selectedSource !== source
                )
            );
        } else {
            setSelectedSources([...selectedSources, source]);
        }
    };

    return (
        <div
            data-testid="checkbox-source-list-container"
            className="checkboxSourceList__container"
        >
            {data.map((source) => {
                const isSelected = selectedSources.includes(source.id);

                const adjustModel = source.integratedModel
                    ? adjustIntegratedModel(source.integratedModel)
                    : source.tenant_model;

                return (
                    <div
                        key={source.id}
                        data-testid="checkbox-source-list-item-wrapper"
                        className="flex justify-flex-start align-flex-start checkboxSourceList__item-wrapper"
                    >
                        <CheckBox
                            checked={isSelected}
                            controlled
                            onClick={() =>
                                handleCheckboxChange(source.id, isSelected)
                            }
                            dataTestId="checkbox-cell"
                        />
                        <div
                            data-testid="checkbox-source-list-item-name"
                            className="checkboxSourceList__item-name"
                        >
                            <ValueWithTooltip
                                title={source.document_name}
                                className="checkboxSourceList__item-name__main"
                                dataTestId="source-main-name"
                            />
                            <Typography
                                type="small1"
                                dataTestId="source-secondary-name"
                                className="checkboxSourceList__item-name__secondary"
                            >
                                {adjustModel}
                            </Typography>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default CheckBoxSourceList;
