import { IntegratedModel, ProductDetailsResponsePayload } from '../types';
import { isEqualStrings } from './utils';
import { unescape } from 'lodash';

export const getIntegratedModels = (
    productDetails: Array<ProductDetailsResponsePayload>,
    copilotModelNames: Array<string>
): Array<IntegratedModel> => {
    let triageModelNames = new Set<string>();
    const triageModels =
        productDetails?.map((pd) => {
            triageModelNames.add(pd.model.name.toLowerCase());
            return {
                modelName: pd.model.name,
                modelDisplay: pd.model.display,
                productTypeName: pd.producttype.name,
                productTypeDisplay: pd.producttype.display,
                manufacturerName: pd.manufacturer.name,
                manufacturerDisplay: pd.manufacturer.display,
                productIds: {
                    productTypeId: pd.producttype.id,
                    manufacturerId: pd.manufacturer.id,
                    productId: pd.model.id
                }
            };
        }) || [];
    const copilotModels =
        copilotModelNames
            ?.filter(
                (modelName) => !triageModelNames.has(modelName.toLowerCase())
            )
            .map((modelName) => {
                return {
                    modelName
                };
            }) || [];
    return [...triageModels, ...copilotModels];
};

export const getIntegratedModelDisplay = (model: IntegratedModel) =>
    model?.modelDisplay || model?.modelName || '';

export const adjustIntegratedModels = (
    integratedModels: Array<IntegratedModel>
) => {
    return integratedModels.map((model) => adjustIntegratedModel(model));
};

export const adjustIntegratedModel = (integratedModel: IntegratedModel) => {
    const productTypeName =
        integratedModel.productTypeDisplay ||
        integratedModel.productTypeName ||
        '';
    const manufacturerName =
        integratedModel.manufacturerDisplay ||
        integratedModel.manufacturerName ||
        '';

    const ptText =
        productTypeName && manufacturerName
            ? `(${productTypeName} \u2022 ${manufacturerName})`
            : productTypeName
            ? `(${productTypeName})`
            : manufacturerName
            ? `(${manufacturerName})`
            : '';

    return `${getIntegratedModelDisplay(integratedModel)} ${ptText}`;
};

export const getModelNameToObject = (
    integratedModels: Array<IntegratedModel>,
    shouldUseOnlyModelName = false
): Record<string, IntegratedModel> => {
    const nameToObject: Record<string, IntegratedModel> = {};
    integratedModels.forEach((model: IntegratedModel) => {
        if (shouldUseOnlyModelName) {
            nameToObject[model.modelName] = model;
        } else {
            nameToObject[adjustIntegratedModel(model)] = model;
        }
    });
    return nameToObject;
};

export const getModelFromIntegratedModels = (
    integratedModels: Array<IntegratedModel>,
    model: string,
    productType?: string,
    manufacturer?: string
): IntegratedModel | undefined => {
    if (productType && manufacturer) {
        return integratedModels?.find(
            (integrateModel) =>
                (isEqualStrings(integrateModel.modelName, model) ||
                    isEqualStrings(integrateModel.modelDisplay, model)) &&
                (isEqualStrings(integrateModel.productTypeName, productType) ||
                    isEqualStrings(
                        integrateModel.productTypeDisplay,
                        productType
                    )) &&
                (isEqualStrings(
                    integrateModel.manufacturerName,
                    manufacturer
                ) ||
                    isEqualStrings(
                        integrateModel.manufacturerDisplay,
                        manufacturer
                    ))
        );
    }
    return integratedModels?.find(
        (integrateModel) =>
            isEqualStrings(integrateModel.modelName, model) ||
            isEqualStrings(integrateModel.modelDisplay, model)
    );
};

export const getModelFromIntegratedModelsByIds = (
    integratedModels: Array<IntegratedModel>,
    modelName: string,
    productTypeId?: string,
    manufacturerId?: string
): IntegratedModel | undefined => {
    if (productTypeId && manufacturerId) {
        return integratedModels?.find(
            (integratedModel) =>
                isEqualStrings(integratedModel.modelName, modelName) &&
                isEqualStrings(
                    integratedModel.productIds.productTypeId,
                    productTypeId
                ) &&
                isEqualStrings(
                    integratedModel.productIds.manufacturerId,
                    manufacturerId
                )
        );
    }
    return integratedModels?.find((integratedModel) =>
        isEqualStrings(integratedModel.modelName, modelName)
    );
};

export const checkIfModelExistsInTriage = (integratedModels: Array<IntegratedModel>, modelName: string) => {
    return integratedModels.some((integratedModel) => integratedModel.modelName === modelName && !!integratedModel.productIds);
}

const parseCatalogDataManufacturerResponse = (data: any) => {
    return data
        ? {
              ...data,
              extractID: data?.manufacturer_id || data?.id || '',
              extractName: data?.name || data?.manufacturer_name || '',
              extractDisplayed: unescape(
                  data?.translation?.display ||
                      data?.display ||
                      data?.name ||
                      ''
              )
          }
        : undefined;
};

const parseCatalogDataProductTypeResponse = (data: any) => {
    return data
        ? {
              ...data,
              extractID: data?.producttype_id || data?.id || '',
              extractDisplayed: unescape(
                  data?.translation?.display ||
                      data?.display ||
                      data?.name ||
                      ''
              )
          }
        : undefined;
};

export const parseCatalogModelResponseToIntegratedModels = (
    data: any
): Array<IntegratedModel> => {
    const manufacturers = data?.additional_data?.manufacturers;
    const productTypes = data?.additional_data?.product_types;
    const catalogData = (data?.catalog_data || []).map((product) => {
        const manufacturer = parseCatalogDataManufacturerResponse(
            manufacturers[product.manufacturer_id || '']
        );
        const productType = parseCatalogDataProductTypeResponse(
            productTypes[product.producttype_id || '']
        );
        return {
            modelName: product?.name || product?.model_name || '',
            modelDisplay: unescape(
                product?.translation?.display ||
                    product?.display ||
                    product?.name ||
                    ''
            ),
            productTypeName: productType?.producttype_name || '',
            productTypeDisplay: unescape(productType?.extractDisplayed || ''),
            manufacturerName: manufacturer?.manufacturer_name || '',
            manufacturerDisplay: unescape(
                manufacturer?.translation?.display ||
                    manufacturer?.display ||
                    manufacturer?.name ||
                    ''
            ),
            productIds: {
                productTypeId: productType.extractID,
                manufacturerId: manufacturer.extractID,
                productId: product?.product_id || product?.id || ''
            }
        };
    });

    return catalogData;
};

export const getModelDisplaysWithMap = (ingestedModels: IntegratedModel[]) => {
    const modelNamesSet: Set<string> = new Set();
    const integratedModelsMap: Record<string, IntegratedModel> = {};
    const modelDisplays = ingestedModels.map((model) => {
        const modelDisplay = getIntegratedModelDisplay(model);
        modelNamesSet.add(model.modelName);
        integratedModelsMap[modelDisplay] = model;
        return modelDisplay;
    });
    return {
        modelDisplays: modelDisplays.sort(),
        integratedModelsMap,
        modelNamesSet
    };
};

export const mergeCatalogAndUploadedModels = (
    catalogModels: IntegratedModel[],
    modelsWithUploadedFiles: string[]
): IntegratedModel[] => {
    const catalogModelsNamesMap = getModelNameToObject(catalogModels, true);
    const filteredModelsWithUploadedFiles: IntegratedModel[] =
        modelsWithUploadedFiles
            .filter((modelName) => !catalogModelsNamesMap[modelName])
            .map((modelName) => ({ modelName }));
    const ingestModelList = [
        ...Object.values(catalogModelsNamesMap),
        ...filteredModelsWithUploadedFiles
    ];
    return ingestModelList;
};
