import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Typography
} from 'aqui';
import { WarningIcon } from 'aqui/icons';
import { t } from 'i18next';
import { useFailedImportData } from '../../../context/DataManagementContext';

const FailedImportModal = () => {
    const { failedImportData, setFailedImportData } = useFailedImportData();

    const onClickOk = () => {
        setFailedImportData([]);
    };

    return (
        <Modal
            isOpen={Boolean(failedImportData.length)}
            closeModal={() => setFailedImportData([])}
        >
            <ModalHeader>{t('import-failed.import-failed')}</ModalHeader>
            <ModalBody className="failedImport__body">
                <div className="flex flex-col failedImport__container">
                    <div className="flex align-center failedImport__title">
                        <WarningIcon className="failedImport__title__icon" />
                        <Typography
                            type="body3"
                            className="failedImport__title__text"
                        >
                            {t('import-failed.reason')}
                        </Typography>
                    </div>
                    <div className={`failedImport__hidden-data`}>
                        <div className="flex flex-col failedImport__reasons">
                            {failedImportData.map((reason) => (
                                <Typography type="body3">{`${'\u2022'} ${reason}`}</Typography>
                            ))}
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                    dataTestId="ok-btn-import-failed-modal"
                    color="blue"
                    onClick={onClickOk}
                    text={t('common.ok')}
                    variant="filled"
                />
            </ModalFooter>
        </Modal>
    );
};

export default FailedImportModal;
