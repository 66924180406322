import React from 'react';
const PersonIconMedium = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.99984 2.66666C8.70708 2.66666 9.38536 2.94762 9.88546 3.44771C10.3856 3.94781 10.6665 4.62609 10.6665 5.33333C10.6665 6.04057 10.3856 6.71885 9.88546 7.21895C9.38536 7.71905 8.70708 8 7.99984 8C7.29259 8 6.61432 7.71905 6.11422 7.21895C5.61412 6.71885 5.33317 6.04057 5.33317 5.33333C5.33317 4.62609 5.61412 3.94781 6.11422 3.44771C6.61432 2.94762 7.29259 2.66666 7.99984 2.66666ZM7.99984 13.3333C7.99984 13.3333 13.3332 13.3333 13.3332 12C13.3332 10.4 10.7332 8.66666 7.99984 8.66666C5.2665 8.66666 2.6665 10.4 2.6665 12C2.6665 13.3333 7.99984 13.3333 7.99984 13.3333Z"
                fill="#050F29"
            />
        </svg>
    );
};

export default PersonIconMedium;
