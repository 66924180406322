import React, { ReactNode } from 'react';
import { Fade } from '@mui/material';
import classnames from 'classnames/bind';

import styles from './FloatingMenu.module.scss';
const cx = classnames.bind(styles);

interface FloatingMenuProps {
    className?: string;
    children: ReactNode;
    dataTestId?: string;
    withTransition?: boolean;
}

const FloatingMenu: React.FC<FloatingMenuProps> = ({
    className,
    children,
    dataTestId = 'floatingMenu',
    withTransition = false
}) => (
    <Fade in={withTransition} style={{ transitionDelay: '100ms' }}>
        <div
            data-testid={dataTestId}
            className={cx(className, 'floating-menu')}
        >
            {children}
        </div>
    </Fade>
);

export default FloatingMenu;
