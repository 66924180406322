import React from 'react';
import classnames from 'classnames/bind';
import { ArrowIcon } from 'aqui/icons';

import styles from '../../Table.module.scss';
const cx = classnames.bind(styles);

const AscendingIcon = () => (
    <ArrowIcon className={cx('data-grid__sort-icon')} />
);

export default AscendingIcon;
