import React from 'react';
const Sparkles = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.99996 5.83398C10.1799 5.83398 10.3395 5.94952 10.3958 6.12046L11.281 8.81167L11.281 8.81167C11.3059 8.88743 11.3482 8.95627 11.4046 9.01266C11.461 9.06905 11.5299 9.11141 11.6056 9.13632L11.6056 9.13633L14.2968 10.0215C14.4678 10.0777 14.5833 10.2374 14.5833 10.4173C14.5833 10.5973 14.4678 10.7569 14.2968 10.8131L11.6056 11.6983L11.6056 11.6983C11.5299 11.7232 11.461 11.7656 11.4046 11.822C11.3482 11.8784 11.3059 11.9472 11.281 12.023L11.281 12.023L10.3958 14.7142C10.3395 14.8851 10.1799 15.0007 9.99996 15.0007C9.82 15.0007 9.66038 14.8851 9.60415 14.7142L8.71897 12.023L8.71897 12.023C8.69405 11.9472 8.65169 11.8784 8.5953 11.822C8.53891 11.7656 8.47007 11.7232 8.39431 11.6983L8.39431 11.6983L5.70311 10.8131C5.53216 10.7569 5.41663 10.5973 5.41663 10.4173C5.41663 10.2374 5.53216 10.0777 5.70311 10.0215L8.39431 9.13633L8.39431 9.13632C8.47007 9.11141 8.53891 9.06905 8.5953 9.01266C8.65169 8.95627 8.69405 8.88743 8.71897 8.81167L8.71897 8.81167L9.60415 6.12046C9.66038 5.94952 9.82 5.83398 9.99996 5.83398ZM9.51058 9.07204C9.51058 9.07204 9.51058 9.07204 9.51058 9.07204C9.44489 9.27176 9.33322 9.45325 9.18456 9.60192C9.0359 9.75058 8.8544 9.86225 8.65468 9.92794C8.65468 9.92794 8.65468 9.92794 8.65468 9.92794L7.16684 10.4173L8.65468 10.9067C8.65468 10.9067 8.65468 10.9067 8.65468 10.9067C8.8544 10.9724 9.0359 11.0841 9.18456 11.2327C9.33322 11.3814 9.44489 11.5629 9.51058 11.7626C9.51058 11.7626 9.51058 11.7626 9.51058 11.7626L9.99996 13.2504L10.4893 11.7626C10.4893 11.7626 10.4893 11.7626 10.4893 11.7626C10.555 11.5629 10.6667 11.3814 10.8154 11.2327C10.964 11.0841 11.1455 10.9724 11.3452 10.9067C11.3452 10.9067 11.3452 10.9067 11.3452 10.9067L12.8331 10.4173L11.3452 9.92794C11.3452 9.92794 11.3452 9.92794 11.3452 9.92794C11.1455 9.86225 10.964 9.75058 10.8154 9.60192C10.6667 9.45325 10.555 9.27176 10.4893 9.07204C10.4893 9.07204 10.4893 9.07204 10.4893 9.07204L9.99996 7.5842L9.51058 9.07204Z"
                fill="currentcolor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.08329 5.83268C7.08329 5.60256 6.89674 5.41602 6.66663 5.41602C6.43651 5.41602 6.24996 5.60256 6.24996 5.83268V6.24935H5.83329C5.60317 6.24935 5.41663 6.4359 5.41663 6.66602C5.41663 6.89613 5.60317 7.08268 5.83329 7.08268H6.24996V7.49935C6.24996 7.72947 6.43651 7.91602 6.66663 7.91602C6.89674 7.91602 7.08329 7.72947 7.08329 7.49935V7.08268H7.49996C7.73008 7.08268 7.91663 6.89613 7.91663 6.66602C7.91663 6.4359 7.73008 6.24935 7.49996 6.24935H7.08329V5.83268Z"
                fill="currentcolor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.75 12.5007C13.75 12.2705 13.5635 12.084 13.3334 12.084C13.1033 12.084 12.9167 12.2705 12.9167 12.5007V12.9173H12.5C12.2699 12.9173 12.0834 13.1039 12.0834 13.334C12.0834 13.5641 12.2699 13.7507 12.5 13.7507H12.9167V14.1673C12.9167 14.3974 13.1033 14.584 13.3334 14.584C13.5635 14.584 13.75 14.3974 13.75 14.1673V13.7507H14.1667C14.3968 13.7507 14.5834 13.5641 14.5834 13.334C14.5834 13.1039 14.3968 12.9173 14.1667 12.9173H13.75V12.5007Z"
                fill="currentcolor"
            />
        </svg>
    );
};

export default Sparkles;
