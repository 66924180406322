import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Fade, Popper } from '@mui/material';
import ValueWithTooltip from '../ValueWithTooltip/ValueWithTooltip';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import useAnalytics from '../../hooks/useAnalytics';
import { ANALYTICS_EVENTS } from '../../consts';
import { useAppContext, useScopSettings } from '../../AppContext';
import { State as PopperState } from '@popperjs/core';
import { useSelector } from 'react-redux';
import { useSmallChatInPortal } from '../../context/ChatContext';

const ARROW_DOWN = 'ArrowDown';
const ARROW_UP = 'ArrowUp';
const ENTER = 'Enter';

type SearchSuggestionsProps = {
    anchorEl: HTMLInputElement | null;
    setPrefixText: (a: string) => void;
    value: string;
    setValue: (a: string) => void;
    setSuggestionsAnchor: (a: HTMLInputElement | null) => void;
    withPresetSuggestions?: boolean;
};

const SearchSuggestions: FunctionComponent<SearchSuggestionsProps> = ({
    anchorEl,
    setPrefixText,
    value,
    setValue,
    setSuggestionsAnchor,
    withPresetSuggestions = false
}) => {
    const [isOpenOnTop, setIsOpenOnTop] = useState(false);
    const [isPresetSuggestions, setIsPresetSuggestions] = useState(false);
    const { questionPresets, suggestionQuestions } = useAppContext();
    const [list, setList] = useState<string[]>([]);
    const [hoverIndex, setHoverIndex] = useState<number | null>(null);
    const hoverIndexRef = useRef(hoverIndex);
    const popperRef = useRef();
    const valueRef = useRef(value);
    const setHoverIndexRef = (hoverIndex: number | null) => {
        hoverIndexRef.current = hoverIndex;
        setHoverIndex(hoverIndex);
    };
    const { sendEvent } = useAnalytics();
    const { searchQuestionSuggestionEnabled } = useScopSettings();
    const { isSmallChat } = useSelector((state: any) => state.coPilotReducer);
    const { isSmallChatInPortal } = useSmallChatInPortal();
    const isSmallChatStyle = isSmallChat || isSmallChatInPortal;

    useEffect(() => {
        valueRef.current = value;
    }, [value]);

    useEffect(() => {
        if (!anchorEl) {
            return;
        }

        const presetSuggestionsList = withPresetSuggestions
            ? questionPresets
            : [];
        const suggestionQuestionList = searchQuestionSuggestionEnabled
            ? suggestionQuestions
            : [];
        const list = !anchorEl?.value.length
            ? presetSuggestionsList
            : suggestionQuestionList;
        setIsPresetSuggestions(
            !anchorEl?.value.length && withPresetSuggestions
        );
        setList(list);
    }, [
        suggestionQuestions.length,
        questionPresets.length,
        anchorEl?.value.length,
        withPresetSuggestions,
        searchQuestionSuggestionEnabled
    ]);

    useOnClickOutside(popperRef, () => setSuggestionsAnchor(null), anchorEl);

    useEffect(() => {
        if (anchorEl) {
            document.addEventListener('keydown', handleArrowClick, true);
        } else {
            setHoverIndexRef(null);
        }
        return () =>
            document.removeEventListener('keydown', handleArrowClick, true);
    }, [anchorEl, list.length]);

    const handleArrowClick = (event: KeyboardEvent) => {
        if (!anchorEl || !list.length) {
            return;
        }

        if (event.key === ARROW_DOWN) {
            event.preventDefault();
            if (
                hoverIndexRef.current === null ||
                hoverIndexRef.current === list.length - 1
            ) {
                setHoverIndexRef(0);
            } else {
                setHoverIndexRef(hoverIndexRef.current + 1);
            }
        } else if (event.key === ARROW_UP) {
            event.preventDefault();
            if (hoverIndexRef.current === null || hoverIndexRef.current === 0) {
                setHoverIndexRef(list.length - 1);
            } else {
                setHoverIndexRef(hoverIndexRef.current - 1);
            }
        } else if (event.key === ENTER) {
            if (
                (hoverIndexRef.current || hoverIndexRef.current === 0) &&
                list[hoverIndexRef.current] &&
                valueRef.current !== list[hoverIndexRef.current]
            ) {
                event.preventDefault();
                event.stopPropagation();
                handleClickOption(list[hoverIndexRef.current]);
            }
        }
    };

    const handleClickOption = (option: string) => {
        setPrefixText(option);
        setValue(option);
        if (isPresetSuggestions) {
            sendEvent(ANALYTICS_EVENTS.PICKED_Q_PRESET, {
                'pre-set name': option
            });
        }
    };

    const onFirstUpdate = (option: Partial<PopperState>) => {
        setIsOpenOnTop(option.placement === 'top-start');
    };

    if (!list.length) {
        return null;
    }

    return (
        <Popper
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            transition
            placement={'bottom-start'}
            className={`searchSuggestions__${
                isOpenOnTop ? 'popper_top' : 'popper_bottom'
            }`}
            popperOptions={{
                onFirstUpdate
            }}
        >
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <div
                        ref={popperRef}
                        className={`searchSuggestions__container ${
                            isSmallChatStyle ? '--small-chat' : ''
                        }`}
                    >
                        {list.map((option, index) => {
                            return (
                                <div
                                    onMouseEnter={() => setHoverIndexRef(index)}
                                    onMouseLeave={() => setHoverIndexRef(null)}
                                    key={index}
                                >
                                    <ValueWithTooltip
                                        title={
                                            option +
                                            (isPresetSuggestions ? '...' : '')
                                        }
                                        className={`searchSuggestions__option ${
                                            index === hoverIndex ? 'hover' : ''
                                        }`}
                                        type="body3"
                                        onClick={() =>
                                            handleClickOption(option)
                                        }
                                    />
                                </div>
                            );
                        })}
                    </div>
                </Fade>
            )}
        </Popper>
    );
};

export default SearchSuggestions;
