import React, { FunctionComponent } from 'react';


type Rect = {
    top: number;
    left: number;
    width?: number;
    height?: number;
}

type HighlightSelectedTextProps = {
    relativeRect: Rect;
    rects: Array<Rect>
};

const HighlightSelectedText: FunctionComponent<HighlightSelectedTextProps> = ({
    relativeRect,
    rects
}) => {
    return <>{rects.map((rect, i) => {
        let calculatedTop = !!relativeRect ? rect.top - relativeRect.top : rect.top;
        let calculatedLeft = !!relativeRect ? rect.left - relativeRect.left : rect.left;
        return (<div key={i} style={{ background: 'linear-gradient(#CDDDFF, #D3EDFF)', width: rect.width, height: rect.height, position: 'absolute', top: calculatedTop + 'px' , left: calculatedLeft + 'px', zIndex: -1 }}></div>)
    })}</>
};

export default HighlightSelectedText;

