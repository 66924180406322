import React, { FunctionComponent, ReactNode } from 'react';
import classnames from 'classnames';
import { Popover } from '@mui/material';
import { PopoverRow } from '../../types';
import ValueWithTooltip from '../ValueWithTooltip/ValueWithTooltip';

type SmallChatHeaderProps = {
    anchorEl: Element;
    setAnchorEl: Function;
    rows: Array<PopoverRow>;
    header?: ReactNode;
    rootClassName?: string;
    maxRowLines?: number;
    className?: string;
    tooltipPlacement?: string;
    horizontalOrigin?: number | 'left' | 'center' | 'right';
};

const PopoverBox: FunctionComponent<SmallChatHeaderProps> = ({
    anchorEl,
    setAnchorEl,
    rows,
    header,
    rootClassName,
    maxRowLines = 1,
    tooltipPlacement,
    className,
    horizontalOrigin = 'left'
}) => {
    return (
        <>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: horizontalOrigin
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: horizontalOrigin
                }}
                classes={{ paper: `${rootClassName} popoverBox__root` }}
                transitionDuration={500}
            >
                <div
                    className={classnames(
                        'popoverBox__container',
                        'flex',
                        'flex-col',
                        className
                    )}
                >
                    {header}
                    <div className="popoverBox__rows flex flex-col">
                        {rows.map((row, index) => {
                            if (!row.show) {
                                return null;
                            }
                            return (
                                <div
                                    key={index}
                                    onClick={() => row.onClick()}
                                    className={`popoverBox__row flex align-center ${
                                        row.isDisabled ? '--disabled' : ''
                                    }`}
                                >
                                    {row.icon && (
                                        <div className="popoverBox__icon">
                                            {row.icon}
                                        </div>
                                    )}
                                    <ValueWithTooltip
                                        title={row.title}
                                        type="body3"
                                        maxLines={maxRowLines}
                                        placement={tooltipPlacement}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Popover>
        </>
    );
};

export default PopoverBox;
