import { t } from 'i18next';
import { FeedbackValue } from '../../types';

export const getTextAreaLabel = (
    value: FeedbackValue,
    dislikeQuestion = ''
) => {
    switch (value) {
        case FeedbackValue.Like: {
            return t('feedback-modal.text-area-like-label');
        }
        case FeedbackValue.Dislike: {
            return dislikeQuestion;
        }
        case FeedbackValue.Worse: {
            return t('feedback-modal.text-area-worse-label');
        }
        case FeedbackValue.Better: {
            return t('feedback-modal.text-area-better-label');
        }
    }
};

export const getModalHeaderText = (value: FeedbackValue) => {
    switch (value) {
        case FeedbackValue.Worse: {
            return t('feedback-modal.header-worse');
        }
        case FeedbackValue.Better: {
            return t('feedback-modal.header-better');
        }
        default: {
            return t('feedback-modal.header');
        }
    }
};
