import { FunctionComponent } from 'react';
import { CircleBurgerArrow } from '../../SVG/CircleBurgerArrow';
import { useThreadsPanel } from '../../AppContext';
import { Tooltip } from '@material-ui/core';
import { t } from 'i18next';

const ThreadsButton: FunctionComponent = () => {
    const { isThreadsPanelOpen, setIsThreadsPanelOpen } = useThreadsPanel();
    const tooltipTitle = `${
        isThreadsPanelOpen ? t('common.hide') : t('common.show')
    } ${t('threads.chat-history')}`;

    return (
        <Tooltip
            title={tooltipTitle}
            placement="bottom-start"
            data-testid="thread-button-tooltip"
            PopperProps={{ style: { marginTop: -10 } }}
            classes={{
                tooltip: 'tooltip'
            }}
        >
            <div>
                <CircleBurgerArrow
                    className={`main-header__threads-button ${
                        !isThreadsPanelOpen ? '--rotated' : ''
                    }`}
                    onClick={() => setIsThreadsPanelOpen(!isThreadsPanelOpen)}
                    data-testid="threads-button"
                    data-opened={isThreadsPanelOpen}
                />
            </div>
        </Tooltip>
    );
};

export default ThreadsButton;
