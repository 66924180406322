import React from 'react';
import { Tooltip } from '@material-ui/core';
import SparklesLarge from '../../SVG/SparklesLarge';

interface CoPilotButtonProps {
    className?: string;
    onClick?: Function;
    tooltipTitle?: string;
    isLarge?: boolean;
    dataTestId?: string;
}

const CoPilotButton: React.FC<CoPilotButtonProps> = ({
    className = '',
    onClick,
    tooltipTitle = '',
    isLarge,
    dataTestId = 'copilot-btn'
}) => {
    const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
        onClick?.(event);
    };

    return (
        <Tooltip
            title={tooltipTitle}
            classes={{ tooltip: 'tooltip tooltip__select-button-icon' }}
        >
            <div
                className={className}
                onClick={handleClick}
                data-testid={dataTestId}
            >
                <div
                    className={`copilot-btn__wrapper flex align-center justify-center ${
                        isLarge ? '--is-large' : ''
                    }`}
                >
                    <div
                        className={`copilot-btn__icon flex align-center justify-center ${
                            isLarge ? '--is-large' : ''
                        }`}
                    >
                        <SparklesLarge />
                    </div>
                </div>
            </div>
        </Tooltip>
    );
};

export default CoPilotButton;
