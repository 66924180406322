class Util {
    public static popupWindow(url: string, windowName: string) {
        url = window.location.origin + url;

        // TODO: adjust for all resolutions...
        const w = 600;
        const h = 500;
        const y = window.top!.outerHeight / 2 + window.top!.screenY - h / 2;
        const x = window.top!.outerWidth / 2 + window.top!.screenX - w / 2;
        return window.open(
            url,
            windowName,
            `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no,
          resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
        );
    }

    public static getMyHost() {
        const uriPattern = window['__URI_PATTERN__'];
        var jsscript = document.getElementsByTagName('script');
        for (var i = 0; i < jsscript.length; i++) {
            var pattern = new RegExp(uriPattern);
            if (pattern.test(jsscript[i].getAttribute('src'))) {
                var src = jsscript[i].getAttribute('src');
                src = src.replace(uriPattern, '');
                return src;
            }
        }
    }

    public static fromTriage() {
        const href = window.location.href;
        // TODO: find better way to reconginze if page opened from triage
        return href.includes('vf.force') || href.includes('lightning.force');
    }

    public static fromSite() {
        const href = window.location.href;
        return href.includes('my.site.com');
    }

    public static resolveUri(uri: string) {
        return `${Util.getMyHost() || window.location.origin}${uri}`;
    }

    public static getPropWithPrefix(prop: string) {
        if (window.prefix) {
            return window.prefix + prop;
        } else {
            return prop;
        }
    }

    public static async downloadFileFromExternalUrl(
        path: string,
        fileName: string,
        handlers?: {
            onSuccess?: Function;
            onFailure?: Function;
            onFinal?: Function;
        }
    ) {
        try {
            const response = await fetch(path, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf'
                }
            });
            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            handlers?.onSuccess?.();
        } catch (ex: any) {
            console.error(ex);
            window.sentry.log(ex);
            handlers?.onFailure?.();
        } finally {
            handlers?.onFinal?.();
        }
    }
}

export default Util;
