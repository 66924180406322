import { FunctionComponent } from 'react';
import { Typography } from 'aqui';
import { useTranslation } from 'react-i18next';

type PreviewBannerProps = {
    show: boolean;
    percentLoaded: number;
    isPreviewLoaded: boolean;
};

const PreviewBanner: FunctionComponent<PreviewBannerProps> = ({
    show,
    percentLoaded,
    isPreviewLoaded
}) => {
    const { t } = useTranslation();
    return (
        <Typography
            type="body3"
            tag="div"
            className={`pdf-viewer__message ${
                show ? 'pdf-viewer__message--show' : ''
            }`}
            dataTestId="pdf-viewer-message"
            data-open={show}
        >
            {percentLoaded === 100
                ? t('document.preparing-doc')
                : isPreviewLoaded
                ? t('document.loading-doc-with-preview')
                : t('document.loading-doc')}
            <span className="pdf-viewer__message__percent">
                {percentLoaded}%
            </span>
        </Typography>
    );
};

export default PreviewBanner;
