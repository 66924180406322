import { FunctionComponent } from 'react';
import { Popover, Tooltip } from '@mui/material';
import MenuItem from '../Menu/MenuItem';
import Menu from '../Menu/Menu';
import { Typography } from 'aqui';
import AquantSmallLogo from '../../SVG/AquantSmallLogo';
import { PopoverRow } from '../../types';

type MoreOptionsButtonProps = {
    anchorEl;
    setAnchorEl: Function;
    rows: PopoverRow[];
    withAquantIcon: boolean;
};
const MoreOptionsPopover: FunctionComponent<MoreOptionsButtonProps> = ({
    anchorEl,
    setAnchorEl,
    rows,
    withAquantIcon
}) => {
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOptionClick = (callbackFunc) => {
        handleClose();
        callbackFunc();
    };

    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            classes={{
                paper: 'popoverBox__root'
            }}
            transitionDuration={500}
        >
            <Menu className="main-header__menu">
                {rows.map((row) =>
                    row.show ? (
                        <div key={row.title}>
                            <Tooltip
                                title={row.tooltip || ''}
                                placement="top"
                                classes={{
                                    tooltip: 'tooltip'
                                }}
                            >
                                {row.isNotSelectable ? (
                                    <div
                                        data-testid={row.dataTestId}
                                        className="main-header__menu__non-selectable-item"
                                    >
                                        <Typography type="body3">
                                            {row.title}
                                        </Typography>
                                        {row.RightComponent && (
                                            <div className="main-header__menu__row-right-component">
                                                <row.RightComponent
                                                    closeMenu={handleClose}
                                                />
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <MenuItem
                                        onClick={() =>
                                            handleOptionClick(row.onClick)
                                        }
                                        dataTestId={row.dataTestId}
                                        selected={row.isSelected}
                                        disabled={row.isDisabled}
                                    >
                                        <Typography type="body3">
                                            {row.title}
                                        </Typography>
                                        {row.RightComponent && (
                                            <div className="main-header__menu__row-right-component">
                                                <row.RightComponent />
                                            </div>
                                        )}
                                    </MenuItem>
                                )}
                            </Tooltip>
                            {row.withSeparator && (
                                <div className="main-header__menu__separator" />
                            )}
                        </div>
                    ) : null
                )}
                {withAquantIcon && (
                    <div className="main-header__menu-logo">
                        <AquantSmallLogo />
                    </div>
                )}
            </Menu>
        </Popover>
    );
};

export default MoreOptionsPopover;
