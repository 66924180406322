import SingleModelThread from './SingleModelThread';
import { useThreadsHandling } from '../../AppContext';

const ModelThreads = () => {
    const { chatHistoryData } = useThreadsHandling();

    if (!chatHistoryData[0].tenant_model) {
        return null;
    }
    return (
        <div data-testid="threads-model-list">
            {chatHistoryData.map((modelData, index) => {
                return (
                    <SingleModelThread
                        key={modelData.tenant_model}
                        modelData={modelData}
                        modelIndex={index}
                    />
                );
            })}
        </div>
    );
};

export default ModelThreads;
