import { useMemo, useRef } from 'react';
import { useDataManagementContext } from '../../../../context/DataManagementContext';
import { IngestModalTypes } from '../../../../types';

export const useIngestModalTriggering = () => {
    const {
        isAddPersonasToSourcesModalOpen,
        setIsAddPersonasToSourcesModalOpen,
        isDeleteSourcesModalOpen,
        setIsDeleteSourcesModalOpen
    } = useDataManagementContext();
    return {
        isAddPersonasToSourcesModalOpen,
        setIsAddPersonasToSourcesModalOpen,
        isDeleteSourcesModalOpen,
        setIsDeleteSourcesModalOpen
    };
};

export const useIngestModal = () => {
    const {
        isAddPersonasToSourcesModalOpen,
        isDeleteSourcesModalOpen,
        setIsAddPersonasToSourcesModalOpen,
        setIsDeleteSourcesModalOpen
    } = useIngestModalTriggering();

    const ref = useRef(null);

    const modalType = useMemo(() => {
        switch (true) {
            case isAddPersonasToSourcesModalOpen:
                ref.current = IngestModalTypes.addPersona;
                return IngestModalTypes.addPersona;
            case isDeleteSourcesModalOpen:
                ref.current = IngestModalTypes.deleteSources;
                return IngestModalTypes.deleteSources;
            default:
                return ref.current;
        }
    }, [isAddPersonasToSourcesModalOpen, isDeleteSourcesModalOpen]);

    const isModalOpen =
        isAddPersonasToSourcesModalOpen || isDeleteSourcesModalOpen;

    const handleCloseModal = () => {
        if (isAddPersonasToSourcesModalOpen) {
            setIsAddPersonasToSourcesModalOpen(false);
        } else if (isDeleteSourcesModalOpen) {
            setIsDeleteSourcesModalOpen(false);
        }
    };

    return { isModalOpen, handleCloseModal, modalType };
};
