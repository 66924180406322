import { FunctionComponent, useEffect, useState } from 'react';

import PDFValidator from '../PDFViewer/PDFValidator';
import { FileValidation, SourceFile } from '../../types';
import VideoValidator from '../Common/VideoValidator';
import { useSourceFiles, useUploadModel } from './UploadFilesModalHooks';
import { MAX_NUMBER_OF_FILES_TO_INGEST } from '../../consts';

const SourceFilesValidator: FunctionComponent = () => {
    const {
        addToSourceFiles,
        sourceFilesToValidate,
        validSourceFiles,
        setSourceFilesToValidate,
        sourceFiles
    } = useSourceFiles();
    const { currentModelUploadedFiles } = useUploadModel();
    const [videoFilesToValidate, setVideoFilesToValidate] = useState<
        SourceFile[]
    >([]);
    const [PDFFilesToValidate, setPDFFilesToValidate] = useState<SourceFile[]>(
        []
    );
    const [validatedFiles, setValidatedFiles] = useState<SourceFile[]>([]);
    const shouldStopValidation =
        validSourceFiles.length >= MAX_NUMBER_OF_FILES_TO_INGEST;

    useEffect(() => {
        if (
            validatedFiles.length &&
            validatedFiles.length === sourceFilesToValidate.length
        ) {
            addToSourceFiles(validatedFiles);
            setValidatedFiles([]);
            setSourceFilesToValidate([]);
        }
    }, [validatedFiles]);

    useEffect(() => {
        if (!sourceFilesToValidate.length || shouldStopValidation) {
            return;
        }
        const filesToValidate = sourceFilesToValidate.filter(
            (file) =>
                !sourceFiles.some(
                    (sourceFile) => sourceFile.file.name === file.file.name
                )
        );
        if (!filesToValidate.length) {
            return;
        }
        const validatedFiles = [];
        const newVideoFilesToValidate = [];
        const newPDFFilesToValidate = [];
        for (const sourceFile of filesToValidate) {
            const firstValidationType = validateFileNameAndExistence(
                sourceFile.file
            );
            if (firstValidationType) {
                validatedFiles.push({
                    ...sourceFile,
                    validationType: firstValidationType
                });
            } else if (sourceFile.file.type === 'application/pdf') {
                newPDFFilesToValidate.push(sourceFile);
            } else if (sourceFile.file.type.startsWith('video')) {
                newVideoFilesToValidate.push(sourceFile);
            }
        }
        if (validatedFiles.length) {
            addFilesAfterValidation(validatedFiles);
        }
        setVideoFilesToValidate(newVideoFilesToValidate);
        setPDFFilesToValidate(newPDFFilesToValidate);
    }, [sourceFilesToValidate]);

    const addFilesAfterValidation = (sourceFiles: SourceFile[]) => {
        if (!shouldStopValidation) {
            setValidatedFiles((prevState) => [...sourceFiles, ...prevState]);
        } else {
            addToSourceFiles(validatedFiles);
            setSourceFilesToValidate([]);
            setValidatedFiles([]);
        }
    };

    const validateFileNameAndExistence = (file: File) => {
        const fileName = file.name;
        if (/[!@#$%^&*,.?":{}|<>]/.test(fileName.replace('.', ''))) {
            return FileValidation.BadFilename;
        }
        if (currentModelUploadedFiles.has(fileName)) {
            return FileValidation.Exists;
        }
        return null;
    };

    const addPDFSourceFileAsValid = (sourceFile: SourceFile, PDFDoc: any) => {
        addFilesAfterValidation([
            {
                ...sourceFile,
                numPages: PDFDoc.numPages,
                validationType: FileValidation.Valid
            }
        ]);
    };

    const addVideoSourceFileAsValid = (
        sourceFile: SourceFile,
        videoRef: HTMLVideoElement
    ) => {
        addFilesAfterValidation([
            {
                ...sourceFile,
                duration: videoRef.duration,
                validationType: FileValidation.Valid
            }
        ]);
    };

    const addSourceFilesAsCorrupt = (sourceFile: SourceFile) => {
        addFilesAfterValidation([
            { ...sourceFile, validationType: FileValidation.Corrupt }
        ]);
    };

    const addSourceFilesAsProtected = (sourceFile: SourceFile) => {
        addFilesAfterValidation([
            { ...sourceFile, validationType: FileValidation.Protected }
        ]);
    };

    return (
        <>
            {PDFFilesToValidate.map((sourceFile, index) => (
                <PDFValidator
                    file={sourceFile.file}
                    key={sourceFile.file.name + index}
                    onSuccess={(PDFDoc) =>
                        addPDFSourceFileAsValid(sourceFile, PDFDoc)
                    }
                    onError={() => addSourceFilesAsCorrupt(sourceFile)}
                    onPassword={() => addSourceFilesAsProtected(sourceFile)}
                />
            ))}
            {videoFilesToValidate.map((sourceFile, index) => (
                <VideoValidator
                    key={sourceFile.file.name + index}
                    videoUrl={URL.createObjectURL(sourceFile.file)}
                    onError={() => addSourceFilesAsCorrupt(sourceFile)}
                    onValid={(video) =>
                        addVideoSourceFileAsValid(sourceFile, video)
                    }
                    fileType={sourceFile.file.type}
                />
            ))}
        </>
    );
};

export default SourceFilesValidator;
