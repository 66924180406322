import { t } from 'i18next';
import {
    ImportedFileDataIngestedSourcesRow,
    IngestedSourcesFilters,
    IngestedSourcesRow,
    IngestedSourcesStatus,
    IntegratedModel,
    SourceDocument,
    SourceFile
} from '../../../types';
import { getFileMediaType } from '../../UploadFilesModal/UploadFilesModalHelpers';
import { COLUMNS, IMPORT_COLUMNS } from './consts';
import { getIntegratedModelDisplay } from '../../../utils/productUtils';
import { AQUANT_USER, UNKNOWN_USER } from './consts';

export const getFilteredIngestedSourceRows = (
    rows: IngestedSourcesRow[],
    filters: IngestedSourcesFilters,
    searchTerm: string,
    personaNameToId: Record<string, string>,
    personaIdToName: Record<string, string>
) => {
    return rows.filter((row) => {
        const isSearchTermInPersona =
            searchTerm &&
            row.personas.length > 0 &&
            row.personas.some((persona) => {
                return personaIdToName[persona]
                    ?.toLowerCase()
                    .includes(searchTerm.toLowerCase());
            });
        return (
            (!filters.persona ||
                row.personas.includes(personaNameToId[filters.persona])) &&
            (!filters.user || row.created_by === filters.user) &&
            (!filters.status || row.status === filters.status) &&
            (!filters.models?.length ||
                filters.models.includes(row.modelDisplay)) &&
            (!filters.minCreateDate ||
                filters.minCreateDate <= row.created_at) &&
            (!filters.maxCreateDate ||
                filters.maxCreateDate >= row.created_at) &&
            (!searchTerm ||
                row.document_name
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                isSearchTermInPersona)
        );
    });
};

export const validateFileColumns = (columns, columnsFromFile) => {
    let includesAll = columns.every((item) => columnsFromFile.includes(item));
    if (!includesAll) {
        return ['Invalid columns in file, please use the template format.'];
    }
    return [];
};

export const validateRows = (data, ingestedFileToModelMap, personasNames) => {
    const notValidPersonas = new Set();
    const sentencesArr = [];
    data.forEach((file) => {
        let sentence = '';
        const filename = file['FILE NAME'];
        const model = file['MODEL'];
        const models = ingestedFileToModelMap[filename];
        if (!models) {
            sentence = `"${filename}" not exists.`;
        } else if (!models) {
            sentence = `Model is missing for "${filename}".`;
        } else if (!models.includes(model)) {
            sentence = `Wrong model ("${model}") assigned to "${filename}".`;
        }
        if (sentence) {
            sentencesArr.push(sentence);
        }
        const personas = file['PERSONAS'].split(',').map((p) => p.trim());
        personas.forEach((persona: string) => {
            if (!personasNames.includes(persona)) {
                notValidPersonas.add(`"${persona}"`);
            }
        });
    });
    if (notValidPersonas.size) {
        sentencesArr.push(
            `${
                notValidPersonas.size > 1 ? 'Personas' : 'Persona'
            }: ${Array.from(notValidPersonas).join(
                ', '
            )} are not valid or not exists.`
        );
    }
    return sentencesArr;
};

export const validateData = (
    data: ImportedFileDataIngestedSourcesRow[],
    ingestedSourceRows: IngestedSourcesRow[],
    personasNames: string[]
) => {
    const coulumsValidation = validateFileColumns(
        IMPORT_COLUMNS,
        Object.keys(data[0])
    );
    if (coulumsValidation.length) {
        return coulumsValidation;
    }
    const ingestedFileToModelMap = ingestedSourceRows.reduce((acc, row) => {
        if (acc[row.document_name]) {
            acc[row.document_name].push(row.tenant_model);
        } else {
            acc[row.document_name] = [row.tenant_model];
        }
        return acc;
    }, {});
    const sentencesArr = validateRows(
        data,
        ingestedFileToModelMap,
        personasNames
    );
    if (!sentencesArr.length) {
        return [];
    }
    return sentencesArr;
};

export const getColumnsData = (withCheckbox: boolean = true) => {
    const newArr = [];
    if (withCheckbox) {
        newArr.push({
            field: COLUMNS.CHECKBOX,
            maxWidth: 50,
            sortable: false
        });
    }
    return [
        ...newArr,
        {
            field: COLUMNS.FILE_NAME,
            headerName: t('ingested-sources.column-file-name'),
            minWidth: 190,
            flex: 1
        },
        {
            field: COLUMNS.MODEL_DISPLAY,
            headerName: t('ingested-sources.column-model'),
            minWidth: 190,
            maxWidth: 250,
            flex: 1
        },
        {
            field: COLUMNS.CREATED_AT,
            headerName: t('ingested-sources.column-upload-date'),
            width: 160
        },
        {
            field: COLUMNS.CREATED_BY,
            headerName: t('ingested-sources.column-username'),
            minWidth: 190,
            maxWidth: 250,
            flex: 1
        },
        {
            field: COLUMNS.PERSONAS,
            headerName: t('ingested-sources.column-personas'),
            width: 310,
            sortable: false
        },
        {
            field: COLUMNS.INGEST_STATUS,
            headerName: t('ingested-sources.column-ingest-status'),
            width: 160
        },
        {
            field: COLUMNS.ACTIONS,
            headerName: t('data-management.actions-column'),
            width: 85,
            sortable: false
        }
    ];
};

export const getStatusFilterItems = () => {
    return Object.values(IngestedSourcesStatus)
        .filter(
            (status) =>
                status !== IngestedSourcesStatus.Queued &&
                status !== IngestedSourcesStatus.Uploading
        )
        .map((status) => ({
            value: status,
            display: t(`ingested-sources.status-${status.toLowerCase()}`)
        }));
};

export const sourceFilesToIngestedSourceRows = (
    sourceFiles: SourceFile[],
    integratedModel: IntegratedModel,
    user: string,
    status: IngestedSourcesStatus,
    personas: string[]
): IngestedSourcesRow[] => {
    const ingestedSourceRows: IngestedSourcesRow[] = sourceFiles.map((f) => ({
        id: integratedModel.modelName + f.file.name,
        document_name: f.file.name,
        tenant_model: integratedModel.modelName,
        status,
        created_at: new Date(),
        created_by: user,
        document_type: getFileMediaType(f.file),
        integratedModel,
        modelDisplay: getIntegratedModelDisplay(integratedModel),
        personas
    }));

    return ingestedSourceRows;
};

export const ingestedSourceRowToSourceDocument = (
    row: IngestedSourcesRow
): SourceDocument => ({
    metadata: {
        page: 1,
        filename: row.document_name,
        model: row.tenant_model,
        media_type: row.document_type,
        source: '',
        start: 0
    },
    id: row.id
});

export const extractFilterValuesFromIngestedRows = (
    docs: IngestedSourcesRow[]
) => {
    const uniqueModels: Set<string> = new Set();
    const uniqueUsers: Set<string> = new Set();
    for (const doc of docs) {
        uniqueModels.add(doc.modelDisplay);
        uniqueUsers.add(doc.created_by);
    }
    return [Array.from(uniqueModels).sort(), Array.from(uniqueUsers).sort()];
};

const isCreatedByAquant = (user: string) =>
    !user || user === UNKNOWN_USER || user.includes('aquant');

export const getIngestedSourcePopulatedRows = (
    rows: IngestedSourcesRow[],
    ingestedModels: IntegratedModel[]
): IngestedSourcesRow[] =>
    rows?.map((doc) => {
        const integratedModel = ingestedModels?.find(
            (model) => model.modelName === doc.tenant_model
        ) || { modelName: doc.tenant_model };
        doc.integratedModel = integratedModel;
        doc.modelDisplay = getIntegratedModelDisplay(integratedModel);
        doc.created_by = isCreatedByAquant(doc.created_by)
            ? AQUANT_USER
            : doc.created_by;
        doc.status =
            doc.status === IngestedSourcesStatus.Queued
                ? IngestedSourcesStatus.Processing
                : doc.status;
        doc.created_at = new Date(doc.created_at);
        return doc;
    }) || [];

export const getIsDeleteButtonDisabled = (status: IngestedSourcesStatus) =>
    status === IngestedSourcesStatus.Processing ||
    status === IngestedSourcesStatus.Uploading;
