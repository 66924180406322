const RotateIcon = ({ color = 'currentColor', clockwise = true, ...props }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ transform: clockwise ? 'none' : 'scaleX(-1)' }}
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.347 7.18463C10.3913 6.60882 11.5944 6.38802 12.7751 6.55549C13.9558 6.72296 15.05 7.26963 15.893 8.11313L15.9039 8.12369L18.0737 10.167H15.3333C15.0571 10.167 14.8333 10.3909 14.8333 10.667C14.8333 10.9431 15.0571 11.167 15.3333 11.167H19.3333C19.5913 11.167 19.8036 10.9716 19.8304 10.7207C19.8327 10.6996 19.8336 10.6783 19.8333 10.6572V6.66699C19.8333 6.39085 19.6094 6.16699 19.3333 6.16699C19.0571 6.16699 18.8333 6.39085 18.8333 6.66699V9.50863L16.5949 7.40077C15.5994 6.40702 14.3083 5.76296 12.9155 5.5654C11.5202 5.36748 10.0983 5.62843 8.86414 6.30893C7.63 6.98942 6.65046 8.0526 6.07312 9.33824C5.49579 10.6239 5.35194 12.0623 5.66326 13.4368C5.97458 14.8113 6.7242 16.0474 7.79916 16.9588C8.87411 17.8702 10.2162 18.4076 11.6231 18.4899C13.03 18.5722 14.4256 18.195 15.5994 17.4152C16.7733 16.6353 17.662 15.4951 18.1314 14.1662C18.2234 13.9059 18.0869 13.6202 17.8266 13.5282C17.5662 13.4363 17.2805 13.5728 17.1886 13.8331C16.7913 14.9575 16.0394 15.9223 15.0461 16.5822C14.0528 17.2421 12.872 17.5612 11.6815 17.4916C10.491 17.422 9.35543 16.9673 8.44585 16.1961C7.53628 15.4249 6.90198 14.379 6.63856 13.2159C6.37514 12.0529 6.49685 10.8357 6.98536 9.74789C7.47388 8.66004 8.30272 7.76043 9.347 7.18463Z"
                fill={color}
            />
        </svg>
    );
};

export default RotateIcon;
