import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Typography } from 'aqui';
import { useKAModal } from '../../AppContext';
import { t } from 'i18next';
import { ANALYTICS_EVENTS, MAX_KA_ROWS_ON_START_TRIAGE } from '../../consts';
import KASectionRow from './KASectionRow';
import { aggregateResults, getNumOfUniqueFiles } from './KASectionHelpers';
import { SourceDocument } from '../../types';
import useAnalytics from '../../hooks/useAnalytics';
import ChevronIconSmall from '../../SVG/ChevronIconSmall';

enum KaResultType {
    Related = 'Related',
    All = 'All'
}

type KAListProps = {
    sourceDocuments: Array<SourceDocument>;
    relatedSourceDocuments: Array<SourceDocument>;
    maxRowsOnStart?: number;
    isCoPilotPanel?: boolean;
    resultsPluralSuffix: string;
};

const KAList: FunctionComponent<KAListProps> = ({
    sourceDocuments,
    relatedSourceDocuments,
    isCoPilotPanel = false,
    maxRowsOnStart = MAX_KA_ROWS_ON_START_TRIAGE,
    resultsPluralSuffix
}) => {
    const { handleShowSourceDocumentDetails, seenSourceDocuments } =
        useKAModal();

    const { sendEvent } = useAnalytics();
    const [sourcesToShow, setSourcesToShow] = useState<number>(0);
    const [showAdditionalSourcesEnabled, setShowAdditionalSourcesEnabled] =
        useState(false);

    const calculateMaxSources = (sourcesLength: number) =>
        sourcesLength > maxRowsOnStart ? maxRowsOnStart : sourcesLength;

    useEffect(() => {
        setSourcesToShow(calculateMaxSources(sourceDocuments.length));
    }, [sourceDocuments]);

    const isAllResultsDeleted = useMemo(
        () => !relatedSourceDocuments.some((source) => !source.isHidden),
        [relatedSourceDocuments]
    );

    const aggregatedResults = useMemo(
        () => aggregateResults(sourceDocuments),
        [sourceDocuments]
    );

    const uniqueFilesNum = useMemo(
        () => getNumOfUniqueFiles(sourceDocuments),
        [sourceDocuments]
    );

    const resultsToShow = useMemo(
        () => aggregatedResults.slice(0, sourcesToShow),
        [aggregatedResults, sourcesToShow]
    );

    const { showMoreBtnEnabled, showLessBtnEnabled } = useMemo(() => {
        return {
            showMoreBtnEnabled: aggregatedResults?.length > sourcesToShow,
            showLessBtnEnabled: sourcesToShow > maxRowsOnStart
        };
    }, [aggregatedResults?.length, maxRowsOnStart, sourcesToShow]);

    const onResultClick = (
        doc: SourceDocument,
        kaType: string,
        allDocuments = sourceDocuments
    ) => {
        handleShowSourceDocumentDetails(
            allDocuments.indexOf(doc),
            allDocuments,
            kaType
        );
    };

    const onShowMoreClick = () => {
        setSourcesToShow(
            (prevState) =>
                prevState +
                calculateMaxSources(aggregatedResults.length - sourcesToShow)
        );
        sendEvent(ANALYTICS_EVENTS.SOURCE_PANEL_CLICK_SHOW_MORE);
    };

    const onShowLessClick = () => {
        setShowAdditionalSourcesEnabled(false);
        setSourcesToShow(calculateMaxSources(aggregatedResults.length));
        sendEvent(ANALYTICS_EVENTS.SOURCE_PANEL_CLICK_SHOW_LESS);
    };

    const onShowAdditionalSourcesClick = () => {
        setShowAdditionalSourcesEnabled((prevState) => !prevState);
    };

    const checkIsSourceResultSeen = (
        results: SourceDocument[],
        type = KaResultType.All
    ) => {
        const selectedResults =
            type === KaResultType.All ? results : [results?.[0]];
        return selectedResults.every((result) =>
            seenSourceDocuments.has(result)
        );
    };

    return (
        <div
            className={`ka-list__knowledge-articles flex flex-col ${
                isCoPilotPanel ? '--in-portal' : ''
            }`}
            data-testid="ka-list"
        >
            {!!relatedSourceDocuments?.length && (
                <>
                    {relatedSourceDocuments.map((result, index) => {
                        return (
                            <KASectionRow
                                results={[result]}
                                isResultSeen={checkIsSourceResultSeen(
                                    [result],
                                    KaResultType.Related
                                )}
                                seenResults={seenSourceDocuments}
                                onResultClick={(doc, kaType) =>
                                    onResultClick(
                                        doc,
                                        kaType,
                                        relatedSourceDocuments
                                    )
                                }
                                key={index}
                                isCoPilotPanel={isCoPilotPanel}
                                resultIndex={result.isHidden ? -1 : index + 1}
                                isAllResultsDeleted={isAllResultsDeleted}
                            />
                        );
                    })}
                    {aggregatedResults?.length > 0 && (
                        <>
                            <div className="ka-list__separator" />
                            <div
                                className="ka-list__ka-btn"
                                onClick={onShowAdditionalSourcesClick}
                                data-test-id="show-additional-sources-btn"
                            >
                                <Typography
                                    className="ka-list__ka-btn__text"
                                    type="small1"
                                    dataTestId="ka-list_show-additional-sources-btn"
                                    variant="text"
                                >
                                    {`${aggregatedResults?.length} ${t(
                                        `search.additional-result${resultsPluralSuffix}`
                                    )}`}
                                </Typography>
                                <ChevronIconSmall
                                    direction="down"
                                    className={`ka-list__ka-btn__icon ${
                                        showAdditionalSourcesEnabled
                                            ? 'ka-list__ka-btn__icon--open'
                                            : ''
                                    }`}
                                />
                            </div>
                        </>
                    )}
                </>
            )}
            {(!relatedSourceDocuments?.length ||
                showAdditionalSourcesEnabled) && (
                <>
                    {showAdditionalSourcesEnabled && (
                        <Typography
                            type={isCoPilotPanel ? 'body3' : 'small1'}
                            className="kaSection__header"
                        >
                            {`${t(
                                `search.additional-result${
                                    uniqueFilesNum > 1 ? 's' : ''
                                }-from`
                            )} ${uniqueFilesNum} ${t(
                                `search.source${uniqueFilesNum > 1 ? 's' : ''}`
                            )}.`}
                            <br />
                            {uniqueFilesNum > 1
                                ? t('search.sorting-by-relevance')
                                : ''}
                        </Typography>
                    )}
                    {resultsToShow.map((results, index) => (
                        <KASectionRow
                            results={results}
                            isResultSeen={checkIsSourceResultSeen(
                                results,
                                KaResultType.All
                            )}
                            seenResults={seenSourceDocuments}
                            onResultClick={onResultClick}
                            key={index}
                            isCoPilotPanel={isCoPilotPanel}
                        />
                    ))}
                    <div
                        className={`ka-list__ka-btns_conatiner ${
                            isCoPilotPanel ? '--in-portal' : ''
                        }`}
                    >
                        {showMoreBtnEnabled && (
                            <div
                                className={`ka-list__ka-btn ka-list__show-more-btn ${
                                    showLessBtnEnabled ? '--with-show-less' : ''
                                }`}
                                data-testid="ka-list-show-more-btn"
                                onClick={onShowMoreClick}
                            >
                                <Typography
                                    className="ka-list__ka-btn__text"
                                    type="small1"
                                    dataTestId="ka-list_show-more-btn"
                                >
                                    {`${t('common.show')} ${calculateMaxSources(
                                        aggregatedResults.length - sourcesToShow
                                    )} ${t('common.more')}`}
                                </Typography>
                                <ChevronIconSmall
                                    direction="down"
                                    className="ka-list__ka-btn__icon"
                                />
                            </div>
                        )}
                        {showLessBtnEnabled && (
                            <div
                                className="ka-list__ka-btn ka-list__show-less-btn"
                                onClick={onShowLessClick}
                                data-testid="ka-list-show-less-btn"
                            >
                                <Typography
                                    className="ka-list__ka-btn__text"
                                    type="small1"
                                    dataTestId="ka-list_show-less-btn"
                                >
                                    {t('common.show-less')}
                                </Typography>
                                <ChevronIconSmall
                                    direction="up"
                                    className="ka-list__ka-btn__icon"
                                />
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default KAList;
