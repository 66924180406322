import React, { FunctionComponent } from 'react';
import PersonIconMedium from '../../../SVG/PersonIconMedium';
import { Typography } from 'aqui';
import { useSelector } from 'react-redux';
import { useSmallChatInPortal } from '../../../context/ChatContext';

type ExpertResponseLabelProps = {
    name: string;
    date: string;
};

const ExpertResponseLabel: FunctionComponent<ExpertResponseLabelProps> = ({
    name,
    date
}) => {
    const { isSmallChatInPortal } = useSmallChatInPortal();
    const { isSmallChat } = useSelector((state: any) => state.coPilotReducer);

    const isScreenOfSmallChat = isSmallChatInPortal || isSmallChat;

    return (
        <div
            className={`flex align-center space-between full-width expertResponseLabel__container ${
                isScreenOfSmallChat ? 'smallChat' : ' '
            }`}
        >
            <div className="flex align-center expertResponseLabel__name">
                <PersonIconMedium />
                <Typography type="small1">{`Last edited by expert ${name}`}</Typography>
            </div>
            <Typography type="small1" className="expertResponseLabel__date">
                {date}
            </Typography>
        </div>
    );
};

export default ExpertResponseLabel;
