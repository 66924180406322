import { Typography } from 'aqui';
import { FunctionComponent, useEffect, useState } from 'react';
import StopIcon from '../../SVG/StopIcon';
import RefreshIcon from '../../SVG/RefreshIcon';
import { t } from 'i18next';
import { Popover } from '@mui/material';
import useAnalytics from '../../hooks/useAnalytics';
import { ANALYTICS_EVENTS } from '../../consts';
import { useRegenerate } from '../../context/ChatContext';
import {
    useAskStream,
    useAskStreamContext
} from '../../context/AskStreamContext';

type GenerateButtonProps = {
    isHidden: Boolean;
    setTypingStarted: Function;
    generationCounter: number;
    setGenerationCounter: Function;
    isStreamInProgress: boolean;
    chatWindowState: string;
    isSmallChat: boolean;
};

const GenerateButton: FunctionComponent<GenerateButtonProps> = ({
    isHidden,
    setTypingStarted,
    generationCounter,
    setGenerationCounter,
    isStreamInProgress,
    isSmallChat
}) => {
    const [displayAnimation, setDisplayAnimation] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isExpired, setIsExpired] = useState<boolean>(false);
    const [popoverTimeOut, setPopoverTimeOut] = useState<NodeJS.Timeout>();

    const { setIsStreamStopped, cancelStream } = useAskStreamContext();
    const { regenerateLastAnswer } = useAskStream();

    const { setIsRegenerateAvailable } = useRegenerate();

    const { sendEvent } = useAnalytics();

    //Cleanup effect
    useEffect(() => clearPopoverTimeOut, []);

    useEffect(() => {
        if (!isHidden && !displayAnimation) {
            setDisplayAnimation(true);
        }
        setIsExpired(false);
    }, [isHidden, isStreamInProgress]);

    const clearPopoverTimeOut = () => {
        if (popoverTimeOut) {
            clearTimeout(popoverTimeOut);
            setPopoverTimeOut(null);
        }
    };

    const onClosePopover = () => {
        setAnchorEl(null);
        setIsExpired(true);
        clearPopoverTimeOut();
    };

    const onClick = (e) => {
        setTypingStarted(false);
        if (!isStreamInProgress) {
            if (generationCounter === 2) {
                setAnchorEl(anchorEl ? null : e.currentTarget);
                setPopoverTimeOut(
                    setTimeout(() => {
                        setAnchorEl(null);
                        setIsExpired(true);
                    }, 10000)
                );
                return;
            }
            sendEvent(ANALYTICS_EVENTS.RESUME_GENERATING);
            regenerateLastAnswer();
            setGenerationCounter((prevValue) => prevValue + 1);
            return;
        }
        sendEvent(ANALYTICS_EVENTS.STOP_GENERATING);
        setIsStreamStopped(true);
        setIsRegenerateAvailable(true);
        cancelStream();
    };

    const generateLabel = () => {
        if (isSmallChat) {
            return t(
                `generate.${
                    !isStreamInProgress ? 'regenerate-small' : 'stop-small'
                }`
            );
        } else {
            return t(`generate.${!isStreamInProgress ? 'regenerate' : 'stop'}`);
        }
    };
    return (
        <div
            onClick={(e) => !anchorEl && onClick(e)}
            className={`generateButton__button ${
                isHidden || isExpired
                    ? displayAnimation
                        ? 'remove'
                        : 'hidden'
                    : 'show'
            } flex align-center justify-center`}
            data-testid={`${
                !isStreamInProgress ? 'rewrite' : 'stop'
            }-response-button'`}
        >
            <div className="generateButton__icon flex align-center justify-center">
                {!isStreamInProgress ? <RefreshIcon /> : <StopIcon />}
            </div>
            <Typography type="small1" className="generateButton__header">
                {generateLabel()}
            </Typography>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={onClosePopover}
                anchorOrigin={{
                    vertical: -10,
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                classes={{
                    paper: 'generateButton__tooltip-container',
                    root: 'generateButton__tooltip-root'
                }}
            >
                <Typography
                    className={`generateButton__tooltip ${
                        isSmallChat ? 'smallChat' : ''
                    }`}
                    type="small2"
                >
                    Currently, the regenerate feature is limited to 2 times per
                    question
                </Typography>
            </Popover>
        </div>
    );
};

export default GenerateButton;
