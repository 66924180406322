import React, { FunctionComponent } from 'react';
import { Typography } from 'aqui';
import { t } from 'i18next';
import { IngestModalTypes } from '../../../../types';

type IngestModalTitleProps = {
    count: number;
    modalType: IngestModalTypes;
};

const IngestModalTitle: FunctionComponent<IngestModalTitleProps> = ({
    count,
    modalType
}) => {
    const titleData = {
        [IngestModalTypes.addPersona]: {
            title: t('ingested-sources.add-personas'),
            subTitle: null
        },
        [IngestModalTypes.deleteSources]: {
            title: t('ingested-sources.delete-sources'),
            subTitle: () => (
                <>
                    {t('ingested-sources.danger-delete-sources-1')}{' '}
                    <span className="bold">
                        {t('ingested-sources.danger-delete-sources-2')}
                    </span>{' '}
                    {t('ingested-sources.danger-delete-sources-3')}
                </>
            )
        }
    };

    return (
        <div className="flex flex-col ingestModalTitle__title">
            <Typography type="h3">{titleData[modalType]?.title}</Typography>
            {titleData[modalType]?.subTitle && (
                <Typography
                    type="body1"
                    className="ingestModalTitle__title__subtitle"
                >
                    {titleData[modalType]?.subTitle()}
                </Typography>
            )}

            <Typography
                dataTestId={`${modalType}-count-title`}
                type="body3"
                className="ingestModalTitle__title__count"
            >
                {t('ingested-sources.selected-sources')}:{' '}
                <span data-testid={`${modalType}-count`} className="bold">
                    {count}
                </span>
            </Typography>
        </div>
    );
};

export default IngestModalTitle;
