import { ArrowIcon } from 'aqui/icons';
import { Typography } from 'aqui';
import { FunctionComponent } from 'react';

interface PaginationControllerProps {
    pageNum: number;
    numPages: number;
    navToPrevPage: Function;
    navToNextPage: Function;
    isPrevPageDisabled: boolean;
    isNextPageDisabled: boolean;
}

const PaginationController: FunctionComponent<PaginationControllerProps> = ({
    pageNum,
    numPages,
    navToPrevPage,
    navToNextPage,
    isPrevPageDisabled,
    isNextPageDisabled
}) => {
    return (
        <div className="doc-control-panel__pagination">
            <ArrowIcon
                direction="left"
                onClick={navToPrevPage}
                className={`doc-control-panel__button ${
                    isPrevPageDisabled ? '--disabled' : ''
                }`}
                dataTestId="pdf-controller-nav-to-prev-page"
                data-disabled={isPrevPageDisabled}
            />
            <Typography
                type="small1Bold"
                dataTestId="pdf-controller-current-page-display"
            >{`${pageNum} / ${numPages}`}</Typography>
            <ArrowIcon
                direction="right"
                onClick={navToNextPage}
                className={`doc-control-panel__button ${
                    isNextPageDisabled ? '--disabled' : ''
                }`}
                dataTestId="pdf-controller-nav-to-next-page"
                data-disabled={isNextPageDisabled}
            />
        </div>
    );
};

export default PaginationController;
