const MinimizedIcon = ({ className = '' }) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <rect
                x="1"
                y="13"
                width="14"
                height="2"
                rx="1"
                fill="currentcolor"
            />
        </svg>
    );
};

export default MinimizedIcon;
