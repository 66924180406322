import { CheckBox } from 'aqui';
import { IngestedSourcesRow } from '../../../types';
import { useMemo } from 'react';

interface CheckboxCellProps {
    row: IngestedSourcesRow;
    selectedRows: IngestedSourcesRow[];
    setSelectedRows: React.Dispatch<React.SetStateAction<IngestedSourcesRow[]>>;
}

const CheckboxCell: React.FC<CheckboxCellProps> = ({
    row,
    selectedRows,
    setSelectedRows
}) => {
    const selectedRowIdsSet = useMemo(
        () => new Set(selectedRows.map((row: IngestedSourcesRow) => row.id)),
        [selectedRows]
    );

    const toggleSelectedRow = (row: IngestedSourcesRow) => {
        if (selectedRowIdsSet.has(row.id)) {
            setSelectedRows((prevRows: IngestedSourcesRow[]) =>
                prevRows.filter(
                    (prevRow: IngestedSourcesRow) => prevRow.id !== row.id
                )
            );
        } else {
            setSelectedRows((prevRows: IngestedSourcesRow[]) => [
                ...prevRows,
                row
            ]);
        }
    };

    return (
        <CheckBox
            checked={selectedRowIdsSet.has(row.id)}
            color="lightgrey"
            controlled
            onClick={() => toggleSelectedRow(row)}
            dataTestId="checkbox-cell"
        />
    );
};

export default CheckboxCell;
