import { useEffect, useRef, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import { Typography } from 'aqui';

const ValueWithTooltip = ({
    title,
    tooltipTitle = title,
    className = '',
    onClick = null,
    dataTestId = title,
    type = 'small1',
    maxLines = 1,
    ...props
}) => {
    const [isOverflow, setIsOverflow] = useState(false);

    const ref = useRef();

    useEffect(() => {
        setIsOverflow(
            ref.current.scrollWidth > ref.current.clientWidth ||
                ref.current.scrollHeight > ref.current.clientHeight
        );
    }, [title]);

    return (
        <Tooltip
            key={`${tooltipTitle}_tooltip`}
            placement="top"
            title={isOverflow ? tooltipTitle : ''}
            classes={{
                tooltip: 'tooltip'
            }}
            {...props}
        >
            <Typography
                type={type}
                key={`${title}_text`}
                data-testid={dataTestId}
                ref={ref}
                className={`lines-limit-${maxLines} ${className}`}
                onClick={onClick}
            >
                {title}
            </Typography>
        </Tooltip>
    );
};

export default ValueWithTooltip;
