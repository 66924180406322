import { FunctionComponent } from 'react';
import { Tooltip } from '@mui/material';
import { t } from 'i18next';
import RotateIcon from '../../../icons/RotateIcon';

interface RotateControllerProps {
    onRotateCCW: Function;
    onRotateCW: Function;
}

const RotateController: FunctionComponent<RotateControllerProps> = ({
    onRotateCCW,
    onRotateCW
}) => {
    return (
        <div className="doc-control-panel__rotate">
            <Tooltip
                title={t('pdf.rotate-ccw')}
                placement="top"
                classes={{
                    tooltip: 'tooltip'
                }}
            >
                <div>
                    <RotateIcon
                        className="doc-control-panel__button"
                        clockwise={false}
                        onClick={onRotateCCW}
                        data-testid="pdf-controller-rotate-ccw"
                    />
                </div>
            </Tooltip>
            <Tooltip
                title={t('pdf.rotate-cw')}
                placement="top"
                classes={{
                    tooltip: 'tooltip'
                }}
            >
                <div>
                    <RotateIcon
                        className="doc-control-panel__button"
                        onClick={onRotateCW}
                        data-testid="pdf-controller-rotate-cw"
                    />
                </div>
            </Tooltip>
        </div>
    );
};

export default RotateController;
