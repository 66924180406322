const CheckMarkRoundSmallIcon = ({ color = 'currentColor', ...props }) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect width="16" height="16" rx="8" fill={color} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.9696 5.57564C12.2039 5.80995 12.2039 6.18985 11.9696 6.42417L7.46962 10.9242C7.23531 11.1585 6.85541 11.1585 6.62109 10.9242L4.57564 8.87871C4.34132 8.6444 4.34132 8.2645 4.57564 8.03018C4.80995 7.79587 5.18985 7.79587 5.42417 8.03018L7.04536 9.65137L11.1211 5.57564C11.3554 5.34132 11.7353 5.34132 11.9696 5.57564Z"
                fill="white"
            />
        </svg>
    );
};

export default CheckMarkRoundSmallIcon;
