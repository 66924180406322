const SortArrows = ({ color = 'A3AABB' }) => {
    return (
        <svg
            width="14"
            height="12"
            viewBox="0 0 14 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="Group 67757">
                <path
                    id="Vector"
                    d="M1 8.66699L3.66667 11.3337L6.33333 8.66699"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    id="Vector_2"
                    d="M3.66663 11.3337V0.666992"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    id="Vector_3"
                    d="M13 3.33366L10.3333 0.666992L7.66663 3.33366"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    id="Vector_4"
                    d="M10.3334 0.666992V11.3337"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};

export default SortArrows;
