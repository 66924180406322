const degree = {
    up: '180deg',
    down: '0',
    right: '260deg',
    left: '90deg'
};

const ChevronIconSmall = ({
    direction = '',
    color = 'currentColor',
    height = 16,
    width = 16,
    ...props
}) => (
    <div
        style={{
            rotate: degree[direction],
            height: `${height}px`,
            width: `${width}px`
        }}
    >
        <svg
            width={width}
            height={height}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.86177 6.86225C5.12212 6.6019 5.54423 6.6019 5.80458 6.86225L7.99984 9.05752L10.1951 6.86225C10.4554 6.6019 10.8776 6.6019 11.1379 6.86225C11.3983 7.1226 11.3983 7.54471 11.1379 7.80506L8.47124 10.4717C8.21089 10.7321 7.78878 10.7321 7.52843 10.4717L4.86177 7.80506C4.60142 7.54471 4.60142 7.1226 4.86177 6.86225Z"
                fill={color}
            />
        </svg>
    </div>
);

export default ChevronIconSmall;
