export const RESET_WORK_ORDER_STATE = 'RESET_WORK_ORDER_STATE';
export const resetWorkOrderState = () => ({
    type: RESET_WORK_ORDER_STATE
});

export const RESET_STATE = 'RESET_STATE';
export const resetState = () => ({
    type: RESET_STATE
});

export const RESET_OBSERVATION_INPUTS = 'RESET_OBSERVATION_INPUTS';
export const resetObservationInputs = () => ({
    type: RESET_OBSERVATION_INPUTS
});
