
import { useState } from 'react';
import { Tooltip } from '@mui/material';
import { Typography } from 'aqui';
import { FunctionComponent } from 'react';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    IntegratedModel,
    MessageInfo,
    MessageInvestigationDetails,
    ProductIds,
    SmallWindowState
} from '../../../types';
import {
    setChatWindowState,
    setInvestigationDetails,
    setIsSmallChat,
    setObservationToInvestigate
} from '../../../actions/coPilotActions';
import { ANALYTICS_EVENTS } from '../../../consts';
import useAnalytics from '../../../hooks/useAnalytics';
import { useIntegratedModels, useMessages, useModel, useProductDetails, useScopSettings } from '../../../AppContext';
import Util from '../../../Util';
import ModelList from '../ModelList/ModelList';
import { adjustIntegratedModel } from '../../../utils/productUtils';

type InvestigateInTriageProps = {
    disabled: boolean;
    messageInvestigationDetails: MessageInvestigationDetails;
    message: MessageInfo;
    isTriageModel: boolean;
};

const InvestigateInTriage: FunctionComponent<InvestigateInTriageProps> = ({
    disabled,
    messageInvestigationDetails,
    message,
    isTriageModel
}) => {
    const dispatch = useDispatch();
    const { sendEvent } = useAnalytics();
    const { showInvestigateButtonBasedOnObservations } = useScopSettings();
    const { investigationId } = useSelector(
        (state: any) => state.deviceInspectionReducer
    );

    const { integratedModels } = useIntegratedModels();
    const { model } = useModel();
    const { setProductDetails } = useProductDetails();
    const { messages } = useMessages();

    const [selectedModel, setSelectedModel] = useState(null);

    const shouldDisabled = disabled || (!isTriageModel && !selectedModel);

    const investigateInTriage = () => {
        if (shouldDisabled) {
            return;
        }
        sendEvent(ANALYTICS_EVENTS.CLICKED_INVESTIGATE_IN_TRIAGE);
        if (showInvestigateButtonBasedOnObservations) {
            dispatch(
                setObservationToInvestigate(
                    messageInvestigationDetails?.observationObject
                )
            );
        }
        dispatch(setIsSmallChat(true));
        dispatch(setChatWindowState(SmallWindowState.Maximized));
        const rootElement = document.getElementById('stark');
        rootElement.style.display = 'flex';
    };


    const observationName =
        messageInvestigationDetails?.observationObject[
            Util.getPropWithPrefix('Display__c')
        ] || messageInvestigationDetails?.observationObject.Name;

    const onModelSelect = (model: IntegratedModel) => {
        setSelectedModel(model);
        setProductDetails(model);
        if (model.productIds) {
            settingInvestigationDetails(model.productIds, messages[0].message);
        }
        sendEvent(ANALYTICS_EVENTS.SELECT_DIFFERENT_MODEL_CLICK, {
            'Model value': adjustIntegratedModel(model)
        });
    };

    const settingInvestigationDetails = (
        productIds: ProductIds,
        message: string
    ) => {
        const { productTypeId, manufacturerId, productId } = productIds;
        dispatch(
            setInvestigationDetails({
                productTypeId,
                manufacturerId,
                productId,
                observation: message
            })
        );
    };

    return (
        <div
            className="investigate-in-triage"
            onClick={investigateInTriage}
            data-testid="investigate-in-triage"
            data-disabled={disabled}
        >
            <Tooltip
                title={disabled ? t('chat.disabled-triage-link') : ''}
                placement="top"
                classes={{
                    tooltip: 'tooltip'
                }}
            >
                <div
                    className={`investigate-in-triage__container ${
                        disabled ? 'disabled' : ''
                    }`}
                >
                    {messageInvestigationDetails &&
                    observationName &&
                    showInvestigateButtonBasedOnObservations ? (
                        <Typography
                            type="subtitle1"
                            dataTestId="investigate-in-triage-text"
                        >
                            {`${t('chat.use-triage-observation1')} `}
                            <span className="bold">"{observationName}"</span>
                            {` ${t('chat.use-triage-observation2')}`}
                        </Typography>
                    ) : (
                        <Typography
                            type="subtitle1"
                            dataTestId="investigate-in-triage-text"
                        >
                            {`${t('chat.use-triage')}`}
                            <span className="bold">{` ${message.model}`}</span>.
                            {!isTriageModel && !selectedModel && <><div>{t('chat.verify-model')}:</div>
                            <ModelList mainModelsList={integratedModels.filter((integeratedModel => integeratedModel.modelName === model))} selectedModel={null} onModelSelect={(modelString) => onModelSelect(modelString)}/>
                          </>}
                        </Typography>
                    )}
                    <Typography
                        type="button"
                        className={`investigate-in-triage__button ${
                            shouldDisabled ? 'disabled' : ''
                        }`}
                        dataTestId="investigate-in-triage-button"
                        data-disabled={shouldDisabled}
                    >
                        {investigationId && !shouldDisabled
                            ? t('chat.continue-investigation')
                            : t('chat.triage')}
                    </Typography>
                </div>
            </Tooltip>
        </div>
    );
};

export default InvestigateInTriage;
