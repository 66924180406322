import classnames from 'classnames/bind';
import { Typography } from 'aqui';

import styles from '../Table.module.scss';
const cx = classnames.bind(styles);

export const getColumns = (columns) =>
    columns.map((column) => ({
        ...column,
        headerClassName: cx('data-grid__header'),
        renderHeader: () => (
            <Typography type="small1" textTransform="uppercase">
                {column.headerName}
            </Typography>
        )
    }));
