import { FC } from 'react';
import ValueWithTooltip from '../../ValueWithTooltip/ValueWithTooltip';
import styles from './Cells.module.scss';
import classnames from 'classnames/bind';

const cx = classnames.bind(styles);

interface TextCellProps {
    field: string;
    text: string;
    icon?: any;
    className?: string;
}

const TextCell: FC<TextCellProps> = ({
    field,
    text,
    icon = null,
    className = ''
}) => {
    return (
        <div
            className={cx(className, 'textCell__container')}
            data-testid="clickable-cell"
        >
            <ValueWithTooltip
                type="subtitle3"
                dataTestId={`${field}-cell`}
                title={text}
            />
            {icon}
        </div>
    );
};

export default TextCell;
