import { FunctionComponent, useEffect, useRef, useState } from 'react';
import MessageFeedbackModal from '../MessageFeedbackModal/MessageFeedbackModal';
import {
    ANALYTICS_EVENTS,
    FEEDBACK_BUTTONS_ANIMATION_DELAY,
    FEEDBACK_BUTTONS_HIDE_TOOLTIP_DELAY,
    FEEDBACK_BUTTONS_SHOW_TOOLTIP_DELAY
} from '../../consts';
import useAnalytics from '../../hooks/useAnalytics';
import { useFeedback } from '../../AppContext';
import {
    FeedbackType,
    FeedbackValue,
    MessageInfo,
    SourceDocument
} from '../../types';
import LikeIcon from '../../SVG/LikeIcon';
import { Grow, Tooltip } from '@material-ui/core';
import classnames from 'classnames';
import { t } from 'i18next';

type FeedbackMessageButtonsProps = {
    type: string;
    message: MessageInfo;
    sourceDocument?: SourceDocument;
    historyMessage?: MessageInfo;
    shouldShowTooltip?: boolean;
};

const FeedbackMessageButtons: FunctionComponent<
    FeedbackMessageButtonsProps
> = ({ type, message, sourceDocument, historyMessage, shouldShowTooltip }) => {
    const [openFeedbackModal, setOpenFeedbackModal] = useState<boolean>(false);
    const [isSendingInProgress, setIsSendingInProgress] =
        useState<boolean>(false);
    const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
    const tooltipTimeout = useRef<any>();
    const currentMessage = historyMessage || message;
    const { sendEvent } = useAnalytics();
    const key = sourceDocument
        ? sourceDocument.id
        : currentMessage.rowID || currentMessage.sentTime;

    const {
        feedbackData,
        setFeedbackValue,
        sendFeedback,
        dontAsk,
        setDontAsk
    } = useFeedback(key);
    const clickedFeedback = feedbackData?.type;
    const feedbackType = sourceDocument
        ? FeedbackType.Source
        : FeedbackType.Response;

    const feedbackEventData = {
        Location: feedbackType,
        'Feedback type': clickedFeedback
    };

    useEffect(() => {
        setOpenFeedbackModal(false);
        setIsSendingInProgress(false);
    }, [message, historyMessage]);

    useEffect(() => {
        if (shouldShowTooltip) {
            tooltipTimeout.current = setTimeout(
                () => showTooltip(),
                FEEDBACK_BUTTONS_SHOW_TOOLTIP_DELAY
            );
        }
        return () => clearTimeout(tooltipTimeout.current);
    }, [shouldShowTooltip]);

    const showTooltip = () => {
        setIsTooltipOpen(true);
        tooltipTimeout.current = setTimeout(() => {
            setIsTooltipOpen(false);
            tooltipTimeout.current = null;
        }, FEEDBACK_BUTTONS_HIDE_TOOLTIP_DELAY);
    };

    const handleSendFeedback = async (
        clickedType: FeedbackValue,
        categories: Array<string> = null,
        feedbackText: string = null
    ) => {
        if (isSendingInProgress) {
            return;
        }
        setIsSendingInProgress(true);
        await sendFeedback(
            clickedType,
            categories,
            feedbackText,
            message,
            feedbackType,
            historyMessage,
            sourceDocument
        );
        setIsSendingInProgress(false);
    };

    const onLikeDislikeClick = (clickedType: FeedbackValue) => {
        if (isSendingInProgress) {
            return;
        }
        if (clickedType === clickedFeedback) {
            setFeedbackValue(null);
            handleSendFeedback(null);
            return;
        }
        setFeedbackValue(clickedType);
        const eventName =
            clickedType === FeedbackValue.Like
                ? ANALYTICS_EVENTS.LIKE_ANSWER_CLICK
                : ANALYTICS_EVENTS.DISLIKE_ANSWER_CLICK;
        const eventData = {
            'Row ID': currentMessage.rowID,
            'Chat History ID': currentMessage.chatID,
            'No Response': currentMessage.noResponse,
            type
        };
        sendEvent(eventName, eventData);
        if (dontAsk) {
            handleSendFeedback(clickedType);
        } else {
            setOpenFeedbackModal(true);
        }
    };

    const sendFeedbackModalEvent = (
        isSkipped: boolean,
        categories: Array<string>,
        feedbackText: string
    ) => {
        const eventData = {
            ...feedbackEventData,
            Category: categories.join(', '),
            "Don't ask again": dontAsk ? 'checked' : 'unchecked',
            'Comment length': feedbackText.length
        };
        const event = isSkipped
            ? ANALYTICS_EVENTS.FEEDBACK_MODAL_SKIP_CLICK
            : ANALYTICS_EVENTS.FEEDBACK_MODAL_SUBMIT_CLICK;
        sendEvent(event, eventData);
    };

    const onFeedbackSubmit = (
        dontAsk,
        categories: Array<string>,
        feedbackText: string,
        isSkipped: false
    ) => {
        sendFeedbackModalEvent(isSkipped, categories, feedbackText);
        handleSendFeedback(clickedFeedback, categories, feedbackText);
        setOpenFeedbackModal(false);
        if (dontAsk) {
            setDontAsk(dontAsk);
        }
    };

    return (
        <>
            <Tooltip
                title={t('feedback-modal.your-feedback-helps')}
                open={isTooltipOpen}
                placement="top"
                arrow
                classes={{
                    tooltip: 'tooltip',
                    arrow: 'tooltip__arrow'
                }}
            >
                <div className="feedback-message-btns">
                    <Grow in timeout={FEEDBACK_BUTTONS_ANIMATION_DELAY[1]}>
                        <div>
                            <LikeIcon
                                onClick={() =>
                                    onLikeDislikeClick(FeedbackValue.Like)
                                }
                                className={classnames(
                                    'feedback-message-btns__btn',
                                    {
                                        '--progressing': isSendingInProgress,
                                        '--active':
                                            clickedFeedback ===
                                            FeedbackValue.Like
                                    }
                                )}
                                filled={clickedFeedback === FeedbackValue.Like}
                                data-testid={`${
                                    sourceDocument ? 'source' : 'message'
                                }-like-button`}
                                data-active={
                                    clickedFeedback === FeedbackValue.Like
                                }
                            />
                        </div>
                    </Grow>
                    <Grow in timeout={FEEDBACK_BUTTONS_ANIMATION_DELAY[0]}>
                        <div>
                            <LikeIcon
                                onClick={() =>
                                    onLikeDislikeClick(FeedbackValue.Dislike)
                                }
                                className={classnames(
                                    'feedback-message-btns__btn',
                                    {
                                        '--progressing': isSendingInProgress,
                                        '--active':
                                            clickedFeedback ===
                                            FeedbackValue.Dislike
                                    }
                                )}
                                filled={
                                    clickedFeedback === FeedbackValue.Dislike
                                }
                                data-testid={`${
                                    sourceDocument ? 'source' : 'message'
                                }-dislike-button`}
                                data-selected={
                                    clickedFeedback === FeedbackValue.Dislike
                                }
                                dislike
                            />
                        </div>
                    </Grow>
                </div>
            </Tooltip>
            <MessageFeedbackModal
                isOpen={openFeedbackModal}
                feedbackValue={clickedFeedback}
                onSubmit={onFeedbackSubmit}
                feedbackEventData={feedbackEventData}
            />
        </>
    );
};

export default FeedbackMessageButtons;
