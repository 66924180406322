import { FunctionComponent } from 'react';
import { Typography } from 'aqui';
import { t } from 'i18next';
import ValueWithTooltip from '../../ValueWithTooltip/ValueWithTooltip';

type SuggestQuestionMessageProps = {
    onClickSuggestion: (a: string, b?: boolean) => void;
    suggestions: string[];
    isSmallChat: boolean;
    originalQuestion: string;
};

const SuggestQuestionMessage: FunctionComponent<
    SuggestQuestionMessageProps
> = ({ onClickSuggestion, suggestions, isSmallChat, originalQuestion }) => {
    const textTypes = {
        message: isSmallChat ? 'body3' : 'subtitle1'
    };
    const originalQuestionText = `Only “${originalQuestion}” results`;
    return (
        <div className="suggestQuestionMessage__container">
            <Typography
                dataTestId="suggested-question-message-header"
                className="suggestQuestionMessage__header"
                type={textTypes.message}
            >
                {t('suggested-questions.header')}
            </Typography>
            <div
                data-testid="suggested-question-message-options"
                className="suggestQuestionMessage__suggested-wrapper flex flex-col"
            >
                {suggestions.map((suggestion: string, index: number) => (
                    <ValueWithTooltip
                        dataTestId={`suggested-question-message-option_${index}`}
                        key={index}
                        title={suggestion}
                        className="suggestion"
                        type="body3"
                        onClick={() => onClickSuggestion(suggestion, true)}
                    />
                ))}
                <ValueWithTooltip
                    dataTestId={`suggested-question-message-option_${suggestions.length}`}
                    key={suggestions.length}
                    title={originalQuestionText}
                    className="suggestion"
                    type="body3"
                    onClick={() => onClickSuggestion(originalQuestion, true)}
                />
            </div>
        </div>
    );
};

export default SuggestQuestionMessage;
