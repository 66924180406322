import { useDispatch } from 'react-redux';
import SearchBar from '../SearchBar/SearchBar';
import {
    useInitialSearch,
    useModel,
    useFirstRequestText,
    useIntegratedModels,
    useProductDetails,
    useMessages,
    usePortalInitialization
} from '../../AppContext';
import { useEffect, useMemo } from 'react';
import useAnalytics from '../../hooks/useAnalytics';
import { ANALYTICS_EVENTS } from '../../consts';
import useSwitchPanel from '../../hooks/useSwitchPanel';
import { adjustIntegratedModel } from '../../utils/productUtils';
import { setInvestigationDetails } from '../../actions/coPilotActions';
import EclipseLoader from '../../SVG/EclipseLoader';
import FirstRequestPanelLogo from './FirstRequestPanelLogo';

const FirstRequestPanel = () => {
    const { model, setModelData } = useModel();
    const { productDetails, setProductDetails } = useProductDetails();
    const { integratedModels } = useIntegratedModels();
    const { setCoPilotPanel } = useSwitchPanel();
    const { setInitialSearch } = useInitialSearch();
    const { firstRequestText, setFirstRequestText, resetFirstRequestText } =
        useFirstRequestText();
    const { sendEvent } = useAnalytics();
    const { setMessages } = useMessages();
    const { isPortalInitialized, setIsPortalInitialized } =
        usePortalInitialization();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isPortalInitialized) {
            sendEvent(ANALYTICS_EVENTS.PORTAL_LOADED);
            setIsPortalInitialized(true);
        }
    }, []);

    const handleSearchSubmit = () => {
        setMessages([]);
        setInitialSearch(firstRequestText);
        setCoPilotPanel();
        resetFirstRequestText();
        if (productDetails?.productTypeName) {
            dispatch(
                setInvestigationDetails({
                    productTypeId: productDetails?.productIds?.productTypeId,
                    manufacturerId: productDetails?.productIds?.manufacturerId,
                    productId: productDetails?.productIds?.productId,
                    observation: firstRequestText
                })
            );
        }
    };

    const onSelectedValue = (modelObject) => {
        setModelData(modelObject.modelName);
        setProductDetails(modelObject);
    };

    const selectedValue = useMemo(() => {
        if (!model || model === 'all') {
            return 'all';
        }
        return adjustIntegratedModel({
            modelName: model,
            modelDisplay: productDetails?.modelDisplay,
            productTypeDisplay: productDetails?.productTypeDisplay,
            manufacturerDisplay: productDetails?.manufacturerDisplay,
            productTypeName: productDetails?.productTypeName,
            manufacturerName: productDetails?.manufacturerName
        });
    }, [model, productDetails]);

    return (
        <div className="first-request">
            <FirstRequestPanelLogo />
            {integratedModels.length ? (
                <div className="first-request__input-container">
                    <SearchBar
                        placeholder="Ask a service question"
                        withClearButton
                        withSelect={Boolean(model)}
                        selectedValue={selectedValue}
                        setSelectedValue={onSelectedValue}
                        onSubmit={handleSearchSubmit}
                        dataTestId="first_request_search_bar"
                        disabled={!integratedModels.length}
                        value={firstRequestText}
                        setValue={setFirstRequestText}
                        growWithText={false}
                        withPresetSuggestions
                        modelAutoSelect
                    />
                </div>
            ) : (
                <div className="first-request__loader">
                    <EclipseLoader color="#17b1e2" width="100" height="100" />
                </div>
            )}
        </div>
    );
};

export default FirstRequestPanel;
