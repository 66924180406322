import ValueWithTooltip from '../../ValueWithTooltip/ValueWithTooltip';
import styles from './Cells.module.scss';
import classnames from 'classnames/bind';
const cx = classnames.bind(styles);

const FileNameCell = ({ field, row, isSelected, onClick }) => {
    return (
        <ValueWithTooltip
            type="subtitle3"
            dataTestId={`${field}-cell`}
            title={row[field]}
            className={cx('file-name', { '--selected': isSelected })}
            onClick={onClick}
        />
    );
};

export default FileNameCell;
