import { Typography } from 'aqui';
import DocumentSmallIcon from '../../../../SVG/DocumentSmallIcon';
import VideoSmallIcon from '../../../../SVG/VideoSmallIcon';
import ValueWithTooltip from '../../../ValueWithTooltip/ValueWithTooltip';
import { t } from 'i18next';
import {
    useCopyPersonaSourcesModal,
    usePersonaSourcesModal,
    useSourcesByPersona
} from '../PersonaManagementHooks';
import { adjustIntegratedModel } from '../../../../utils/productUtils';
import { useServerlessIndex } from '../../../../AppContext';

const PersonaSourcesModalBody = () => {
    const { selectedPersonaSources, setSelectedPersonaSources } =
        usePersonaSourcesModal();
    const { setCopySourcesFromPersonaModal } = useCopyPersonaSourcesModal();
    const { sourcesByPersona } = useSourcesByPersona();
    const { isServerlessIndex } = useServerlessIndex();

    const getIcon = (type: string) => {
        if (type === 'video') {
            return <VideoSmallIcon className="personaSourcesModalBody__icon" />;
        }
        return <DocumentSmallIcon className="personaSourcesModalBody__icon" />;
    };

    const onClickCopySources = () => {
        setSelectedPersonaSources(null);
        setCopySourcesFromPersonaModal(selectedPersonaSources);
    };

    if (sourcesByPersona.length === 0) {
        return (
            <div className="flex flex-col align-center justify-center personaSourcesModalBody__empty__container full-height">
                <Typography type="subtitle3">
                    {t('add-persona.no-sources-yet')}
                </Typography>
                {!isServerlessIndex && (
                    <Typography
                        className="personaSourcesModalBody__empty__copy-sources"
                        dataTestId="persona-sources-empty-copy-sources"
                        type="small1"
                        onClick={onClickCopySources}
                    >
                        {t('add-persona.copy-sources-from-persona')}
                    </Typography>
                )}
            </div>
        );
    }

    return (
        <div className="personaSourcesModalBody__container full-height">
            <Typography
                type="small2"
                className="personaSourcesModalBody__sources-count"
                dataTestId="persona-sources-count"
            >
                {`${sourcesByPersona.length} ${t(
                    'add-persona.persona-sources-title'
                )}`}
            </Typography>
            <div
                data-testid="persona-sources-rows"
                className="flex flex-col personaSourcesModalBody__sources"
            >
                {sourcesByPersona.map((obj) => {
                    const adjustModel = obj.integratedModel
                        ? adjustIntegratedModel(obj.integratedModel)
                        : obj.tenant_model;
                    return (
                        <div
                            data-testid="persona-sources-row"
                            className="flex flex-col"
                        >
                            <div className="flex align-center personaSourcesModalBody__source-icon">
                                {getIcon(obj.document_type)}
                                <ValueWithTooltip
                                    title={obj.document_name}
                                    className="checkboxSourceList__item-name__main"
                                    dataTestId="source-name"
                                />
                            </div>
                            <Typography
                                type="small1"
                                className="personaSourcesModalBody__source-model"
                            >
                                {adjustModel}
                            </Typography>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default PersonaSourcesModalBody;
