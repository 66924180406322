import OctagonWarning from '../../SVG/OctagonWarning';
import { Typography } from 'aqui';
import { FunctionComponent } from 'react';

interface HeaderMessageProps {
    message: string;
}

const HeaderMessage: FunctionComponent<HeaderMessageProps> = ({ message }) => {
    return (
        <div className="flex align-center headerMessage__container">
            <OctagonWarning className="headerMessage__icon" />
            <Typography className="headerMessage__text" type="small1">
                {message}
            </Typography>
        </div>
    );
};

export default HeaderMessage;
