import { Textarea, Typography } from 'aqui';
import { t } from 'i18next';
import { splitAndCleanStringToArray } from '../../../utils/utils';

const AddUsersBox = ({ value, setValue, className, dataTestId }) => {
    const handleBlur = () => {
        setValue(splitAndCleanStringToArray(value).sort().join('\n'));
    };

    return (
        <div
            data-testid={dataTestId}
            className="flex flex-col addUsersBox__container"
        >
            <Typography type="subtitle3">
                {t('add-persona.user-emails')}
            </Typography>
            <Typography type="small2" className="addUsersBox__subtext">
                {t('add-persona.add-users-subtitle')}
            </Typography>
            <Textarea
                className={className}
                placeholder="ex. user1@email.com, user2@email.com..."
                dataTestId="add-users-textarea"
                value={value}
                setValue={setValue}
                onBlur={handleBlur}
            />
        </div>
    );
};

export default AddUsersBox;
