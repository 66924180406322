import {
    FunctionComponent,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { Typography } from 'aqui';
import {
    ChevronIcon,
    CopyIcon,
    CheckMarkIcon,
    PencilEditIcon
} from 'aqui/icons';
import { Tooltip } from '@mui/material';
import {
    MessageInfo,
    MessageSenderType,
    SmallWindowState
} from '../../../types';
import KAList from '../../KASection/KAList';
import useAnalytics from '../../../hooks/useAnalytics';
import {
    ANALYTICS_EVENTS,
    FEEDBACK_BUTTONS_ANIMATION_DELAY
} from '../../../consts';
import {
    formatSourceNumbersMessage,
    getNumOfUniqueFiles
} from '../../KASection/KASectionHelpers';
import AnswersNavigator from '../AnswersNavigator/AnswersNavigator';
import {
    useTriageLinkMessageIndexes,
    useClickedMessagesNavigation,
    useAskStream,
    useScopSettings,
    useThreadsHandling,
    useEditedMessages,
    useIntegratedModels,
    useModel
} from '../../../AppContext';
import SuggestQuestionMessage from '../SuggestQuestionMessage/SuggestQuestionMessage';
import FeedbackMessageButtons from '../../FeedbackMessageButtons/FeedbackMessageButtons';
import {
    useChatWindowState,
    useHighlightedMessageIndex,
    useMagicButtonRect,
    useMessageDisclaimers,
    useSelectedText,
    useShowMagicButton,
    useSmallChatInPortal
} from '../../../context/ChatContext';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import { followAnswer, genMessageDetailsKey } from '../helpers';
import InvestigateInTriage from '../InvestigateInTriage/InvestigateInTriage';
import { getAlwaysInvestigateInTriageButton } from '../../../selectors/packageVersionSelectors';
import AquantSenderMessage from '../AquantSenderMessage/AquantSenderMessage';
import { inIframe } from '../../../utils/utils';
import RewriteResponseFeedbackBar from '../RewriteResponseFeedbackBar/RewriteResponseFeedbackBar';
import { Grow } from '@material-ui/core';
import { checkIfModelExistsInTriage } from '../../../utils/productUtils';
import { getIsUserExpertOrAdmin } from '../../../selectors/userPermissionSetsSelectors';
import InfoIcon from '../../../SVG/infoIcon';
import MessageDisclaimers from '../../MessageDisclaimers/MessageDisclaimers';

const LEFT = 'left';

type ChatMessageProps = {
    message: MessageInfo;
    question?: MessageInfo;
    onClickShowArticles?: Function;
    isSmallChat: boolean;
    index: number;
    isTriageModel: boolean;
    typingStarted: Boolean;
    typingFocused: Boolean;
    missedMessagesRelativeHight: number[];
    setMissedMessagesCounterRelativeHight: Function;
    historyMessages: MessageInfo[];
    isMessageFocused: boolean;
    onClickSuggestion?: (a: string, b?: boolean) => void;
    showSuggestions: boolean;
    isLastMessage: boolean;
    askedQuestion: string;
    shouldShowError?: boolean;
};

const ChatMessage: FunctionComponent<ChatMessageProps> = ({
    message,
    onClickShowArticles,
    isSmallChat,
    index,
    isTriageModel,
    typingStarted,
    typingFocused,
    missedMessagesRelativeHight,
    setMissedMessagesCounterRelativeHight,
    historyMessages,
    isMessageFocused,
    onClickSuggestion,
    showSuggestions = false,
    isLastMessage,
    askedQuestion,
    shouldShowError = false
}) => {
    const [isTyping, setIsTyping] = useState<boolean>(true);
    const [copiedText, setCopiedText] = useState<boolean>(false);
    const [showKA, setShowKA] = useState<boolean>(false);
    const [messageIndex, setMessageIndex] = useState<number | null>(null);
    const [rects, setRects] = useState<any>([]);
    const [expertEdited, setExpertEdited] = useState<boolean>(false);
    const { highlightedMessageIndex, setHighlightedMessageIndex } =
        useHighlightedMessageIndex();
    const { setSelectedText } = useSelectedText();
    const { sendEvent } = useAnalytics();
    const { isStreamInProgress } = useAskStream();
    const { triageLinkMessageIndexes } = useTriageLinkMessageIndexes();
    const { setClickedMessagesNavigation } = useClickedMessagesNavigation();
    const { setShowMagicButton } = useShowMagicButton();
    const {
        messagesPerSelectedChat,
        selectedThread,
        triageBtnIndexOnThreadClick,
        isChatHistoryLoaded
    } = useThreadsHandling();
    const { chatWindowState } = useChatWindowState();
    const {
        setIndexAndHeighOfEditedEntireMessage,
        indexAndHeightOfEditedEntireMessage
    } = useEditedMessages();
    const { isSmallChatInPortal } = useSmallChatInPortal();
    const { integratedModels } = useIntegratedModels();
    const { model } = useModel();
    const { messageDisclaimers } = useMessageDisclaimers(message.rowID);
    const [isDisclaimerCollapseInProgress, setIsDisclaimerCollapseInProgress] =
        useState<boolean>(false);

    const {
        showInvestigateButtonBasedOnObservations,
        enableEditResponsesByExperts,
        displayKeywordDisclaimers
    } = useScopSettings();
    const messageRef = useRef<HTMLDivElement>();
    const textMessageRef = useRef<HTMLParagraphElement>();

    const { messagesInvestigationDetails } = useSelector(
        (state: any) => state.coPilotReducer
    );
    const { magicButtonRect, setMagicButtonRect } = useMagicButtonRect();
    const isUserExpertOrAdmin = useSelector(getIsUserExpertOrAdmin);
    const senderRef = useRef<HTMLDivElement>();

    const messageInvestigationDetails = useMemo(
        () =>
            messagesInvestigationDetails?.[
                genMessageDetailsKey(askedQuestion, message.productDetails)
            ],
        [messagesInvestigationDetails, message, askedQuestion]
    );

    const showEditAnswerBtn = useMemo(() => {
        return (
            enableEditResponsesByExperts &&
            isUserExpertOrAdmin &&
            !isSmallChatInPortal &&
            !isSmallChat &&
            !indexAndHeightOfEditedEntireMessage[index] &&
            (!selectedThread ||
                messagesPerSelectedChat[selectedThread] - 1 < index)
        );
    }, [
        messagesPerSelectedChat,
        indexAndHeightOfEditedEntireMessage,
        isSmallChatInPortal,
        isSmallChat
    ]);

    const showRewriteFeedbackBar =
        !!historyMessages?.length && !isMessageFocused;

    const eventData = {
        'Row ID': message.rowID,
        'Chat History ID': message.chatID,
        'No Response': message.noResponse
    };

    const alwaysInvestigateInTriageButton = useSelector(
        getAlwaysInvestigateInTriageButton
    );

    const textTypes = {
        message: isSmallChat ? 'body3' : 'subtitle1'
    };

    const shouldShowTriageLink = useMemo(() => {
        return triageLinkMessageIndexes.includes(index);
    }, [index, triageLinkMessageIndexes]);

    const isTriageLinkDisabled =
        index < triageLinkMessageIndexes[triageLinkMessageIndexes.length - 1];

    useEffect(() => {
        if (!isStreamInProgress && isTyping) {
            setIsTyping(false);
        }
    }, [isStreamInProgress]);

    useEffect(() => {
        if (messageIndex !== null && isMessageFocused) {
            setMessageIndex(null);
        }
    }, [isMessageFocused]);

    useEffect(() => {
        if (expertEdited) {
            onClickResults();
            setExpertEdited(false);
        }
    }, [expertEdited]);

    useLayoutEffect(() => {
        if (
            message.sender !== MessageSenderType.Aquant ||
            !message.message ||
            !isChatHistoryLoaded ||
            isDisclaimerCollapseInProgress
        ) {
            return;
        }
        if (typingStarted && typingFocused) {
            followAnswer();
        }
        if (!typingStarted) {
            if (!typingFocused) {
                setMissedMessagesCounterRelativeHight([
                    ...missedMessagesRelativeHight,
                    messageRef.current.offsetTop +
                        messageRef.current.clientHeight
                ]);
            } else {
                followAnswer();
            }
        }
    }, [message.message, typingStarted]);

    useLayoutEffect(() => {
        if (isDisclaimerCollapseInProgress && typingFocused) {
            followAnswer();
        }
    }, [isDisclaimerCollapseInProgress, typingFocused]);

    const onClickResults = () => {
        if (isSmallChat) {
            setShowKA(!showKA);
        } else {
            onClickShowArticles?.();
        }
        sendEvent(ANALYTICS_EVENTS.SHOW_SOURCES_CLICK, {
            'Values Selection': showKA ? 'open' : 'close'
        });
    };

    const onCopyToClipBoard = async () => {
        const messageToCopy =
            messageIndex === null
                ? message.message
                : historyMessages[messageIndex].message;
        navigator.clipboard.writeText(
            !!message.relatedSourceDocuments.length
                ? formatSourceNumbersMessage(messageToCopy)
                : messageToCopy
        );
        setCopiedText(true);
        setTimeout(() => {
            setCopiedText(false);
        }, 1500);
        sendEvent(ANALYTICS_EVENTS.COPY_TO_CLIPBOARD_CLICK, eventData);
    };

    const getShowInvestigateInTriage = () => {
        const isTriageModelRelated =
            isTriageModel ||
            checkIfModelExistsInTriage(integratedModels, model);
        if (
            isMessageFocused ||
            isSmallChat ||
            message.sender !== MessageSenderType.Aquant ||
            !isTriageModelRelated
        ) {
            return false;
        }
        if (showInvestigateButtonBasedOnObservations) {
            return !!messageInvestigationDetails;
        } else if (selectedThread) {
            return index === triageBtnIndexOnThreadClick;
        } else {
            return shouldShowTriageLink || alwaysInvestigateInTriageButton;
        }
    };

    const showSuggested = () => {
        return (
            showSuggestions &&
            message.sender === MessageSenderType.Aquant &&
            !isTyping &&
            isLastMessage
        );
    };

    const kaSourceDisplay = () => {
        if (!message?.sourceDocuments?.length || messageIndex !== null) {
            return;
        }
        const resultsNum = message.sourceDocuments.length;
        const uniqueFilesNum = getNumOfUniqueFiles(message.sourceDocuments);
        const text = !!message.relatedSourceDocuments?.length
            ? `${t('search.based-on')} ${
                  message.relatedSourceDocuments?.length
              } ${t('search.sources')}`
            : `${resultsNum} ${t('search.results-from')} ${uniqueFilesNum} ${t(
                  'search.sources'
              )}`;

        if (isSmallChat) {
            return (
                <>
                    <ChevronIcon
                        direction="down"
                        className={`${
                            showKA ? 'chatMessage__chevron-open' : ''
                        }`}
                    />
                    {!!message.relatedSourceDocuments?.length ? (
                        <Typography type="small1">{`${t('search.based-on')} ${
                            message.relatedSourceDocuments?.length
                        } ${t('search.sources')}`}</Typography>
                    ) : (
                        <Typography type="small1">{`${uniqueFilesNum} ${t(
                            'search.sources'
                        )}`}</Typography>
                    )}
                </>
            );
        } else {
            return (
                <>
                    <Typography type="small1" className=" bold">
                        {text}
                    </Typography>
                    <ChevronIcon direction="right" />
                </>
            );
        }
    };

    const onClickMessageArrow = (direction: string) => {
        if (direction === LEFT) {
            setMessageIndex(
                messageIndex === null
                    ? historyMessages.length - 1
                    : messageIndex - 1
            );
        } else {
            setMessageIndex(
                messageIndex + 1 === historyMessages.length
                    ? null
                    : messageIndex + 1
            );
        }
        setClickedMessagesNavigation(true);
    };

    const handleClickOutside = () => {
        if (highlightedMessageIndex) {
            if (highlightedMessageIndex === index) {
                setHighlightedMessageIndex(null);
            }
        }
    };

    useOnClickOutside(senderRef, handleClickOutside);

    const handleSuggestionClick = (
        textContent: string,
        useOriginalValue: boolean
    ) => {
        sendEvent(ANALYTICS_EVENTS.CLICKED_ALTERNATIVE_QUESTION);
        onClickSuggestion(textContent, useOriginalValue);
    };

    const onClickEditResponse = () => {
        sendEvent(ANALYTICS_EVENTS.CLICKED_EDIT_RESPONSE, {
            'Editing type': 'full',
            'Editing location': 'UI'
        });
        setIndexAndHeighOfEditedEntireMessage({
            [index]: textMessageRef.current.clientHeight + 'px'
        });
    };

    if (!message.message.length) {
        return <></>;
    }

    const elementContainsSelection = (el, selection) => {
        if (selection.rangeCount > 0) {
            for (let i = 0; i < selection.rangeCount; i++) {
                if (
                    !el.current.contains(
                        selection.getRangeAt(i).commonAncestorContainer
                    )
                ) {
                    return false;
                }
            }
            return true;
        }
        return false;
    };

    const handleTextSelection = (event) => {
        if (message.sender !== MessageSenderType.Aquant || isSmallChat) {
            return;
        }
        if (
            event.target.closest('.copilot-btn__icon') ||
            event.target.closest('.copilot-popper__container')
        ) {
            return;
        }
        const selection = window.getSelection();
        if (!(selection && selection.rangeCount > 0)) return;
        const range = selection.getRangeAt(0);
        const selectedText = selection.toString();
        const contained = elementContainsSelection(senderRef, selection);
        if (!selectedText || !contained) {
            setHighlightedMessageIndex(null);
            return;
        }
        const rect = range.getBoundingClientRect();
        if (!!messageRef.current) {
            const msgTop = messageRef.current.getBoundingClientRect().top;
            const msgLeft = messageRef.current.getBoundingClientRect().left;
            setMagicButtonRect({
                top: rect.top - msgTop - 30,
                left: rect.right - msgLeft,
                msgTop,
                msgLeft
            });
        }
        setShowMagicButton(true);
        setSelectedText(selectedText);
        setHighlightedMessageIndex(index);
        const rects = [];
        for (let i = 0; i < selection.rangeCount; i++) {
            const range = selection.getRangeAt(i);
            const lineRects: any = range.getClientRects();

            for (const rect of lineRects) {
                rects.push({
                    left: rect.left,
                    top: rect.top,
                    width: rect.width,
                    height: rect.height,
                    right: rect.right,
                    bottom: rect.bottom
                });
            }
        }
        setRects(rects);
    };

    return (
        <div
            ref={messageRef}
            className={`chatMessage__container ${message.sender}`}
            data-testid={`${message.sender?.toLowerCase()}-chat-message`}
        >
            <div
                className={`chatMessage__message-container ${message.sender} ${
                    isSmallChat ? '--smallChat' : ''
                }`}
            >
                <div
                    className={`chatMessage__message ${message.sender} ${
                        isSmallChat ? '--smallChat' : ''
                    } ${
                        showRewriteFeedbackBar ? '--with-rewrite-feedback' : ''
                    }`}
                >
                    <Typography
                        ref={textMessageRef}
                        tag={'div'}
                        type={textTypes.message}
                        onMouseUp={handleTextSelection}
                        dataTestId={`chat-message-text`}
                    >
                        <AquantSenderMessage
                            senderRef={senderRef}
                            message={message}
                            askedQuestion={askedQuestion}
                            highlightedMessageIndex={highlightedMessageIndex}
                            magicButtonRect={magicButtonRect}
                            messageIndex={index}
                            rects={rects}
                            showMagicButton={!isSmallChat}
                            historyMessages={historyMessages}
                            historyMessageIndex={messageIndex}
                            setExpertEdited={setExpertEdited}
                        />
                    </Typography>
                    {message.sender === MessageSenderType.Aquant &&
                        shouldShowError && (
                            <div className="chatMessage__message__error flex align-center">
                                <InfoIcon />
                                <Typography
                                    type="small1"
                                    className="chatMessage__message__error__text"
                                >
                                    {t('error.stream-error')}
                                </Typography>
                            </div>
                        )}
                    {message.sender === MessageSenderType.Aquant &&
                        !isMessageFocused &&
                        historyMessages?.length > 0 &&
                        isSmallChat && (
                            <AnswersNavigator
                                onClickMessageArrow={onClickMessageArrow}
                                messageIndex={messageIndex}
                                historyMessages={historyMessages}
                                isSmallChat={isSmallChat}
                            />
                        )}
                    {message.sender === MessageSenderType.Aquant &&
                        !isMessageFocused && (
                            <>
                                <div className="chatMessage__message__footer flex align-center space-between">
                                    <div
                                        className={`chatMessage__message__footer__text flex align-center ${
                                            isSmallChat ? '--smallChat' : ''
                                        }`}
                                        data-testid="chat-message-show-sources-button"
                                        onClick={onClickResults}
                                    >
                                        {kaSourceDisplay()}
                                    </div>
                                    <div className="chatMessage__message__footer__icons flex align-center">
                                        {historyMessages?.length > 0 &&
                                            !isSmallChat && (
                                                <AnswersNavigator
                                                    onClickMessageArrow={
                                                        onClickMessageArrow
                                                    }
                                                    messageIndex={messageIndex}
                                                    historyMessages={
                                                        historyMessages
                                                    }
                                                    isSmallChat={isSmallChat}
                                                />
                                            )}
                                        {showEditAnswerBtn && (
                                            <div
                                                className="flex align-center edit-answer"
                                                onClick={onClickEditResponse}
                                            >
                                                <PencilEditIcon />
                                                <Typography type="small1">
                                                    {message.noResponse
                                                        ? 'Submit a response'
                                                        : 'Edit response'}
                                                </Typography>
                                            </div>
                                        )}
                                        <Tooltip
                                            title={
                                                copiedText
                                                    ? 'Text copied'
                                                    : 'Copy to clipboard'
                                            }
                                            placement="top"
                                            classes={{
                                                tooltip:
                                                    'tooltip tooltip__copy-to-clipboard'
                                            }}
                                        >
                                            {!inIframe() && (
                                                <div
                                                    className={`copy-to-clipboard flex flex-col ${
                                                        copiedText
                                                            ? 'slide-up'
                                                            : ''
                                                    }`}
                                                >
                                                    <Grow
                                                        in
                                                        timeout={
                                                            FEEDBACK_BUTTONS_ANIMATION_DELAY[2]
                                                        }
                                                    >
                                                        <div data-testid="copy-to-clipboard-message-button">
                                                            <CopyIcon
                                                                onClick={
                                                                    onCopyToClipBoard
                                                                }
                                                            />
                                                        </div>
                                                    </Grow>
                                                    <CheckMarkIcon />
                                                </div>
                                            )}
                                        </Tooltip>
                                        <FeedbackMessageButtons
                                            type={
                                                message.expertDetails
                                                    ? 'Expert Response'
                                                    : 'Response'
                                            }
                                            message={message}
                                            historyMessage={
                                                historyMessages?.[messageIndex]
                                            }
                                            shouldShowTooltip={
                                                isLastMessage &&
                                                !(
                                                    isSmallChat &&
                                                    chatWindowState !==
                                                        SmallWindowState.Maximized
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                                {showKA && isSmallChat && (
                                    <KAList
                                        sourceDocuments={
                                            message.sourceDocuments
                                        }
                                        relatedSourceDocuments={
                                            message.relatedSourceDocuments
                                        }
                                        resultsPluralSuffix={
                                            message.sourceDocuments?.length > 1
                                                ? 's'
                                                : ''
                                        }
                                    />
                                )}
                            </>
                        )}
                </div>
                {showRewriteFeedbackBar && (
                    <RewriteResponseFeedbackBar
                        message={message}
                        historyMessage={historyMessages?.[messageIndex]}
                        isSmallChat={isSmallChat}
                    />
                )}
            </div>
            {displayKeywordDisclaimers && !!messageDisclaimers?.length && (
                <MessageDisclaimers
                    disclaimers={messageDisclaimers}
                    setIsCollapseInProgress={setIsDisclaimerCollapseInProgress}
                />
            )}
            {showSuggested() && (
                <SuggestQuestionMessage
                    isSmallChat={isSmallChat}
                    onClickSuggestion={handleSuggestionClick}
                    suggestions={message.promptInfo?.suggested_alternatives}
                    originalQuestion={askedQuestion}
                />
            )}
            {getShowInvestigateInTriage() && (
                <InvestigateInTriage
                    message={message}
                    disabled={isTriageLinkDisabled}
                    messageInvestigationDetails={messageInvestigationDetails}
                    isTriageModel={isTriageModel}
                />
            )}
        </div>
    );
};

export default ChatMessage;
