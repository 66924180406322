import { t } from 'i18next';
import { FileValidation, MediaType, SourceFile } from '../../types';
import VideoSmallIcon from '../../SVG/VideoSmallIcon';
import DocumentSmallIcon from '../../SVG/DocumentSmallIcon';
import CheckMarkRoundSmallIcon from '../../SVG/CheckMarkRoundSmallIcon';
import WarningRoundSmallIcon from '../../SVG/WarningRoundSmallIcon';
import LockRoundSmallIcon from '../../SVG/LockRoundSmallIcon';
import React from 'react';
import { getTimeStringFromSeconds } from '../KASection/KASectionHelpers';
import { getIsStringContainsSpecialChar } from '../../utils/utils';
import { MAX_MODEL_NAME_LENGTH } from '../../consts';

export const getMetadataText = (sourceFile: SourceFile) => {
    const { file, validationType, numPages, duration } = sourceFile;
    if (validationType !== FileValidation.Valid) {
        return '';
    }
    const fileSizeMB = file.size / 1024 / 1024;
    const fileSizeText = `${
        Math.floor(fileSizeMB) || fileSizeMB.toFixed(2)
    } ${t('upload-files-modal.mb')}`;

    if (numPages) {
        const pagesText = numPages === 1 ? t('common.page') : t('common.pages');
        return `${numPages} ${pagesText}, ${fileSizeText}`;
    }
    if (duration) {
        return `${getTimeStringFromSeconds(duration)}, ${fileSizeText}`;
    }
    return fileSizeText;
};

export const getMediaTypeIcon = (fileType: string) =>
    fileType.startsWith('video') ? VideoSmallIcon : DocumentSmallIcon;

export const getValidationTypeIcon = (type: FileValidation) => {
    switch (type) {
        case FileValidation.Valid:
            return CheckMarkRoundSmallIcon;
        case FileValidation.Corrupt:
        case FileValidation.BadFilename:
        case FileValidation.Exists:
            return WarningRoundSmallIcon;
        case FileValidation.Protected:
            return LockRoundSmallIcon;
        default:
            return React.Fragment;
    }
};

export const getAddNewModelButtonState = (
    searchText: string,
    modelNamesSet: Set<string>
) => {
    let shouldShowAddNewModel = true;
    let isAddNewModelDisabled = false;
    let disabledReason = '';

    if (!searchText?.trim() || modelNamesSet.has(searchText)) {
        shouldShowAddNewModel = false;
    } else if (searchText.length > MAX_MODEL_NAME_LENGTH) {
        isAddNewModelDisabled = true;
        disabledReason = t('model.validation-max-characters');
    } else if (!getIsStringContainsSpecialChar(searchText)) {
        isAddNewModelDisabled = true;
        disabledReason = t('model.validation-special-characters');
    }
    return { shouldShowAddNewModel, isAddNewModelDisabled, disabledReason };
};

export const getFileMediaType = (file: File): MediaType => {
    const type = file.type;
    switch (type) {
        case 'video/mp4':
            return MediaType.VIDEO;
        default:
            return MediaType.PDF;
    }
};

