import mixpanel from 'mixpanel-browser';
import { useCurrentPanel, useModel } from '../AppContext';
import { AppTypes } from '../types';
import { useSelector } from 'react-redux';
import { getUrlParams } from '../utils/componentUtils';

const useAnalytics = () => {
    const { currentPanel } = useCurrentPanel();
    const params = getUrlParams();
    const { model } = useModel();

    const { investigationId } = useSelector(
        (state: any) => state.deviceInspectionReducer
    );
    const { fullUserName, customerName, isProductionUser } = useSelector(
        (state: any) => state.userProfileReducer
    );
    const { username: userEmail, packageVersion, userId } = useSelector((state: any) => state.globalReducer);

    const { chatId, isSmallChat } = useSelector(
        (state: any) => state.coPilotReducer
    );

    const appType = isSmallChat ? AppTypes.Triage : currentPanel;

    const defaultTrackData = {
        'Aquant Timestamp': new Date().toISOString(),
        'Chat Id': chatId,
        'App Type': appType,
        '$name': fullUserName,
        '$email': userEmail,
        'Customer Name': customerName,
        'Org Id': window?.__sfdcIds?.orgId,
        'Investigation Id': investigationId,
        'Is Production User': isProductionUser,
        'Integration': !!params.model,
        'Model': model,
        'Package version': packageVersion,
        'Platform': 'web',
        'Session Id': window?.__sfdcIds?.sessionId,
        'User Id': userId
    };

    const getEventNameWithAppType = (eventName: string) =>
        `${eventName} (${appType})`;

    const sendEvent = (
        eventName: string,
        eventData?: Record<string, unknown>
    ) => {
        mixpanel.track(getEventNameWithAppType(eventName), {
            ...defaultTrackData,
            ...eventData
        });
    };

    /*
    Tells Mixpanel that we want to start the time untill <eventName> event occurs.
    So when <eventName> event is triggered Mixpanel will send the duration in seconds as an event property with that event.
    */
    const startDurationTimerEvent = (eventName: string) =>
        mixpanel.time_event(getEventNameWithAppType(eventName));

    return { sendEvent, startDurationTimerEvent };
};

export default useAnalytics;
