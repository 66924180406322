import React, { FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import LogoCoPilotSmall from '../../SVG/LogoCoPilotSmall';
import { MoreIcon, ArrowIcon, TrashIcon } from 'aqui/icons';
import MinimizeIcon from '../../SVG/MinimizeIcon';
import MaximizeIcon from '../../SVG/MaximizeIcon';
import { Tooltip } from '@material-ui/core';
import PopoverBox from '../PopoverBox/PopoverBox';
import { SmallWindowState } from '../../types';
import { ANALYTICS_EVENTS } from '../../consts';
import useAnalytics from '../../hooks/useAnalytics';
import MinimizedIcon from '../../SVG/MinimizedIcon';
import CustomFooterLogo from '../../SVG/CustomFooterLogo';

type SmallChatHeaderProps = {
    onMinimizeClick: Function;
    onMaximizeClick: Function;
    onClearClick: Function;
    onClickBack: Function;
    chatWindowState: String;
    setIsMinimizedWithInput: Function;
    isMinimizedWithInput: boolean;
};
const SmallChatHeader: FunctionComponent<SmallChatHeaderProps> = ({
    onMinimizeClick,
    onMaximizeClick,
    onClearClick,
    onClickBack,
    chatWindowState,
    isMinimizedWithInput,
    setIsMinimizedWithInput
}) => {
    const { sendEvent } = useAnalytics();
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const [showResizeTooltip, setShowResizeTooltip] = useState<boolean>(false);
    const { isUsingCustomLogo } = useSelector(
        (state: any) => state.globalReducer
    );

    const { isCopilotMode } = useSelector((state: any) => state.coPilotReducer);

    const isMinimized =
        chatWindowState === SmallWindowState.Minimized ||
        chatWindowState === SmallWindowState.Initial;
    const isMaximized = chatWindowState === SmallWindowState.Maximized;

    const getRowsData = () => {
        return [
            {
                show: isCopilotMode,
                title: 'Back to portal',
                icon: <ArrowIcon direction="left" />,
                onClick: () => {
                    onClickBack();
                    sendEvent(ANALYTICS_EVENTS.CLICKED_MENU_ITEM, {
                        'User selection': 'Back to portal'
                    });
                    setAnchorEl(null);
                }
            },
            {
                show: true,
                title: 'Clear chat',
                icon: <TrashIcon />,
                onClick: () => {
                    onClearClick();
                    setAnchorEl(null);
                    sendEvent(ANALYTICS_EVENTS.CLICKED_MENU_ITEM, {
                        'User selection': 'Clear chat'
                    });
                }
            }
        ];
    };

    const handleMinimizeClick = () => {
        onMinimizeClick();
        sendEvent(ANALYTICS_EVENTS.MINIMIZE_ICON_SCOP_PANEL_CLICK);
    };

    const handleMaximizeClick = () => {
        onMaximizeClick();
        sendEvent(ANALYTICS_EVENTS.EXPAND_ICON_SCOP_MINIMIZED_CLICK);
    };

    const handleResizeClick = () => {
        setShowResizeTooltip(false);
        isMinimized ? handleMaximizeClick() : handleMinimizeClick();
    };

    const handleMoreIconClick = (event) => {
        setAnchorEl(event.currentTarget);
        sendEvent(ANALYTICS_EVENTS.CLICKED_THREE_DOT_MENU);
    };

    const resizeIconToShow = () => {
        return isMinimized ? <MaximizeIcon /> : <MinimizeIcon />;
    };

    const onHeaderClick = () => {
        sendEvent(ANALYTICS_EVENTS.CLICKED_KNOWLEGDE_BAR, {
            Action: isMinimizedWithInput ? 'open' : 'close'
        });
        setIsMinimizedWithInput(!isMinimizedWithInput);
    };

    return (
        <div
            className={`smallChatHeader__header flex align-center ${
                isMinimized ? 'minimized-header' : ''
            }`}
        >
            <div
                className={`smallChatHeader__header-logo flex align-center space-between full-width ${
                    isMinimized ? 'minimized-header' : ''
                }`}
                data-testid="small-chat-header"
                onClick={() => !isMaximized && onHeaderClick()}
            >
                {isUsingCustomLogo ? (
                    <CustomFooterLogo />
                ) : (
                    <LogoCoPilotSmall />
                )}

                {!isMaximized && (
                    <MinimizedIcon
                        className={`minimizedIcon ${
                            isMinimizedWithInput ? 'isMinimizedWithInput' : ''
                        }`}
                    />
                )}
            </div>
            <div className="smallChatHeader__header-icons flex align-center full-height">
                {isMaximized && (
                    <>
                        <div
                            className={`smallChatHeader-icon flex align-center${
                                Boolean(anchorEl) ? 'open' : ''
                            }`}
                            onClick={(event) => handleMoreIconClick(event)}
                            data-testid="small-chat-menu-button"
                        >
                            <MoreIcon />
                        </div>
                        <PopoverBox
                            anchorEl={anchorEl}
                            setAnchorEl={setAnchorEl}
                            rows={getRowsData()}
                        />
                    </>
                )}
                <Tooltip
                    placement="bottom"
                    classes={{
                        tooltip: `tooltip tooltip__smallChatHeader-icon ${
                            isMinimized ? 'minimized-header' : ''
                        }`
                    }}
                    title={isMaximized ? 'Collapse' : 'Expand'}
                    open={showResizeTooltip}
                >
                    <div
                        className="smallChatHeader-icon flex align-center full-height"
                        onClick={() => handleResizeClick()}
                        onMouseEnter={() => setShowResizeTooltip(true)}
                        onMouseLeave={() => setShowResizeTooltip(false)}
                        data-testid={`small-chat-${
                            isMaximized ? 'collapse' : 'expand'
                        }-button`}
                    >
                        {resizeIconToShow()}
                    </div>
                </Tooltip>
            </div>
        </div>
    );
};

export default SmallChatHeader;
