import { FunctionComponent, useEffect, useMemo, useRef } from 'react';
import { Page, PageProps } from 'react-pdf';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import {
    calcIntersectionObserverThreshold,
    getRotateDegree,
    getPageWidth
} from './helpers';
interface PDFPageProps extends PageProps {
    onInViewPortChange?: Function;
    scrollIntoView?: boolean;
    parentElement?: Element;
    onScrollIntoView?: Function;
    onRender?: Function;
    show?: boolean;
    contentToHighlight?: string;
    rotateSteps?: number;
    page?: any;
}

const PDFPage: FunctionComponent<PDFPageProps> = ({
    pageNumber,
    onInViewPortChange,
    scrollIntoView,
    parentElement,
    scale = 1,
    onScrollIntoView,
    onRender,
    show = true,
    contentToHighlight = '',
    rotateSteps = 0,
    page,
    ...rest
}) => {
    // Hide  the highlight feature for now

    // const contentToHighlightMap = useMemo(
    //     () => getSourceContentMap(contentToHighlight),
    //     [contentToHighlight]
    // );

    // const condensedSourceContent = useMemo(
    //     () => getCondensedString(contentToHighlight),
    //     [contentToHighlight]
    // );

    // const textRenderer = useCallback(
    //     (textItem) =>
    //         highlightPattern(
    //             textItem.str,
    //             contentToHighlightMap,
    //             condensedSourceContent,
    //             rotateDegree
    //         ),
    //     [contentToHighlightMap, condensedSourceContent, rotateDegree]
    // );

    const rotateDegree = useMemo(
        () => getRotateDegree(page?.rotate || 0, rotateSteps),
        [page, rotateSteps]
    );

    const pageWidth = useMemo(
        () => getPageWidth(page?.height, rotateSteps),
        [rotateSteps, page]
    );
    const ref = useRef<HTMLDivElement>();

    const threshold = useMemo(
        () => calcIntersectionObserverThreshold(scale),
        [scale]
    );

    const observerEntry = useIntersectionObserver(ref, {
        root: parentElement,
        threshold
    });

    const onRenderSuccess = (page: any) => {
        onRender?.(pageNumber);
    };

    useEffect(() => {
        if (observerEntry) {
            onInViewPortChange?.(observerEntry.isIntersecting, pageNumber);
        }
    }, [observerEntry?.isIntersecting, pageNumber]);

    useEffect(() => {
        if (scrollIntoView && ref.current) {
            ref.current.scrollIntoView();
            onScrollIntoView?.();
        }
    }, [scrollIntoView, pageNumber]);

    return (
        <div
            className={`pdf-page ${show ? '' : 'pdf-page--hide'}`}
            data-testid={'pdf-page_' + pageNumber}
        >
            <Page
                key={`page_${pageNumber}`}
                pageNumber={pageNumber}
                width={pageWidth}
                {...rest}
                inputRef={ref}
                rotate={rotateDegree}
                scale={scale}
                onRenderSuccess={onRenderSuccess}
                // customTextRenderer={textRenderer}
            />
        </div>
    );
};

export default PDFPage;
