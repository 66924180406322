import { FunctionComponent, useState } from 'react';
import Table from '../../Table/Table';
import ExpertResponsesHeader from './ExpertResponsesHeader';
import { getColumnsData } from './ExpertResponsesHelper';
import {
    useExpertResponsesRows,
    useFetchData
} from './useExpertResponsesHooks';
import { useExpertResponsesTable } from './useExpertResponsesTable';
import EditResponsesModal from './EditResponsesModal/EditResponsesModal';
import { EXPERT_RESPONSES_PINNED_COLUMNS } from './consts';
import { PaginationModel } from '../../../types';

const ExpertResponses: FunctionComponent = () => {
    const {
        areExpertResponsesFetching,
        expertResponsesRows,
        expertResponsesSortModel,
        setExpertResponsesSortModel
    } = useExpertResponsesRows();
    const { columnComponents } = useExpertResponsesTable();
    const [paginationModel, setPaginationModel] = useState<PaginationModel>({
        page: 0,
        pageSize: 15
    });
    const [rowsCount, setRowsCount] = useState<number>(0);

    useFetchData(paginationModel, expertResponsesSortModel, setRowsCount);

    return (
        <div className="data-management-table">
            <ExpertResponsesHeader />
            <Table
                rows={expertResponsesRows}
                columnComponents={columnComponents}
                columnsData={getColumnsData()}
                isLoading={areExpertResponsesFetching}
                dataTestId="expert-responses-table"
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                hideFooter={false}
                dataControlSide="server"
                sortModel={expertResponsesSortModel}
                onSortModelChange={setExpertResponsesSortModel}
                rowCount={rowsCount}
                pinnedColumns={EXPERT_RESPONSES_PINNED_COLUMNS}
            />
            <EditResponsesModal />
        </div>
    );
};

export default ExpertResponses;
