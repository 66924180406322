import ThreadsPanelFooterLogo from '../../SVG/ThreadsPanelFooterLogo';
import {
    useAskStream,
    useStartNewSession,
    useThreadsHandling
} from '../../AppContext';
const ThreadsPanelFooter = () => {
    const { startNewSession } = useStartNewSession();
    const { isStreamInProgress } = useAskStream();
    const { isChatHistoryLoaded } = useThreadsHandling();

    const onClickLogo = () => {
        if (isStreamInProgress || !isChatHistoryLoaded) {
            return;
        }
        startNewSession();
    };

    return (
        <div
            className={`flex align-center threadsPanel__footer ${
                isStreamInProgress || !isChatHistoryLoaded ? 'disabled' : ''
            }`}
            onClick={onClickLogo}
        >
            <ThreadsPanelFooterLogo/>
        </div>
    );
    
};

export default ThreadsPanelFooter;
