export const addIntegratedModelsToResponse = (
    expertResponses,
    integratedModels
) => {
    const productIds = expertResponses.map(
        (response) => response.triage_product_id
    );
    const productIdsSet = new Set(productIds);
    const integratedModelsFromResponse = {};
    for (let model of integratedModels) {
        if (model.productIds && productIdsSet.has(model.productIds.productId)) {
            integratedModelsFromResponse[model.productIds.productId] = model;
        }
    }
    const newResponse = expertResponses.map((response) => {
        const integratedModel = response.triage_product_id
            ? integratedModelsFromResponse[response.triage_product_id]
            : null;
        return {
            ...response,
            integratedModel
        };
    });
    return newResponse;
};

export const removeIndicatorsFromText = (textArray) => {
    return textArray.reduce((acc, segment) => {
        if (!segment.includes('SOURCE_') && !segment.includes('EXPERT_EDIT')) {
            return acc + segment;
        } else {
            return acc;
        }
    }, '');
};
