import { Fade } from '@material-ui/core';
import { Typography } from 'aqui';
import { UndoIcon } from 'aqui/icons';
import React, { useMemo } from 'react';
import {
    getMetadataText,
    getValidationTypeIcon
} from './UploadFilesModalHelpers';
import { t } from 'i18next';
import { FileValidation, SourceFile } from '../../types';
import ProgressBar from './ProgressBar';
import { useUploadFiles } from './UploadFilesModalHooks';

interface UploadFileFileRowStatusProps {
    onDelete: () => void;
    sourceFile: SourceFile;
}

const UploadFileFileRowStatus: React.FC<UploadFileFileRowStatusProps> = ({
    onDelete,
    sourceFile
}) => {
    const {
        validationType,
        isDeletedFailed,
        isUploadFiled,
        isUploaded,
        percentLoaded
    } = sourceFile;

    const { reuploadFile } = useUploadFiles();

    const { metadataText, ValidationTypeIcon } = useMemo(() => {
        const metadataText = getMetadataText(sourceFile);
        const ValidationTypeIcon = getValidationTypeIcon(validationType);
        return { metadataText, ValidationTypeIcon };
    }, [sourceFile]);

    const shouldShowProgressBar =
        validationType === FileValidation.Valid &&
        !isUploadFiled &&
        !isUploaded;
    const shouldShowMetadata =
        validationType && !shouldShowProgressBar && !isUploadFiled;

    return (
        <div className="upload-files__file__metadata">
            {shouldShowProgressBar && (
                <Fade in timeout={500}>
                    <ProgressBar progress={percentLoaded} />
                </Fade>
            )}
            {isUploadFiled && (
                <Fade in timeout={500}>
                    <Typography
                        type="small2"
                        className="upload-files__file__reupload-btn"
                        onClick={() => reuploadFile(sourceFile)}
                        dataTestId="upload-files-file-reupload-bnt"
                    >
                        <UndoIcon />
                        {t('upload-files-modal.upload-failed')}
                    </Typography>
                </Fade>
            )}
            {shouldShowMetadata && (
                <Fade in timeout={500}>
                    <>
                        <Typography
                            type="answersButton"
                            className={`upload-files__file__validation-type --${validationType}`}
                            dataTestId="upload-files-file-validation-type"
                        >
                            <ValidationTypeIcon />
                            {t(`upload-files-modal.file-${validationType}`)}
                        </Typography>
                        <Typography
                            type="small2"
                            dataTestId="upload-files-file-metadata"
                        >
                            {metadataText}
                        </Typography>

                        <Fade in={isDeletedFailed} timeout={500}>
                            <Typography
                                type="small2"
                                className="upload-files__file__reupload-btn"
                                dataTestId="upload-files-file-redelete-bnt"
                                onClick={onDelete}
                            >
                                <UndoIcon />
                                {t('upload-files-modal.delete-failed')}
                            </Typography>
                        </Fade>
                    </>
                </Fade>
            )}
        </div>
    );
};

export default UploadFileFileRowStatus;
