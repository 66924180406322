import { Tooltip } from '@mui/material';
import React, { useLayoutEffect, useMemo, useRef } from 'react';
import { PencilEditIcon } from 'aqui/icons';
import {
    useKAModal,
    useMessages,
    useModel,
    useScopSettings,
    useThreadsHandling
} from '../../../AppContext';
import PersonIconSmall from '../../../SVG/PersonIconSmall';
import MessageEditing from '../MessageEditing/MessageEditing';
import { useSelector } from 'react-redux';
import { prepareDate, updateHistoryMessage } from '../../../utils/utils';
import Backend from '../../../backend/Backend';
import {
    useHistoryMessages,
    useSmallChatInPortal
} from '../../../context/ChatContext';
import { ANALYTICS_EVENTS } from '../../../consts';
import useAnalytics from '../../../hooks/useAnalytics';
import { getIsUserExpertOrAdmin } from '../../../selectors/userPermissionSetsSelectors';

const MessageChunk = ({
    index,
    segment,
    messageData,
    onRelatedSourceClick,
    clickedEditIndex = false,
    setClickedEditIndex,
    messageIndex,
    showEditBtn,
    setShowEditBtnIndex,
    getTooltipTitle,
    showEditOnNewParagraph = false,
    textArr,
    setExpertEdited,
    historyMessageIndex
}) => {
    const { messages, setMessages } = useMessages();
    const { model } = useModel();
    const { getIsSourceActive } = useKAModal();
    const { messagesPerSelectedChat, selectedThread } = useThreadsHandling();
    const { isSmallChatInPortal } = useSmallChatInPortal();
    const { sendEvent } = useAnalytics();
    const { historyMessages, setHistoryMessages } = useHistoryMessages();
    const { enableEditResponsesByExperts } = useScopSettings();

    const { isSmallChat } = useSelector((state: any) => state.coPilotReducer);

    const { fullUserName } = useSelector(
        (state: any) => state.userProfileReducer
    );
    const isUserExpertOrAdmin = useSelector(getIsUserExpertOrAdmin);
    const isEditing = clickedEditIndex === index + 1;
    const textareaRef = useRef<HTMLTextAreaElement>();

    useLayoutEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height =
                textareaRef.current.scrollHeight + 'px';
        }
    }, [textareaRef.current, isEditing]);

    useLayoutEffect(() => {
        if (textareaRef.current) {
            if (
                textareaRef.current.scrollHeight >
                textareaRef.current.clientHeight
            ) {
                textareaRef.current.style.height =
                    25 + textareaRef.current.scrollHeight + 'px';
            }
        }
    }, [textareaRef?.current?.scrollHeight]);

    const updateMessages = (newTextArr) => {
        const message = messages[messageIndex];
        const question = messages[messageIndex - 1].message;
        Backend.updateRespsonseByExpert(
            message.chatID,
            message.rowID,
            model,
            question,
            message.condensedQuestion,
            newTextArr.join(''),
            message.productDetails?.productIds?.productId,
            fullUserName.user_name
        );
        if (historyMessageIndex !== null) {
            updateHistoryMessage(
                historyMessages,
                setHistoryMessages,
                messageIndex,
                historyMessageIndex,
                newTextArr.join(''),
                fullUserName.user_name
            );
        } else {
            setMessages((prevMessages) => {
                return prevMessages.map((message, index) => {
                    if (index === messageIndex) {
                        return {
                            ...message,
                            message: newTextArr.join(''),
                            expertDetails: {
                                name: fullUserName.user_name,
                                date: prepareDate(new Date())
                            }
                        };
                    }
                    return message;
                });
            });
        }
    };

    const allowEdit = () => {
        return (
            enableEditResponsesByExperts &&
            isUserExpertOrAdmin &&
            !isSmallChatInPortal &&
            !isSmallChat &&
            clickedEditIndex === null &&
            (!selectedThread ||
                messagesPerSelectedChat[selectedThread] - 1 < messageIndex)
        );
    };

    const onClickEdit = () => {
        sendEvent(ANALYTICS_EVENTS.CLICKED_EDIT_RESPONSE, {
            'Editing type': 'partial',
            'Editing location': 'UI'
        });
        setClickedEditIndex(index);
    };

    const onClickCancel = () => {
        setClickedEditIndex(null);
    };

    const onClickSave = (newText) => {
        if (!newText) {
            const indexesToDelete = [index];
            for (let i = index + 1; i < textArr.length; i++) {
                if (
                    textArr[i].includes('SOURCE_') ||
                    textArr[i].includes('EXPERT_EDIT') ||
                    textArr[i] === '\n\n'
                ) {
                    indexesToDelete.push(i);
                } else {
                    break;
                }
            }
            const newTextArr = textArr.filter(
                (_, i) => !indexesToDelete.includes(i)
            );

            updateMessages(newTextArr);
        } else {
            if (!textArr[index + 1].includes('EXPERT_EDIT')) {
                newText += ' EXPERT_EDIT';
            }
            const newTextArr = textArr;
            newTextArr[index] = newText;
            if (textArr[index + 1].includes('SOURCE_')) {
                newTextArr[index + 1] = '';
            }
            updateMessages(newTextArr);
        }
        setClickedEditIndex(null);
        setExpertEdited(true);
    };

    const textSegment = useMemo(() => {
        if (isEditing) {
            return (
                <MessageEditing
                    text={segment}
                    onClickCancel={onClickCancel}
                    onClickSave={onClickSave}
                />
            );
        }

        return (
            <span
                key={index}
                onMouseEnter={() => {
                    setShowEditBtnIndex(index + 1);
                }}
                onMouseLeave={() => setShowEditBtnIndex(null)}
            >
                {segment}
            </span>
        );
    }, [segment, clickedEditIndex]);

    const showEditButton = () => {
        return (
            <PencilEditIcon
                className={`chatMessage__source-btns__edit ${
                    showEditBtn ? '--show' : ''
                }`}
                onClick={() => onClickEdit()}
            />
        );
    };

    if (clickedEditIndex === index) {
        return <></>;
    }

    if (segment.includes('SOURCE_')) {
        const sourceIndexes = segment.split(/SOURCE_/).filter(Boolean);
        return sourceIndexes.map((sourceIndex, i) => {
            const relatedSource =
                messageData?.relatedSourceDocuments?.[sourceIndex - 1];
            const isTooltipDisabled =
                !messageData?.relatedSourceDocuments?.length || !relatedSource;
            const isActive = getIsSourceActive(relatedSource);
            return (
                <div
                    className="chatMessage__source-btns"
                    onMouseEnter={() => setShowEditBtnIndex(index)}
                    onMouseLeave={() => setShowEditBtnIndex(null)}
                >
                    <Tooltip
                        title={getTooltipTitle(relatedSource)}
                        disableHoverListener={isTooltipDisabled}
                        disableTouchListener
                        disableFocusListener={isTooltipDisabled}
                        placement="top"
                        classes={{
                            tooltip: 'tooltip'
                        }}
                        key={i}
                    >
                        <span
                            className={`bold chatMessage__source-number ${
                                !relatedSource || !relatedSource.metadata.source ? '--disabled' : ''
                            } ${isActive ? '--active' : ''}`}
                            data-testid="answer-related-source-number"
                            onClick={() => onRelatedSourceClick(relatedSource)}
                        >
                            {sourceIndex}
                        </span>
                    </Tooltip>
                    {allowEdit() &&
                        i === sourceIndexes.length - 1 &&
                        showEditButton()}
                </div>
            );
        });
    } else if (segment.includes('EXPERT_EDIT')) {
        return (
            <div
                className="chatMessage__source-btns__edited-and-edit"
                onMouseEnter={() => setShowEditBtnIndex(index)}
                onMouseLeave={() => setShowEditBtnIndex(null)}
            >
                <Tooltip
                    title="This paragraph was edited by an expert."
                    placement="top"
                    classes={{
                        tooltip: 'tooltip tooltip__threadsPanel'
                    }}
                >
                    <div className="chatMessage__source-btns__edited">
                        <PersonIconSmall />
                    </div>
                </Tooltip>
                {allowEdit() && showEditButton()}
            </div>
        );
    } else if (showEditOnNewParagraph) {
        return (
            <>
                <div
                    className="chatMessage__source-btns__lone-edit"
                    onMouseEnter={() => setShowEditBtnIndex(index)}
                    onMouseLeave={() => setShowEditBtnIndex(null)}
                >
                    {allowEdit() && showEditButton()}
                </div>
                {segment}
            </>
        );
    } else {
        return textSegment;
    }
};

export default MessageChunk;
