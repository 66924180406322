import { Button, SearchInput } from 'aqui';
import { t } from 'i18next';
import { FunctionComponent, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import PersonasManagementFilters from './PersonasManagementFilters';
import {
    useAddPersonaModal,
    useMoreOptionsRowsPersonasManagement,
    usePersonaManagenentFilters,
    usePersonasManagementRows
} from './PersonaManagementHooks';
import MoreOptionsButton from '../../MainHeader/MoreOptionsButton';
import { MAX_PERSONAS_ALLOWED } from './consts';
import { useIsPersonasEnabled } from '../../../context/DataManagementContext';
import { Tooltip } from '@material-ui/core';
import { useServerlessIndex } from '../../../AppContext';

const PersonasManagementHeader: FunctionComponent = () => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const { setPersonaManagemantSearchTerm } = usePersonaManagenentFilters();
    const { setAddPersonaModalOpen } = useAddPersonaModal();
    const { rows, hasRowsToShow } = useMoreOptionsRowsPersonasManagement();
    const { arePersonasManagementDataFetching, personasManagementRows } =
        usePersonasManagementRows();
    const { isPersonasEnabled } = useIsPersonasEnabled();
    const { isServerlessIndex } = useServerlessIndex();

    useEffect(() => {
        debounce(() => setPersonaManagemantSearchTerm(searchTerm), 500)();
    }, [searchTerm]);

    const generateTooltip = () => {
        if (!isPersonasEnabled) {
            return t('add-persona.feature-disabled');
        }
        if (personasManagementRows.length >= MAX_PERSONAS_ALLOWED) {
            return t('add-persona.max-reached');
        }
        return '';
    };

    return (
        <div className="ingested-sources__header">
            <PersonasManagementFilters />
            <div className="ingested-sources__header__section">
                <Tooltip
                    title={generateTooltip()}
                    placement="top"
                    classes={{
                        tooltip: 'tooltip'
                    }}
                >
                    <Button
                        variant="filled"
                        text={t('add-persona.add-persona-btn')}
                        dataTestId="open-add-persona-modal-btn"
                        onClick={() => setAddPersonaModalOpen(true)}
                        disabled={
                            isServerlessIndex ||
                            !isPersonasEnabled ||
                            arePersonasManagementDataFetching ||
                            personasManagementRows.length >=
                                MAX_PERSONAS_ALLOWED
                        }
                    />
                </Tooltip>
                <MoreOptionsButton
                    rows={rows}
                    hasRowsToShow={hasRowsToShow}
                    disabled={arePersonasManagementDataFetching}
                />
                <SearchInput
                    className={'ingested-sources__search'}
                    placeholder="Search"
                    value={searchTerm}
                    setValue={setSearchTerm}
                    dataTestId="persona-management-search"
                    disabled={arePersonasManagementDataFetching}
                />
            </div>
        </div>
    );
};

export default PersonasManagementHeader;
