import { useEffect, useRef, useState } from 'react';
import { SMALL_SCREEN_WIDTH } from '../consts';

const useScreenSize = () => {
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(
        window.innerWidth <= SMALL_SCREEN_WIDTH
    );

    const isSmallScreenRef = useRef(isSmallScreen);
    const setIsSmallScreenRef = (isSmallScreen: boolean) => {
        isSmallScreenRef.current = isSmallScreen;
        setIsSmallScreen(isSmallScreen);
    };

    useEffect(() => {
        window.addEventListener('resize', handleScreenResize);
        return () => window.removeEventListener('resize', handleScreenResize);
    }, []);

    const handleScreenResize = () => {
        if (
            window.innerWidth <= SMALL_SCREEN_WIDTH &&
            !isSmallScreenRef.current
        ) {
            setIsSmallScreenRef(true);
        } else if (
            window.innerWidth > SMALL_SCREEN_WIDTH &&
            isSmallScreenRef.current
        ) {
            setIsSmallScreenRef(false);
        }
    };
    return { isSmallScreen };
};

export default useScreenSize;
