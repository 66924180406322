import React from 'react';
const PersonIconSmall = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10 6C10.5304 6 11.0391 6.21071 11.4142 6.58579C11.7893 6.96086 12 7.46957 12 8C12 8.53043 11.7893 9.03914 11.4142 9.41421C11.0391 9.78929 10.5304 10 10 10C9.46957 10 8.96086 9.78929 8.58579 9.41421C8.21071 9.03914 8 8.53043 8 8C8 7.46957 8.21071 6.96086 8.58579 6.58579C8.96086 6.21071 9.46957 6 10 6ZM10 14C10 14 14 14 14 13C14 11.8 12.05 10.5 10 10.5C7.95 10.5 6 11.8 6 13C6 14 10 14 10 14Z"
                fill="#050F29"
            />
        </svg>
    );
};

export default PersonIconSmall;
