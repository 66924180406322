import { forwardRef } from 'react';

import { SvgIcon } from 'aqui/icons';

const RefreshIconStandardSize = forwardRef(({ ...rest }, ref) => (
    <SvgIcon ref={ref} {...rest}>
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M12 8.18182V10.0909L14.5 7.54545L12 5V6.90909C9.2375 6.90909 7 9.18727 7 12C7 12.9991 7.2875 13.9282 7.775 14.7109L8.6875 13.7818C8.40625 13.2536 8.25 12.6427 8.25 12C8.25 9.89364 9.93125 8.18182 12 8.18182ZM16.225 9.28909L15.3125 10.2182C15.5875 10.7527 15.75 11.3573 15.75 12C15.75 14.1064 14.0687 15.8182 12 15.8182V13.9091L9.5 16.4545L12 19V17.0909C14.7625 17.0909 17 14.8127 17 12C17 11.0009 16.7125 10.0718 16.225 9.28909Z" />
        </svg>
    </SvgIcon>
));

export default RefreshIconStandardSize;
