import { FunctionComponent, useEffect, useState } from 'react';
import {
    useExpertResponsesFilters,
    useExpertResponsesRows
} from './useExpertResponsesHooks';
import FiltersMultiSelect from '../filters/FiltersMultiSelect';
import { FiltersRow } from 'aqui';
import { t } from 'i18next';
import Backend from '../../../backend/Backend';

const ExpertResponsesFilters: FunctionComponent = () => {
    const { areExpertResponsesFetching } = useExpertResponsesRows();
    const { expertResponsesFilters, setModelsToFilter } =
        useExpertResponsesFilters();
    const [expertResponsesModels, setExpertResponsesModels] = useState<
        string[]
    >([]);

    useEffect(() => {
        Backend.fetchExpertResponsesModels()
            .then((models) => {
                setExpertResponsesModels(models);
            })
            .catch((ex: any) => {
                console.error('Failed to fetch data.', ex);
                window.sentry.log(ex);
            });
    }, []);

    return (
        <FiltersRow withFilterIcon={false}>
            <FiltersMultiSelect
                filters={expertResponsesModels}
                selectedFilters={expertResponsesFilters.models}
                onApplyClick={setModelsToFilter}
                allFilterLabel={`${t('model.models')}: ${t('common.all')}`}
                dataTestId="expert_responses-models-filter"
                disabled={areExpertResponsesFetching}
            />
        </FiltersRow>
    );
};

export default ExpertResponsesFilters;
