import React, { useRef, useEffect } from 'react';

interface VideoPlayerProps {
    videoUrl: string;
    currentTime: number;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoUrl, currentTime }) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        const video = videoRef.current;

        if (video) {
            // Seek to the specified current time (in seconds)
            video.currentTime = currentTime;

            // Play the video
            video.play();
        }
    }, [currentTime]);

    const formattedVideoUrl = videoUrl.replace(/%20/g, '+');

    return (
        <video
            ref={videoRef}
            controls
            style={{ width: '100%', height: '100%' }}
        >
            <source src={formattedVideoUrl} type="video/mp4" />
        </video>
    );
};

export default VideoPlayer;
