export const SUGGESTED_OBSERVATIONS_FOUND = 'SUGGESTED_OBSERVATIONS_FOUND';
export const SUGGESTED_SOLUTIONS_FOUND = 'SUGGESTED_SOLUTIONS_FOUND';

export const RESET_CHAT_ID = 'RESET_CHAT_ID';
export const resetChatId = (value) => ({
    type: RESET_CHAT_ID,
    payload: value
});

export const SET_CHAT_WINDOW_STATE = 'SET_CHAT_WINDOW_STATE';
export const setChatWindowState = (windowState) => ({
    type: SET_CHAT_WINDOW_STATE,
    payload: windowState
});

export const SET_IS_SMALL_CHAT = 'SET_IS_SMALL_CHAT';
export const setIsSmallChat = (isSmallChat) => ({
    type: SET_IS_SMALL_CHAT,
    payload: isSmallChat
});

export const SET_INVESTIGATION_DETAILS = 'SET_INVESTIGATION_DETAILS';
export const setInvestigationDetails = (investigationDetails) => ({
    type: SET_INVESTIGATION_DETAILS,
    payload: investigationDetails
});

export const suggestionsFound = (suggestions, type) => ({
    type,
    payload: suggestions
});

export const CLEAR_CO_PILOT_SUGGESTIONS = 'CLEAR_CO_PILOT_SUGGESTIONS';
export const resetSuggestionsFound = () => ({
    type: CLEAR_CO_PILOT_SUGGESTIONS
});

export const RESET_COPILOT = 'RESET_COPILOT';
export const resetCoPilot = () => ({
    type: RESET_COPILOT
});

export const SET_CHAT_ID = 'SET_CHAT_ID';
export const setTriageChatId = (chatId, model = null) => ({
    type: SET_CHAT_ID,
    payload: { chatId, model }
});

export const SET_RESET_COPILOT = 'SET_RESET_COPILOT';
export const setResetCopilot = (resetCopilot) => ({
    type: SET_RESET_COPILOT,
    payload: resetCopilot
});

export const RERENDER_TRIAGE = 'RERENDER_TRIAGE';
export const rerenderTriage = () => ({
    type: RERENDER_TRIAGE
});

export const SET_OBSERVATION_TO_INVESTIGATE = 'SET_OBSERVATION_TO_INVESTIGATE';
export const setObservationToInvestigate = (observationObject) => ({
    type: SET_OBSERVATION_TO_INVESTIGATE,
    payload: observationObject
});

export const UPDATE_ASKING_QUERY = 'UPDATE_ASKING_QUERY';
export const updateAskingQuery = (askingQuery) => ({
    type: UPDATE_ASKING_QUERY,
    payload: askingQuery
});

export const SAVE_INVESTIGATION_ID = 'SAVE_INVESTIGATION_ID';
export const saveInvestigationId = (investigationId) => ({
    type: SAVE_INVESTIGATION_ID,
    payload: investigationId
});

export const SET_SCOP_MESSAGES = 'SET_SCOP_MESSAGES';
export const setScopMessages = (messages) => ({
    type: SET_SCOP_MESSAGES,
    payload: messages
});

export const SET_SCOP_FEEDBACKS_MAP = 'SET_SCOP_FEEDBACKS_MAP';
export const setScopFeedbacksMap = (feedbacks) => ({
    type: SET_SCOP_FEEDBACKS_MAP,
    payload: feedbacks
});