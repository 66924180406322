import { Typography } from 'aqui';
import { prepareDate } from '../../../utils/utils';

const DateCell = ({ field, row }) => {
    const dateText = row[field] ? prepareDate(row[field]) || '' : '';
    return (
        <Typography
            type="subtitle3"
            className="flex align-center"
            dataTestId={`${field}-cell`}
        >
            {dateText}
        </Typography>
    );
};

export default DateCell;
