import React from 'react';

interface ProgressBarProps {
    progress: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress = 0 }) => {
    return (
        <div className="scop-progress-bar__container">
            <div
                className="scop-progress-bar__progress"
                style={{ width: `${progress}%` }}
            ></div>
        </div>
    );
};

export default ProgressBar;
