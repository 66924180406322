import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';

import './i18n';

const Loader = () => <div style={{ display: 'none' }}></div>;
ReactDOM.render(
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
        <Provider store={window.__triageStore}>
            <React.StrictMode>
                <Suspense fallback={<Loader />}>
                    <App />
                </Suspense>
            </React.StrictMode>
        </Provider>
    </Sentry.ErrorBoundary>,
    document.getElementById('copilot-root')
);
reportWebVitals();
