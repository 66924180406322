import { FC } from 'react';

import { Typography, Toggle } from 'aqui';
import { editedResponseVersion, SourceDocument } from '../../../../types';
import { t } from 'i18next';
import { PencilEditIcon } from 'aqui/icons';
import PersonIconMedium from '../../../../SVG/PersonIconMedium';
import { prepareDate, splitTextByIndicators } from '../../../../utils/utils';
import MessageChunk from '../../../Chat/MessageChunk/MessageChunk';
import MessageEditing from '../../../Chat/MessageEditing/MessageEditing';
import SourceTooltipContent from '../../../Chat/SourceTooltipContent/SourceTooltipContent';
import { getAggregatedResultDetails } from '../../../KASection/KASectionHelpers';
import { useKAModal } from '../../../../AppContext';
import Backend from '../../../../backend/Backend';
import {
    useEditedResponseVersions,
    useExpertResponsesRows
} from '../useExpertResponsesHooks';
import { useSelector } from 'react-redux';
import { removeIndicatorsFromText } from '../utils';
import { Tooltip } from '@material-ui/core';

interface EditResponseBoxProps {
    data: editedResponseVersion;
    editingIndex: number;
    setEditingIndex: (index: number) => void;
    index: number;
    editModalContainerRef: React.RefObject<HTMLDivElement>;
}
const EditResponseBox: FC<EditResponseBoxProps> = ({
    data,
    index,
    editingIndex,
    setEditingIndex,
    editModalContainerRef
}) => {
    const { handleShowSourceDocumentDetails } = useKAModal();
    const { activateEditedResponseVersionsRows, updateEditedResponseVersions } =
        useEditedResponseVersions();
    const {
        setActiveRows,
        expertResponsesEditModalData,
        updateExpertResponsesRows
    } = useExpertResponsesRows();

    const { fullUserName } = useSelector(
        (state: any) => state.userProfileReducer
    );

    const editMode = editingIndex === index;

    const getTooltipTitle = (relatedSource: SourceDocument) => {
        if (!relatedSource || !relatedSource.metadata.source) {
            return t('ingested-sources.source-not-available');
        }
        return <SourceTooltipContent relatedSource={relatedSource} />;
    };

    const onRelatedSourceClick = (selectedSource: SourceDocument) => {
        if (!selectedSource || !selectedSource?.metadata?.source) {
            return;
        }
        const { fileType } = getAggregatedResultDetails([selectedSource]);
        handleShowSourceDocumentDetails(
            data.answer_sources.indexOf(selectedSource),
            data.answer_sources,
            fileType
        );
    };

    const onClickCancelEdit = () => {
        setEditingIndex(null);
    };

    const onActivateResponse = (active: boolean) => {
        Backend.updateExpertResponse(
            data.id,
            '',
            '',
            active,
            expertResponsesEditModalData.vector_id,
            expertResponsesEditModalData.model
        );
        activateEditedResponseVersionsRows(index, active);
        setActiveRows(
            data.id,
            active,
            expertResponsesEditModalData.vector_id,
            expertResponsesEditModalData.model
        );
        editModalContainerRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const onClickSaveEdit = (newResponse: string) => {
        Backend.updateExpertResponse(
            data.id,
            newResponse,
            fullUserName,
            data.active ? null : true,
            expertResponsesEditModalData.vector_id,
            expertResponsesEditModalData.model
        );

        updateEditedResponseVersions(
            index,
            newResponse,
            fullUserName,
            !data.active
        );
        updateExpertResponsesRows(
            data.id,
            newResponse,
            fullUserName,
            !data.active,
            expertResponsesEditModalData.vector_id,
            expertResponsesEditModalData.model
        );
        setEditingIndex(null);
        editModalContainerRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const renderTextWithSources = (text) => {
        const textArrayWithGroupedSourceIndications =
            splitTextByIndicators(text);

        if (editMode) {
            return (
                <MessageEditing
                    text={removeIndicatorsFromText(
                        textArrayWithGroupedSourceIndications
                    )}
                    onClickCancel={onClickCancelEdit}
                    onClickSave={onClickSaveEdit}
                    allowEmptyText={false}
                    className="editResponsesModal__text-area"
                />
            );
        }

        return textArrayWithGroupedSourceIndications.map((segment, index) => {
            let showEditOnNewParagraph = false;
            const lastSegment =
                textArrayWithGroupedSourceIndications[index - 1];
            if (
                segment === '\n\n' &&
                !lastSegment?.includes('SOURCE_') &&
                !lastSegment?.includes('EXPERT_EDIT')
            ) {
                showEditOnNewParagraph = true;
            }
            const messageData = {
                relatedSourceDocuments: data.answer_sources
            };
            return (
                <MessageChunk
                    messageIndex={0}
                    key={index}
                    index={index}
                    segment={segment}
                    messageData={messageData}
                    onRelatedSourceClick={onRelatedSourceClick}
                    setShowEditBtnIndex={() => {}}
                    showEditBtn={false}
                    getTooltipTitle={getTooltipTitle}
                    showEditOnNewParagraph={showEditOnNewParagraph}
                    textArr={textArrayWithGroupedSourceIndications}
                />
            );
        });
    };
    return (
        <div
            key={data.id}
            className="flex flex-col editResponsesModal__response"
        >
            <Typography type="subtitle3">
                {data.active
                    ? t('expert-responses-dm.displayed-expert-responses')
                    : t('expert-responses-dm.previous-expert-responses')}
            </Typography>
            <div
                className={`flex flex-col editResponsesModal__response__box ${
                    editMode && 'edit-mode'
                }`}
            >
                {!editMode && (
                    <div className="flex space-between editResponsesModal__response__header">
                        <div className="flex align-center editResponsesModal__response__header__expert">
                            <PersonIconMedium />
                            <Typography type="small1">{`Edited by expert ${data.user_name}`}</Typography>
                        </div>
                        <Typography
                            className="editResponsesModal__response__header__date"
                            type="small1"
                        >
                            {prepareDate(data.date)}
                        </Typography>
                    </div>
                )}
                <div className="editResponsesModal__response__body">
                    {renderTextWithSources(data.expert_response)}
                </div>
                {!editMode && (
                    <div className="flex space-between editResponsesModal__response__footer">
                        <Tooltip
                            title={t(
                                'expert-responses-dm.activate-response-modal'
                            )}
                            placement="top"
                            classes={{
                                tooltip: 'tooltip'
                            }}
                        >
                            <div
                                className="flex align-center editResponsesModal__response__footer__toggle"
                                onClick={() => onActivateResponse(!data.active)}
                            >
                                <Toggle checked={data.active} />
                                <Typography type="small1">
                                    {data.active
                                        ? t(
                                              'expert-responses-dm.displayed-response'
                                          )
                                        : t(
                                              'expert-responses-dm.displayed-response-off'
                                          )}
                                </Typography>
                            </div>
                        </Tooltip>
                        {editingIndex === null && (
                            <div
                                className="flex align-center editResponsesModal__response__footer__edit"
                                onClick={() => setEditingIndex(index)}
                            >
                                <PencilEditIcon />
                                <Typography type="small1">
                                    {t('expert-responses-dm.edit-response')}
                                </Typography>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default EditResponseBox;
