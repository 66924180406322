import SidePanel from '../../../SidePanel/SidePanel';
import {
    useAddUsersModal,
    useNotValidEmails,
    usePersonasManagementRows
} from '../PersonaManagementHooks';
import { t } from 'i18next';
import AddUsersModalFooter from './AddUsersModalFooter';
import AddUsersModalBody from './AddUsersModalBody';
import { useEffect, useState } from 'react';
import { returnValdinAndNotValidMails } from '../../../../utils/utils';
import Backend from '../../../../backend/Backend';
import { useTenant } from '../../../../AppContext';
import EclipseLoader from '../../../../SVG/EclipseLoader';

const AddUsersModal = () => {
    const { addUsersModalOpen, setAddUsersModalOpen } = useAddUsersModal();
    const { setNotValidEmails } = useNotValidEmails();
    const { updatePersona } = usePersonasManagementRows();
    const { tenantId } = useTenant();

    const [users, setUsers] = useState<string>('');
    const [personaHadUsers, setPersonaHadUsers] = useState<boolean>(false);
    const [selectedPersona, setSelectedPersona] = useState<string>(
        addUsersModalOpen?.name
    );
    const [personaSearchText, setPersonaSearchText] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (addUsersModalOpen?.name) {
            setSelectedPersona(addUsersModalOpen?.name);
            setPersonaSearchText(addUsersModalOpen?.name);
            Backend.getUsersByPersona(tenantId, [addUsersModalOpen?.id]).then(
                (response) => {
                    const personaIdToUsers = response.data.persona_id_to_users;
                    const rearrengedUsers = personaIdToUsers[
                        addUsersModalOpen.id
                    ]
                        ?.sort()
                        .join('\n');
                    setUsers(rearrengedUsers || '');
                    setIsLoading(false);
                    setPersonaHadUsers(Boolean(rearrengedUsers));
                }
            );
            return;
        }
        return () => resetState();
    }, [addUsersModalOpen?.name]);

    const handleSave = () => {
        const { notValidEmails, validEmails } = returnValdinAndNotValidMails(
            users.split('\n').filter((email) => Boolean(email))
        );
        if (notValidEmails.length) {
            setNotValidEmails({ [selectedPersona]: notValidEmails });
        }
        setAddUsersModalOpen(null);
        updatePersona(
            [
                {
                    id: addUsersModalOpen?.id,
                    users: validEmails
                }
            ],
            true
        );
        resetState();
    };

    const handleCancel = () => {
        resetState();
        setAddUsersModalOpen(null);
    };

    const handleDisableSave = () => {
        return (!users && !personaHadUsers) || !selectedPersona || isLoading;
    };

    const resetState = () => {
        setUsers('');
        setSelectedPersona('');
        setPersonaSearchText('');
        setIsLoading(true);
        setPersonaHadUsers(false);
    };

    return (
        <SidePanel
            open={Boolean(addUsersModalOpen?.name)}
            onClose={handleCancel}
            classes={{
                container: 'side-panel__upload-container',
                body: 'side-panel__body side-panel__upload-body flex-col',
                header: 'ingestModal__side-panel__header',
                footer: 'side-panel__footer'
            }}
            title={t('add-persona.add-users-modal-title')}
            footer={
                <AddUsersModalFooter
                    disableSave={handleDisableSave()}
                    handleSave={handleSave}
                    handleCancel={handleCancel}
                />
            }
        >
            {isLoading ? (
                <div
                    className="upload-files__loader"
                    style={{
                        backgroundColor: `rgba(248, 249, 252, '0.1)`
                    }}
                >
                    <EclipseLoader color="#17b1e2" width="100" height="100" />
                </div>
            ) : (
                <AddUsersModalBody
                    users={users}
                    setUsers={setUsers}
                    selectedPersona={selectedPersona}
                    setSelectedPersona={setSelectedPersona}
                    personaSearchText={personaSearchText}
                    setPersonaSearchText={setPersonaSearchText}
                />
            )}
        </SidePanel>
    );
};

export default AddUsersModal;
