import { FunctionComponent, useEffect, useState } from 'react';
import { t } from 'i18next';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Typography,
    CheckBox
} from 'aqui';
import LikeIcon from '../../SVG/LikeIcon';
import { doesContainHebrew } from '../../utils/utils';
import { ANALYTICS_EVENTS, MAX_FREE_TEXT_FEEDBACK_LENGTH } from '../../consts';
import { useAppContext } from '../../AppContext';
import useAnalytics from '../../hooks/useAnalytics';
import { FeedbackValue } from '../../types';
import {
    getModalHeaderText,
    getTextAreaLabel
} from './messageFeedbackModalHelpers';

type MessageFeedbackModalProps = {
    isOpen: boolean;
    feedbackValue: FeedbackValue;
    onSubmit: Function;
    feedbackEventData: {
        [key: string]: string;
    };
};

const MessageFeedbackModal: FunctionComponent<MessageFeedbackModalProps> = ({
    isOpen = false,
    feedbackValue,
    onSubmit,
    feedbackEventData
}) => {
    const { feedbackCategories } = useAppContext();
    const { sendEvent } = useAnalytics();
    const [dontAsc, setDontAsk] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState(new Set());
    const [freeText, setFreeText] = useState('');

    const modalHeaderText = getModalHeaderText(feedbackValue);

    const textAreaLabel = getTextAreaLabel(
        feedbackValue,
        feedbackCategories?.question
    );

    useEffect(() => {
        setDontAsk(false);
        setSelectedCategories(new Set());
        setFreeText('');
    }, [isOpen]);

    const handleSubmit = () => {
        onSubmit(dontAsc, Array.from(selectedCategories), freeText);
    };

    const handleSkip = () => {
        onSubmit(dontAsc, Array.from(selectedCategories), freeText, true);
    };

    const onFreeTextChange = (value: string) => {
        const fixedValue =
            value.length > MAX_FREE_TEXT_FEEDBACK_LENGTH
                ? value.slice(0, MAX_FREE_TEXT_FEEDBACK_LENGTH)
                : value;
        setFreeText(fixedValue);
    };

    const toggleCategory = (category: string) => {
        const nextState = new Set(selectedCategories);
        if (nextState.has(category)) {
            nextState.delete(category);
            onFeedbackTagClick(false);
        } else {
            nextState.add(category);
            onFeedbackTagClick(true);
        }
        setSelectedCategories(nextState);
    };

    const onFeedbackCommentClick = () => {
        sendEvent(ANALYTICS_EVENTS.CLICKED_COMMENT_FIELD, feedbackEventData);
    };

    const onDontAskCheck = () => {
        sendEvent(ANALYTICS_EVENTS.CHECKED_DONT_ASK_AGAIN, feedbackEventData);
        setDontAsk(!dontAsc);
    };

    const onFeedbackTagClick = (selected: boolean) => {
        const eventData = {
            ...feedbackEventData,
            Action: selected ? 'select' : 'unselect'
        };
        sendEvent(ANALYTICS_EVENTS.CLICKED_FEEDBACK_TAG, eventData);
    };

    const handleCloseModal = () => {
        handleSkip();
        sendEvent(ANALYTICS_EVENTS.CLICKED_CLEAR_FEEDBACK, feedbackEventData);
    };

    const isNegativeFeedbackValue =
        feedbackValue === FeedbackValue.Dislike ||
        feedbackValue === FeedbackValue.Worse;

    return (
        <Modal
            isOpen={isOpen}
            closeModal={handleCloseModal}
            data-testid="message-feedback-modal"
            style={{ width: '600px' }}
        >
            <ModalHeader showCloseButton>
                <div className="flex align-center">
                    <LikeIcon
                        filled
                        dislike={isNegativeFeedbackValue}
                        color="#2468FF"
                        className="feedback-modal__icon"
                        data-testid={
                            isNegativeFeedbackValue
                                ? 'dislike-icon'
                                : 'like-icon'
                        }
                    />
                    <Typography type="h6" tag="span">
                        {modalHeaderText}
                    </Typography>
                </div>
            </ModalHeader>

            <ModalBody className="feedback-modal__body">
                {isNegativeFeedbackValue && (
                    <div className="feedback-modal__categories">
                        {feedbackCategories?.chips?.map((category) => (
                            <div
                                className={`feedback-modal__category-btn ${
                                    selectedCategories.has(category)
                                        ? '--selected'
                                        : ''
                                }`}
                                onClick={() => toggleCategory(category)}
                                data-testid="category-btn"
                                key={category}
                            >
                                <Typography type="small1">
                                    {category}
                                </Typography>
                            </div>
                        ))}
                    </div>
                )}

                <div className="feedback-modal__textarea-container">
                    <Typography type="body3">
                        {textAreaLabel}
                        <span className="feedback-modal--grey">
                            {' '}
                            ({t('common.optional')})
                        </span>
                    </Typography>
                    <div className="input-frame">
                        <textarea
                            value={freeText}
                            className={`input-frame__input
                                ${
                                    doesContainHebrew(freeText?.[0])
                                        ? '--rtl'
                                        : ''
                                }
                            `}
                            onChange={(e) => onFreeTextChange(e.target.value)}
                            onClick={onFeedbackCommentClick}
                            autoComplete="off"
                            data-testid=""
                            rows={4}
                        />
                    </div>
                    <Typography
                        type="body3"
                        className="feedback-modal--indication"
                    >
                        {freeText.length}/{MAX_FREE_TEXT_FEEDBACK_LENGTH}
                    </Typography>
                </div>
            </ModalBody>

            <ModalFooter>
                <div
                    className="feedback-modal__checkbox-container"
                    onClick={onDontAskCheck}
                >
                    <CheckBox
                        controlled
                        checked={dontAsc}
                        dataTestId="message-feedback-modal-dont-ask-checkbox"
                    />
                    <p className="feedback-modal__checkbox-label">
                        {t('feedback-modal.dont-ask')}
                    </p>
                </div>
                <Button
                    text={t('common.skip')}
                    onClick={handleSkip}
                    className="feedback-modal__skip-btn"
                    dataTestId="message-feedback-modal-skip-btn"
                />
                <Button
                    variant="filled"
                    text={t('common.submit')}
                    onClick={handleSubmit}
                    dataTestId="message-feedback-modal-submit-btn"
                />
            </ModalFooter>
        </Modal>
    );
};

export default MessageFeedbackModal;
