import { useSelector } from 'react-redux';
import CoPilotFooterLogo from './CoPilotFooterLogo';
import CustomFooterLogo from './CustomFooterLogo';

const ThreadsPanelFooterLogo = () => {
    const { isUsingCustomLogo } = useSelector((state) => state.globalReducer);
    return (
        <div className="flex align-center threadsPanel__footer">
            <div className="footer-logo">
                {isUsingCustomLogo ? (
                    <CustomFooterLogo />
                ) : (
                    <CoPilotFooterLogo />
                )}
            </div>
        </div>
    );
};

export default ThreadsPanelFooterLogo;
