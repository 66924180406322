const NeutralFaceIcon = ({
    color = 'currentColor',
    filled = false,
    ...props
}) => {
    return (
        <div
            style={{
                width: '24px',
                height: '24px',
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center'
            }}
            {...props}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill={filled ? color : 'none'}
            >
                <g clipPath="url(#clip0_1368_478)">
                    <path
                        d="M8.00004 14.6667C11.6819 14.6667 14.6667 11.6819 14.6667 8C14.6667 4.3181 11.6819 1.33334 8.00004 1.33334C4.31814 1.33334 1.33337 4.3181 1.33337 8C1.33337 11.6819 4.31814 14.6667 8.00004 14.6667Z"
                        stroke={filled ? '#eceff6' : color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M5.33337 10H10.6667"
                        stroke={filled ? '#eceff6' : color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M6 6H6.00667"
                        stroke={filled ? '#eceff6' : color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M10 6H10.0067"
                        stroke={filled ? '#eceff6' : color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_1368_478">
                        <rect width="16" height="16" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export default NeutralFaceIcon;
