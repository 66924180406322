import { Tooltip } from '@material-ui/core';
import { Typography, Bold } from 'aqui';
import { CirclePlusIcon } from 'aqui/icons';
import { t } from 'i18next';
import React from 'react';

interface AddModelButtonProps {
    disabledReason: string;
    isDisabled: boolean;
    onClick: () => void;
    modelName: string;
}

const AddModelButton: React.FC<AddModelButtonProps> = ({
    disabledReason,
    isDisabled,
    onClick,
    modelName
}) => {
    const handleClick = () => {
        if (!isDisabled) {
            onClick();
        }
    };
    return (
        <Tooltip
            title={disabledReason}
            classes={{
                tooltip: 'tooltip tooltip__close-top'
            }}
            placement="top-start"
        >
            <Typography
                type="small1"
                className={`upload-files__model-select-add-model ${
                    isDisabled ? '--disabled' : ''
                }`}
                onClick={handleClick}
            >
                <CirclePlusIcon />
                {`${t('common.add')} "`} <Bold>{modelName}</Bold>
                {`" ${t('model.model').toLocaleLowerCase()}`}
            </Typography>
        </Tooltip>
    );
};

export default AddModelButton;
