import { FunctionComponent } from 'react';

import { adjustIntegratedModel } from "../../../utils/productUtils";
import ValueWithTooltip from "../../ValueWithTooltip/ValueWithTooltip";
import { IntegratedModel } from '../../../types';

type ModelListProps = {
    mainModelsList: IntegratedModel[];
    selectedModel: IntegratedModel;
    onModelSelect: (model: IntegratedModel) => void;
};

const ModelList: FunctionComponent<ModelListProps> = ({ mainModelsList, selectedModel, onModelSelect }) => (
    (
        <div className="NoModelMessage__models flex flex-col">
           {mainModelsList.map((model, modelIndex) => {
                const modelTitle = adjustIntegratedModel(model);
                const isModelSelected = selectedModel === model;

                return (
                    <ValueWithTooltip
                        index={modelIndex}
                        title={modelTitle}
                        className={`ModelList__model ${
                            selectedModel
                                ? !isModelSelected
                                    ? 'ModelList__model--disabled'
                                    : 'ModelList__model--chosen'
                                : ''
                        }`}
                        type="body2"
                        onClick={() => onModelSelect(model)}
                        maxLines={2}
                        key={modelTitle}
                    />
                );
            })}
        </div>
    )

)

export default ModelList