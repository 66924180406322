import { FunctionComponent, ReactNode } from 'react';
import MenuItem from '../Menu/MenuItem';
import { Typography, SearchInput } from 'aqui';
import Menu from '../Menu/Menu';
import classnames from 'classnames/bind';

import styles from './FilterSelect.module.scss';
import ValueWithTooltip from '../ValueWithTooltip/ValueWithTooltip';
const cx = classnames.bind(styles);

interface SelectMenuProps {
    values: string[];
    value: string;
    disabled?: boolean;
    headerText?: string;
    showFooterMessage?: boolean;
    footerMessageText?: string;
    withSearch?: boolean;
    searchPlaceholder?: string;
    searchText?: string;
    setSearchText?: Function;
    onRowClick: Function;
    className?: string;
    StaticBottomRow?: ReactNode;
    shouldShowStaticBottomRow?: boolean;
    noValuesMessage?: string;
}

const SelectMenu: FunctionComponent<SelectMenuProps> = (
    {
        values,
        value,
        disabled,
        headerText,
        showFooterMessage = false,
        footerMessageText = '',
        withSearch = false,
        searchPlaceholder = '',
        searchText = '',
        setSearchText = () => {},
        onRowClick,
        className = '',
        StaticBottomRow,
        shouldShowStaticBottomRow,
        noValuesMessage
    },
    ref
) => {
    return (
        <Menu className={`filter-select__menu ${className}`}>
            {withSearch && (
                <SearchInput
                    placeholder={searchPlaceholder}
                    value={searchText}
                    setValue={setSearchText}
                    className={cx('filter-select__search')}
                    autoFocus
                />
            )}
            {!withSearch && headerText && (
                <p className={cx('filter-select__select-header')}>
                    {headerText}
                </p>
            )}

            {!values?.length && noValuesMessage && (
                <Typography
                    className={cx('filter-select__no-values-message')}
                    type="button"
                >
                    {noValuesMessage}
                </Typography>
            )}

            <div className={cx('filter-select__select-values')}>
                {values.map((val) => (
                    <MenuItem
                        selected={val === value}
                        key={val}
                        onClick={() => onRowClick(val)}
                        dataTestId="filterSelectOption"
                        disabled={disabled}
                    >
                        <ValueWithTooltip
                            type={val === value ? 'subtitle3' : 'body3'}
                            title={val}
                            placement={'right'}
                            maxLines={2}
                        />
                    </MenuItem>
                ))}
            </div>
            {shouldShowStaticBottomRow && StaticBottomRow && (
                <div className={cx('filter-select__static-bottom-row')}>
                    {StaticBottomRow}
                </div>
            )}
            {showFooterMessage && footerMessageText && (
                <Typography
                    tag="div"
                    type="small1"
                    className={cx('filter-select__footer-message')}
                >
                    {footerMessageText}
                </Typography>
            )}
        </Menu>
    );
};

export default SelectMenu;
