import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useIngestedSourcesActions } from './IngestedSourcesHooks';

import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Typography,
    Bold
} from 'aqui';
import { IngestedSourcesRow } from '../../../types';

interface ConfirmDeletionModalProps {
    rowToConfirmDeletion: IngestedSourcesRow;
    closeModal: () => void;
}

const ConfirmDeletionModal: FC<ConfirmDeletionModalProps> = ({
    rowToConfirmDeletion,
    closeModal
}) => {
    const { t } = useTranslation();
    const { deleteSource } = useIngestedSourcesActions();

    const onDelete = () => {
        deleteSource(rowToConfirmDeletion);
        closeModal();
    };

    return (
        <Modal isOpen={!!rowToConfirmDeletion} closeModal={closeModal}>
            <ModalHeader>
                {t('ingested-sources.confirm-delete-header')}
            </ModalHeader>
            <ModalBody>
                <Typography type="body1" tag="div">
                    {t('ingested-sources.confirm-delete-body1')}{' '}
                    <Bold>{t('ingested-sources.confirm-delete-body2')}</Bold>{' '}
                    <p>{t('ingested-sources.confirm-delete-body3')}</p>
                </Typography>
            </ModalBody>

            <ModalFooter>
                <Button
                    dataTestId="confirm-deletion-cancel-button"
                    color="blue"
                    onClick={closeModal}
                    text={t('common.cancel')}
                    variant="outlined"
                />

                <Button
                    dataTestId="confirm-deletion-delete-button"
                    color="red"
                    onClick={onDelete}
                    text={t('common.delete')}
                    variant="filled"
                    style={{ marginLeft: '11px' }}
                />
            </ModalFooter>
        </Modal>
    );
};

export default ConfirmDeletionModal;
