const SmallAquantLogo = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.93067 10.8334C9.83835 10.8041 9.75228 10.7579 9.67683 10.6972L9.67429 10.6951C9.47461 10.531 9.08539 9.50333 8.97158 9.23944C8.89515 9.06266 8.8173 8.88659 8.73805 8.71122L8.72493 8.68204C8.50451 8.17922 8.25606 7.68914 7.98076 7.21412V7.21158C7.75188 6.83688 7.54289 6.58906 7.38847 6.58906C7.09233 6.58906 6.65445 7.2839 6.15355 8.23164L6.14001 8.25702V8.25955C5.60695 9.27412 5.10773 10.5344 5.16019 10.7412C5.16019 10.7497 5.1678 10.7606 5.15427 10.7695H5.14792H5.14453L5.1145 10.778C4.97852 10.8227 4.83854 10.8541 4.69651 10.8719C4.47888 10.8848 4.26059 10.8625 4.05007 10.8059C3.53773 10.627 3.43028 10.21 3.53773 9.71352C3.71965 8.85248 5.54814 5.95342 6.14255 5.04205C6.42685 4.60476 6.80295 4 7.39228 4C7.90334 4 8.24518 4.48339 8.53032 4.85809C8.70336 5.08603 9.14588 5.79103 9.57106 6.49771L9.57487 6.50363C9.7771 6.84196 9.97551 7.17648 10.1392 7.46152C10.2239 7.61292 10.3085 7.76433 10.3897 7.92038C10.569 8.25802 10.7227 8.6086 10.8496 8.9692C10.8803 9.05547 10.9085 9.14231 10.9342 9.22971C11.0065 9.47115 11.0612 9.71751 11.0979 9.96684C11.191 10.6228 10.5014 10.9137 9.93067 10.8334Z"
                fill="url(#paint0_linear_398_1330)"
            />
            <path
                opacity="0.06"
                d="M10.6014 8.35215C10.4043 8.33904 9.60171 8.47479 8.81904 8.8884C8.78858 8.8196 8.75769 8.75067 8.72639 8.68159C8.50508 8.17909 8.25593 7.68931 7.9801 7.21452C7.96317 7.18703 7.94668 7.15996 7.93018 7.13459C8.39555 6.78949 8.86304 6.44017 9.37918 6.18262C9.44264 6.28708 9.5061 6.39407 9.57125 6.4998C9.77432 6.83813 9.97443 7.17646 10.139 7.46361C10.2236 7.61417 10.3082 7.76642 10.3899 7.92247C10.4643 8.06316 10.5349 8.20638 10.6014 8.35215Z"
                fill="black"
            />
            <path
                opacity="0.06"
                d="M9.93056 10.8331C9.83745 10.8034 9.75066 10.7567 9.67461 10.6953C9.57434 10.6128 9.42669 10.3147 9.29004 10.0034C9.9936 9.73865 10.5635 8.89367 10.7128 8.61328C10.761 8.73127 10.8067 8.84969 10.849 8.96979C10.9643 9.29348 11.0477 9.6276 11.0982 9.96744C11.1913 10.6225 10.5021 10.9135 9.93056 10.8331Z"
                fill="black"
            />
            <path
                d="M12.4595 8.606C12.3956 9.3351 12.0039 10.1775 11.5055 10.5319C11.0071 10.8863 10.6171 10.8961 10.2651 10.8885H10.2228C10.1239 10.8843 10.0262 10.8661 9.93253 10.8343C9.84016 10.8043 9.75411 10.7576 9.67869 10.6964H9.67658C10.0573 10.7633 10.6843 9.87769 10.9348 9.22979C10.9661 9.14986 10.9925 9.06808 11.0139 8.98493C11.0875 8.68889 11.2288 8.13446 10.8751 7.9488C10.7262 7.87056 10.5523 7.88156 10.3894 7.92046C10.3264 7.93569 10.2625 7.95514 10.2067 7.97502C9.69012 8.15264 9.20951 8.42669 8.7378 8.71046C8.49876 8.85425 8.26227 9.00015 8.02409 9.13718C7.45464 9.46536 6.88689 9.79776 6.31744 10.1268C6.25144 10.1657 6.18516 10.2042 6.1186 10.2422L5.97603 10.3239L5.82076 10.4127L5.77169 10.4406L5.71627 10.4723L5.63165 10.5188L5.50727 10.5882L5.44931 10.6199L5.44254 10.6237C5.34892 10.6786 5.25167 10.7271 5.15147 10.7688H5.14513H5.14174H5.13836C4.99917 10.7688 5.01398 10.5666 5.11171 10.2757C5.20943 9.98469 5.39474 9.59223 5.58765 9.22261C5.6105 9.18031 5.63377 9.13464 5.65704 9.09108C5.66507 9.0767 5.67269 9.0619 5.68073 9.04879C5.70104 9.01073 5.72303 8.97309 5.74207 8.93629C5.89099 8.66436 6.03356 8.42161 6.1351 8.25964C6.13933 8.25203 6.14398 8.24484 6.14864 8.23765C6.18121 8.21565 6.23325 8.18309 6.3056 8.14249C6.64659 7.94584 7.37933 7.55634 7.97416 7.21589C8.06935 7.16134 8.15904 7.11059 8.24196 7.06703C8.66593 6.83416 9.11261 6.64526 9.57504 6.50329C9.86879 6.41367 10.1716 6.35693 10.4779 6.33413C11.0401 6.29691 11.5918 6.46481 11.973 6.89237C12.3872 7.35207 12.5111 8.01731 12.4595 8.606Z"
                fill="url(#paint1_linear_398_1330)"
            />
            <path
                opacity="0.06"
                d="M6.31854 10.1271L5.97713 10.3241L5.82186 10.4129L5.77279 10.4409L5.71736 10.4726L5.63275 10.5191L5.50837 10.5884L5.45041 10.6202L5.44364 10.624C5.35002 10.6789 5.25277 10.7273 5.15257 10.769H5.14623H5.13988C5.00069 10.769 5.0155 10.5673 5.1128 10.2763C5.21011 9.98538 5.39584 9.59291 5.58875 9.22329C5.6116 9.181 5.63487 9.13533 5.65814 9.09177C5.66617 9.07739 5.67379 9.06259 5.68183 9.04947C5.70213 9.01141 5.72413 8.97377 5.74317 8.93698C5.89209 8.66505 6.03466 8.4223 6.1362 8.26032L6.13874 8.25609L6.15143 8.23622C6.18401 8.21423 6.23604 8.18166 6.30839 8.14106L6.35915 8.1123C6.27454 8.49758 5.88363 9.75658 6.31854 10.1271Z"
                fill="black"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_398_1330"
                    x1="0.0673308"
                    y1="10.775"
                    x2="9.79564"
                    y2="7.03962"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.27" stopColor="#1DCAFF" />
                    <stop offset="0.98" stopColor="#2468FF" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_398_1330"
                    x1="3.68132"
                    y1="8.24484"
                    x2="10.8599"
                    y2="8.76243"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2468FF" />
                    <stop offset="1" stopColor="#1DCAFF" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default SmallAquantLogo;
