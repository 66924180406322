// File
export const SMALL_PDF_SIZE_THRESHOLD = 5000; //kb

// Page
export const DEFAULT_PAGE_WIDTH = 717;

// Scale
export const MAX_SCALE = 5.0;
export const MIN_SCALE = 0.25;
export const SCALES = [
    0.25, 0.33, 0.5, 0.67, 0.75, 0.8, 0.9, 1.0, 1.1, 1.25, 1.5, 1.75, 2.0, 2.5,
    3.0, 4.0, 5.0
];

// Highlight
export const MIN_TEXT_LENGTH_TO_HIGHLIGHT = 10;

// Rotate
export const ROTATE_STEP_DEGREE = 90;
