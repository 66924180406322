import { FunctionComponent, useEffect, useState } from 'react';
import { FeedbackType, FeedbackValue, MessageInfo } from '../../../types';
import { useFeedback } from '../../../AppContext';
import MessageFeedbackModal from '../../MessageFeedbackModal/MessageFeedbackModal';
import useAnalytics from '../../../hooks/useAnalytics';
import { ANALYTICS_EVENTS } from '../../../consts';
import { Typography } from 'aqui';
import { t } from 'i18next';
import LikeIcon from '../../../SVG/LikeIcon';
import NeutralFaceIcon from '../../../SVG/NeutralFaceIcon';
import classnames from 'classnames';

type RewriteResponseFeedbackBarProps = {
    message: MessageInfo;
    historyMessage: MessageInfo;
    isSmallChat: boolean;
};

const BUTTONS = [FeedbackValue.Better, FeedbackValue.Worse, FeedbackValue.Same];

const RewriteResponseFeedbackBar: FunctionComponent<
    RewriteResponseFeedbackBarProps
> = ({ message, historyMessage, isSmallChat }) => {
    const [openFeedbackModal, setOpenFeedbackModal] = useState<boolean>(false);
    const [isSendingInProgress, setIsSendingInProgress] =
        useState<boolean>(false);
    const currentMessage = historyMessage || message;
    const { sendEvent } = useAnalytics();
    const key =
        (currentMessage.rowID || currentMessage.sentTime) +
        FeedbackType.Rewrite;

    const {
        feedbackData,
        setFeedbackValue,
        sendFeedback,
        dontAsk,
        setDontAsk
    } = useFeedback(key);
    const clickedFeedback = feedbackData?.type;

    const feedbackEventData = {
        Location: FeedbackType.Rewrite,
        'Feedback type': clickedFeedback
    };

    useEffect(() => {
        setOpenFeedbackModal(false);
        setIsSendingInProgress(false);
    }, [message, historyMessage]);

    const handleSendFeedback = async (
        clickedType: FeedbackValue,
        categories: Array<string> = null,
        feedbackText: string = null
    ) => {
        if (isSendingInProgress) {
            return;
        }
        setIsSendingInProgress(true);
        await sendFeedback(
            clickedType,
            categories,
            feedbackText,
            message,
            FeedbackType.Rewrite,
            historyMessage
        );
        setIsSendingInProgress(false);
    };

    const onClick = (clickedType: FeedbackValue) => {
        if (isSendingInProgress) {
            return;
        }
        if (clickedType === clickedFeedback) {
            setFeedbackValue(null);
            handleSendFeedback(null);
            return;
        }
        setFeedbackValue(clickedType);
        if (dontAsk || clickedType === FeedbackValue.Same) {
            handleSendFeedback(clickedType);
        } else {
            setOpenFeedbackModal(true);
        }
    };

    const sendFeedbackModalEvent = (
        isSkipped: boolean,
        categories: Array<string>,
        feedbackText: string
    ) => {
        const eventData = {
            ...feedbackEventData,
            Category: categories.join(', '),
            "Don't ask again": dontAsk ? 'checked' : 'unchecked',
            'Comment length': feedbackText.length
        };
        const event = isSkipped
            ? ANALYTICS_EVENTS.FEEDBACK_MODAL_SKIP_CLICK
            : ANALYTICS_EVENTS.FEEDBACK_MODAL_SUBMIT_CLICK;
        sendEvent(event, eventData);
    };

    const onFeedbackSubmit = (
        dontAsk,
        categories: Array<string>,
        feedbackText: string,
        isSkipped: false
    ) => {
        sendFeedbackModalEvent(isSkipped, categories, feedbackText);
        handleSendFeedback(clickedFeedback, categories, feedbackText);
        setOpenFeedbackModal(false);
        if (dontAsk) {
            setDontAsk(dontAsk);
        }
    };

    const getButtonIcon = (value: FeedbackValue) => {
        const props = {
            className: classnames('rewrite-feedback__icon', {
                '--active': clickedFeedback === value
            }),
            filled: clickedFeedback === value
        };
        switch (value) {
            case FeedbackValue.Better: {
                return <LikeIcon {...props} />;
            }
            case FeedbackValue.Worse: {
                return <LikeIcon {...props} dislike />;
            }
            case FeedbackValue.Same: {
                return <NeutralFaceIcon {...props} />;
            }
        }
    };

    return (
        <div
            className={classnames('rewrite-feedback', {
                '--small': isSmallChat
            })}
        >
            <Typography type="small1" className="rewrite-feedback__label">
                {t('feedback-modal.how-was-this-response')}
            </Typography>
            <div className="rewrite-feedback__buttons">
                {BUTTONS.map((feedbackValue) => (
                    <div
                        className={classnames('rewrite-feedback__button', {
                            '--small': isSmallChat,
                            '--progressing': isSendingInProgress,
                            '--active': feedbackValue === clickedFeedback
                        })}
                        onClick={() => onClick(feedbackValue)}
                        key={feedbackValue}
                    >
                        {getButtonIcon(feedbackValue)}
                        <Typography type={isSmallChat ? 'small2' : 'small1'}>
                            {feedbackValue}
                        </Typography>
                    </div>
                ))}
            </div>
            <MessageFeedbackModal
                isOpen={openFeedbackModal}
                feedbackValue={clickedFeedback}
                onSubmit={onFeedbackSubmit}
                feedbackEventData={feedbackEventData}
            />
        </div>
    );
};

export default RewriteResponseFeedbackBar;
