import { useDispatch, useSelector } from 'react-redux';
import {
    IntegratedModel,
    CaseInfo,
    GenerationType,
    AskStreamCustomInstructions,
    ResponseDepth,
    MessageInfo,
    MessageSenderType,
    SingleChatHistoryData
} from '../types';
import {
    useState,
    createContext,
    useContext,
    ReactNode,
    Dispatch,
    SetStateAction,
    FC,
    useEffect
} from 'react';
import {
    useAppContext,
    useMessages,
    useModel,
    useProductDetails,
    useSelectedMessage,
    useThreadsHandling,
    useUserData
} from '../AppContext';
import {
    useChatContext,
    useHistoryMessages,
    useMessageDisclaimers,
    useRegenerate
} from './ChatContext';
import Backend from '../backend/Backend';
import { consoleLog, getTodayDateAndTime } from '../utils/utils';
import useAnalytics from '../hooks/useAnalytics';
import { useTranslation } from 'react-i18next';
import { setTriageChatId } from '../actions/coPilotActions';
import { ANALYTICS_EVENTS } from '../consts';

export interface AskStreamContextValue {
    resetAskStreamContext: () => void;
    reader: ReadableStreamDefaultReader;
    setReader: Dispatch<SetStateAction<ReadableStreamDefaultReader>>;
    isStreamStopped: boolean;
    setIsStreamStopped: Dispatch<SetStateAction<boolean>>;
    isStreamInProgress: boolean;
    setIsStreamInProgress: Dispatch<SetStateAction<boolean>>;
    isAskRequestInProgress: boolean;
    setIsAskRequestInProgress: Dispatch<SetStateAction<boolean>>;
    abortFunction: AbortController;
    setAbortFunction: Dispatch<SetStateAction<AbortController>>;
    typingStarted: boolean;
    setTypingStarted: Dispatch<SetStateAction<boolean>>;
    isStreamError: boolean;
    setIsStreamError: Dispatch<SetStateAction<boolean>>;
    cancelStream: () => void;
    generationType: GenerationType,
    setGenerationType: Dispatch<SetStateAction<GenerationType>>;
}

const AskStreamContext = createContext<AskStreamContextValue | null>(null);

export const AskStreamContextProvider: FC<{ children: ReactNode }> = ({
    children
}) => {
    const [isAskRequestInProgress, setIsAskRequestInProgress] =
        useState<boolean>(false);
    const [isStreamInProgress, setIsStreamInProgress] =
        useState<boolean>(false);
    const [abortFunction, setAbortFunction] = useState<AbortController>(null);
    const [reader, setReader] = useState<ReadableStreamDefaultReader>();
    const [isStreamStopped, setIsStreamStopped] = useState<boolean>(false);
    const [typingStarted, setTypingStarted] = useState<boolean>(false);
    const [isStreamError, setIsStreamError] = useState<boolean>(false);
    const [generationType, setGenerationType] = useState<GenerationType>(
        GenerationType.SemanticOnly
    );

    const { resetCopilot } = useSelector((state: any) => state.coPilotReducer);

    const resetAskStreamContext = () => {
        cancelStream();
        setIsStreamError(false);
        setIsStreamStopped(false);
        setGenerationType(GenerationType.SemanticOnly);
    };

    const cancelStream = () => {
        reader?.cancel();
        abortFunction?.abort();
        setIsStreamInProgress(false);
        setIsAskRequestInProgress(false);
        setTypingStarted(false);
    };

    useEffect(() => {
        if (resetCopilot) {
            resetAskStreamContext();
        }
    }, [resetCopilot]);

    const value: AskStreamContextValue = {
        resetAskStreamContext,
        reader,
        setReader,
        isStreamStopped,
        setIsStreamStopped,
        isStreamInProgress,
        setIsStreamInProgress,
        isAskRequestInProgress,
        setIsAskRequestInProgress,
        abortFunction,
        setAbortFunction,
        typingStarted,
        setTypingStarted,
        isStreamError,
        setIsStreamError,
        cancelStream,
        generationType,
        setGenerationType
    };

    return (
        <AskStreamContext.Provider value={value}>
            {children}
        </AskStreamContext.Provider>
    );
};

export const useAskStreamContext = (): AskStreamContextValue => {
    const context = useContext(AskStreamContext);

    if (!context) {
        throw new Error('Did you forget to use the Provider?');
    }

    return context;
};

export const useAskStream = () => {
    const {
        setReader,
        setIsStreamStopped,
        setIsStreamInProgress,
        setIsAskRequestInProgress,
        setAbortFunction,
        setTypingStarted,
        setIsStreamError,
        cancelStream,
        isStreamInProgress,
        setGenerationType,
        generationType
    } = useAskStreamContext();
    const { responseDepth } = useUserData();
    const { chatId } = useSelector((state: any) => state.coPilotReducer);
    const { model } = useModel();
    const { productDetails } = useProductDetails();
    const { t } = useTranslation();
    const { sendEvent } = useAnalytics();
    const { scopSettings } = useAppContext();
    const { fetchMessageDisclaimers } = useMessageDisclaimers();
    const { setShowSuggestedQuestions } = useChatContext();
    const { addNewThread } = useThreadsHandling();
    const { historyMessages, setHistoryMessages } = useHistoryMessages();
    const { messages, setMessages } = useMessages();

    const dispatch = useDispatch();

    const { setSelectedMessage } = useSelectedMessage();
    const { setIsRegenerateAvailable } = useRegenerate();

    const { investigationId } = useSelector(
        (state: any) => state.deviceInspectionReducer
    );
    const { AssetID } = useSelector(
        (state: any) => state.integrationReducer.integrationTokens
    );
    const { workOrder } = useSelector((state: any) => state.workOrderReducer);

    const askAndStream = async (
        modelName: string,
        question: string,
        returnAllResults: boolean,
        regenerate: boolean,
        previousGenerationType: GenerationType | null,
        handleAnswer: Function,
        skipQuestionPp: boolean = false,
        selectedProductDetails: IntegratedModel,
        newResponseDepth: ResponseDepth = responseDepth
    ) => {
        const newAbortController = new AbortController();
        setAbortFunction(newAbortController);
        try {
            setIsStreamError(false);
            setIsStreamStopped(false);
            setIsStreamInProgress(true);
            setIsAskRequestInProgress(true);
            const caseInfo = {
                investigation_id: investigationId,
                work_order_id: workOrder,
                asset_id: AssetID
            } as CaseInfo;

            const customInstructions = {
                response_depth:
                    newResponseDepth === ResponseDepth.Concise
                        ? newResponseDepth
                        : undefined
            } as AskStreamCustomInstructions;

            const response = await Backend.askStream(
                modelName,
                question,
                returnAllResults,
                chatId,
                regenerate,
                previousGenerationType,
                skipQuestionPp,
                newAbortController.signal,
                selectedProductDetails?.productIds?.productId,
                caseInfo,
                customInstructions
            );
            const reader = response.body.getReader();
            setReader(reader);
            setIsAskRequestInProgress(false);
            if (response.status !== 200) {
                handleAnswer(' ');
                throw new Error(response.statusText);
            }

            while (true) {
                const { value, done } = await reader.read();
                if (done) {
                    break;
                }
                let str = new TextDecoder().decode(value);
                handleAnswer(str);
            }
        } catch (ex: any) {
            if (ex.name === 'AbortError') {
                consoleLog('Stream is aborted');
                return;
            }
            setIsRegenerateAvailable(true);
            setIsStreamInProgress(false);
            setIsAskRequestInProgress(false);
            setTypingStarted(false);
            setIsRegenerateAvailable(true);
            cancelStream();
            setIsStreamError(true);
            console.error('Failed to steam answer: ', ex.message);
            window.sentry.log(ex);
        }
    };

    const askStream = async (
        question: string,
        selectedModel = null,
        newMessages,
        regenerate = false,
        useOriginalValue: boolean = false,
        modelDisplayValue = selectedModel || '',
        askAnyway: boolean = false,
        selectedProductDetails: IntegratedModel = null,
        newResponseDepth?: ResponseDepth
    ) => {
        if (isStreamInProgress && !askAnyway) {
            return;
        }

        let productDetailsToUse = productDetails;
        if (!productDetailsToUse && selectedProductDetails) {
            productDetailsToUse = selectedProductDetails;
        }
        let latestMessage: MessageInfo = {
            chatID: '',
            rowID: '',
            message: '',
            questionId: '',
            sentTime: Date.now().toString(),
            direction: 'incoming',
            sender: MessageSenderType.Aquant,
            sourceDocuments: [],
            relatedSourceDocuments: [],
            condensedQuestion: '',
            canProvideFeedback: false,
            triageLink: null,
            triageSolutionCount: 0,
            siLink: null,
            promptInfo: {},
            model:
                productDetails?.modelDisplay ||
                productDetails?.modelName ||
                modelDisplayValue,
            productDetails: productDetailsToUse,
            expertDetails: null
        };
        if (regenerate) {
            latestMessage = newMessages.slice(-1)[0];
            latestMessage.message = '';
            latestMessage.rowID = '';
            latestMessage.sentTime = Date.now().toString();
            setMessages([...newMessages.slice(0, -1), latestMessage]);
        } else {
            newMessages.push(latestMessage);
            setMessages([...newMessages]);
        }
        const headerMarkerBegin = '___SCOP_RESPONSE_HEADER_BEGIN___';
        const headerMarkerEnd = '___SCOP_RESPONSE_HEADER_END___';
        const metadataMarkerBegin = '___SCOP_RESPONSE_METADATA_BEGIN___';
        const metadataMarkerEnd = '___SCOP_RESPONSE_METADATA_END___';
        const scopErrorResponseMarker = '___SCOP_RESPONSE_ERROR___';
        let __headersData__ = '';
        let __metadata__ = '';
        let metadataReady = false;

        function handleAnswer(answer: string) {
            if (answer.includes(scopErrorResponseMarker)) {
                setIsStreamInProgress(false);
                setIsAskRequestInProgress(false);
                setTypingStarted(false);
                setIsRegenerateAvailable(true);
                setIsStreamError(true);
                if (!latestMessage.message) {
                    latestMessage.message = ' ';
                    setMessages([...newMessages]);
                }
                return;
            }
            if (
                answer.startsWith(headerMarkerBegin) &&
                answer.includes(headerMarkerEnd)
            ) {
                __headersData__ = answer.substring(
                    headerMarkerBegin.length,
                    answer.indexOf(headerMarkerEnd)
                );
                let headersdataObj = JSON.parse(__headersData__);
                if (
                    headersdataObj['prompt_info'] &&
                    headersdataObj['prompt_info'].suggested_alternatives
                        ?.length > 0
                ) {
                    setShowSuggestedQuestions(true);
                    latestMessage.promptInfo = headersdataObj['prompt_info'];
                }
                answer = answer.substring(
                    answer.indexOf(headerMarkerEnd) + headerMarkerEnd.length,
                    answer.length
                );
                latestMessage.expertDetails = headersdataObj['expert_details'];
            }
            if (
                answer.startsWith(metadataMarkerBegin) &&
                answer.endsWith(metadataMarkerEnd)
            ) {
                __metadata__ = answer.substring(
                    metadataMarkerBegin.length,
                    answer.length - metadataMarkerEnd.length
                );
                metadataReady = true;
            } else if (
                answer.includes(metadataMarkerBegin) &&
                answer.endsWith(metadataMarkerEnd)
            ) {
                const partOfAnswer = answer.substring(
                    0,
                    answer.indexOf(metadataMarkerBegin)
                );
                setTypingStarted(true);
                latestMessage.message += partOfAnswer;
                setMessages([...newMessages]);
                __metadata__ = answer.substring(
                    partOfAnswer.length + metadataMarkerBegin.length,
                    answer.length - metadataMarkerEnd.length
                );
                metadataReady = true;
            } else if (answer.startsWith(metadataMarkerBegin)) {
                __metadata__ = answer.substring(metadataMarkerBegin.length);
            } else if (answer.includes(metadataMarkerBegin)) {
                const partOfAnswer = answer.substring(
                    0,
                    answer.indexOf(metadataMarkerBegin)
                );
                __metadata__ = answer.substring(
                    partOfAnswer.length + metadataMarkerBegin.length
                );
                setTypingStarted(true);
                latestMessage.message += partOfAnswer;
                setMessages([...newMessages]);
            } else if (answer.endsWith(metadataMarkerEnd)) {
                __metadata__ += answer.substring(
                    0,
                    answer.length - metadataMarkerEnd.length
                );
                metadataReady = true;
            } else if (__metadata__.length > 0) {
                __metadata__ += answer;
            }

            if (metadataReady) {
                let canProvideFeedback = false;
                let metadataObj = JSON.parse(__metadata__);
                let answer = metadataObj['answer'];
                let condensedQuestion = metadataObj['condensed_question'];
                let sourceDocuments = [];
                let relatedSourceDocuments = [];
                let sdocs = metadataObj['source_documents'];
                latestMessage.rowID = metadataObj['chat_history_line_id'];
                if (
                    (sdocs && sdocs.length > 0) ||
                    (metadataObj?.['answer_sources'] &&
                        metadataObj?.['answer_sources'].length > 0)
                ) {
                    sourceDocuments = (sdocs || []).map(
                        (sd: any, index: number) => {
                            return {
                                content: sd.page_content,
                                metadata: sd.metadata,
                                messageId: latestMessage.rowID,
                                id: latestMessage.rowID + index
                            };
                        }
                    );
                    relatedSourceDocuments = (
                        metadataObj?.['answer_sources'] || []
                    ).map((sd: any, index: number) => {
                        return {
                            content: sd.page_content,
                            metadata: sd.metadata,
                            messageId: latestMessage.rowID,
                            id: latestMessage.rowID + index
                        };
                    });
                }

                if (answer.startsWith("Hmm, I'm not sure")) {
                    answer = t('error.not-sure');
                } else if (answer === '__AQ_GENAI_API_ERROR__') {
                    answer = t('error.api-error');
                    sourceDocuments = [];
                    relatedSourceDocuments = [];
                } else {
                    canProvideFeedback = true;
                    if (!chatId) {
                        dispatch(
                            setTriageChatId(
                                metadataObj['chat_id'],
                                selectedModel
                            )
                        );
                        const newChat: SingleChatHistoryData = {
                            chat_id: metadataObj['chat_id'],
                            investigation_id: null,
                            user_email: null,
                            tenant_model: selectedModel || model,
                            question: question,
                            created_at: getTodayDateAndTime(),
                            user_external_id: null,
                            triage_product_id:
                                productDetails?.productIds?.productId
                        };
                        addNewThread(newChat);
                    }
                }

                latestMessage.questionId = condensedQuestion;
                latestMessage.sourceDocuments = sourceDocuments;
                latestMessage.relatedSourceDocuments = relatedSourceDocuments;
                latestMessage.condensedQuestion = condensedQuestion;
                latestMessage.triageLink = metadataObj['triage_link'];
                latestMessage.triageSolutionCount =
                    metadataObj['triage_solution_count'] || 0;
                latestMessage.canProvideFeedback = canProvideFeedback;
                latestMessage.siLink = metadataObj['si_link'];
                latestMessage.noResponse = metadataObj['no_response'];
                latestMessage.chatID = metadataObj['chat_id'];
                latestMessage.rowID = metadataObj['chat_history_line_id'];
                consoleLog('"askStream" response:', latestMessage);
                setIsRegenerateAvailable(true);
                const eventData = {
                    'Row ID': latestMessage.rowID,
                    'Chat History ID': latestMessage.chatID,
                    Question: question ?? '',
                    Answer: latestMessage.message,
                    'Condensed Question': latestMessage.condensedQuestion,
                    'No Response': latestMessage.noResponse
                };
                sendEvent(ANALYTICS_EVENTS.RESPONSE_DISPLAYED, eventData);
                setMessages([...newMessages]);
                setSelectedMessage(latestMessage);
                setIsStreamInProgress(false);
                setIsAskRequestInProgress(false);
                setGenerationType(metadataObj['generation_type']);
                if (
                    scopSettings.displayKeywordDisclaimers &&
                    !latestMessage.noResponse &&
                    latestMessage.message !== t('error.api-error') &&
                    latestMessage.message !== t('error.not-sure')
                ) {
                    fetchMessageDisclaimers(
                        question,
                        latestMessage.message,
                        latestMessage.rowID,
                        () => setTypingStarted(false)
                    );
                } else {
                    setTypingStarted(false);
                }
            } else if (__metadata__.length === 0) {
                if (!latestMessage.message) {
                    setSelectedMessage(null);
                }
                setTypingStarted(true);
                latestMessage.message += answer;
                setMessages([...newMessages]);
            }
        }

        askAndStream(
            selectedModel || model,
            question,
            true,
            regenerate,
            regenerate ? generationType : null,
            handleAnswer,
            useOriginalValue,
            selectedProductDetails,
            newResponseDepth
        );
    };

    const regenerateLastAnswer = () => {
        const latestMessage = { ...messages.slice(-1)[0] };
        const latestUserMessage = messages
            .filter((mess) => mess.sender === MessageSenderType.User)
            .slice(-1)[0];
        setHistoryMessages({
            ...historyMessages,
            [messages.length - 1]: historyMessages[messages.length - 1]
                ? [...historyMessages[messages.length - 1], latestMessage]
                : [latestMessage]
        });
        setTypingStarted(false);
        setIsAskRequestInProgress(true);
        setIsStreamInProgress(true);
        setIsStreamStopped(false);
        setIsRegenerateAvailable(false);
        askStream(latestUserMessage.message, null, messages, true);
    };

    const askAgain = (responseDepth?: ResponseDepth) => {
        const latestUserMessage = messages
            .filter((mess) => mess.sender === MessageSenderType.User)
            .slice(-1)[0];
        if (!latestUserMessage || !model || model === 'all') {
            return;
        }
        setTypingStarted(false);
        setIsAskRequestInProgress(true);
        setIsStreamInProgress(true);
        setIsStreamStopped(false);
        setIsRegenerateAvailable(false);
        askStream(
            latestUserMessage.message,
            null,
            messages.slice(0, -1),
            false,
            false,
            '',
            true,
            null,
            responseDepth
        );
    };

    return {
        askStream,
        regenerateLastAnswer,
        askAgain
    };
};
