import { t } from 'i18next';
import CheckboxCell from '../../Table/Cells/CheckboxCell';
import FileNameCell from '../../Table/Cells/FileNameCell';
import TextCell from '../../Table/Cells/TextCell';
import DateCell from '../../Table/Cells/DateCell';
import IngestStatusCell from '../../Table/Cells/IngestStatusCell';
import ActionsCell from '../../Table/Cells/ActionsCell';
import { useEffect, useState } from 'react';
import {
    getFilteredIngestedSourceRows,
    getIsDeleteButtonDisabled,
    ingestedSourceRowToSourceDocument
} from './IngestedSourcesHelpers';
import { IngestedSourcesRow, SourceDocument } from '../../../types';
import {
    useFilteredIngestedSourcesRows,
    useIngestedSourceRows,
    useIngestedSourcesActions,
    useIngestedSourcesFilters,
    useSelectedIngestedSourceRows
} from './IngestedSourcesHooks';
import { useKAModal, useServerlessIndex } from '../../../AppContext';
import {
    useDataManagementContext,
    useIsPersonasEnabled
} from '../../../context/DataManagementContext';
import MultiSelectCell from '../../Table/Cells/MultiSelectCell';
import { COLUMNS } from './consts';
import { usePersonasManagementRows } from '../PersonasManagement/PersonaManagementHooks';

export const useIngestedSourcesTable = () => {
    const {
        ingestedSourceRows,
        fetchIngestedSourceRowsAndPersonas,
        areIngestedSourcesFetching
    } = useIngestedSourceRows();

    const [rowToConfirmDeletion, setRowToConfirmDeletion] =
        useState<IngestedSourcesRow>(null);
    const {
        selectedIngestedSourceRows,
        setSelectedIngestedSourceRows,
        selectedIngestedSourceRowIdsSet
    } = useSelectedIngestedSourceRows();

    const { ingestedSourceSearchTerm, ingestedSourcesFilters } =
        useIngestedSourcesFilters();

    const { ingestSource } = useIngestedSourcesActions();
    const { setFilteredIngestSourcesRows } = useFilteredIngestedSourcesRows();
    const { handleShowSourceDocumentDetails } = useKAModal();
    const { ingestedSourcesSortModel, setIngestedSourcesSortModel } =
        useDataManagementContext();
    const { personasNames, personaNameToId, personaIdToName } =
        usePersonasManagementRows();
    const { isServerlessIndex } = useServerlessIndex();

    useEffect(() => {
        if (ingestedSourceRows.length === 0) {
            fetchIngestedSourceRowsAndPersonas();
        }
    }, []);

    useEffect(() => {
        setFilteredIngestSourcesRows(
            getFilteredIngestedSourceRows(
                ingestedSourceRows,
                ingestedSourcesFilters,
                ingestedSourceSearchTerm,
                personaNameToId,
                personaIdToName
            )
        );
    }, [ingestedSourceSearchTerm, ingestedSourcesFilters, ingestedSourceRows]);

    const onFileNameClick = (row: IngestedSourcesRow) => {
        const sourceDocument: SourceDocument =
            ingestedSourceRowToSourceDocument(row);
        handleShowSourceDocumentDetails(0, [sourceDocument], row.document_type);
    };

    const getActionsPopoverRows = (row) => {
        return [
            // Uncomment with download file implementation
            // {
            //     show: true,
            //     title: t('common.download'),
            //     onClick: () => {}
            // },
            {
                show: true,
                title: t('common.delete'),
                onClick: () => setRowToConfirmDeletion(row),
                isDisabled:
                    getIsDeleteButtonDisabled(row.status) || isServerlessIndex
            }
        ];
    };

    const columnComponents = {
        [COLUMNS.CHECKBOX]: (params) => (
            <CheckboxCell
                selectedRows={selectedIngestedSourceRows}
                setSelectedRows={setSelectedIngestedSourceRows}
                row={params.row}
            />
        ),
        [COLUMNS.FILE_NAME]: (params) => (
            <FileNameCell
                {...params}
                isSelected={selectedIngestedSourceRowIdsSet.has(
                    params?.row?.id
                )}
                onClick={() => onFileNameClick(params.row)}
            />
        ),
        [COLUMNS.MODEL_DISPLAY]: (params) => (
            <TextCell field={params.field} text={params.row.modelDisplay} />
        ),
        [COLUMNS.CREATED_AT]: (params) => <DateCell {...params} />,
        [COLUMNS.PERSONAS]: (params) => (
            <MultiSelectCell
                data={personasNames}
                existingData={params.row?.personas?.map(
                    (id: string) => personaIdToName[id]
                )}
                {...params}
            />
        ),
        [COLUMNS.CREATED_BY]: (params) => (
            <TextCell field={params.field} text={params.row.created_by} />
        ),
        [COLUMNS.INGEST_STATUS]: (params) => (
            <IngestStatusCell
                {...params}
                isSelected={selectedIngestedSourceRowIdsSet.has(
                    params?.row?.id
                )}
                onButtonClick={() => ingestSource(params?.row)}
            />
        ),
        [COLUMNS.ACTIONS]: (params) => (
            <ActionsCell rows={getActionsPopoverRows(params.row)} />
        )
    };

    return {
        columnComponents,
        rowToConfirmDeletion,
        setRowToConfirmDeletion,
        areIngestedSourcesFetching,
        ingestedSourcesSortModel,
        setIngestedSourcesSortModel
    };
};
