import { SearchInput } from 'aqui';
import { FunctionComponent, useEffect, useState } from 'react';
import MoreOptionsButton from '../../MainHeader/MoreOptionsButton';
import {
    useExpertResponsesFilters,
    useExpertResponsesRows,
    useMoreOptionsRowsExpertResponses
} from './useExpertResponsesHooks';
import ExpertResponsesFilters from './ExpertResponsesFilters';

const ExpertResponsesHeader: FunctionComponent = () => {
    const { setExpertResponsesSearchTerm } = useExpertResponsesFilters();
    const { areExpertResponsesFetching } = useExpertResponsesRows();
    const { rows, hasRowsToShow } = useMoreOptionsRowsExpertResponses();

    const [searchTerm, setSearchTerm] = useState<string>('');

    useEffect(() => {
        const searchTimeout = setTimeout(() => {
            setExpertResponsesSearchTerm(searchTerm);
        }, 500);
        return () => clearTimeout(searchTimeout);
    }, [searchTerm]);

    return (
        <div className="data-management-table__header">
            <ExpertResponsesFilters />
            <div className="data-management-table__header__section">
                <MoreOptionsButton
                    rows={rows}
                    hasRowsToShow={hasRowsToShow}
                    disabled={areExpertResponsesFetching}
                />
                <SearchInput
                    className={'data-management-table__search'}
                    placeholder="Search"
                    value={searchTerm}
                    setValue={setSearchTerm}
                    dataTestId="expert-responses-search"
                    disabled={areExpertResponsesFetching}
                />
            </div>
        </div>
    );
};

export default ExpertResponsesHeader;
