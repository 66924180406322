import React, { FunctionComponent } from 'react';
import CheckBoxSourceList from '../../../CheckBoxSourceList/CheckBoxSourceList';
import { Typography } from 'aqui';
import { t } from 'i18next';
import {
    IngestModalTypes,
    MultiSelectSearchValuesProps
} from '../../../../types';
import PersonasSelection from '../PersonasSelection/PersonasSelection';
import { useSelectedIngestedSourceRows } from '../IngestedSourcesHooks';

type IngestModalBodyProps = {
    selectedSources: string[];
    setSelectedSources: (selectedSources: string[]) => void;
    modalType: IngestModalTypes;
    selectedItems: MultiSelectSearchValuesProps[];
    setSelectedItems: (selectedItems: MultiSelectSearchValuesProps[]) => void;
};

const IngestModalBody: FunctionComponent<IngestModalBodyProps> = ({
    selectedSources,
    setSelectedSources,
    modalType,
    selectedItems,
    setSelectedItems
}) => {
    const { selectedIngestedSourceRows } = useSelectedIngestedSourceRows();

    return (
        <div className="full-height flex flex-col space-between">
            <CheckBoxSourceList
                selectedSources={selectedSources}
                setSelectedSources={setSelectedSources}
                data={selectedIngestedSourceRows}
            />
            {modalType === IngestModalTypes.addPersona && (
                <div
                    data-testid="add-persona-container"
                    className="flex flex-col ingestModalBody__add-persona"
                >
                    <Typography type="body3">
                        {t('add-persona.add-description')}
                    </Typography>
                    <PersonasSelection
                        selectedItems={selectedItems}
                        setSelectedItems={setSelectedItems}
                    />
                </div>
            )}
        </div>
    );
};

export default IngestModalBody;
