import { FunctionComponent } from 'react';
import Table from '../../Table/Table';
import IngestedSourcesHeader from './IngestedSourcesHeader';
import {
    useFetchIngestData,
    useFilteredIngestedSourcesRows,
    useIngestedSourcesDisabledRows,
    useSelectedIngestedSourceRows
} from './IngestedSourcesHooks';
import ConfirmDeletionModal from './ConfirmDeletionModal';
import { useIngestedSourcesTable } from './useIngestedSourcesTable';
import { getColumnsData } from './IngestedSourcesHelpers';
import { INGESTED_SOURCES_PINNED_COLUMNS } from './consts';
import TableFloatingMenu from '../../Table/TableFloatingMenu/TableFloatingMenu';
import AddPersonasMenuItem from './AddPersonasMenuItem';
import { useIngestModalTriggering } from './IngestModal/IngestModalHooks';

const IngestedSources: FunctionComponent = () => {
    const {
        selectedIngestedSourceRows,
        setSelectedIngestedSourceRows,
        selectAllEnabledRows
    } = useSelectedIngestedSourceRows();
    const { getIsRowDisabled } = useIngestedSourcesDisabledRows();
    const { setIsDeleteSourcesModalOpen } = useIngestModalTriggering();

    const {
        columnComponents,
        rowToConfirmDeletion,
        setRowToConfirmDeletion,
        areIngestedSourcesFetching,
        ingestedSourcesSortModel,
        setIngestedSourcesSortModel,
        isCheckboxColumnVisible
    } = useIngestedSourcesTable();
    const { filteredIngestSourcesRows } = useFilteredIngestedSourcesRows();

    useFetchIngestData();

    return (
        <div className="data-management-table">
            <IngestedSourcesHeader />
            <Table
                rows={filteredIngestSourcesRows}
                selectedRows={selectedIngestedSourceRows}
                setSelectedRows={selectAllEnabledRows}
                columnComponents={columnComponents}
                columnsData={getColumnsData(isCheckboxColumnVisible)}
                pinnedColumns={INGESTED_SOURCES_PINNED_COLUMNS}
                getIsRowDisabled={getIsRowDisabled}
                isLoading={areIngestedSourcesFetching}
                sortModel={ingestedSourcesSortModel}
                onSortModelChange={setIngestedSourcesSortModel}
                dataTestId="ingested-sources-table"
                disabledRows={filteredIngestSourcesRows.filter((row) =>
                    getIsRowDisabled(row)
                )}
            />
            <TableFloatingMenu
                viewRows={filteredIngestSourcesRows}
                selectedRows={selectedIngestedSourceRows}
                onDelete={() => setIsDeleteSourcesModalOpen(true)}
                setSelectedRows={setSelectedIngestedSourceRows}
                showDelete={false}
            >
                <AddPersonasMenuItem />
            </TableFloatingMenu>
            <ConfirmDeletionModal
                rowToConfirmDeletion={rowToConfirmDeletion}
                closeModal={() => setRowToConfirmDeletion(null)}
            />
        </div>
    );
};

export default IngestedSources;
