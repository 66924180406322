import { FunctionComponent } from 'react';
import Table from '../../Table/Table';
import { useIsPersonasEnabled } from '../../../context/DataManagementContext';
import PersonasManagementHeader from './PersonasManagementHeader';
import { usePersonasManagementTable } from './usePersonasManagementTable';
import { getColumnsData } from './PersonasManagementHelpers';
import { PERSONA_MANAGEMENT_PINNED_COLUMNS } from './consts';
import AddPersonaModal from './AddPersonaModal';
import EmailValidationModal from './EmailValidationModal/EmailValidationModal';
import CopyPersonaSourcesModal from './CopyPersonaSourcesModal/CopyPersonaSourcesModal';
import {
    useDisabledPersonaManagementRows,
    useFilteredPersonaManagementRows
} from './PersonaManagementHooks';

const PersonasManagement: FunctionComponent = () => {
    const { columnComponents, arePersonasManagementDataFetching } =
        usePersonasManagementTable();
    const { filteredPersonaManagementRows } =
        useFilteredPersonaManagementRows();
    const { getIsRowDisabled } = useDisabledPersonaManagementRows();
    const { isPersonasEnabled } = useIsPersonasEnabled();

    return (
        <div className="ingested-sources">
            <PersonasManagementHeader />
            <Table
                rows={filteredPersonaManagementRows}
                columnComponents={columnComponents}
                columnsData={getColumnsData()}
                pinnedColumns={PERSONA_MANAGEMENT_PINNED_COLUMNS}
                isLoading={arePersonasManagementDataFetching}
                dataTestId="personas-management-table"
                getIsRowDisabled={getIsRowDisabled}
                withPaddingsAlways={isPersonasEnabled}
            />
            <AddPersonaModal />
            <EmailValidationModal />
            <CopyPersonaSourcesModal />
        </div>
    );
};

export default PersonasManagement;
