import {
    DEFAULT_PAGE_WIDTH,
    MIN_TEXT_LENGTH_TO_HIGHLIGHT,
    ROTATE_STEP_DEGREE
} from './consts';

export const getIsNextPageDisabled = (
    pagesInViewport: number[],
    pageNum: number,
    numPages: number
): boolean =>
    pagesInViewport.length
        ? pagesInViewport.includes(numPages)
        : pageNum >= numPages;

export const getIsPrevPageDisabled = (pageNum: number) => pageNum <= 1;

export const calcIntersectionObserverThreshold = (scale: number) =>
    Math.max(0, 1 - scale / 2.5);

export const getPageErrorHandlers = (
    handler: Function,
    message: string | number = ''
) => ({
    onLoadError: (error: any) => handler(error, 'PAGE LOAD ' + message),
    onRenderError: (error: any) => handler(error, 'PAGE RENDER ' + message),
    onGetTextError: (error: any) => handler(error, 'GET TEXT ' + message),
    onGetStructTreeError: (error: any) =>
        handler(error, 'GET STRUCT TREE ' + message),
    onGetAnnotationsError: (error: any) =>
        handler(error, 'GET ANNOTATIONS ' + message)
});

export const getDocErrorHandlers = (handler: Function) => ({
    onLoadError: (error: any) => handler(error, 'DOCUMENT LOAD'),
    onSourceError: (error: any) => handler(error, 'DOCUMENT RENDER')
});

export const getCondensedString = (str: string) =>
    str.replace(/[^a-zA-Z0-9]/g, '');

export const getSourceContentMap = (content: string) =>
    new Set(
        content
            .split(/\n|\s{2,}/)
            .map((line) => getCondensedString(line))
            .filter((line) => !!line)
    );

export const highlightPattern = (
    text: string,
    observationContentMap: Set<string>,
    condensedObservationContent: string,
    rotateDegree: number = 0
) => {
    const condensedText = getCondensedString(text);
    if (condensedText && observationContentMap?.has(condensedText)) {
        return `<mark style="rotate: ${rotateDegree}deg" class="pdf-page--highlight">${text}</mark>`;
    } else if (
        condensedText.length >= MIN_TEXT_LENGTH_TO_HIGHLIGHT &&
        condensedObservationContent?.includes(condensedText)
    ) {
        return `<mark style="rotate: ${rotateDegree}deg" class="pdf-page--highlight">${text}</mark>`;
    }
    return text;
};

export const getRotateDegree = (currentDeg: number, steps: number) =>
    getAbsDegree(currentDeg + steps * ROTATE_STEP_DEGREE) % 360;

export const getAbsDegree = (deg: number) => {
    if (deg === -90) {
        return 270;
    }
    if (deg === -270) {
        return 90;
    }
    return Math.abs(deg);
};

export const getPageWidth = (height: number, rotateSteps: number = 0) =>
    !(rotateSteps % 2) || !height ? DEFAULT_PAGE_WIDTH : height;

export const getPageProxyMap = (doc: any) => {
    const pageProxyMap = {};
    for (let i = 1; i <= doc.numPages; i++) {
        doc.getPage(i).then((page: any) => (pageProxyMap[i] = page));
    }
    return pageProxyMap;
};
