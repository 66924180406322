import { Dispatch, SetStateAction, useState } from 'react';
import isEqual from 'lodash/isEqual';

import useUpdateEffect from './useUpdateEffect';

const useControlledState = <T>([stateProps, setStateProps]: [
    T,
    (value: T) => void
]): [T, Dispatch<SetStateAction<T>>] => {
    const [state, setState] = useState<T>(stateProps);

    useUpdateEffect(() => {
        if (!isEqual(state, stateProps)) {
            setState(stateProps);
        }
    }, [stateProps]);

    useUpdateEffect(() => {
        if (!isEqual(state, stateProps)) {
            setStateProps(state);
        }
    }, [state]);

    return [state, setState];
};

export default useControlledState;
