import { createSelector } from 'reselect';

export const getPackageVersionNumber = ({ globalReducer }) =>
    window.prefix ? parseFloat(globalReducer.packageVersion) : 999;

export const getIsVersionAllowsUsageOfAlwaysInvestigateInTriageSetting =
    createSelector(
        //Navigate to Triage based on obs
        //Task: https://app.asana.com/0/1205907227810792/1206001720103941/f
        //Release version: 104.0
        getPackageVersionNumber,
        (packageVersion) => packageVersion <= 103
    );

export const getAlwaysInvestigateInTriageButton = createSelector(
    [
        getIsVersionAllowsUsageOfAlwaysInvestigateInTriageSetting,
        (state: any) => state.appSettingsReducer.AlwaysInvestigateInTriageButton
    ],
    (
        isVersionAllowsUsageOfAlwaysInvestigateInTriageSetting,
        alwaysInvestigateInTriageButton
    ) =>
        isVersionAllowsUsageOfAlwaysInvestigateInTriageSetting &&
        alwaysInvestigateInTriageButton
);
