const AquantSmallLogo = ({ width = 65, height = 15, color = '#333A4D' }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 65 15"
            fill="none"
        >
            <g mask="url(#mask0_107_9840)">
                <path
                    d="M14.0085 14.7456C13.8242 14.6995 13.6399 14.5613 13.4556 14.4691C13.0408 14.1005 12.1653 11.8886 11.9349 11.3357C11.7506 10.967 11.6124 10.5984 11.428 10.1837L11.382 10.1376C10.9212 9.03168 10.3682 8.01792 9.76915 6.95808C9.26227 6.12864 8.80147 5.62176 8.47891 5.62176C7.83379 5.62176 6.86612 7.09632 5.80628 9.16992L5.76019 9.216C4.60819 11.3818 3.50227 14.1005 3.64051 14.5613V14.6074H3.59444C3.31796 14.6995 2.9954 14.7917 2.67284 14.7917C2.21204 14.8378 1.70515 14.7917 1.24435 14.6534C0.138432 14.2848 -0.0919647 13.3632 0.138435 12.3034C0.553155 10.4602 4.51604 4.19328 5.80628 2.25792C6.40532 1.33632 7.23475 0 8.52499 0C9.63091 0 10.3682 1.05984 11.0133 1.8432C11.382 2.35008 12.3496 3.87072 13.2712 5.39136C13.732 6.12864 14.1468 6.81984 14.5154 7.46496C14.6997 7.78752 14.884 8.11008 15.0684 8.43264C15.437 9.16992 15.8056 9.9072 16.0821 10.6906C16.1282 10.8749 16.2204 11.0592 16.2664 11.2435C16.4047 11.7504 16.5429 12.3034 16.6351 12.8102C16.7272 14.2848 15.2527 14.8838 14.0085 14.7456Z"
                    fill="url(#paint0_linear_107_9840)"
                />
                <path
                    opacity="0.06"
                    d="M15.4368 9.40054C15.022 9.35446 13.271 9.67702 11.566 10.5525C11.52 10.4143 11.4278 10.2761 11.3817 10.0917C10.9209 9.0319 10.368 7.97206 9.76892 6.91222C9.72284 6.86614 9.67676 6.77398 9.67676 6.7279C10.6905 5.99062 11.7043 5.25334 12.8102 4.6543C12.9484 4.8847 13.0867 5.1151 13.2249 5.3455C13.6857 6.08278 14.1004 6.82006 14.4691 7.4191C14.6534 7.74166 14.8377 8.06422 15.022 8.38678C15.1603 8.75542 15.2985 9.07798 15.4368 9.40054Z"
                    fill="black"
                />
                <path
                    opacity="0.06"
                    d="M14.0094 14.7453C13.825 14.6992 13.6407 14.5609 13.4564 14.4688C13.226 14.2845 12.9034 13.6393 12.627 12.9942C14.1476 12.4413 15.3918 10.5981 15.7143 9.99902C15.8065 10.2755 15.8986 10.5059 15.9908 10.7824C16.2212 11.4736 16.4055 12.2109 16.5438 12.9481C16.7281 14.2845 15.2535 14.8835 14.0094 14.7453Z"
                    fill="black"
                />
                <path
                    d="M19.4916 9.907C19.3534 11.4737 18.4779 13.2708 17.418 14.0542C16.3121 14.8376 15.4827 14.8376 14.6993 14.8376H14.6072C14.3768 14.8376 14.1924 14.7915 13.962 14.6993C13.7777 14.6532 13.5934 14.515 13.4091 14.4228C14.2385 14.5611 15.6209 12.6718 16.1278 11.2433C16.1739 11.059 16.266 10.8747 16.3121 10.7364C16.4504 10.0913 16.7729 8.89324 15.9896 8.5246C15.667 8.34028 15.2984 8.38636 14.9297 8.47852C14.7915 8.5246 14.6532 8.57068 14.515 8.61676C13.4091 8.9854 12.3492 9.58444 11.3355 10.1835C10.8286 10.506 10.3217 10.8286 9.76876 11.1051C8.5246 11.7963 7.28045 12.5336 6.03629 13.2248C5.89805 13.3169 5.7598 13.4091 5.62156 13.4552L5.299 13.6395L4.97645 13.8238L4.88428 13.8699L4.74605 13.916L4.56173 14.0081L4.28525 14.1464L4.147 14.1924C3.96268 14.3307 3.73228 14.4228 3.50188 14.515C3.17932 14.515 3.2254 14.1003 3.4558 13.4552C3.6862 12.81 4.05484 11.9806 4.46956 11.1972C4.51564 11.1051 4.56172 11.0129 4.6078 10.9208C4.6078 10.8747 4.65388 10.8747 4.65388 10.8286C4.69996 10.7364 4.74605 10.6443 4.79213 10.5982C5.11469 9.99916 5.43725 9.49228 5.66765 9.12364C5.66765 9.12364 5.66764 9.07756 5.71372 9.07756C5.80588 9.03148 5.89805 8.93932 6.03629 8.89324C6.77357 8.47852 8.38636 7.64908 9.6766 6.9118C9.86092 6.77356 10.0913 6.6814 10.2756 6.58924C11.1972 6.08236 12.1649 5.66764 13.1787 5.39116C13.8238 5.20684 14.4689 5.0686 15.1601 5.02252C16.4043 4.93036 17.6024 5.299 18.4318 6.2206C19.3534 7.23436 19.5838 8.66284 19.4916 9.907Z"
                    fill="url(#paint1_linear_107_9840)"
                />
                <path
                    opacity="0.06"
                    d="M6.12906 13.2253L5.39178 13.64L5.06922 13.8243L4.97706 13.8704L4.83882 13.9165L4.6545 14.0086L4.37802 14.1469L4.23978 14.1929C4.05546 14.3312 3.82506 14.4233 3.59466 14.5155C3.2721 14.5155 3.31818 14.1008 3.54858 13.4557C3.77898 12.8105 4.14762 11.9811 4.56234 11.1977C4.60842 11.1056 4.6545 11.0134 4.70058 10.9213C4.70058 10.8752 4.74666 10.8752 4.74666 10.8291C4.79274 10.7369 4.83882 10.6448 4.8849 10.5987C5.20746 9.99966 5.53002 9.49278 5.76042 9.12414L5.8065 9.07806C5.89866 9.03198 5.99082 8.93982 6.12906 8.89374L6.22122 8.84766C6.0369 9.6771 5.20746 12.3958 6.12906 13.2253Z"
                    fill="black"
                />
                <path
                    d="M21.5195 7.14234C21.5195 5.1609 22.9019 3.73242 24.6991 3.73242C25.7128 3.73242 26.404 4.10106 26.8648 4.65402L26.9109 3.87066H28.6619V10.4601H26.9109L26.8648 9.67674C26.4501 10.2297 25.7128 10.5983 24.6991 10.5983C22.9019 10.5983 21.5195 9.12378 21.5195 7.14234ZM25.0677 5.29914C24.0078 5.29914 23.2706 6.0825 23.2706 7.14234C23.2706 8.20218 24.0078 9.03162 25.0677 9.03162C26.1275 9.03162 26.8648 8.20218 26.8648 7.14234C26.8648 6.0825 26.1275 5.29914 25.0677 5.29914Z"
                    fill={color}
                />
                <path
                    d="M34.8367 4.6542L34.8828 3.87084H36.5877V13.179H34.7906V9.723C34.3298 10.2299 33.5925 10.5524 32.6248 10.5524C30.7356 10.5524 29.4453 9.12396 29.4453 7.14252C29.4453 5.16108 30.7816 3.68652 32.6248 3.68652C33.6847 3.7326 34.3759 4.10124 34.8367 4.6542ZM32.9935 5.29932C31.9336 5.29932 31.1964 6.12876 31.1964 7.1886C31.1964 8.24844 31.9336 9.0318 32.9935 9.0318C34.0533 9.0318 34.7906 8.24844 34.7906 7.1886C34.7906 6.12876 34.0533 5.29932 32.9935 5.29932Z"
                    fill={color}
                />
                <path
                    d="M42.1172 3.87109H43.8683V7.74181C43.8683 9.40069 42.6241 10.5988 40.7348 10.5988C38.8916 10.5988 37.6475 9.44677 37.6475 7.78789V3.87109H39.3985V7.55749C39.3985 8.38693 39.9515 8.93989 40.7348 8.93989C41.5643 8.93989 42.1172 8.38693 42.1172 7.55749V3.87109Z"
                    fill={color}
                />
                <path
                    d="M44.5596 7.14234C44.5596 5.1609 45.942 3.73242 47.7391 3.73242C48.7529 3.73242 49.4441 4.10106 49.9049 4.65402L49.9509 3.87066H51.6559V10.4601H49.9049L49.8588 9.67674C49.4441 10.2297 48.7529 10.5983 47.693 10.5983C45.942 10.5983 44.5596 9.12378 44.5596 7.14234ZM48.1077 5.29914C47.0479 5.29914 46.3106 6.0825 46.3106 7.14234C46.3106 8.20218 47.0479 9.03162 48.1077 9.03162C49.1676 9.03162 49.9049 8.20218 49.9049 7.14234C49.9049 6.0825 49.1676 5.29914 48.1077 5.29914Z"
                    fill={color}
                />
                <path
                    d="M59.2135 6.31325V10.4605H57.4625V6.77405C57.4625 5.85245 56.8635 5.39165 56.0801 5.39165C55.2967 5.39165 54.6055 5.89853 54.6055 6.77405V10.4605H52.8545V3.87101H54.5134L54.5595 4.65437C54.882 4.19357 55.6193 3.77885 56.587 3.77885C58.1076 3.73277 59.2135 4.74653 59.2135 6.31325Z"
                    fill={color}
                />
                <path
                    d="M62.4384 5.34522V8.06394C62.4384 8.75514 62.807 9.03162 63.4061 9.03162C63.7286 9.03162 64.0512 8.98554 64.3737 8.93946V10.414C64.0973 10.5062 63.8208 10.5523 63.5443 10.5523C62.9453 10.5523 62.3462 10.5523 61.7933 10.3219C61.3785 10.1375 61.056 9.86106 60.8717 9.44634C60.6873 9.0777 60.5952 8.66298 60.5952 8.24826V5.29914H59.4893V3.87066H60.5952V1.75098H62.3001V3.87066H63.8669V5.34522H62.4384Z"
                    fill={color}
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_107_9840"
                    x1="-7.40786"
                    y1="14.7174"
                    x2="13.694"
                    y2="6.5428"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.27" stopColor="#1DCAFF" />
                    <stop offset="0.98" stopColor="#2468FF" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_107_9840"
                    x1="0.405492"
                    y1="9.13337"
                    x2="16.0105"
                    y2="10.2686"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2468FF" />
                    <stop offset="1" stopColor="#1DCAFF" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default AquantSmallLogo;
