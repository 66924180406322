import { createSelector } from 'reselect';

export const getUserPermissionSets = ({ userProfileReducer }) =>
    userProfileReducer.permissionSets;

export const getIsUserExpertAdmin = ({ globalReducer }) =>
    globalReducer.isUserExpertAdmin;

export const getIsUserExpert = ({ globalReducer }) =>
    globalReducer.isUserAllowedToExpertMode;

export const getIsUserTriageAdmin = createSelector(
    [getUserPermissionSets],
    (permissionSets) => permissionSets.includes('Aquant Triage Admin')
);

export const getIsUserExpertOrAdmin = createSelector(
    [getIsUserTriageAdmin, getIsUserExpert, getIsUserExpertAdmin],
    (isUserTriageAdmin, isUserExpert, isUserExpertAdmin) =>
        isUserTriageAdmin || isUserExpert || isUserExpertAdmin
);
