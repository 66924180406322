const DocumentSmallIcon = ({ color = 'currentColor', ...props }) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M6 10C5.72386 10 5.5 10.2239 5.5 10.5C5.5 10.7761 5.72386 11 6 11H10C10.2761 11 10.5 10.7761 10.5 10.5C10.5 10.2239 10.2761 10 10 10H6Z"
                fill={color}
            />
            <path
                d="M5.5 8C5.5 7.72386 5.72386 7.5 6 7.5H10C10.2761 7.5 10.5 7.72386 10.5 8C10.5 8.27614 10.2761 8.5 10 8.5H6C5.72386 8.5 5.5 8.27614 5.5 8Z"
                fill={color}
            />
            <path
                d="M6 5C5.72386 5 5.5 5.22386 5.5 5.5C5.5 5.77614 5.72386 6 6 6H10C10.2761 6 10.5 5.77614 10.5 5.5C10.5 5.22386 10.2761 5 10 5H6Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 4C3 2.89543 3.89543 2 5 2H11C12.1046 2 13 2.89543 13 4V12C13 13.1046 12.1046 14 11 14H5C3.89543 14 3 13.1046 3 12V4ZM5 3H11C11.5523 3 12 3.44772 12 4V12C12 12.5523 11.5523 13 11 13H5C4.44772 13 4 12.5523 4 12V4C4 3.44772 4.44772 3 5 3Z"
                fill={color}
            />
        </svg>
    );
};

export default DocumentSmallIcon;
