import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import ValueWithTooltip from '../../ValueWithTooltip/ValueWithTooltip';
import classnames from 'classnames/bind';
import { Button } from 'aqui';

import styles from './Cells.module.scss';
import { Popover } from '@mui/material';
import { t } from 'i18next';

const cx = classnames.bind(styles);
const EditableCell = ({ field, row, id, width = '250px', onSubmit }) => {
    const [newValue, setNewValue] = useState(row[field]);
    const [anchorEl, setAnchorEl] = useState(null);

    const textareaRef = useRef<HTMLTextAreaElement>();

    useLayoutEffect(() => {
        if (textareaRef?.current) {
            if (
                textareaRef.current.scrollHeight >
                textareaRef.current.clientHeight
            ) {
                textareaRef.current.style.height =
                    25 + textareaRef.current.scrollHeight + 'px';
            }
        }
    }, [textareaRef?.current?.scrollHeight]);

    const textArea = useCallback(
        (node) => {
            if (node !== null && anchorEl) {
                const textArea = document.getElementById('textArea');
                textArea.style.height = 25 + textArea.scrollHeight + 'px';
            }
        },
        [anchorEl]
    );

    const onClickEdit = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const onClickSubmit = () => {
        if (row[field] === newValue) {
            return;
        }
        onSubmit(newValue);
        onClose();
    };

    const onClickCancel = () => {
        setNewValue(row[field]);
        onClose();
    };

    const onClose = () => {
        setAnchorEl(null);
    };

    const returnCellView = () => {
        return (
            <ValueWithTooltip
                type="subtitle3"
                dataTestId={`${field}-cell`}
                title={row[field]}
                className={cx('editable-cell__text')}
            />
        );
    };

    return (
        <>
            <div
                onClick={onClickEdit}
                className={cx('editable-cell__container')}
            >
                {returnCellView()}
            </div>
            <Popover
                id={id}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left'
                }}
            >
                <div
                    className={cx('editable-cell__wrapper')}
                    style={{ width }}
                    ref={textArea}
                >
                    <textarea
                        id="textArea"
                        onKeyDown={(e) => e.stopPropagation()}
                        ref={textareaRef}
                        data-testid="editable-cell"
                        value={newValue}
                        className={cx('editable-cell__text-area')}
                        onChange={(e) => {
                            setNewValue(e.target.value);
                        }}
                    />
                    <div className={cx('editable-cell__btns')}>
                        <Button
                            dataTestId="ok-btn-import-failed-modal"
                            color="blue"
                            onClick={onClickCancel}
                            text={t('common.cancel')}
                            variant="outlined"
                        />
                        <Button
                            dataTestId="ok-btn-import-failed-modal"
                            color="blue"
                            onClick={onClickSubmit}
                            text={t('common.apply')}
                            variant="filled"
                            disabled={row[field] === newValue}
                        />
                    </div>
                </div>
            </Popover>
        </>
    );
};

export default EditableCell;
