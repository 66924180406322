import { FunctionComponent, useState } from 'react';
import { Button } from 'aqui';
import { MoreIcon } from 'aqui/icons';
import MoreOptionsPopover from './MoreOptionsPopover';
import { PopoverRow } from '../../types';

type MoreOptionsButtonProps = {
    rows: PopoverRow[];
    hasRowsToShow: boolean;
    withAquantIcon?: boolean;
    disabled?: boolean;
};

const MoreOptionsButton: FunctionComponent<MoreOptionsButtonProps> = ({
    rows,
    hasRowsToShow,
    withAquantIcon = false,
    disabled = false
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const onOpen = (e) => {
        setAnchorEl(e.currentTarget);
    };

    if (!hasRowsToShow) {
        return null;
    }
    return (
        <>
            <Button
                onClick={onOpen}
                dataTestId="more-options-button"
                IconComponent={MoreIcon}
                color="blue"
                variant="outlined"
                shape="circle"
                disabled={disabled}
            />
            <MoreOptionsPopover
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                rows={rows}
                withAquantIcon={withAquantIcon}
            />
        </>
    );
};

export default MoreOptionsButton;
