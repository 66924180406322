import { FunctionComponent } from 'react';
import { t } from 'i18next';
import { Button } from 'aqui';

type AddUsersModalFooterProps = {
    disableSave: boolean;
    handleSave: () => void;
    handleCancel: () => void;
};

const AddUsersModalFooter: FunctionComponent<AddUsersModalFooterProps> = ({
    disableSave,
    handleSave,
    handleCancel
}) => {
    return (
        <div
            data-testid={`add-users-modal-footer`}
            className="upload-files__footer-btns"
        >
            <Button
                text={t('common.cancel')}
                dataTestId="add-users-modal-cancel-button"
                onClick={() => handleCancel()}
            />
            <Button
                variant="filled"
                text={t('common.submit')}
                dataTestId="add-users-modal-submit-button"
                disabled={disableSave}
                onClick={handleSave}
            />
        </div>
    );
};

export default AddUsersModalFooter;
