import { FC, useEffect, useMemo, useState } from 'react';
import { Disclaimer } from '../../types';
import MessageSingleTypeDisclaimer from './MessageSingleTypeDisclaimer';
import { Collapse } from '@mui/material';
import TextButton from '../TextButton/TextButton';
import { t } from 'i18next';
import useAnalytics from '../../hooks/useAnalytics';
import { ANALYTICS_EVENTS } from '../../consts';
import {
    getAggregatedDisclaimersByType,
    getDisclaimersAnalyticsData
} from './MessageDisclaimersHelpers';

type MessageDisclaimersProps = {
    disclaimers: Disclaimer[];
    setIsCollapseInProgress: (isInProgress: boolean) => void;
};

const MessageDisclaimers: FC<MessageDisclaimersProps> = ({
    disclaimers,
    setIsCollapseInProgress
}) => {
    const [shouldShowAllDisclaimers, setShouldShowAllDisclaimers] =
        useState(false);
    const { sendEvent } = useAnalytics();

    const [firstDisclaimer, restDisclaimers, numberOfRestDisclaimers] =
        useMemo(() => {
            if (!disclaimers.length) {
                return [[], [], 0];
            }
            const aggregatedDisclaimers =
                getAggregatedDisclaimersByType(disclaimers);
            const restDisclaimers = aggregatedDisclaimers.slice(1);
            return [
                aggregatedDisclaimers[0],
                restDisclaimers,
                restDisclaimers.flat().length
            ];
        }, [disclaimers]);

    useEffect(() => {
        firstDisclaimer.forEach((disclaimer) => {
            sendEvent(
                ANALYTICS_EVENTS.DISCLAIMER_VIEWED,
                getDisclaimersAnalyticsData(disclaimer)
            );
        });
    }, [firstDisclaimer]);

    const onCollapseAnimationEnd = () => setIsCollapseInProgress(false);

    const onShowDisclaimersClick = (shouldShow: boolean) => {
        setShouldShowAllDisclaimers(shouldShow);
        setIsCollapseInProgress(true);
        if (shouldShow) {
            restDisclaimers.flat().forEach((disclaimer) => {
                sendEvent(
                    ANALYTICS_EVENTS.DISCLAIMER_EXPANDED,
                    getDisclaimersAnalyticsData(disclaimer)
                );
            });
        } else {
            sendEvent(ANALYTICS_EVENTS.DISCLAIMER_COLLAPSED);
        }
    };

    return (
        <div className="message-disclaimers">
            {firstDisclaimer.length > 0 && (
                <MessageSingleTypeDisclaimer
                    disclaimers={firstDisclaimer}
                    key={firstDisclaimer[0].rowid}
                />
            )}
            <Collapse
                in={shouldShowAllDisclaimers}
                onEntered={onCollapseAnimationEnd}
                onExited={onCollapseAnimationEnd}
            >
                <div className="message-disclaimers__rest-disclaimers">
                    {restDisclaimers.map((disclaimers) => (
                        <MessageSingleTypeDisclaimer
                            disclaimers={disclaimers}
                            key={disclaimers[0].rowid}
                        />
                    ))}
                </div>
            </Collapse>

            {restDisclaimers.length > 0 && (
                <>
                    {shouldShowAllDisclaimers ? (
                        <TextButton
                            onClick={() => onShowDisclaimersClick(false)}
                            text={t('common.show-less')}
                            withChevron
                            className="message-disclaimers__show-less-btn"
                            dataTestId="message-disclaimers-show-less-btn"
                        />
                    ) : (
                        <TextButton
                            onClick={() => onShowDisclaimersClick(true)}
                            text={`${numberOfRestDisclaimers} ${t(
                                'disclaimer.additional'
                            )}`}
                            withChevron
                            className={`message-disclaimers__show-more-btn`}
                            dataTestId="message-disclaimers-show-more-btn"
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default MessageDisclaimers;
