import { useRef } from 'react';
import {
    useCurrentPanel,
    useIntegratedModels,
    useThreadsPanel
} from '../AppContext';
import useAnalytics from '../hooks/useAnalytics';
import { format, differenceInMinutes } from 'date-fns';
import { Panel } from '../types';

const useSwitchPanel = () => {
    const { currentPanel, setCurrentPanel, prevPanel, setPrevPanel } =
        useCurrentPanel();
    const { sendEvent } = useAnalytics();
    const { setIsThreadsPanelOpen } = useThreadsPanel();
    const { fetchInitModels } = useIntegratedModels();
    const isFirstRequestPanel = currentPanel === Panel.FirstRequestPanel;
    const isCoPilotPanel = currentPanel === Panel.CoPilotPanel;
    const isDataManagementPanel = currentPanel === Panel.DataManagementPanel;

    const startTime = useRef(new Date());

    const endPanelTimer = () => {
        if (startTime.current) {
            const prevPanel = currentPanel;
            const endTime = new Date();
            const duration = differenceInMinutes(endTime, startTime.current);

            sendEvent(`Time spent on ${prevPanel}`, {
                'Start Time': format(startTime.current, 'hh:mm:ss a'),
                'End Time': format(endTime, 'hh:mm:ss a'),
                Duration: `${duration < 1 ? '1' : duration} min`
            });
        }
    };

    const setPanel = (panel: Panel) => {
        endPanelTimer();
        startTime.current = new Date();
        setCurrentPanel(panel);
    };

    const setCoPilotPanel = () => {
        setPanel(Panel.CoPilotPanel);
    };

    const setFirstRequestPanel = () => {
        fetchInitModels();
        setPanel(Panel.FirstRequestPanel);
    };

    const setDataManagementPanel = () => {
        setPrevPanel(currentPanel);
        setPanel(Panel.DataManagementPanel);
        setIsThreadsPanelOpen(false);
    };

    const backToPrevPanel = () => {
        const panelToBackTo = prevPanel || Panel.FirstRequestPanel;
        if (panelToBackTo === Panel.FirstRequestPanel) {
            fetchInitModels();
        }
        setPanel(panelToBackTo);
        setPrevPanel(null);
    };

    return {
        isFirstRequestPanel,
        isCoPilotPanel,
        isDataManagementPanel,
        setCoPilotPanel,
        setFirstRequestPanel,
        setDataManagementPanel,
        backToPrevPanel
    };
};
export default useSwitchPanel;
