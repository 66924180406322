import { FunctionComponent } from 'react';
import { t } from 'i18next';
import { Button } from 'aqui';
import { IngestModalTypes } from '../../../../types';
import { useIngestModal } from './IngestModalHooks';

type AddPersonasToSourcesModalFooterProps = {
    disableSave: boolean;
    modalType: IngestModalTypes;
    onClickSave: () => void;
    onClickDelete: () => void;
};

const AddPersonasToSourcesModalFooter: FunctionComponent<
    AddPersonasToSourcesModalFooterProps
> = ({ disableSave = false, modalType, onClickSave, onClickDelete }) => {
    const { handleCloseModal } = useIngestModal();

    const proceedButton = {
        [IngestModalTypes.addPersona]: {
            title: t('common.save'),
            color: 'blue',
            onClick: onClickSave
        },
        [IngestModalTypes.deleteSources]: {
            title: t('common.delete'),
            color: 'red',
            onClick: onClickDelete
        }
    };

    return (
        <div
            data-testid={`${modalType}-footer`}
            className="upload-files__footer-btns"
        >
            <Button
                text={t('common.cancel')}
                dataTestId={`${modalType}-cancel-button`}
                onClick={() => handleCloseModal()}
            />
            <Button
                variant="filled"
                text={proceedButton[modalType].title}
                color={proceedButton[modalType].color}
                dataTestId={`${modalType}-${proceedButton[modalType].title}-button`}
                disabled={disableSave}
                onClick={proceedButton[modalType].onClick}
            />
        </div>
    );
};

export default AddPersonasToSourcesModalFooter;
