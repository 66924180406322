import SFBackend from '../backend/SFBackend';

export const getAdditionalChatHistoryData = (data, byModels = false) => {
    const chatIds = new Set();
    const userIds = new Set();

    for (const object of data) {
        if (byModels) {
            object.data.forEach((thread) => {
                chatIds.add(thread.chat_id);
                userIds.add(thread.user_external_id);
            });
        } else {
            chatIds.add(object.chat_id);
            userIds.add(object.user_external_id);
        }
    }
    return SFBackend.getAdditionalChatHistoryData([...chatIds], [...userIds]);
};

export const assignDataToChats = (chatsData, usersData, chatHistory) => {
    for (const obj of chatHistory.data) {
        if (obj.tenant_model)
            obj['investigation_id'] =
                chatsData[obj.chat_id]?.investigationId || null;
        obj['user_email'] = usersData[obj.user_external_id]?.userEmail || null;
    }
    return chatHistory;
};

export const performCallWithTimeoutRetry = async (
    callback,
    retryAttempts = 2,
    timeout = 5000
) => {
    let retryCount = 0;
    while (retryCount <= retryAttempts) {
        try {
            return await callback();
        } catch (e) {
            if (e.statusCode === 504 && retryCount < retryAttempts) {
                await new Promise((resolve) => setTimeout(resolve, timeout));
                retryCount++;
                console.error('Retrying. Attempt: ', retryCount);
            } else {
                throw e;
            }
        }
    }
};