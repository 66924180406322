import {
    getAggregatedResultDetails,
    getPageIndication
} from './KASectionHelpers';
import VideoSmallIcon from '../../SVG/VideoSmallIcon';
import DocumentSmallIcon from '../../SVG/DocumentSmallIcon';
import ValueWithTooltip from '../ValueWithTooltip/ValueWithTooltip';
import { Typography } from 'aqui';
import ChevronIconSmall from '../../SVG/ChevronIconSmall';
import { t } from 'i18next';
import { FunctionComponent, useState } from 'react';
import { SourceDocument } from '../../types';
import { Collapse } from '@mui/material';
import useAnalytics from '../../hooks/useAnalytics';
import { ANALYTICS_EVENTS } from '../../consts';
import { useKAModal } from '../../AppContext';

type KASectionRowProps = {
    results: Array<SourceDocument>;
    resultIndex?: number;
    isResultSeen: boolean;
    seenResults: Set<SourceDocument>;
    onResultClick: Function;
    isCoPilotPanel: boolean;
    isAllResultsDeleted?: boolean;
};

const KASectionRow: FunctionComponent<KASectionRowProps> = ({
    results,
    resultIndex,
    isResultSeen,
    seenResults,
    onResultClick,
    isCoPilotPanel = false,
    isAllResultsDeleted = false
}) => {
    const { fileName, mediaType, fileType } =
        getAggregatedResultDetails(results);
    const [showPages, setShowPages] = useState(false);
    const { sendEvent } = useAnalytics();
    const { getIsSourceActive } = useKAModal();
    const isActive = results.length === 1 && getIsSourceActive(results[0]);
    const isSourceDisabled =
        results.length === 1 && !results[0].metadata.source;

    const MediaTypeIcon = ({ type, ...rest }) =>
        type === 'video' ? (
            <VideoSmallIcon {...rest} />
        ) : (
            <DocumentSmallIcon {...rest} />
        );

    const onResultTitleClick = (results: SourceDocument[]) => {
        if (isSourceDisabled) {
            return;
        }

        if (results.length > 1) {
            setShowPages(!showPages);
            if (!showPages) {
                sendEvent(ANALYTICS_EVENTS.SOURCE_PANEL_CLICK_SOURCE_EXPAND, {
                    'Source name': results[0].metadata.filename
                });
            }
        } else {
            onResultClick(results[0], fileType);
            sendEvent(ANALYTICS_EVENTS.KNOWLEDGE_SOURCE_CLICK, {
                'Source name': results[0].metadata.filename,
                'Type of source': fileType
            });
        }
    };

    const onPageClick = (result: SourceDocument) => {
        onResultClick(result, fileType);
        sendEvent(ANALYTICS_EVENTS.SOURCE_PANEL_CLICK_PAGE_NUMBER, {
            'Source name': result.metadata.filename,
            'Page number': getPageIndication(result, false)
        });
    };

    return (
        <div className="ka-row" data-testid="ka-row">
            <div
                className={`flex align-center ka-row__hover-area ${
                    results.length > 1 ? '--multiple-pages' : ''
                } ${isActive ? 'ka-row__hover-area--active' : ''}
                ${isSourceDisabled ? '--disabled' : ''}`}
                onClick={() => onResultTitleClick(results)}
                data-testid="ka-row-source"
                data-aggregated={results.length > 1}
                data-active={isActive}
                data-opened={isResultSeen}
            >
                {resultIndex && !isAllResultsDeleted && (
                    <span
                        className={`bold chatMessage__source-number ${
                            isActive ? 'chatMessage__source-number--active' : ''
                        }
                            ${isSourceDisabled ? '--disabled' : ''}
                        `}
                        data-testid="ka-row-source-number"
                    >
                        {resultIndex === -1 ? '' : resultIndex}
                    </span>
                )}
                <MediaTypeIcon
                    type={mediaType}
                    className="ka-row__type-icon"
                    data-testid="ka-row-source-media-type"
                    data-type={mediaType}
                />

                <ValueWithTooltip
                    title={fileName}
                    tooltipTitle={
                        isSourceDisabled
                            ? t('ingested-sources.source-not-available')
                            : fileName
                    }
                    dataTestId="ka-row-source-file-name"
                    type={isResultSeen ? 'small1' : 'small1Bold'}
                    className={`ka-row__file-name ${
                        isCoPilotPanel ? '--in-portal' : ''
                    }`}
                />
                {results.length > 1 ? (
                    <div className="ka-row__num-of-results-indicator">
                        <Typography
                            className="lines-limit-1"
                            type="small1Bold"
                            dataTestId="ka-row_num-of-results-indicator"
                        >{`${results.length} ${t(
                            'search.results'
                        )}`}</Typography>
                        <ChevronIconSmall
                            direction="down"
                            className={`ka-row__chevron-icon ${
                                showPages ? 'ka-row__chevron-icon--open' : ''
                            }`}
                        />
                    </div>
                ) : (
                    <Typography
                        type={isResultSeen ? 'small1' : 'small1Bold'}
                        tag="div"
                        className={`ka-row__page-num ${
                            isSourceDisabled ? '--disabled' : ''
                        }`}
                        dataTestId="ka-row-source-page-num"
                    >
                        {getPageIndication(results[0])}
                    </Typography>
                )}
            </div>
            <Collapse in={showPages}>
                <div
                    className="ka-row__pages-list"
                    data-testid="ka-row-pages-list"
                    data-open={showPages}
                >
                    {results.map((result) => {
                        const isPageSeen = seenResults.has(result);
                        const isPageActive = getIsSourceActive(result);
                        const { page, start } = result.metadata;
                        const key = page || start;
                        return (
                            <div
                                className={`ka-row__page ${
                                    isPageActive ? 'ka-row__page--active' : ''
                                }`}
                                key={fileName + key}
                            >
                                <div className="ka-row__page-bullet"></div>
                                <Typography
                                    type={isPageSeen ? 'small1' : 'small1Bold'}
                                    tag="div"
                                    className={`ka-row__page-num --multiple-pages ${
                                        isPageActive
                                            ? 'ka-row__page-num--active'
                                            : ''
                                    }`}
                                    onClick={() => onPageClick(result)}
                                    data-testid={'ka-row-page'}
                                    data-active={isPageActive}
                                    data-opened={isPageSeen}
                                >
                                    {getPageIndication(result)}
                                </Typography>
                            </div>
                        );
                    })}
                </div>
            </Collapse>
        </div>
    );
};

export default KASectionRow;
