import React from 'react';
const InfoIcon = () => {
    return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.67419 0.293077C9.01546 0.100939 9.40049 0 9.79213 0C10.1838 0 10.5688 0.100939 10.9101 0.293077C11.2513 0.485215 11.5373 0.762067 11.7405 1.09692L11.7419 1.09929L19.2789 13.6818C19.4779 14.0264 19.5831 14.4171 19.5843 14.815C19.5854 15.2129 19.4823 15.6041 19.2852 15.9498C19.0882 16.2955 18.8041 16.5835 18.4612 16.7853C18.1182 16.9871 17.7284 17.0956 17.3306 17.1L17.3251 17.1L2.25369 17.1C1.85582 17.0956 1.46603 16.9871 1.12311 16.7853C0.780175 16.5835 0.496059 16.2955 0.299022 15.9498C0.101985 15.6041 -0.00110519 15.2129 8.93564e-06 14.815C0.00112304 14.4171 0.106402 14.0264 0.305372 13.6819L0.309379 13.6749L7.84238 1.0993L8.27133 1.35625L7.84381 1.09692C8.04693 0.762067 8.33292 0.485215 8.67419 0.293077ZM8.69945 1.6145L8.69881 1.61555L1.16953 14.1851C1.05906 14.3777 1.00063 14.5957 1.00001 14.8178C0.99938 15.0411 1.05723 15.2606 1.1678 15.4546C1.27837 15.6486 1.4378 15.8102 1.63024 15.9235C1.82197 16.0363 2.0398 16.0971 2.26221 16.1H17.3221C17.5445 16.0971 17.7623 16.0363 17.954 15.9235C18.1465 15.8102 18.3059 15.6486 18.4165 15.4546C18.527 15.2606 18.5849 15.0411 18.5843 14.8178C18.5836 14.5957 18.5252 14.3777 18.4148 14.1851L10.8855 1.61555L10.8848 1.6145C10.7709 1.42706 10.6106 1.27208 10.4195 1.16446C10.228 1.05664 10.0119 1 9.79213 1C9.57236 1 9.3563 1.05664 9.16479 1.16446C8.97364 1.27208 8.81339 1.42706 8.69945 1.6145ZM9.79224 5.42755C10.0684 5.42755 10.2922 5.65141 10.2922 5.92755V9.48502C10.2922 9.76116 10.0684 9.98502 9.79224 9.98502C9.51609 9.98502 9.29224 9.76116 9.29224 9.48502V5.92755C9.29224 5.65141 9.51609 5.42755 9.79224 5.42755ZM9.79224 12.5425C9.51609 12.5425 9.29224 12.7663 9.29224 13.0425C9.29224 13.3186 9.51609 13.5425 9.79224 13.5425H9.80224C10.0784 13.5425 10.3022 13.3186 10.3022 13.0425C10.3022 12.7663 10.0784 12.5425 9.80224 12.5425H9.79224Z" fill="#C70030"/>
    </svg>
    );
};

export default InfoIcon;
