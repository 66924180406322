import React, { FunctionComponent, useMemo, useState } from 'react';
import SearchSelect from '../../SearchSelect/SearchSelect';
import { t } from 'i18next';
import { usePersonasManagementRows } from './PersonaManagementHooks';

type PersonaSelectProps = {
    selectedPersona: string;
    setSelectedPersona: (value: string) => void;
    menuClassName?: string;
};

const PersonaSelect: FunctionComponent<PersonaSelectProps> = ({
    selectedPersona,
    setSelectedPersona,
    menuClassName = ''
}) => {
    const { personasNames } = usePersonasManagementRows();
    const [isOpenPersonaSelect, setIsOpenPersonaSelect] =
        useState<boolean>(false);
    const [personaSearchText, setPersonaSearchText] = useState<string>('');

    const filteredValues = useMemo(() => {
        return personasNames.filter((row) =>
            row.toLowerCase().includes(personaSearchText)
        );
    }, [personaSearchText, personasNames]);

    const onValueSelect = (value: string) => {
        setSelectedPersona(value);
        setPersonaSearchText(value);
    };

    return (
        <SearchSelect
            values={filteredValues}
            setValue={onValueSelect}
            value={selectedPersona}
            classes={{
                popover: 'addPersonaModal__persona-sources-popover',
                menu: `addPersonaModal__persona-sources-menu ${menuClassName}`,
                search: 'addPersonaModal__persona-sources-search'
            }}
            leftIcon={null}
            disabled={false}
            isOpen={isOpenPersonaSelect}
            setIsOpen={setIsOpenPersonaSelect}
            dataTestId="add-persona-select-persona-sources"
            searchPlaceholder={t('add-persona.select-persona')}
            searchText={personaSearchText}
            setSearchText={setPersonaSearchText}
            processing={false}
            onClearButtonClick={() => setSelectedPersona('')}
            noValuesMessage={t('add-persona.no-persona-found')}
        />
    );
};

export default PersonaSelect;
