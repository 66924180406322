import { useEffect } from 'react';

export const useOnClickOutside = (ref, handler, buttonRef = null) => {
    useEffect(() => {
        const listener = (event) => {
            const buttonObj = !!buttonRef?.current
                ? buttonRef.current
                : buttonRef;
            if (
                !ref ||
                !ref.current ||
                ref.current.contains(event.target) ||
                (buttonRef && buttonObj.contains(event.target))
            ) {
                return;
            }
            handler(event);
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, buttonRef, handler]);
};
