import * as Sentry from '@sentry/react';
import { HttpClient, CaptureConsole } from '@sentry/integrations';
import { NON_PROD_SUBSTRINGS } from '../consts';

export class SentryWrapper {
    isProdServer: boolean;

    constructor() {
        this.isProdServer = !NON_PROD_SUBSTRINGS.some((v) =>
            process.env.REACT_APP_API_SERVER.includes(v)
        );
    }

    init() {
        if (this.isProdServer) {
            Sentry.init({
                dsn: 'https://07b1a5bbc212b1059b858a091503de3c@o4504076950896640.ingest.sentry.io/4506693676171264',
                integrations: [
                    new Sentry.BrowserTracing({
                        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                        // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
                    }),
                    new HttpClient(),
                    new CaptureConsole({ levels: ['assert', 'error'] })
                ],
                // Performance Monitoring
                tracesSampleRate: 1.0, //  Capture 100% of the transactions
                // Session Replay
                replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
                replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            });
        } else {
            console.debug(
                'Non production environment detected! Sentry is logged off.'
            );
        }
    }

    log(error: Error) {
        Sentry.captureException(error);
    }
}
