import React from 'react';
const MaximizeIcon = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.25 2C9.25 1.58579 9.58579 1.25 10 1.25H14C14.4142 1.25 14.75 1.58579 14.75 2V6C14.75 6.41421 14.4142 6.75 14 6.75C13.5858 6.75 13.25 6.41421 13.25 6V3.81057L9.86332 7.19702C9.57042 7.4899 9.09554 7.48988 8.80266 7.19698C8.50978 6.90408 8.50979 6.4292 8.8027 6.13632L12.1893 2.75H10C9.58579 2.75 9.25 2.41421 9.25 2ZM7.197 8.803C7.48989 9.0959 7.48989 9.57077 7.197 9.86366L3.81066 13.25H6C6.41421 13.25 6.75 13.5858 6.75 14C6.75 14.4142 6.41421 14.75 6 14.75H2C1.58579 14.75 1.25 14.4142 1.25 14V10C1.25 9.58579 1.58579 9.25 2 9.25C2.41421 9.25 2.75 9.58579 2.75 10V12.1893L6.13634 8.803C6.42923 8.51011 6.9041 8.51011 7.197 8.803Z"
                fill="currentcolor"
            />
        </svg>
    );
};

export default MaximizeIcon;
