import { useMemo, useState } from 'react';
import Backend from '../../backend/Backend';
import { useDataManagementContext } from '../../context/DataManagementContext';
import {
    IngestPayload,
    IngestedSourcesStatus,
    IntegratedModel,
    SourceFile
} from '../../types';
import { useSelector } from 'react-redux';
import SFBackend from '../../backend/SFBackend';
import {
    mergeCatalogAndUploadedModels,
    parseCatalogModelResponseToIntegratedModels
} from '../../utils/productUtils';
import { useScopSettings, useTenant } from '../../AppContext';
import {
    useIngestedSourceRows,
    useIngestedSourcesDisabledRows
} from '../DataManagementPanel/IngestedSources/IngestedSourcesHooks';
import { PERSONA_CONSTANT_NAMES } from '../DataManagementPanel/PersonasManagement/consts';
import { sourceFilesToIngestedSourceRows } from '../DataManagementPanel/IngestedSources/IngestedSourcesHelpers';

export const useSourceFiles = () => {
    const {
        sourceFiles,
        setSourceFiles,
        validSourceFiles,
        sourceFilesRef,
        sourceFilesToValidate,
        setSourceFilesToValidate
    } = useDataManagementContext();

    const updateSourceFiles = (
        filesToUpdate: SourceFile[],
        updates: object
    ) => {
        const updatedSourceFiles = sourceFilesRef.current.map((sourceFile) =>
            filesToUpdate.some(({ file }) => file.name === sourceFile.file.name)
                ? { ...sourceFile, ...updates }
                : sourceFile
        );
        sourceFilesRef.current = updatedSourceFiles;
        setSourceFiles(updatedSourceFiles);
    };

    const deleteSourceFiles = (fileNames: string[]) => {
        setSourceFiles(
            sourceFilesRef.current.filter(
                (f) => !fileNames.includes(f.file.name)
            )
        );
    };

    const addToSourceFiles = (files: SourceFile[]) => {
        const updatedSourceFiles = [...sourceFilesRef.current, ...files];
        sourceFilesRef.current = updatedSourceFiles;
        setSourceFiles(updatedSourceFiles);
    };

    return {
        sourceFiles,
        setSourceFiles,
        validSourceFiles,
        updateSourceFiles,
        deleteSourceFiles,
        addToSourceFiles,
        sourceFilesToValidate,
        setSourceFilesToValidate
    };
};

export const useUploadModel = () => {
    const {
        uploadModel,
        setUploadModel,
        uploadModelsList,
        setUploadModelsList,
        isCatalogModelsFetched,
        setIsCatalogModelsFetched,
        currentModelUploadedFiles
    } = useDataManagementContext();
    const { tenantId } = useTenant();
    const fetchModels = async () => {
        try {
            const [catalogModelsData, uploadedModels] = await Promise.all([
                SFBackend.fetchCatalogProduct(),
                Backend.getIngestModels(tenantId)
            ]);
            const catalogModels: IntegratedModel[] =
                parseCatalogModelResponseToIntegratedModels(catalogModelsData);
            setUploadModelsList(
                mergeCatalogAndUploadedModels(catalogModels, uploadedModels)
            );
            setIsCatalogModelsFetched(true);
        } catch (ex: any) {
            console.error('Failed to fetch models. ', ex.message);
            window.sentry.log(ex);
        }
    };
    const addModelToUploadModelsList = (model: IntegratedModel) => {
        setUploadModelsList([...uploadModelsList, model]);
    };

    return {
        uploadModel,
        setUploadModel,
        uploadModelsList,
        fetchModels,
        isCatalogModelsFetched,
        addModelToUploadModelsList,
        currentModelUploadedFiles
    };
};

export const useUploadFilesModal = () => {
    const {
        isUploadFilesModalOpen,
        setIsUploadFilesModalOpen,
        resetUploadModalState,
        isIngestRequestInProgress,
        isCatalogModelsFetched
    } = useDataManagementContext();
    const { setSelectedPersonasForIngest } = useSelectedPersonasForIngest();
    const closeUploadFilesModal = () => {
        setSelectedPersonasForIngest([]);
        setIsUploadFilesModalOpen(false);
    };

    const openUploadFilesModal = () => setIsUploadFilesModalOpen(true);

    const isLoading = isIngestRequestInProgress || !isCatalogModelsFetched;

    return {
        isUploadFilesModalOpen,
        closeUploadFilesModal,
        openUploadFilesModal,
        resetUploadModalState,
        isLoading
    };
};

export const useIngestFiles = () => {
    const { disableRows } = useIngestedSourcesDisabledRows();
    const { selectedPersonasForIngestIds } = useSelectedPersonasForIngest();
    const { sourceFiles, validSourceFiles } = useSourceFiles();
    const { isIngestRequestInProgress, setIsIngestRequestInProgress } =
        useDataManagementContext();
    const { resetUploadModalState } = useUploadFilesModal();
    const { customerName } = useSelector(
        (state: any) => state.userProfileReducer
    );
    const { uploadModel } = useUploadModel();
    const { username: userEmail, orgId } = useSelector(
        (state: any) => state.globalReducer
    );
    const {
        sendEmailNotificationsForDocumentImportStatus,
        documentsImportStatusEmail
    } = useScopSettings();
    const { addSourceFilesToIngestedSources } = useIngestedSourceRows();

    const isIngestAvailable = useMemo(
        () =>
            validSourceFiles.length &&
            sourceFiles.every((f) => f.validationType) &&
            validSourceFiles.every((f) => f.isUploaded),
        [validSourceFiles]
    );

    const ingestUploadedSourceFiles = async (sourceFiles: SourceFile[]) => {
        setIsIngestRequestInProgress(true);
        try {
            await ingestFiles(
                uploadModel.modelName,
                sourceFiles.map((f) => f.file.name),
                selectedPersonasForIngestIds
            );
            resetUploadModalState();
            addSourceFilesToIngestedSources(
                sourceFiles,
                uploadModel,
                userEmail,
                IngestedSourcesStatus.Processing,
                selectedPersonasForIngestIds
            );
            const newIngestedSourcesRows = sourceFilesToIngestedSourceRows(
                sourceFiles,
                uploadModel,
                userEmail,
                IngestedSourcesStatus.Processing,
                selectedPersonasForIngestIds
            );
            disableRows(newIngestedSourcesRows);
        } catch (ex: any) {
            console.error('Failed to send ingest request. ', ex.message);
            window.sentry.log(ex);
            setIsIngestRequestInProgress(false);
        }
    };

    const ingestFiles = async (
        model: string,
        fileNames: string[],
        personaIds = []
    ) => {
        const mailsToNotify = sendEmailNotificationsForDocumentImportStatus
            ? documentsImportStatusEmail?.split(',') || []
            : [];
        const config = await Backend.getIngestConfig(customerName, model);
        const ingestPayload: IngestPayload = {
            document_names: fileNames,
            recreate: false,
            triage_url: config.triage_url || '',
            initial_observation: '',
            enable_copilot: config.enable_copilot || false,
            sf_org_id: orgId,
            enable_cache: config.enable_cache || false,
            preprocess_prompt: config.preprocess_prompt || false,
            provider: config.provider || 'OpenAI',
            embedding_model: config.embedding_model || 'text-embedding-ada-002',
            requested_by_email: null,
            send_mail_notification: true,
            mails_to_notify: mailsToNotify,
            persona_ids: personaIds
        };
        return Backend.ingest(customerName, model, ingestPayload);
    };

    return {
        isIngestAvailable,
        ingestUploadedSourceFiles,
        isIngestRequestInProgress,
        ingestFiles
    };
};

export const useUploadFiles = () => {
    const { selectedPersonasForIngestIds } = useSelectedPersonasForIngest();
    const { sourceFilesRef } = useDataManagementContext();
    const { sourceFiles, updateSourceFiles, deleteSourceFiles } =
        useSourceFiles();
    const { uploadModel } = useUploadModel();
    const { tenantId } = useTenant();
    const { username: userEmail } = useSelector(
        (state: any) => state.globalReducer
    );
    const { deleteIngestedSourceRows } = useIngestedSourceRows();
    const { addSourceFilesToIngestedSources } = useIngestedSourceRows();

    const getFolderName = () =>
        tenantId + '/' + uploadModel?.modelName.replaceAll('/', '-');

    const deleteUploadedFile = async (sourceFile: SourceFile) => {
        if (sourceFile.isUploaded) {
            updateSourceFiles([sourceFile], {
                isDeleting: true,
                isDeletedFailed: false
            });
            try {
                await Backend.deleteFiles(tenantId, uploadModel.modelName, [
                    sourceFile.file.name
                ]);
                deleteSourceFiles([sourceFile.file.name]);
                deleteIngestedSourceRows([
                    uploadModel.modelName + sourceFile.file.name
                ]);
            } catch (ex: any) {
                updateSourceFiles([sourceFile], {
                    isDeleting: false,
                    isDeletedFailed: true
                });
                console.error(
                    `Failed to delete the uploaded file: "${sourceFile.file.name}"`,
                    ex.message
                );
                window.sentry.log(ex);
            }
        } else {
            sourceFile.abortUpload?.();
            deleteSourceFiles([sourceFile.file.name]);
            deleteIngestedSourceRows([
                uploadModel.modelName + sourceFile.file.name
            ]);
        }
    };

    const reuploadFile = (sourceFile: SourceFile) => {
        updateSourceFiles([sourceFile], {
            isUploaded: false,
            isUploadFiled: false,
            isUploadStarted: false,
            percentLoaded: 0
        });
    };

    const onUploadProgress = (
        sourceFile: SourceFile,
        position: number,
        total: number,
        abortUpload: () => void
    ) => {
        const percentLoaded = Math.trunc((position / total) * 100);
        updateSourceFiles([sourceFile], {
            percentLoaded,
            abortUpload: percentLoaded !== 100 ? abortUpload : null
        });
    };

    const uploadFile = async (sourceFile: SourceFile, presignedData) => {
        addSourceFilesToIngestedSources(
            [sourceFile],
            uploadModel,
            userEmail,
            IngestedSourcesStatus.Uploading,
            selectedPersonasForIngestIds
        );
        try {
            await Backend.uploadToPresignedUrl(
                sourceFile.file,
                presignedData,
                (position: number, total: number, abortUpload: () => void) => {
                    onUploadProgress(sourceFile, position, total, abortUpload);
                }
            );
            const filePath = getFolderName() + '/' + sourceFile.file.name;
            await Promise.all([
                Backend.updateMetadata(tenantId, [filePath]),
                Backend.addUploadedFiles(
                    tenantId,
                    uploadModel.modelName,
                    [sourceFile.file.name],
                    selectedPersonasForIngestIds
                )
            ]);
            updateSourceFiles([sourceFile], {
                isUploaded: true
            });
            addSourceFilesToIngestedSources(
                [sourceFile],
                uploadModel,
                userEmail,
                IngestedSourcesStatus.Uploaded,
                selectedPersonasForIngestIds
            );
        } catch (ex: any) {
            console.error(
                `Failed to upload file: "${sourceFile.file.name}"`,
                ex.message
            );
            window.sentry.log(ex);
            updateSourceFiles([sourceFile], {
                isUploadFiled: true,
                abortUpload: null
            });
            deleteIngestedSourceRows([
                uploadModel.modelName + sourceFile.file.name
            ]);
        }
    };

    const uploadFiles = async (sourceFilesToUpload: SourceFile[]) => {
        if (!sourceFiles.length) {
            return;
        }
        updateSourceFiles(sourceFilesToUpload, {
            isUploaded: false,
            isUploadFiled: false,
            isUploadStarted: true,
            percentLoaded: 0
        });
        const folderName = getFolderName();
        const fileNames = sourceFilesToUpload.map(
            (f) => folderName + '/' + f.file.name
        );

        try {
            const presignedDataUrls = await Backend.getUploadUrls(
                tenantId,
                fileNames,
                userEmail
            );
            presignedDataUrls.data.forEach((presignedData: any) => {
                const sourceFile = sourceFilesRef.current.find((f) =>
                    presignedData.fields['key'].toString().endsWith(f.file.name)
                );
                if (sourceFile) {
                    uploadFile(sourceFile, presignedData);
                }
            });
        } catch (ex: any) {
            console.error('Failed to upload files', ex.message);
            window.sentry.log(ex);
            updateSourceFiles(sourceFilesToUpload, {
                isUploadFiled: true
            });
        }
    };

    return {
        uploadFiles,
        reuploadFile,
        deleteUploadedFile
    };
};

export const useSelectedPersonasForIngest = () => {
    const {
        selectedPersonasForIngest,
        setSelectedPersonasForIngest,
        personasManagementRows
    } = useDataManagementContext();

    const selectedPersonasForIngestIds = selectedPersonasForIngest.map(
        (persona) => persona.Id
    );
    const addDefaultPersona = () => {
        if (selectedPersonasForIngest.length === 0) {
            const defaultPersona = personasManagementRows.find(
                (persona) => persona.name === PERSONA_CONSTANT_NAMES.DEFAULT
            );
            setSelectedPersonasForIngest([
                { value: PERSONA_CONSTANT_NAMES.DEFAULT, Id: defaultPersona.id }
            ]);
        }
    };

    return {
        selectedPersonasForIngest,
        setSelectedPersonasForIngest,
        selectedPersonasForIngestIds,
        addDefaultPersona
    };
};
