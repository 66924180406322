import { FC } from 'react';
import { useSelector } from 'react-redux';
import { MAIN_LOGO_MAX_HEIGHT } from '../../consts';
import { useTranslation } from 'react-i18next';
import ScTitleWithAquantLogo from '../ScTitleWithAquantLogo/ScTitleWithAquantLogo';

const FirstRequestPanelLogo: FC = () => {
    const { t } = useTranslation();
    const { isUsingCustomLogo } = useSelector(
        (state: any) => state.globalReducer
    );
    const { scopMainLogo } = useSelector((state: any) => state.logoReducer);
    return (
        <div>
            {isUsingCustomLogo && scopMainLogo ? (
                <img
                    src={scopMainLogo}
                    alt="Custom Logo"
                    style={{
                        maxHeight: MAIN_LOGO_MAX_HEIGHT,
                        marginBottom: '46px'
                    }}
                />
            ) : (
                <div>
                    <ScTitleWithAquantLogo />
                    <p
                        className="first-request__heading"
                        data-testid="first-request-page-heading-text"
                    >
                        {t('search.sub-label')}
                    </p>
                </div>
            )}
        </div>
    );
};

export default FirstRequestPanelLogo;
