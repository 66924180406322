// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import noop from 'lodash/noop';
import Backend from '../backend/Backend';

const useTaskStatusInterval = ({
    tenantId,
    successCallback = noop,
    failureCallback = noop
}) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [taskId, setTaskId] = useState<string>('');
    const [taskEnded, setTaskEnded] = useState<boolean>(false);

    useEffect(() => {
        let taskStatusInterval;

        if (taskId) {
            taskStatusInterval = setInterval(async () => {
                const response = await Backend.getTaskStatus(tenantId, taskId);
                if (response.data.status === 'Done') {
                    clearInterval(taskStatusInterval);
                    setIsSubmitting(false);
                    setTaskEnded(true);
                    successCallback();
                } else if (response.data.status === 'Error') {
                    clearInterval(taskStatusInterval);
                    setIsSubmitting(false);
                    setTaskEnded(true);
                    failureCallback();
                }
            }, 7500);
        }

        return () => clearInterval(taskStatusInterval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setIsSubmitting, taskId]);

    return {
        setTaskId,
        isSubmitting,
        setIsSubmitting,
        taskEnded,
        setTaskEnded
    };
};

export default useTaskStatusInterval;
