const SparklesLarge = (props) => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.9999 8.16602C14.2519 8.16602 14.4753 8.32776 14.554 8.56709L15.7933 12.3348L15.7933 12.3348C15.8282 12.4408 15.8875 12.5372 15.9664 12.6162C16.0454 12.6951 16.1418 12.7544 16.2478 12.7893L16.2478 12.7893L20.0155 14.0286C20.2548 14.1073 20.4166 14.3307 20.4166 14.5827C20.4166 14.8346 20.2548 15.0581 20.0155 15.1368L16.2478 16.3761L16.2478 16.3761C16.1418 16.411 16.0454 16.4703 15.9664 16.5492C15.8875 16.6281 15.8282 16.7245 15.7933 16.8306L15.7933 16.8306L14.554 20.5983C14.4753 20.8376 14.2519 20.9993 13.9999 20.9993C13.748 20.9993 13.5245 20.8376 13.4458 20.5983L12.2065 16.8306L12.2065 16.8306C12.1716 16.7245 12.1123 16.6281 12.0334 16.5492C11.9545 16.4703 11.8581 16.411 11.752 16.3761L11.752 16.3761L7.98432 15.1368C7.745 15.0581 7.58325 14.8346 7.58325 14.5827C7.58325 14.3307 7.745 14.1073 7.98432 14.0286L11.752 12.7893L11.752 12.7893C11.8581 12.7544 11.9545 12.6951 12.0334 12.6162C12.1123 12.5372 12.1716 12.4408 12.2065 12.3348L12.2065 12.3348L13.4458 8.56709C13.5245 8.32776 13.748 8.16602 13.9999 8.16602ZM13.3148 12.6993C13.3148 12.6993 13.3148 12.6993 13.3148 12.6993C13.2228 12.9789 13.0665 13.233 12.8584 13.4411C12.6502 13.6492 12.3961 13.8056 12.1165 13.8976C12.1165 13.8976 12.1165 13.8976 12.1165 13.8976L10.0335 14.5827L12.1165 15.2678C12.1165 15.2678 12.1165 15.2678 12.1165 15.2678C12.3961 15.3598 12.6502 15.5161 12.8584 15.7242C13.0665 15.9324 13.2228 16.1865 13.3148 16.4661C13.3148 16.4661 13.3148 16.4661 13.3148 16.4661L13.9999 18.5491L14.685 16.4661C14.685 16.4661 14.685 16.4661 14.685 16.4661C14.777 16.1865 14.9334 15.9324 15.1415 15.7242C15.3496 15.5161 15.6037 15.3598 15.8833 15.2678C15.8833 15.2678 15.8833 15.2678 15.8833 15.2678L17.9663 14.5827L15.8833 13.8976C15.8833 13.8976 15.8833 13.8976 15.8833 13.8976C15.6037 13.8056 15.3496 13.6492 15.1415 13.4411C14.9334 13.233 14.777 12.9789 14.685 12.6993C14.685 12.6993 14.685 12.6993 14.685 12.6993L13.9999 10.6163L13.3148 12.6993Z"
                fill="currentcolor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.91659 8.16732C9.91659 7.84515 9.65542 7.58398 9.33325 7.58398C9.01109 7.58398 8.74992 7.84515 8.74992 8.16732V8.75065H8.16659C7.84442 8.75065 7.58325 9.01182 7.58325 9.33398C7.58325 9.65615 7.84442 9.91732 8.16659 9.91732H8.74992V10.5007C8.74992 10.8228 9.01109 11.084 9.33325 11.084C9.65542 11.084 9.91659 10.8228 9.91659 10.5007V9.91732H10.4999C10.8221 9.91732 11.0833 9.65615 11.0833 9.33398C11.0833 9.01182 10.8221 8.75065 10.4999 8.75065H9.91659V8.16732Z"
                fill="currentcolor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.2501 17.4993C19.2501 17.1772 18.9889 16.916 18.6667 16.916C18.3446 16.916 18.0834 17.1772 18.0834 17.4993V18.0827H17.5001C17.1779 18.0827 16.9167 18.3439 16.9167 18.666C16.9167 18.9882 17.1779 19.2493 17.5001 19.2493H18.0834V19.8327C18.0834 20.1548 18.3446 20.416 18.6667 20.416C18.9889 20.416 19.2501 20.1548 19.2501 19.8327V19.2493H19.8334C20.1556 19.2493 20.4167 18.9882 20.4167 18.666C20.4167 18.3439 20.1556 18.0827 19.8334 18.0827H19.2501V17.4993Z"
                fill="currentcolor"
            />
        </svg>
    );
};

export default SparklesLarge;
