import classnames from 'classnames/bind';
import { TrashIcon } from 'aqui/icons';

import styles from './TableFloatingMenu.module.scss';
import FloatingMenu from '../../Common/FloatingMenu/FloatingMenu';
import LabelsMenuItem from './TableMenuItem';
import FloatingMenuItem from '../../Common/FloatingMenu/FloatingMenuItem';
import { t } from 'i18next';
import { ReactNode } from 'react';
const cx = classnames.bind(styles);

interface TableFloatingMenuProps {
    children: ReactNode;
    viewRows: any[];
    setSelectedRows: (rows: any[]) => void;
    selectedRows: any[];
    showDelete: boolean;
    onDelete?: () => void;
    isDeleteDisabled?: boolean;
}

const TableFloatingMenu: React.FC<TableFloatingMenuProps> = ({
    children,
    viewRows,
    setSelectedRows,
    selectedRows,
    showDelete,
    onDelete,
    isDeleteDisabled = false
}) => {
    const shouldShowFloatingMenu = !!selectedRows?.length;

    return (
        shouldShowFloatingMenu && (
            <FloatingMenu withTransition>
                <LabelsMenuItem
                    viewRows={viewRows}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                />
                {children}
                {showDelete && onDelete && (
                    <FloatingMenuItem
                        dataTestId="floatingMenuActionDelete"
                        onClick={onDelete}
                        className={cx('view-floating-menu__delete')}
                        disabled={isDeleteDisabled}
                        tooltipTitle={
                            isDeleteDisabled
                                ? t(
                                      'data-management.table-action-disabled-tooltip'
                                  )
                                : t('common.delete')
                        }
                    >
                        <TrashIcon />
                    </FloatingMenuItem>
                )}
            </FloatingMenu>
        )
    );
};

export default TableFloatingMenu;
