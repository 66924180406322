import { FunctionComponent, useEffect } from 'react';
import { t } from 'i18next';
import { Typography } from 'aqui';
import DragAndDropArea from './DragAndDropArea';
import UploadFilesModalFileRow from './UploadFilesModalFileRow';
import {
    useSelectedPersonasForIngest,
    useSourceFiles,
    useUploadFiles
} from './UploadFilesModalHooks';
import UploadModelSelect from './UploadModelSelect';
import PersonasSelection from '../DataManagementPanel/IngestedSources/PersonasSelection/PersonasSelection';
import { useIsPersonasEnabled } from '../../context/DataManagementContext';

const UploadFilesModalBody: FunctionComponent = () => {
    const { selectedPersonasForIngest, setSelectedPersonasForIngest } =
        useSelectedPersonasForIngest();
    const { sourceFiles, validSourceFiles } = useSourceFiles();
    const { uploadFiles } = useUploadFiles();
    const { isPersonasEnabled } = useIsPersonasEnabled();

    useEffect(() => {
        if (
            !sourceFiles.length ||
            sourceFiles.some((sourceFile) => !sourceFile.validationType)
        ) {
            return;
        }
        const filesToUpload = validSourceFiles.filter(
            (model) => !model.isUploadStarted
        );
        if (filesToUpload.length) {
            uploadFiles(filesToUpload);
        }
    }, [sourceFiles]);

    const isPersonaDisabled = !!sourceFiles.length;

    return (
        <div className="upload-files__body">
            <Typography type="small1">
                {t('upload-files-modal.relevant-model')}
            </Typography>
            <UploadModelSelect />
            {isPersonasEnabled && (
                <div className="flex flex-col upload-files__persona">
                    <Typography type="small1">
                        {t('add-persona.persona')}
                    </Typography>
                    <PersonasSelection
                        selectedItems={selectedPersonasForIngest}
                        setSelectedItems={setSelectedPersonasForIngest}
                        disabled={isPersonaDisabled}
                    />
                </div>
            )}
            <DragAndDropArea />
            <div
                className="upload-files__files"
                data-testid="upload-files-file-list"
            >
                {sourceFiles.map((sourceFile, i) => (
                    <UploadFilesModalFileRow
                        key={sourceFile.file.name + i}
                        sourceFile={sourceFile}
                    />
                ))}
            </div>
        </div>
    );
};

export default UploadFilesModalBody;
