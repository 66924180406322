import { FilterSelect as AquiFilterSelect, Typography } from 'aqui';
import { t } from 'i18next';
import cl from 'classnames';
import { useMemo } from 'react';

interface itemObject {
    value: string;
    display: string;
}
interface FilterSelectProps {
    items: string[] | itemObject[];
    selectedItem: string;
    allFilterLabel?: string;
    onChange: (item: any) => void;
    dataTestId?: string;
    processing?: boolean;
    disabled?: boolean;
}

const FilterSelect: React.FC<FilterSelectProps> = ({
    items = [],
    selectedItem = '',
    allFilterLabel = t('common.all'),
    onChange,
    dataTestId,
    disabled,
    processing
}) => {
    const displayItemMap = useMemo(() => {
        const map = {};
        items.forEach((item) => {
            if (typeof item === 'string') {
                map[item] = item;
            } else {
                map[item.value] = item.display || item.value;
            }
        });
        return map;
    }, [items]);

    const onFilterChange = (filter: string) => {
        const key = Object.keys(displayItemMap).find(
            (key) => displayItemMap[key] === filter
        );
        onChange(key);
    };

    const selectedItemDisplay = useMemo(() => {
        if (!selectedItem) {
            return allFilterLabel;
        }
        if (items.length && typeof items[0] === 'string') {
            return selectedItem;
        }
        const objectItems = items as itemObject[];
        const selectedItemObj = objectItems.find(
            (item) => typeof item !== 'string' && item.value === selectedItem
        );
        return selectedItemObj?.display || selectedItem;
    }, [selectedItem, items, allFilterLabel]);

    return (
        <AquiFilterSelect
            dataTestId={dataTestId}
            filters={Object.values(displayItemMap)}
            filter={displayItemMap[selectedItem]}
            className={cl('data-management__filter', {
                '--active': selectedItem,
                '--disabled': disabled,
                '--processing': processing
            })}
            disabled={disabled}
            processing={processing}
            onChange={onFilterChange}
            allFilterPosition="top"
            allFilterTitle={t('common.all')}
        >
            <Typography type="small1">{selectedItemDisplay}</Typography>
        </AquiFilterSelect>
    );
};

export default FilterSelect;
