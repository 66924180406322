import { FunctionComponent, useEffect } from 'react';
import ArrowIcon from '../../../SVG/ArrowIcon';
import { SPECIAL_DIV_IDS } from '../../../consts';
import {
    useClickedMessagesNavigation,
    useThreadsHandling
} from '../../../AppContext';
import { SmallWindowState } from '../../../types';
import { useChatWindowState } from '../../../context/ChatContext';

type ScrollToButtonProps = {
    typingFocused: boolean;
    setTypingFocused: Function;
    missedMessagesRelativeHight: number[];
    setMissedMessagesCounterRelativeHight: Function;
    isSmallChat: boolean;
};

const ScrollToButton: FunctionComponent<ScrollToButtonProps> = ({
    typingFocused,
    setTypingFocused,
    missedMessagesRelativeHight,
    setMissedMessagesCounterRelativeHight,
    isSmallChat
}) => {
    const { clickedMessagesNavigation, setClickedMessagesNavigation } =
        useClickedMessagesNavigation();
    const { isChatHistoryLoaded } = useThreadsHandling();

    const { chatWindowState } = useChatWindowState();

    useEffect(() => {
        if (clickedMessagesNavigation) {
            handleScroll();
            setClickedMessagesNavigation(false);
        }
    }, [clickedMessagesNavigation]);

    useEffect(() => {
        if (isChatHistoryLoaded) {
            handleScroll();
        }
    }, [isChatHistoryLoaded]);

    useEffect(() => {
        const objDiv = document.getElementById(SPECIAL_DIV_IDS.CHAT);
        objDiv?.addEventListener('scroll', handleScroll, false);
        return () => objDiv?.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScroll = () => {
        const objDiv = document.getElementById(SPECIAL_DIV_IDS.CHAT);
        if (!objDiv) {
            return;
        }
        const distanceFromTop = objDiv.clientHeight + objDiv.scrollTop;
        if (missedMessagesRelativeHight.length) {
            const arrWithoutValue = missedMessagesRelativeHight.filter(
                (value) => value >= distanceFromTop
            );
            if (arrWithoutValue.length !== missedMessagesRelativeHight.length) {
                setMissedMessagesCounterRelativeHight(arrWithoutValue);
            }
        }
        if (objDiv.scrollHeight - objDiv.scrollTop <= objDiv.clientHeight) {
            setTypingFocused(true);
            setMissedMessagesCounterRelativeHight([]);
        } else if (typingFocused) {
            setTypingFocused(false);
        }
    };

    const handleClick = () => {
        const objDiv = document.getElementById(SPECIAL_DIV_IDS.CHAT);
        objDiv.scrollTo({ top: objDiv.scrollHeight, behavior: 'smooth' });
    };

    const checkIfOverflows = () => {
        const objDiv = document.getElementById(SPECIAL_DIV_IDS.CHAT);
        if (objDiv) {
            return objDiv.scrollHeight > objDiv.clientHeight;
        }
        return false;
    };

    if (
        typingFocused ||
        !checkIfOverflows() ||
        (isSmallChat && chatWindowState !== SmallWindowState.Maximized)
    ) {
        return <></>;
    }
    return (
        <div
            onClick={handleClick}
            className={`scrollToButton__container ${
                isSmallChat ? 'smallChat' : ''
            } absolute clickable`}
        >
            <div className="scrollToButton__button flex align-center justify-center">
                {missedMessagesRelativeHight.length > 0 && (
                    <div className="scrollToButton__counter absolute flex align-center justify-center">
                        {missedMessagesRelativeHight.length}
                    </div>
                )}
                <ArrowIcon />
            </div>
        </div>
    );
};

export default ScrollToButton;
