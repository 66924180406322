import React from 'react';
const SparklesMedium = ({ color = '#2468FF' }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 7C12.2159 7 12.4075 7.13864 12.475 7.34377L13.5372 10.5732L13.5372 10.5732C13.5671 10.6641 13.6179 10.7467 13.6856 10.8144C13.7533 10.8821 13.8359 10.9329 13.9268 10.9628L13.9268 10.9628L17.1562 12.025C17.3614 12.0925 17.5 12.2841 17.5 12.5C17.5 12.7159 17.3614 12.9075 17.1562 12.975L13.9268 14.0372L13.9268 14.0372C13.8359 14.0671 13.7533 14.1179 13.6856 14.1856C13.6179 14.2533 13.5671 14.3359 13.5372 14.4268L13.5372 14.4268L12.475 17.6562C12.4075 17.8614 12.2159 18 12 18C11.7841 18 11.5925 17.8614 11.525 17.6562L10.4628 14.4268L10.4628 14.4268C10.4329 14.3359 10.3821 14.2533 10.3144 14.1856C10.2467 14.1179 10.1641 14.0671 10.0732 14.0372L10.0732 14.0372L6.84377 12.975C6.63864 12.9075 6.5 12.7159 6.5 12.5C6.5 12.2841 6.63864 12.0925 6.84377 12.025L10.0732 10.9628L10.0732 10.9628C10.1641 10.9329 10.2467 10.8821 10.3144 10.8144C10.3821 10.7467 10.4329 10.6641 10.4628 10.5732L10.4628 10.5732L11.525 7.34377C11.5925 7.13864 11.7841 7 12 7ZM11.4127 10.8857C11.4127 10.8857 11.4127 10.8857 11.4127 10.8857C11.3339 11.1253 11.1999 11.3431 11.0215 11.5215C10.8431 11.6999 10.6253 11.8339 10.3857 11.9127C10.3857 11.9127 10.3857 11.9127 10.3857 11.9127L8.60025 12.5L10.3857 13.0873C10.3857 13.0873 10.3857 13.0873 10.3857 13.0873C10.6253 13.1661 10.8431 13.3001 11.0215 13.4785C11.1999 13.6569 11.3339 13.8747 11.4127 14.1143C11.4127 14.1143 11.4127 14.1143 11.4127 14.1143L12 15.8997L12.5873 14.1143C12.5873 14.1143 12.5873 14.1143 12.5873 14.1143C12.6661 13.8747 12.8001 13.6569 12.9785 13.4785C13.1569 13.3001 13.3747 13.1661 13.6143 13.0873C13.6143 13.0873 13.6143 13.0873 13.6143 13.0873L15.3997 12.5L13.6143 11.9127C13.6143 11.9127 13.6143 11.9127 13.6143 11.9127C13.3747 11.8339 13.1569 11.6999 12.9785 11.5215C12.8001 11.3431 12.6661 11.1253 12.5873 10.8857C12.5873 10.8857 12.5873 10.8857 12.5873 10.8857L12 9.10025L11.4127 10.8857Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.5 7C8.5 6.72386 8.27614 6.5 8 6.5C7.72386 6.5 7.5 6.72386 7.5 7V7.5H7C6.72386 7.5 6.5 7.72386 6.5 8C6.5 8.27614 6.72386 8.5 7 8.5H7.5V9C7.5 9.27614 7.72386 9.5 8 9.5C8.27614 9.5 8.5 9.27614 8.5 9V8.5H9C9.27614 8.5 9.5 8.27614 9.5 8C9.5 7.72386 9.27614 7.5 9 7.5H8.5V7Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.5 15C16.5 14.7239 16.2761 14.5 16 14.5C15.7239 14.5 15.5 14.7239 15.5 15V15.5H15C14.7239 15.5 14.5 15.7239 14.5 16C14.5 16.2761 14.7239 16.5 15 16.5H15.5V17C15.5 17.2761 15.7239 17.5 16 17.5C16.2761 17.5 16.5 17.2761 16.5 17V16.5H17C17.2761 16.5 17.5 16.2761 17.5 16C17.5 15.7239 17.2761 15.5 17 15.5H16.5V15Z"
                fill={color}
            />
        </svg>
    );
};

export default SparklesMedium;
