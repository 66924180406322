const CircleCheckMarkIcon = ({ color = 'currentColor', ...props }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M4.5 11.25C4.5 7.52208 7.52208 4.5 11.25 4.5C12.1552 4.5 13.0175 4.67789 13.8049 5.00015C14.0605 5.10475 14.3525 4.98236 14.4571 4.72679C14.5617 4.47122 14.4393 4.17926 14.1837 4.07466C13.2781 3.70405 12.2873 3.5 11.25 3.5C6.96979 3.5 3.5 6.96979 3.5 11.25C3.5 15.5302 6.96979 19 11.25 19C15.5302 19 19 15.5302 19 11.25C19 10.3451 18.8447 9.47536 18.5589 8.66671C18.4669 8.40635 18.1812 8.26989 17.9208 8.36191C17.6605 8.45394 17.524 8.7396 17.616 8.99996C17.8646 9.70307 18 10.4602 18 11.25C18 14.9779 14.9779 18 11.25 18C7.52208 18 4.5 14.9779 4.5 11.25Z"
                fill={color}
            />
            <path
                d="M20.1737 4.35159C20.3679 4.15526 20.3661 3.83868 20.1698 3.6445C19.9735 3.45032 19.6569 3.45207 19.4627 3.64841L10.6922 12.5164L8.3099 10.1101C8.11562 9.91384 7.79904 9.91225 7.6028 10.1065C7.40656 10.3008 7.40497 10.6174 7.59925 10.8136L10.337 13.579C10.431 13.6739 10.5589 13.7273 10.6925 13.7273C10.826 13.7272 10.9539 13.6738 11.0478 13.5789L20.1737 4.35159Z"
                fill={color}
            />
        </svg>
    );
};

export default CircleCheckMarkIcon;
