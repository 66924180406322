import React, { useMemo } from 'react';
import { getMediaTypeIcon } from './UploadFilesModalHelpers';
import { t } from 'i18next';
import { FileValidation, SourceFile } from '../../types';
import ValueWithTooltip from '../ValueWithTooltip/ValueWithTooltip';

import { TrashIcon } from 'aqui/icons';
import { Tooltip } from '@material-ui/core';

interface UploadFileFileRowNameProps {
    onDelete: () => void;
    sourceFile: SourceFile;
    isDeleteDisabled: boolean;
}

const UploadFileFileRowName: React.FC<UploadFileFileRowNameProps> = ({
    onDelete,
    sourceFile,
    isDeleteDisabled
}) => {
    const { validationType, isUploadFiled, isDeleting, file } = sourceFile;
    const isInvalid = validationType !== FileValidation.Valid || isUploadFiled;

    const MediaTypeIcon = useMemo(() => getMediaTypeIcon(file.type), [file]);

    return (
        <div
            className={`upload-files__file__data ${
                isInvalid ? '--invalid' : ''
            }`}
        >
            <MediaTypeIcon
                className="upload-files__file__media-type"
                data-testid="upload-files-file-media-type"
            />
            <ValueWithTooltip
                title={file.name}
                dataTestId="upload-files-file-name"
                type="small1Bold"
                className="upload-files__file__name"
            />
            <Tooltip
                title={isDeleteDisabled ? '' : t('common.delete')}
                classes={{
                    tooltip: 'tooltip tooltip__close-top'
                }}
                placement="top"
            >
                <TrashIcon
                    className={`upload-files__file__delete ${
                        isDeleteDisabled ? '--disabled' : ''
                    } ${isDeleting ? '--progressing' : ''}`}
                    onClick={onDelete}
                    dataTestId="upload-files-file-delete-button"
                />
            </Tooltip>
        </div>
    );
};

export default UploadFileFileRowName;
