import { HTMLAttributes, ReactNode, forwardRef, useState } from 'react';
import useControlledState from '../../hooks/useControlledState';
import { ClickAwayListener, Popper } from '@material-ui/core';
import { SearchInput } from 'aqui';
import { combineRefs } from '../../utils/componentUtils';
import classnames from 'classnames/bind';
import {
    ChevronIcon,
    ThreeDotsLoaderIcon,
    MagnifyingGlassIcon
} from 'aqui/icons';

import styles from './SearchSelect.module.scss';
import SelectMenu from '../FilterSelect/SelectMenu';
const cx = classnames.bind(styles);

type SearchSelectClasses = {
    search?: string;
    popover?: string;
    menu?: string;
};

interface SearchSelectProps extends HTMLAttributes<HTMLDivElement> {
    values: string[];
    value: string;
    disabled?: boolean;
    dataTestId?: string;
    setValue: (value: string) => void;
    processing?: boolean;
    headerText?: string;
    isOpen: boolean;
    setIsOpen: Function;
    showFooterMessage?: boolean;
    footerMessageText?: string;
    searchPlaceholder?: string;
    searchText?: string;
    setSearchText?: Function;
    enableFlip?: boolean;
    classes?: SearchSelectClasses;
    onClearButtonClick?: () => void;
    StaticBottomRow?: ReactNode;
    noValuesMessage?: string;
    shouldShowStaticBottomRow?: boolean;
    leftIcon?: ReactNode;
}

const SearchSelect = forwardRef<HTMLDivElement, SearchSelectProps>(
    (
        {
            values,
            value,
            disabled,
            dataTestId = '',
            setValue,
            onClick,
            processing = false,
            headerText,
            isOpen,
            setIsOpen,
            showFooterMessage = false,
            footerMessageText = '',
            searchPlaceholder = '',
            searchText = '',
            setSearchText = () => {},
            enableFlip = false,
            classes,
            onClearButtonClick,
            StaticBottomRow,
            noValuesMessage,
            shouldShowStaticBottomRow,
            leftIcon = MagnifyingGlassIcon,
            ...rest
        },
        ref
    ) => {
        const [anchorEl, setAnchorEl] = useState<null | Element>(null);

        const [currentValue, setCurrentValue] = useControlledState([
            value,
            setValue
        ]);

        const handleClick = (e: any) => {
            if (disabled || processing) {
                return;
            }
            onClick?.(e);
        };

        const closeHandler = () => setIsOpen(false);
        const openHandler = () => setIsOpen(true);

        const onChangeValue = (value: string) => {
            setCurrentValue(value);
            setIsOpen(false);
        };

        const onChevronClick = (e) => {
            if (disabled || processing || !values.length) {
                return;
            }
            e.stopPropagation();
            setIsOpen(!isOpen);
        };

        return (
            <ClickAwayListener onClickAway={closeHandler}>
                <div
                    data-testid={dataTestId}
                    onClick={handleClick}
                    ref={combineRefs(setAnchorEl, ref)}
                    {...rest}
                >
                    <SearchInput
                        placeholder={searchPlaceholder}
                        value={searchText}
                        setValue={setSearchText}
                        className={`${cx('search')} ${classes?.search}`}
                        onClick={openHandler}
                        onChange={openHandler}
                        disabled={disabled}
                        onClearButtonClick={onClearButtonClick}
                        LeftIcon={leftIcon}
                        RightIcon={
                            processing ? (
                                <ThreeDotsLoaderIcon />
                            ) : (
                                <ChevronIcon
                                    className={cx(
                                        'chevron',
                                        isOpen && '--open'
                                    )}
                                    direction="down"
                                    onClick={onChevronClick}
                                />
                            )
                        }
                    />
                    <Popper
                        anchorEl={anchorEl}
                        open={isOpen}
                        transition
                        className={`popoverBox__root ${classes?.popover}`}
                        placement="bottom-start"
                        modifiers={{
                            flip: {
                                enabled: enableFlip
                            }
                        }}
                    >
                        <SelectMenu
                            value={currentValue}
                            values={values}
                            onRowClick={onChangeValue}
                            disabled={disabled || processing}
                            showFooterMessage={showFooterMessage}
                            footerMessageText={footerMessageText}
                            className={classes?.menu}
                            StaticBottomRow={StaticBottomRow}
                            noValuesMessage={noValuesMessage}
                            shouldShowStaticBottomRow={
                                shouldShowStaticBottomRow
                            }
                        />
                    </Popper>
                </div>
            </ClickAwayListener>
        );
    }
);

export default SearchSelect;
