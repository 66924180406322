import { FunctionComponent } from 'react';
import SourceModal from '../SourceModal/SourceModal';
import UploadFilesModal from '../UploadFilesModal/UploadFilesModal';
import IngestModal from '../DataManagementPanel/IngestedSources/IngestModal/IngestModal';
import AddUsersModal from '../DataManagementPanel/PersonasManagement/PersonasManagementModals/AddUsersModal';
import PersonaSourcesModal from '../DataManagementPanel/PersonasManagement/PersonasManagementModals/PersonaSourcesModal';

const ModalBox: FunctionComponent = () => {
    return (
        <>
            <SourceModal />
            <UploadFilesModal />
            <IngestModal />
            <AddUsersModal />
            <PersonaSourcesModal />
        </>
    );
};

export default ModalBox;
