import Papa from 'papaparse';

export const getFileNameAndType = (text) => {
    const arr = text.split('.');
    return [arr.pop(), arr.join('.')];
};

export const onExportFile = (data, fileName) => {
    const unparsedData = Papa.unparse(data);
    const element = document.createElement('a');
    createDownloadLink(element, unparsedData, fileName);
};

const createDownloadLink = (element, data, fileName) => {
    const csvData = new Blob([data], { type: 'text/csv;charset=utf-8;' });

    if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(csvData, fileName);
    } else {
        element.href = window.URL.createObjectURL(csvData);
        element.download = fileName;
        element.click();
    }
};

export const onImportFile = (onImportSuccessful) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = () => {
        onChangeInput(input.files, onImportSuccessful);
    };

    document.body.appendChild(input);
    input.click();
    document.body.removeChild(input);
};

const onChangeInput = async (inputFiles, onImportSuccessful) => {
    let files = Array.from(inputFiles);
    const file = files[0];
    Papa.parse(file, {
        header: true,
        transform: (value) => {
            if (value.toLowerCase() === 'true') {
                return true;
            } else if (value.toLowerCase() === 'false') {
                return false;
            } else {
                return value;
            }
        },
        complete: (results) => {
            onImportSuccessful(results.data);
        }
    });
};
